"use strict";

/*global Kobo,ko,$,DynamicConfiguration*/

/**
 * Class Kobo.ViewModel.UserRewardProductInfo
 * @param product
 * @constructor
 */
Kobo.ViewModel.UserRewardProductInfo = function () {
  "use strict";

  var self = this;
  self.title = ko.observable('');
  self.subtitle = ko.observable('');
  self.imageurl = ko.observable('');
  self.synopsis = ko.observable('');

  self.update = function (productInfo) {
    if (productInfo) {
      self.title(productInfo.title);
      self.subtitle(productInfo.subtitle);
      self.imageurl(productInfo.imageurl);
      var parsedSynopsis = Kobo.$.parseHTML(productInfo.synopsis);
      self.synopsis(parsedSynopsis && parsedSynopsis.length > 0 ? parsedSynopsis[0].data : "");
    }
  };
};