"use strict";

/*globals Kobo,ko,el*/
Kobo.Gizmo.SavePaymentMethodCreditCard = function (el, options) {
  "use strict";

  var self = this,
      viewModel = {},
      setupViewModel,
      paymentInfo,
      removePaymentMethod,
      savePaymentInfo,
      setupTriggers,
      saveButton,
      changeCaptchaSpacing,
      setupCaptchaSpacing,
      note,
      captchaMargin,
      returnUrl,
      getRecaptcha,
      init,
      hasSavedPaymentMethods,
      strings = window.DynamicConfiguration.resourceStrings.paymentInformation,
      errorMsgBox = el.querySelector('#error-message-box'),
      showNotValidFieldsMessage,
      showErrorMsg,
      savePaymentRequest,
      config,
      deviceDataString;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("SavePaymentMethodCreditCard");

  removePaymentMethod = function removePaymentMethod(paymentMethodId) {
    Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
    Kobo.$.ajax({
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      url: options.RemovePaymentMethodUrl,
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        id: paymentMethodId
      }),
      success: function success(data) {
        if (data.result === 'success') {
          viewModel.showMsgBox(false);
          Kobo.Utilities.reload();
        } else {
          viewModel.isSaveSuccess(false);
          viewModel.showMsgBox(true);
          viewModel.paymentMethodMsg(options.RemovalError);
          Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
        }
      },
      error: function error(_error) {
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
        showErrorMsg(options.RemovalError);
        throw new Error('Error removing payment method: ' + _error + ' with PaymentMethodId ' + paymentMethodId);
      }
    });
  };

  savePaymentRequest = function savePaymentRequest(req, captchaToken) {
    var param = {
      paymentNonce: options.BraintreeClientToken.indexOf('sandbox_') !== -1 ? req.details.bin + '-' + req.details.lastFour + '-' + req.details.cardType + '-' + req.details.expirationMonth + '-' + req.details.expirationYear : req.paymentNonce,
      cardholderName: req.cardholderName,
      deviceData: deviceDataString,
      source: options.Source,
      improperRecaptcha: options.ImproperRecaptcha,
      billingAddressRequired: options.BillingAddressRequired,
      paymentIssue_ChooseAnotherPaymentMethod: options.PaymentIssue_ChooseAnotherPaymentMethod,
      notAbleToAcceptAmexErrorMessage: options.NotAbleToAcceptAmexErrorMessage,
      savedSuccessfullyMsg: options.SavedSuccessfullyMsg
    };

    if (viewModel.isNewUser()) {
      showErrorMsg(options.BillingAddressRequired);
    } else {
      Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
      Kobo.$.ajax({
        type: 'POST',
        headers: {
          'g-recaptcha-response': captchaToken,
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        url: options.SavePaymentMethodUrl + options.AdditionalQueryParameter,
        data: JSON.stringify(param),
        success: function success(result) {
          if (result.Message) {
            viewModel.paymentMethodMsg(result.Message);
          }

          if (result.IsSuccess) {
            viewModel.isSaveSuccess(true);
            setTimeout(function () {
              if (returnUrl != null && returnUrl.length > 0) {
                Kobo.Utilities.navigateTo(returnUrl);
              } else {
                history.go(-1);
              }
            }, 500);
          } else {
            showErrorMsg(result.Message || strings.PaymentIssue_ChooseAnotherPaymentMethod);
          }

          viewModel.showMsgBox(true);
          Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
        },
        error: function error(_error2) {
          showErrorMsg(strings.PaymentIssue_ChooseAnotherPaymentMethod);
          Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
          throw new Error('Error saving payment method: ' + _error2);
        }
      });
    }

    window.grecaptcha.reset();
  };

  getRecaptcha = function getRecaptcha() {
    return window.grecaptcha.getResponse();
  };

  savePaymentInfo = function savePaymentInfo() {
    Kobo._mediator.fire('braintreeHostedFields::validate', {
      validatedCallback: _onHostedFieldsValidated
    });

    function _onHostedFieldsValidated() {
      if (!viewModel.braintreeValidationVM().isPaymentValid()) {
        Kobo._mediator.fire('braintreeHostedFields::submittedWithInvalidFields');

        showErrorMsg(viewModel.braintreeValidationVM().creditCardInvalidMsg());
        return;
      }

      Kobo._mediator.fire('braintreeCard::validate');

      viewModel.isChangingPaymentInfo(false);
    }
  };

  hasSavedPaymentMethods = function hasSavedPaymentMethods() {
    return options.PaymentMethods.length > 0;
  };

  showErrorMsg = function showErrorMsg(msg) {
    errorMsgBox.classList.remove('error-msg-disappear');
    viewModel.isSaveSuccess(false);
    viewModel.showMsgBox(true);
    viewModel.paymentMethodMsg(msg);
    setTimeout(function () {
      errorMsgBox.classList.add('error-msg-disappear');
      setTimeout(function () {
        viewModel.showMsgBox(false);
        errorMsgBox.classList.remove('error-msg-disappear');
      }, 1000);
    }, 2000);
  };

  showNotValidFieldsMessage = function showNotValidFieldsMessage(_ref) {
    var field = _ref.field,
        isValid = _ref.isValid,
        setErrorMessageForScreenReader = _ref.setErrorMessageForScreenReader;

    switch (field) {
      case 'number':
        viewModel.braintreeValidationVM().isCreditCardValid(isValid);
        setErrorMessageForScreenReader(viewModel.braintreeValidationVM().creditCardInvalidLengthMsg());
        break;

      case 'cvv':
        viewModel.braintreeValidationVM().isCVVValid(isValid);
        setErrorMessageForScreenReader(viewModel.braintreeValidationVM().cvvInvalidMsg());
        break;

      case 'expirationMonth':
      case 'expirationYear':
        viewModel.braintreeValidationVM().isExpirationDateValid(isValid);
        setErrorMessageForScreenReader(viewModel.braintreeValidationVM().expiryDateInvalidMsg());
        break;

      default:
        throw new Error('Error field type: ' + field);
    }
  };

  setupViewModel = function setupViewModel() {
    viewModel.savePaymentInfo = function (data, event) {
      event.preventDefault();
      savePaymentInfo();
      return false;
    };

    viewModel.cancelBtnHandler = function (data, event) {
      event.preventDefault();

      Kobo._mediator.fire('braintreeCard::refreshIframe');

      if (viewModel.isChangingPaymentInfo()) {
        viewModel.isChangingPaymentInfo(false);
        viewModel.showSavePayments(false);
        viewModel.showChangeButton(true);
      }
    };

    viewModel.showMsgBox = ko.observable(false);
    viewModel.paymentMethodMsg = ko.observable('');
    viewModel.isSaveSuccess = ko.observable(false);

    viewModel.removePaymentMethod = function (paymentMethodId) {
      removePaymentMethod(paymentMethodId);
    };

    viewModel.cardHolderName = ko.observable('');
    viewModel.creditCardType = ko.observable('');
    viewModel.showSavePayments = ko.observable(!hasSavedPaymentMethods());
    viewModel.showChangeButton = ko.observable(hasSavedPaymentMethods());
    viewModel.isChangingPaymentInfo = ko.observable(false);

    viewModel.showChangePayment = function () {
      viewModel.isChangingPaymentInfo(true);
      viewModel.showSavePayments(true);
      viewModel.showChangeButton(false); // Temporary solution until we allow multiple payment methods

      viewModel.cardHolderName(options.PaymentMethods[0].Name);
    };

    viewModel.showActionContainer = ko.observable(false);

    viewModel.openActionMenu = function () {
      viewModel.showActionContainer(true);
      return false;
    };

    viewModel.closeActionMenu = function () {
      viewModel.showActionContainer(false);
      return false;
    };

    viewModel.isNewUser = ko.observable(options.IsNewUser);
    viewModel.braintreeValidationVM = ko.observable(new Kobo.PurchasePath.ViewModels.BraintreeValidationVM());
  };

  setupTriggers = function setupTriggers() {
    saveButton = Kobo.$(el).find('#savePaymentMethodCreditCardSaveButton');
    saveButton.on('click', function (e) {
      e.preventDefault();
      returnUrl = e.target.value;
      savePaymentInfo();
    });
  };

  changeCaptchaSpacing = function changeCaptchaSpacing() {
    if (window.innerWidth <= 568.2) {
      captchaMargin.addClass("captchaWithMargin");
    } else {
      captchaMargin.addClass("captchaNoMargin");
    }
  };

  setupCaptchaSpacing = function setupCaptchaSpacing() {
    note = $('#save-card-note');
    captchaMargin = $('.captcha-margin');

    if (note.length == 0 && captchaMargin.length) {
      changeCaptchaSpacing();
      $(window).resize(function () {
        changeCaptchaSpacing();
      });
    }
  };

  init = function init() {
    var element = self.el;
    setupViewModel();
    setupTriggers();
    setupCaptchaSpacing();
    var types = ["", "visa", "master-card", "discover", "american-express", "jcb", "diners"];
    var cardType;

    if (options.PaymentMethods.length > 0) {
      //below line will have to change when we have multiple payment types
      cardType = types[options.PaymentMethods[0].Type] || "";
    }

    config = {
      cardType: cardType,
      key: options.BraintreeClientToken,
      isOldPP: false,
      createFields: true,
      collectDeviceData: true,
      triggerBraintreeLocalPayment: false
    };
    paymentInfo = new Kobo.Purchasing.BraintreePayments(config);
    paymentInfo.collectDeviceDataEvent.subscribe(function (collectedDeviceData) {
      deviceDataString = collectedDeviceData;
    });
    paymentInfo.saveRequestEvent.subscribe(function (req) {
      try {
        var reCaptchaToken = getRecaptcha();
        savePaymentRequest(req, reCaptchaToken);
      } catch (error) {
        Kobo.log(error);
      }
    });
    paymentInfo.isValidEvent.subscribe(function (data) {
      try {
        showNotValidFieldsMessage(data);
      } catch (error) {
        Kobo.log(error);
      }
    });
    new Kobo.PurchasePath.Behaviors.Braintree.ValidateBraintreeFieldsBehavior(viewModel.braintreeValidationVM());
    self.viewModel = viewModel;
    ko.applyBindings(viewModel, element);
  };

  init();
};

Kobo.Gizmo.SavePaymentMethodCreditCard.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);