"use strict";

/*global Kobo,ko,$,DynamicConfiguration*/

/**
 * Class Kobo.ViewModel.UserRewardSelector
 * @param element - represents the element list of rewards
 * @param options
 * @param gizmo
 * @constructor
 */
Kobo.ViewModel.UserRewardSelector = function (element, options) {
  'use strict';

  var self = this,
      init;
  Kobo.ViewModel.Base.apply(self, [element, options]);
  self.setType('UserRewardSelector');
  self.selectedRewardIDs = [];
  self.MAX_SELECTIONS_ALLOWED = 1;

  self.updateCtaState = function (selectedReward) {
    var $selectedReward = Kobo.$(selectedReward),
        $rewardCta = $selectedReward.find('.' + options.btnSelector),
        selectButtonLabel = DynamicConfiguration.resourceStrings[options.selectedCtaResourceString],
        unselectButtonLabel = DynamicConfiguration.resourceStrings[options.unselectedCtaResourceString];

    if ($rewardCta.text() === unselectButtonLabel) {
      $rewardCta.text(selectButtonLabel);
    } else {
      $rewardCta.text(unselectButtonLabel);
    }

    $selectedReward.toggleClass(options.selectedSelector);
  };

  self.updateSelectedRewardIDs = function () {
    var i,
        selectedItems = element.find('.' + options.selectedSelector),
        length = selectedItems ? selectedItems.length : 0;
    self.selectedRewardIDs = [];

    if (selectedItems && length > 0) {
      for (i = 0; i < length; i++) {
        self.selectedRewardIDs.push(parseInt(Kobo.$(selectedItems[i]).find('.' + options.btnSelector).attr('id'), 10));
      }
    }
  };

  self.countSelectedItems = function () {
    // count how many reward items have the "selected" class
    return element.find('.' + options.selectedSelector).length;
  };
  /**
   * Maintains the style of a reward item when it's selected/unselected
   * @param selectedReward - reward item (i.e. book, magazine, etc.) that the user selected/unselected
   */


  self.selectReward = function (event, data) {
    var selectedReward = Kobo.$(this).closest('.' + options.rewardItemSelector);

    if (data && data.detail && data.detail.selectItem) {
      selectedReward = data.detail.selectItem.closest('.' + options.rewardItemSelector);
    }

    if (selectedReward) {
      var currentlySelectedItems = self.countSelectedItems();

      if (currentlySelectedItems < self.MAX_SELECTIONS_ALLOWED || selectedReward.hasClass(options.selectedSelector)) {
        self.updateCtaState(selectedReward);
        self.updateSelectedRewardIDs();
      }

      currentlySelectedItems = self.countSelectedItems();
      self.fire('UserRewardSelector::updateClaimBox', {
        canInputVoucherCode: currentlySelectedItems > 0,
        claimRewardSet: self.selectedRewardIDs
      });
    }
  };

  self.destroy = function () {
    self.unSubscribe('UserRewardSelector::toggleSelect', self.selectReward);
  };

  self.setupHandlers = function () {
    self.register('UserRewardSelector::toggleSelect');
    self.subscribe('UserRewardSelector::toggleSelect', self.selectReward);
    element.find('.' + options.btnSelector).on('click', self.selectReward); // Show synopsis popup on reward item click

    element.find('.' + options.rewardItemDetailsSelector).on('click', function (e) {
      var currentlySelected = self.countSelectedItems(),
          selectedItem = Kobo.$(this).closest('.' + options.rewardItemSelector),
          canSelectMoreItems = !(currentlySelected === self.MAX_SELECTIONS_ALLOWED && !selectedItem.hasClass(options.selectedSelector));

      Kobo._mediator.fire('userRewardSelectorPopup::loadSynopsis', {
        target: e.target,
        canSelectItem: canSelectMoreItems
      });
    });
  };

  init = function init() {
    self.MAX_SELECTIONS_ALLOWED = parseInt(options.userSelectionLimit, 10);
    self.setupHandlers();
  };

  init();
};

Kobo.ViewModel.UserRewardSelector.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);