"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.Behaviors.Braintree.HandleHostedFieldEventsBehavior', HandleHostedFieldEventsBehavior);

  function HandleHostedFieldEventsBehavior(options) {
    var self = this,
        hostedFields,
        iconContainer,
        cardType,
        hostedFieldStrings,
        // events
    submitFormEvent,
        teardownFormEvent,
        isValidEvent,
        // subscriptions
    submitFormEventSubscription,
        teardownFormEventSubscription;

    function fetchFormState() {
      return hostedFields.getState();
    }

    function setMessageForHostedField(field, message) {
      hostedFields.setMessage({
        field: field,
        message: message
      });
    }

    function shiftFocusToHostedField(field) {
      hostedFields.focus(field);
    }

    function tokenizePayload(_ref) {
      var options = _ref.options,
          callback = _ref.callback;
      hostedFields.tokenize(options, callback);
    }

    function teardownHostedFields(callback) {
      hostedFields.teardown(callback);
    }

    function _onFetchFormState(event, data) {
      var formStateReadyCallback = data.detail.formStateReadyCallback;
      var formState = fetchFormState();
      formStateReadyCallback(formState);
    }

    function _onCardTypeChange(event) {
      iconContainer.removeClass();

      if (event.cards.length === 1) {
        iconContainer.addClass(event.cards[0].type);
      } else if (event.fields.number.isEmpty) {
        iconContainer.addClass(cardType);
      }
    }

    function _onValidityChange(event) {
      var field = event.emittedBy;
      var isValid = event.fields[event.emittedBy].isValid;
      $(Kobo.PurchasePath.Enums.BraintreeFieldsElementIdMapper[field]).attr('aria-hidden', isValid);
      notifyFieldBasedValidityChanges(field, isValid);
    }

    function _onBlur(event) {
      var field = event.emittedBy;
      var isValid = event.fields[event.emittedBy].isValid;
      notifyFieldBasedValidityChanges(field, isValid);
    }

    function notifyFieldBasedValidityChanges(field, isValid) {
      if (isDateField(field)) {
        var state = fetchFormState();
        var _state$fields = state.fields,
            expirationMonth = _state$fields.expirationMonth,
            expirationYear = _state$fields.expirationYear;

        Kobo._mediator.fire('braintreeHostedFields::validateExpiry', {
          expirationMonth: expirationMonth,
          expirationYear: expirationYear,
          emittedBy: field,
          dateValidityCallback: notify
        });

        return; // let expiry be validated
      }

      notify(field, isValid);

      function notify(field, isValid) {
        var setErrorMessageForScreenReader = function setErrorMessageForScreenReader(message) {
          message = isValid ? '' : message;
          setMessageForHostedField(field, message); // sets visually hidden message for screen readers
        };

        isValidEvent.notifySubscribers({
          field: field,
          isValid: isValid,
          setErrorMessageForScreenReader: setErrorMessageForScreenReader
        });
      }
    }

    function isDateField(field) {
      return field === hostedFieldStrings.CreditCard.ExpirationMonth || field === hostedFieldStrings.CreditCard.ExpirationYear;
    }

    function _onSubmittedWithInvalidFields() {
      var state = fetchFormState();
      var firstInvalidField = Object.keys(state.fields).filter(function (key) {
        return !state.fields[key].isValid;
      })[0];
      shiftFocusToHostedField(firstInvalidField); // required for accessiblity
    }

    function _onInputSubmitRequest() {
      Kobo._mediator.fire('braintreeHostedFields::submitRequested');
    }

    function init() {
      hostedFieldStrings = Kobo.PurchasePath.Enums.BraintreeHostedFields;
      iconContainer = options.iconContainer;
      cardType = options.cardType;
      iconContainer.removeClass();
      iconContainer.addClass(cardType);
      hostedFields = options.hostedFieldsInstance;
      setupHandlers();
      setupBraintreeHostedFieldEventHandlers();
      setupEvents();
      setRequiredAttributes();
    }

    function setupHandlers() {
      Kobo._mediator.register('braintreeHostedFields::fetchFormState');

      Kobo._mediator.subscribe('braintreeHostedFields::fetchFormState', _onFetchFormState);

      Kobo._mediator.register('braintreeHostedFields::submittedWithInvalidFields');

      Kobo._mediator.subscribe('braintreeHostedFields::submittedWithInvalidFields', _onSubmittedWithInvalidFields);

      Kobo._mediator.register('braintreeHostedFields::clearHandlers');

      Kobo._mediator.subscribe('braintreeHostedFields::clearHandlers', self.clearHandlers);
    }

    function setupBraintreeHostedFieldEventHandlers() {
      hostedFields.on('cardTypeChange', _onCardTypeChange);
      hostedFields.on('validityChange', _onValidityChange);
      hostedFields.on('blur', _onBlur);
      hostedFields.on('inputSubmitRequest', _onInputSubmitRequest);
    }

    function setupEvents() {
      isValidEvent = options.isValidEvent;
      submitFormEvent = options.submitFormEvent;
      teardownFormEvent = options.teardownFormEvent;
      teardownFormEventSubscription = teardownFormEvent.subscribe(function () {
        teardownHostedFields(disposeSubscriptions);
      });
      submitFormEventSubscription = submitFormEvent.subscribe(tokenizePayload);
    }

    function setRequiredAttributes() {
      var requiredFields = Object.values(hostedFieldStrings.CreditCard); // every hosted field is a required field

      requiredFields.forEach(function (fieldValue) {
        hostedFields.setAttribute({
          field: fieldValue,
          attribute: 'aria-required',
          value: true
        }, function (attributeErr) {
          if (attributeErr) console.error(attributeErr);
        });
      });
    }

    function disposeSubscriptions() {
      submitFormEventSubscription.dispose();
      teardownFormEventSubscription.dispose();
    }

    self.clearHandlers = function () {
      Kobo._mediator.unSubscribe('braintreeHostedFields::fetchFormState', _onFetchFormState);

      Kobo._mediator.unSubscribe('braintreeHostedFields::submittedWithInvalidFields', _onSubmittedWithInvalidFields);

      Kobo._mediator.unSubscribe('braintreeHostedFields::clearHandlers', self.clearHandlers);

      Kobo._mediator.unRegister('braintreeHostedFields::fetchFormState');

      Kobo._mediator.unRegister('braintreeHostedFields::submittedWithInvalidFields');

      Kobo._mediator.unRegister('braintreeHostedFields::clearHandlers');
    };

    self.destroy = function () {
      self.clearHandlers();
      teardownHostedFields(disposeSubscriptions);
    };

    init();
  }
})();