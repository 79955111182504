"use strict";

/*global Kobo,$,ko,Modernizr*/
(function (Kobo) {
  /**
   * CartRecommendationsGrid - retrieves recommendations based on the current users cart and displays
   * @type Gizmo
   * @augments Kobo.Gizmo
   * @param {Element} el
   * @param {Object} options
   * @constructor
   */
  function CartRecommendationsGrid(el, options) {
    Kobo.Gizmo.apply(this, arguments);
    this.setType('CartRecommendationsGrid');
    this.$preloader = this.$el.find('.ajax-preloader');
    this.$recommendationList = this.$el.find('.recommendation-list');
    var viewModel = new Kobo.ViewModel.CartRecommendationsGrid(this);
    ko.applyBindings(viewModel, el);
  }
  /**
   * CartRecommendationsGrid.prototype
   * @augments Kobo.Gizmo.prototype
   * @type {*}
   */


  CartRecommendationsGrid.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
  /**
   * CartRecommendationsGrid - ViewModel that controls the state of the cart recommendations widget and fetches new data
   * @type ViewModel
   * @augments Kobo.ViewModel.Base
   * @param {Gizmo} gizmo
   * @constructor
   */

  function CartRecommendationsGridVM(gizmo) {
    this.gizmo = gizmo;
    this.settings = this.gizmo.settings;
    Kobo.ViewModel.Base.apply(this, [this.gizmo.el, this.settings]);
    this.setType('CartRecommendationsViewModel');
    this.$preloader = this.$el.find('.ajax-preloader');
    this.items = ko.observableArray([]);
    this.isFetching = ko.observable(false);
    this.itemHeight = null;
    this.itemWidth = null;
    this.transitionIn = null;
    this.transitionOut = null;
    this.transitionTiming = 1000; // milliseconds
    // Bind event handlers

    this.onUpdate = this.onUpdate.bind(this); // init

    this.transitionSetup();
    this.setupHandlers();
    this.setupEvents();
  }

  CartRecommendationsGridVM.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);

  CartRecommendationsGridVM.prototype.setupEvents = function () {
    this.gizmo.register('cartRecommendations::updated');
  };

  CartRecommendationsGridVM.prototype.transitionSetup = function () {
    var transitionTiming = this.transitionTiming;
    var $preloader = this.gizmo.$preloader;

    if (Modernizr.csstransitions) {
      this.transitionIn = function ($el) {
        $el.isTransitionIn = true;
        $el.css({
          "display": "block",
          "opacity": "1"
        });
        $preloader.removeClass("no-display");
      };

      this.transitionOut = function ($el) {
        $preloader.addClass("no-display");
        $el.isTransitionIn = false;
        $el.css({
          "opacity": "0"
        });
      };
    } else {
      this.transitionIn = function ($el) {
        $el.fadeIn(transitionTiming);
      };

      this.transitionOut = function ($el) {
        $el.fadeOut(transitionTiming);
      };
    }
  };

  CartRecommendationsGridVM.prototype.startAjax = function () {
    this.transitionIn(this.gizmo.$preloader);
    this.transitionOut(this.gizmo.$recommendationList);
  };

  CartRecommendationsGridVM.prototype.endAjax = function () {
    this.transitionOut(this.gizmo.$preloader);
    this.transitionIn(this.gizmo.$recommendationList);
  };

  CartRecommendationsGridVM.prototype.calcItemHeight = function (numItems, isResize) {
    var needsResize = isResize || Kobo.Object.isNullOrEmpty(this.itemHeight);

    if (needsResize && numItems > 0) {
      var $firstItem = this.gizmo.$recommendationList.find('li:first'); // Ask jQuery to calculate the item's height, including its margins

      this.itemHeight = $firstItem.outerHeight(true);
      this.itemWidth = $firstItem.width();
    }
  };

  CartRecommendationsGridVM.prototype.layoutItems = function (isResize) {
    this.calcItemHeight(this.items().length, isResize);
    var $recommendationList = this.gizmo.$recommendationList;
    var $items = $recommendationList.find('li');
    var listWidth = $recommendationList.width();
    var maxItemsPerRow = Math.floor(listWidth / this.itemWidth);
    var numItemsPerRow = Math.min($items.length, maxItemsPerRow);
    var numRows = 0;
    $items.each(function (i, el) {
      var $item = $(el);

      if (i % numItemsPerRow === 0) {
        $item.css({
          'padding-left': '0'
        });
        numRows++;
      }
    });
    var numRowsForCurrentBreakpoint = this.settings.rows[this.gizmo.currentBreakpoint()];
    var numRowsToShow = Math.min(numRowsForCurrentBreakpoint, numRows);
    var listHeight = numRowsToShow * this.itemHeight;
    $recommendationList.height(listHeight);
  };

  CartRecommendationsGridVM.prototype.onUpdate = function (event) {
    var _this = this;

    this.startAjax();
    Kobo.Ajax({
      cache: false,
      url: this.settings.url,
      success: function success(results) {
        if (results.result) {
          var items = results.data.Items;

          _this.items.removeAll();

          items.forEach(function (item) {
            _this.items.push(new Kobo.ViewModel.CartRecommendationItem(item, _this.gizmo));
          });
        }
      },
      error: function error(err) {
        Kobo.log(err);
      },
      complete: function complete() {
        _this.endAjax();

        _this.layoutItems(false);

        _this.gizmo.fire('cartRecommendations::updated');
      }
    });
  };

  CartRecommendationsGridVM.prototype.setupHandlers = function () {
    var _this2 = this;

    this.subscribe('shoppingCartItems::readyForRecos', this.onUpdate);
    this.subscribe('shoppingCart::opening', this.onUpdate);
    this.subscribe('shoppingCartItems::closing', this.onUpdate);
    this.events.onResize(function () {
      setTimeout(function () {
        _this2.layoutItems(true);

        _this2.fire("shoppingCart::adjustsize");
      }, 500);
    });
  };
  /**
   * CartRecommendationItemVM - Represents an individual product in the CartRecommendationGrid
   * @type ViewModel
   * @param {Object} data
   * @param {Gizmo} gizmo
   * @constructor
   */


  function CartRecommendationItemVM(data, gizmo) {
    'use strict';

    var _this3 = this;

    this.gizmo = gizmo;
    this.id = ko.observable(data.Id);
    this.imageUrl = ko.observable(data.ProductImage);
    this.ageVerificationRequired = ko.observable(data.AgeVerificationRequired);
    this.productDetail = ko.observable(data.ProductDetail);
    this.price = ko.observable(data.IsKoboLove ? data.KoboLovePrice : data.OurPrice);
    this.isKoboLoveEligible = ko.observable(data.IsKoboLoveEligible);
    this.title = ko.observable(data.Title);
    this.itemDataTrack = ko.computed(function () {
      return JSON.stringify({
        productId: _this3.id()
      });
    }); // bind event handlers

    this.addToCart = this.addToCart.bind(this);
  }

  CartRecommendationItemVM.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);

  CartRecommendationItemVM.prototype.addToCart = function () {
    this.gizmo.fire('shoppingCartItems::addItem', {
      productId: this.id(),
      ageVerificationRequired: this.ageVerificationRequired()
    });
  }; // Exports


  Kobo.Gizmo.CartRecommendationsGrid = CartRecommendationsGrid;
  Kobo.ViewModel.CartRecommendationsGrid = CartRecommendationsGridVM;
  Kobo.ViewModel.CartRecommendationItem = CartRecommendationItemVM;
})(Kobo);