"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.KoboLoveMembershipUpsell = function (element, options) {
  "use strict";

  var self = this,
      koboLoveMembershipStatusChanged,
      koboLoveDetailsUpdated,
      shoppingCartItemsUpdated,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("KoboLoveMembershipUpsell");
  /*jslint unparam: true*/

  koboLoveMembershipStatusChanged = function koboLoveMembershipStatusChanged(eventName, data) {
    if (data.detail !== undefined) {
      if (data.detail.showKoboLoveVipUpsell) {
        var showUpsell = data.detail.showKoboLoveVipUpsell;

        if (showUpsell === 1) {
          self.show(false);
        } else if (showUpsell === 2) {
          self.show(true);
        }
      }
    }
  };

  koboLoveDetailsUpdated = function koboLoveDetailsUpdated(eventName, data) {
    if (data.detail && data.detail.vipUpsellText !== undefined) {
      self.message(data.detail.vipUpsellText);
    }
  };

  shoppingCartItemsUpdated = function shoppingCartItemsUpdated(eventName, data) {
    if (data.detail.cartItems.length <= 0) {
      self.show(false);
    } else if (data.detail.showKoboLoveVipUpsell) {
      var showUpsell = data.detail.showKoboLoveVipUpsell;

      if (showUpsell === 1) {
        self.show(false);
      } else if (showUpsell === 2) {
        self.show(true);
      }
    }
  };
  /*jslint unparam: false*/


  self.addKoboLoveMembership = function () {
    self.fire('shoppingCartItems::addItem', {
      productId: options.vipMembershipProductId,
      purchaseToken: options.purchaseToken,
      isKoboLoveMembership: true,
      suppressToast: true
    });
    self.show(false);
  };

  init = function init() {
    var isVipAdded = Kobo.Object.parseBool(options.isVip);
    self.show = ko.observable(Kobo.Object.parseBool(options.vipUpsellText !== ""));
    self.message = ko.observable(options.vipUpsellText);

    if (!isVipAdded && self.show()) {
      self.subscribe('shoppingCartItems::changedKoboLoveMembership', koboLoveMembershipStatusChanged);
      self.subscribe('koboLove::updateDetails', koboLoveDetailsUpdated);
      self.subscribe('shoppingCartItems::update', shoppingCartItemsUpdated);
    }

    ko.applyBindings(self, element);
  };

  init();
};

Kobo.Gizmo.KoboLoveMembershipUpsell.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);