"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PaymentMethodsDisplay.ViewModels.BraintreeCreditCardDisplayItemVM', BraintreeCreditCardDisplayItemViewModel);

  function BraintreeCreditCardDisplayItemViewModel(options) {
    var self = this;
    var paymentMethodId = options.PaymentMethodId,
        paymentMethodType = options.PaymentMethodType,
        iconClassName = options.IconClassName,
        displayText = options.DisplayText,
        expiration = options.Expiration;
    self.paymentMethodId = ko.observable(paymentMethodId);
    self.paymentMethodType = ko.observable(paymentMethodType);
    self.iconClassName = ko.observable(iconClassName);
    self.displayText = ko.observable(displayText);
    self.expiration = ko.observable(expiration);
  }
})();