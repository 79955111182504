"use strict";

/*global Kobo,ko,$,DynamicConfiguration,Modernizr*/

/**
 * Class Kobo.ViewModel.UserRewardSelectorClaim
 * @param element  - represents the promo code box for claiming rewards
 * @param options
 * @constructor
 */
Kobo.ViewModel.UserRewardSelectorClaim = function (element, options) {
  'use strict';

  var self = this,
      init;
  Kobo.ViewModel.Base.apply(self, [element, options]);
  self.setType('UserRewardSelectorClaim');
  /**
   * Enable or Disable the input box and button
   * @param isEnabled
   */

  self.toggleClaimBox = function (isEnabled) {
    self.$targetInputSelector.prop('disabled', !isEnabled);
    self.$claimRewardCtaSelector.prop('disabled', !isEnabled);
  };

  self.updateState = function (event, data) {
    var canEnterCode = true;

    if (data && data.detail && data.detail.canInputVoucherCode !== 'undefined' && data.detail.claimRewardSet) {
      canEnterCode = data.detail.canInputVoucherCode;
      self.selectedRewardIds = data.detail.claimRewardSet;
    }

    self.toggleClaimBox(canEnterCode);
  };

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.showError = function () {
    self.$errorBoxElement.removeClass(options.errorHiddenSelector);
    self.hideSpinner();
  };

  self.claimReward = function () {
    self.showSpinner();
    var inputElem = self.$targetInputSelector;

    if (inputElem === 'undefined') {
      self.showError();
      return;
    }

    var voucherCodeEntered = inputElem.val().trim();

    if (voucherCodeEntered === '') {
      self.showError();
      return;
    }

    if (self.claimAll) {
      self.selectedRewardIds = [];
    }

    Kobo.Ajax({
      url: options.claimRewardUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {
        voucherCode: voucherCodeEntered,
        claimingRewardIds: self.selectedRewardIds
      },
      success: function success(data) {
        if (data === 'undefined' || data.isInvalidCode && data.redirectTo === '') {
          self.showError();
          return;
        }

        if (data.redirectTo !== '') {
          window.location.href = data.redirectTo;
        }
      },
      error: function error() {
        self.showError();
      }
    });
  };

  self.iePlaceholder = function () {
    if (!Modernizr.input.placeholder) {
      var arr = Kobo.$.find('#' + options.targetInputSelector + '[placeholder]'),
          i = 0,
          focus = function focus() {
        var ele = this;

        if (ele.value === ele.getAttribute('placeholder')) {
          ele.value = '';
        }
      },
          blur = function blur() {
        var ele = this;

        if (ele.value === '' || ele.value === ele.getAttribute('placeholder')) {
          ele.value = ele.getAttribute('placeholder');
        }
      },
          elem = null;

      for (i; i < arr.length; i++) {
        elem = arr[i];
        Kobo.$(elem).focus(focus);
        Kobo.$(elem).blur(blur);
        elem.value = elem.getAttribute('placeholder');
      }
    }
  };

  self.destroy = function () {
    self.unSubscribe('UserRewardSelector::error', self.showError);
    self.unSubscribe('UserRewardSelector::claimReward', self.claimReward);
    self.unSubscribe('UserRewardSelector::updateClaimBox', self.updateState);
  };

  self.setupHandlers = function () {
    self.register('UserRewardSelector::error');
    self.register('UserRewardSelector::claimReward');
    self.register('UserRewardSelector::updateClaimBox');
    self.subscribe('UserRewardSelector::error', self.showError);
    self.subscribe('UserRewardSelector::claimReward', self.claimReward);
    self.subscribe('UserRewardSelector::updateClaimBox', self.updateState);
    self.$targetInputSelector.keypress(function (event) {
      if (event.keyCode === 13) {
        self.claimReward();
      }
    });
    self.$claimRewardCtaSelector.on('click', self.claimReward);
  };

  init = function init() {
    self.claimAll = options.claimAll;
    self.$errorBoxElement = element.find('.' + options.errorSelector);
    self.$targetInputSelector = element.find('#' + options.targetInputSelector);
    self.$claimRewardCtaSelector = element.find('#' + options.eventTriggerId);
    self.iePlaceholder();
    self.setupHandlers();

    if (self.claimAll) {
      self.toggleClaimBox(true);
    } else {
      self.toggleClaimBox(false);
    }
  };

  init();
};

Kobo.ViewModel.UserRewardSelectorClaim.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);