"use strict";

/*globals Kobo*/
Kobo.Gizmo.Tabs = function () {
  'use strict';

  var self = this,
      activeSectionId,
      validTabs,
      setActiveTab,
      setActiveSection,
      setupEvents,
      getHashWithoutSuffix,
      onTabClicked,
      getTabMatchingHash,
      init;
  self.defaults = {
    tabsSelector: '',
    hashSuffix: '-tab',
    validTabs: []
  };
  Kobo.Gizmo.apply(this, arguments);

  setActiveTab = function setActiveTab($tab) {
    if ($tab.length > 0) {
      var activeTabHash = $tab.attr('href');
      self.$el.find(self.settings.tabsSelector).attr('aria-selected', 'false');
      $tab.attr('aria-selected', 'true');
      setActiveSection(activeTabHash);
      Kobo.Utilities.replaceState(activeTabHash + self.settings.hashSuffix);
    }
  };

  setActiveSection = function setActiveSection(sectionId) {
    self.$el.find(activeSectionId).attr('aria-hidden', 'true');
    self.$el.find(sectionId).attr('aria-hidden', 'false');
    activeSectionId = sectionId;
  };

  onTabClicked = function onTabClicked(event) {
    event.preventDefault();
    setActiveTab(Kobo.$(this));
  };

  getTabMatchingHash = function getTabMatchingHash(hash) {
    hash = hash || '';
    var hashWithoutSuffix = getHashWithoutSuffix(hash);
    return self.$el.find(self.settings.tabsSelector).filter('[href="' + hashWithoutSuffix + '"]');
  };

  setupEvents = function setupEvents() {
    self.$el.on('click', self.settings.tabsSelector, onTabClicked);
    Kobo.$(validTabs.map(function (x) {
      return x + self.settings.hashSuffix;
    }).join(',')).on('click', onTabClicked);
  };

  getHashWithoutSuffix = function getHashWithoutSuffix(hash) {
    if (hash.indexOf(self.settings.hashSuffix) >= 0) {
      hash = hash.substring(0, hash.length - self.settings.hashSuffix.length);
    }

    return hash;
  };

  init = function init() {
    if (!self.settings.tabsSelector) {
      throw new Error('tabsSelector gizmo config value is required');
    }

    var $tabs = self.$el.find(self.settings.tabsSelector);
    validTabs = $tabs.map(function (i, a) {
      return a.getAttribute('href');
    }).toArray();
    var $defaultActiveTab = self.$el.find(self.settings.tabsSelector).filter('[aria-selected=true]');
    activeSectionId = $defaultActiveTab.attr('href');
    var $activeTab = getTabMatchingHash(Kobo.Utilities.getLocationHash());

    if ($activeTab.length < 1) {
      $activeTab = $defaultActiveTab;
    }

    setActiveTab($activeTab);
    setupEvents();
  };

  self.destroy = function () {
    self.$el.off('click', self.settings.tabsSelector, onTabClicked);
    Kobo.$(validTabs.map(function (x) {
      return x + self.settings.hashSuffix;
    }).join(',')).off('click', onTabClicked);
  };

  init();
};

Kobo.Gizmo.Tabs.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);