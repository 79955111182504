"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.SavePaymentMethod.ViewModels.SaveIdealSepaVM', SaveIdealSepaViewModel);

  function SaveIdealSepaViewModel(options) {
    var _this = this;

    var self = this,
        FailureReason = Kobo.SavePaymentMethod.FailureReason,
        SaveButtonVM = Kobo.SavePaymentMethod.ViewModels.SaveButtonVM,
        _Kobo$PurchasePath$En = Kobo.PurchasePath.Enums,
        PaymentType = _Kobo$PurchasePath$En.PaymentType,
        ConvertPaymentTypeToServer = _Kobo$PurchasePath$En.ConvertPaymentTypeToServer;
    var paymentMethodType = options.PaymentMethodType,
        label = options.Label,
        icons = options.Icons,
        availableBanks = options.AvailableBanks,
        inputRules = options.InputRules,
        savedFullName = options.FullName,
        stripeRedirectUrl = options.stripeRedirectUrl,
        savePaymentMethod = options.savePaymentMethod,
        savePaymentMethodFailureEvent = options.savePaymentMethodFailureEvent;
    this.paymentMethodType = paymentMethodType;
    this.fullName = ko.observable().extend({
      required: {
        params: true,
        message: window.DynamicConfiguration.savePaymentMethod.resourceStrings.fullNameIsRequired
      }
    }).extend({
      minLength: {
        params: inputRules.FullNameMinLength,
        message: window.DynamicConfiguration.savePaymentMethod.resourceStrings.fullNameIsTooShort
      }
    }).extend({
      maxLength: {
        params: inputRules.FullNameMaxLength,
        message: window.DynamicConfiguration.savePaymentMethod.resourceStrings.fullNameIsTooLong
      }
    });
    this.bank = ko.observable();
    this.availableBanks = availableBanks.map(function (bank) {
      return {
        code: bank.Code,
        name: bank.Name
      };
    });
    this.errors = ko.validation.group(self);

    this.canSubmit = function () {
      return _this.errors().length === 0;
    };

    this.fieldIsInvalid = function (field) {
      return field.isModified() && !field.isValid();
    };

    this.handleSubmit = function () {
      if (!_this.canSubmit()) {
        _this.errors.showAllMessages();

        self.handleSavePaymentMethodFailure(FailureReason.IdealAccountInvalid);
        return;
      }

      var paymentMethodData = {
        paymentMethodType: self.paymentMethodType,
        paymentDetails: {
          PaymentType: ConvertPaymentTypeToServer(PaymentType.SepaStripe),
          FullName: self.fullName(),
          Bank: self.bank(),
          SuccessUrl: stripeRedirectUrl
        }
      };
      return self.savePaymentMethod(paymentMethodData).then(function (response) {
        if (!response.nextStepUrl) {
          self.handleSavePaymentMethodFailure(FailureReason.MissingNextStepUrl);
          return;
        }

        Kobo.Utilities.navigateTo(response.nextStepUrl);
      })["catch"](self.handleSavePaymentMethodFailure);
    };

    this.handleSavePaymentMethodFailure = function (failureReason) {
      return self.savePaymentMethodFailureEvent.notifySubscribers(failureReason);
    };

    this.init = function () {
      self.label = label;
      self.icons = icons;
      self.template = 'save-payment-method-ideal-sepa-template';
      self.button = new SaveButtonVM('save-payment-method-ideal-sepa-save-button-template');
      self.savePaymentMethod = savePaymentMethod;
      self.savePaymentMethodFailureEvent = savePaymentMethodFailureEvent;
      if (savedFullName) self.fullName(savedFullName);
    };

    this.init();
  }
})();