"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.EGiftingCustomization.EGiftingCustomizationVM', EGiftingCustomizationViewModel);

  function EGiftingCustomizationViewModel(props) {
    var self = this,
        strings = window.DynamicConfiguration.resourceStrings.egiftingCustomization;
    var rules = props.rules;
    self.amount = ko.observable(props.amount || rules.MinAmount).extend({
      required: {
        params: true,
        message: strings.amountRequired
      }
    }).extend({
      min: {
        params: rules.MinAmount,
        message: Kobo.Utilities.stringFormat(strings.amountMustBeGreaterThan, rules.FormattedMinAmount)
      }
    }).extend({
      max: {
        params: rules.MaxAmount,
        message: Kobo.Utilities.stringFormat(strings.amountMustBeLessThan, rules.FormattedMaxAmount)
      }
    });
    self.amount.subscribe(function () {
      self.updateFormValidity();
    });
    self.email = ko.observable(props.email).extend({
      required: {
        params: true,
        message: strings.emailRequired
      }
    }).extend({
      email: {
        params: true,
        message: strings.emailInvalid
      }
    }).extend({
      maxLength: {
        params: rules.EmailMaxLength,
        message: Kobo.Utilities.stringFormat(strings.emailExceedsMaxLength, rules.EmailMaxLength)
      }
    });
    self.email.subscribe(function () {
      self.updateFormValidity();
    });
    self.confirmEmail = ko.observable(props.confirmEmail).extend({
      required: {
        params: true,
        message: strings.confirmEmailRequired
      }
    }).extend({
      email: {
        params: true,
        message: strings.confirmEmailInvalid
      }
    }).extend({
      maxLength: {
        params: rules.EmailMaxLength,
        message: Kobo.Utilities.stringFormat(strings.confirmEmailExceedsMaxLength, rules.EmailMaxLength)
      }
    }).extend({
      equal: {
        params: self.email,
        message: strings.emailMismatch
      }
    });
    self.confirmEmail.subscribe(function () {
      self.updateFormValidity();
    });
    self.recipientName = ko.observable(props.recipientName).extend({
      required: {
        params: true,
        message: strings.recipientNameRequired
      }
    }).extend({
      maxLength: {
        params: rules.RecipientMaxLength,
        message: Kobo.Utilities.stringFormat(strings.recipientNameExceedsMaxLength, rules.RecipientMaxLength)
      }
    });
    self.recipientName.subscribe(function () {
      self.updateFormValidity();
    });
    self.senderName = ko.observable(props.senderName).extend({
      required: {
        params: true,
        message: strings.senderNameRequired
      }
    }).extend({
      maxLength: {
        params: rules.SenderMaxLength,
        message: Kobo.Utilities.stringFormat(strings.senderNameExceedsMaxLength, rules.SenderMaxLength)
      }
    });
    self.senderName.subscribe(function () {
      self.updateFormValidity();
    });
    self.message = ko.observable(props.messageText).extend({
      maxLength: {
        params: rules.MessageCharacterLimit,
        message: Kobo.Utilities.stringFormat(strings.messageExceedsMaxLength, rules.MessageCharacterLimit)
      }
    });
    self.messageCount = ko.computed(function () {
      if (self.message() == null) {
        return 0;
      }

      return self.message().length;
    });
    self.message.subscribe(function () {
      self.updateFormValidity();
    });
    self.delivery = ko.observable(props.delivery).extend({
      required: {
        params: true,
        message: strings.deliveryRequired
      }
    });
    self.delivery.subscribe(function () {
      self.updateFormValidity();
    });
    self.errors = ko.validation.group(self);
    self.isValid = ko.observable(true);

    self.updateFormValidity = function () {
      if (self.errors().length === 0) {
        self.isValid(true);
      }
    };

    self.canSubmit = function () {
      if (self.errors().length > 0) {
        self.errors.showAllMessages();
        self.isValid(false);
        return false;
      }

      return true;
    };
  }
})();