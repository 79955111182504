"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo*/

/**
 * Kobo.Gizmo.Collapsible.ActionMenu
 * @description Extends Kobo.Gizmo.Collapsible.HasHeader to add extra functionality specific to a menu of actionable items.
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.Collapsible.ActionMenu = function () {
  'use strict';

  var self = this;
  Kobo.Gizmo.Collapsible.HasHeader.apply(this, arguments);
  var KEYCODES = {
    ENTER: 13,
    ESCAPE: 27,
    UP: 38,
    DOWN: 40,
    TAB: 9,
    SPACE: 32
  };
  var _self$options = self.options,
      name = _self$options.name,
      toggleClass = _self$options.toggleClass,
      actionMenuId = _self$options.actionMenuId;
  self.collapsibleIsOpen = false;
  self.toggleButton = null;
  self.actionItems = null;
  self.focusedActionItemIndex = null;
  /**
  * Menu Toggle
  */

  this.getCollapsibleState = function () {
    return new Promise(function (resolve) {
      Kobo._mediator.fire('collapsible::isOpen', {
        name: name,
        callback: resolve
      });
    });
  };

  this.toggleCollapsibleState = function () {
    return self.getCollapsibleState().then(function (isOpen) {
      self.collapsibleIsOpen = isOpen;
      self.toggleButton.toggleClass('activated', isOpen);
    });
  };

  this.handleToggleEvent = function (_, data) {
    var nameOfFiringCollapsible = data.detail.name;
    if (nameOfFiringCollapsible !== name) return;
    self.toggleCollapsibleState();
  };

  this.handleToggleButtonClick = function () {
    self.toggleCollapsibleState();
  };

  this.handleToggleButtonKeyDown = function (e) {
    if (!self.keyPressShouldToggleMenu(e)) return;

    Kobo._mediator.fire('collapsible::closeAllOther', {
      name: name
    });

    self.toggleCollapsibleState().then(function () {
      if (!self.collapsibleIsOpen) self.shiftFocusToFirstAction();
    });
  };

  this.keyPressShouldToggleMenu = function (e) {
    if (e.keyCode === KEYCODES.ENTER || e.keyCode === KEYCODES.SPACE) return true;
    if (e.keyCode === KEYCODES.DOWN && self.options.collapsibleOnDownArrow) return true;
    return false;
  };

  this.deactivateToggle = function (_, data) {
    var nameOfFiringCollapsible = data.detail.name;
    if (nameOfFiringCollapsible !== name) return;
    self.toggleButton.removeClass('activated');
  };

  this.deactivateToggleIfNotNamed = function (_, data) {
    var nameOfFiringCollapsible = data.detail.name;
    if (nameOfFiringCollapsible === name) return;
    self.toggleButton.removeClass('activated');
  };
  /**
  * Menu Actions
  */


  this.shiftFocusToFirstAction = function () {
    Kobo.Utilities.waitForElementToBeVisible("#".concat(self.options.actionMenuId, " .action"), function () {
      self.focusedActionItemIndex = 0;
      var firstAction = self.actionItems[self.focusedActionItemIndex];
      firstAction.focus();
    }, 25);
  };

  this.shiftFocusToNextAction = function (e) {
    e.preventDefault();
    self.focusedActionItemIndex++;
    if (self.focusedActionItemIndex >= self.actionItems.length) self.focusedActionItemIndex = 0;
    var nextAction = self.actionItems[self.focusedActionItemIndex];
    nextAction.focus();
  };

  this.shiftFocusToPreviousAction = function (e) {
    e.preventDefault();
    self.focusedActionItemIndex--;
    if (self.focusedActionItemIndex < 0) self.focusedActionItemIndex = self.actionItems.length - 1;
    var previousAction = self.actionItems[self.focusedActionItemIndex];
    previousAction.focus();
  };

  this.handleActionItemKeyDown = function (e) {
    var _keyCodeActionMap;

    var keyCodeActionMap = (_keyCodeActionMap = {}, _defineProperty(_keyCodeActionMap, KEYCODES.UP, self.shiftFocusToPreviousAction), _defineProperty(_keyCodeActionMap, KEYCODES.DOWN, self.shiftFocusToNextAction), _defineProperty(_keyCodeActionMap, KEYCODES.ENTER, self.simulateActivatedActionState), _defineProperty(_keyCodeActionMap, KEYCODES.TAB, self.handleActionItemFocusOut), _defineProperty(_keyCodeActionMap, KEYCODES.ESCAPE, self.handleEscapeFromActionMenu), _keyCodeActionMap);
    if (!keyCodeActionMap[e.keyCode]) return;
    return keyCodeActionMap[e.keyCode](e);
  };

  this.handleEscapeFromActionMenu = function () {
    self.focusedActionItemIndex = null;

    Kobo._mediator.fire('collapsible::close', {
      name: name
    });

    self.toggleButton.focus();
  };

  this.handleActionItemFocusOut = function () {
    self.focusedActionItemIndex = null;

    Kobo._mediator.fire('collapsible::close', {
      name: name
    });
  };

  this.simulateActivatedActionState = function (e) {
    e.preventDefault();
    var action = e.target;
    action.classList.add('activated');
    setTimeout(function () {
      action.classList.remove('activated');
      action.click();
    }, 25);
  };

  this.setupEvents = function () {
    self.toggleButton.on('click', self.handleToggleButtonClick);
    self.toggleButton.on('keydown', self.handleToggleButtonKeyDown);
    self.actionItems.on('keydown', self.handleActionItemKeyDown);

    Kobo._mediator.subscribe('collapsible::toggle', self.handleToggleEvent);

    Kobo._mediator.subscribe('collapsible::close', self.deactivateToggle);

    Kobo._mediator.subscribe('collapsible::closeAllOther', self.deactivateToggleIfNotNamed);
  };

  this.init = function () {
    if (!toggleClass || !actionMenuId) throw new Error('Missing required options.');
    self.toggleButton = self.$el.find(".".concat(self.options.toggleClass));
    self.actionItems = self.$el.find("#".concat(self.options.actionMenuId, " .action"));
    if (!self.toggleButton.length || !self.actionItems.length) throw new Error('Could not find toggle button or action items.');
    self.setupEvents();
  };

  self.init();
};

Kobo.Gizmo.Collapsible.ActionMenu.prototype = Kobo.chainPrototype(Kobo.Gizmo.Collapsible.HasHeader.prototype);