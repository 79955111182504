"use strict";

/*global Kobo,$,console */
(function (Kobo) {
  function GizmoLandingPageHeader(gizmo, options) {
    "use strict";
    /* inherit from base class */

    var _this = this;

    Kobo.Gizmo.apply(this, arguments);
    this.setType("LandingPageHeader");
    this.settings = Kobo.extend({}, options);
    this.events.onResize(function () {
      _this.resizeDescriptionContainer();
    });
    Kobo.$(document).ready(function () {
      var screenSize = _this.currentBreakpoint();

      if (screenSize !== 'small') {
        _this.resizeDescriptionContainer();
      }
    });
  }

  GizmoLandingPageHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

  GizmoLandingPageHeader.prototype.resizeDescriptionContainer = function () {
    var screenSize = this.currentBreakpoint();

    if (screenSize !== 'small') {
      var innerHeight = this.$gizmo.find('.' + this.settings.descriptionSelector).height();
      this.$gizmo.find('.' + this.settings.descriptionContainerSelector).css('height', innerHeight);
      this.$gizmo.find('.' + this.settings.expandableInnerMaskerSelector).css('height', innerHeight);
    } else {
      this.$gizmo.find('.' + this.settings.descriptionContainerSelector).css('height', '');
      this.$gizmo.find('.' + this.settings.expandableInnerMaskerSelector).css('height', this.settings.smallDescriptionDefaultHeight);
    }
  };

  Kobo.Gizmo.LandingPageHeader = GizmoLandingPageHeader;
})(Kobo);