"use strict";

/*global Kobo, ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RAT.CheckoutHelper', checkoutHelper);

  function checkoutHelper() {
    this.pageHelper = new Kobo.RAT.PageHelper(); //DLV - Checkout - Product Ids

    this.getItemIds = function (carItems) {
      var ProductIds, items;
      ProductIds = [];
      items = carItems;

      for (var i = 0; i < items.length; i++) {
        ProductIds.push(items[i].Id.replace(/(['])/g, "$1"));
      }

      return "[" + ProductIds.join(', ') + "]";
    }; //DLV - Checkout - Item Count


    this.getItemCount = function (carItems) {
      var ItemCount, items;
      ItemCount = [];
      items = carItems;

      for (var i = 0; i < items.length; i++) {
        ItemCount.push("1");
      }

      return ItemCount.join(', ');
    }; //{{DLV - Checkout - Prices}}


    this.getItemPrices = function (carItems) {
      var PriceList, items;
      PriceList = [];
      items = carItems;
      var storeFront = $('#ratCountryCode')[0].value;

      for (var i = 0; i < items.length; i++) {
        var itemPrice = items[i].PriceDetails[0].Amount;

        if (storeFront === "IN") {
          itemPrice = itemPrice.replace("RS.", "");
        }

        PriceList.push(this.pageHelper.convertToNumber(itemPrice.replace(/[^0-9.,-]+/g, "").trim()));
      }

      return PriceList.join(', ');
    }; //{{JS - Checkout - Total Price}}


    this.getTotalPrice = function () {
      var regularCheckoutElement = document.querySelector(".regular-checkout");
      var regularCheckoutViewModel = ko.dataFor(regularCheckoutElement);
      var storeFront = $('#ratCountryCode')[0].value;
      var cartTotal = regularCheckoutViewModel.paymentSummaryVM().priceSummaryVM.total().value;

      if (storeFront === "IN") {
        cartTotal = cartTotal.replace("RS.", "");
      }

      return this.pageHelper.convertToNumber(cartTotal.replace(/[^0-9.,-]+/g, ""));
    }; //{{ JS - Shopping Cart - Product IDs}}


    this.getShoppingCartProductIds = function () {
      var shoppingCartItems = Kobo._gizmo.gizmoList.ShoppingCartItems[0].shoppingCartList.items();

      var shoppingCartProductIds = [];

      for (var i = 0; i < shoppingCartItems.length; i++) {
        shoppingCartProductIds.push(shoppingCartItems[i].id);
      }

      return shoppingCartProductIds;
    }; //{{ JS - Shopping Cart - Quantity - Array}}


    this.getShoppingCartQtyArray = function () {
      var shoppingCartItems = Kobo._gizmo.gizmoList.ShoppingCartItems[0].shoppingCartList.items();

      var shoppingCartQuantity = [];

      for (var i = 0; i < shoppingCartItems.length; i++) {
        shoppingCartQuantity.push(1);
      }

      return shoppingCartQuantity;
    }; //{{JS - RAT - Shopping Cart - cart_type}}


    this.getShoppingCartType = function () {
      var cartItemsLength = Kobo._gizmo.gizmoList.ShoppingCartItems[0].shoppingCartList.items().length;

      var cartType = "";

      if (cartItemsLength === 1) {
        cartType = "add_first";
      } else {
        cartType = "add_more";
      }

      return cartType;
    }; //{{ JS - Shopping Cart - Prices}}


    this.getShoppingCartPrices = function () {
      var shoppingCartItems = Kobo._gizmo.gizmoList.ShoppingCartItems[0].shoppingCartList.items();

      var shoppingCartPrices = [];
      var mRegx = /([0-9,]+(\.[0-9]+)?)/g;

      for (var i = 0; i < shoppingCartItems.length; i++) {
        var matchedRegx = mRegx.exec(shoppingCartItems[i].priceDetails()[0].amount.trim());
        mRegx.lastIndex = 0;
        shoppingCartPrices.push(this.pageHelper.convertToNumber(matchedRegx[0]));
      }

      return shoppingCartPrices;
    };
  }
})();