"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.PurchaseHistory = function (element) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('PurchaseHistory');
  var self = this;

  function init() {
    var settings = self.settings;
    self.viewModel = {
      items: ko.observable(settings.items)
    };
    ko.applyBindings(self.viewModel, element);
  }

  init();
};

Kobo.Gizmo.PurchaseHistory.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);