"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.BillingAddressDisplay.ViewModels.BillingAddressDisplayVM', BillingAddressDisplayViewModel);

  function BillingAddressDisplayViewModel(options) {
    var self = this,
        ViewModels = Kobo.BillingAddressDisplay.ViewModels;

    this.getContentTemplate = function () {
      var contentTemplates = {
        noBillingAddress: "billing-address-display-no-billing-address-template",
        billingAddressDisplay: "billing-address-display-template"
      };
      return self.hasSavedBillingAddress() ? contentTemplates.billingAddressDisplay : contentTemplates.noBillingAddress;
    };

    this.getBillingAddressTemplate = function () {
      var billingAddressTemplates = {
        "CountryOnly": "billing-address-display-country-only-template",
        "CountryStatePostalCode": "billing-address-display-country-state-postal-code-template",
        "CountryVat": "billing-address-display-country-vat-template"
      };
      var displayType = self.billingAddress.displayType;
      var template = billingAddressTemplates[displayType];
      if (!template) throw new Error("Missing template mapping for billing address of type ".concat(displayType, "."));
      return template;
    };

    function buildBillingAddressViewModel(billingAddress) {
      var isCountryStatePostalCode = billingAddress.country && billingAddress.state && billingAddress.postalCode;
      var isCountryVat = billingAddress.country && !billingAddress.state && !billingAddress.postalCode && billingAddress.vatNumber;
      if (isCountryStatePostalCode) return new ViewModels.CountryStatePostalCodeBillingAddressVM(billingAddress);
      if (isCountryVat) return new ViewModels.CountryVatBillingAddressVM(billingAddress);
      return new ViewModels.CountryOnlyBillingAddressVM(billingAddress);
    }

    this.init = function () {
      var billingAddress = {
        country: options.country,
        state: options.state,
        postalCode: options.postalCode,
        vatNumber: options.vatNumber
      };
      self.billingAddress = buildBillingAddressViewModel(billingAddress);
      self.hasSavedBillingAddress = ko.observable(options.hasSavedBillingAddress);
    };

    this.init();
  }
})();