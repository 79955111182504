(function () {
    window.Kobo = window.Kobo || {};
    window.Kobo.PerformanceMonitor = window.Kobo.PerformanceMonitor || PerformanceMonitor;

    function PerformanceMonitor(statsServerUrl, renderingServerId, userStatus) {
        function _getPerformanceStats() {
            if (window.performance == null) {
                return null;
            }

            var viewName = _getViewName();
            var domElementCount = document.getElementsByTagName('*').length;
            var resourceCount = performance.getEntriesByType('resource').length;
            var timestamp = new Date(performance.timing.navigationStart).toISOString();

            return {
                viewName: viewName,
                timestamp: timestamp,
                timing: performance.timing,
                user: {
                    status: userStatus
                },
                navigation: {
                    redirectCount: performance.navigation.redirectCount,
                    type: performance.navigation.type
                },
                page: {
                    domElementCount: domElementCount,
                    resourceCount: resourceCount
                },
                machineName: renderingServerId
            };
        }

        function _getViewName() {
            var trackingData = $('body').data('track-info') || {};
            var viewName = trackingData.pageName || null;
            return viewName;
        }

        function _submit() {
            if (statsServerUrl) {
                var data = _getPerformanceStats();

                if (data == null) {
                    return $.Deferred.reject(null,
                            'Error',
                            'Cannot collect performance stats because the navigation timing api is not supported on this browser')
                        .promise();
                }

                return $.ajax({
                    url: statsServerUrl,
                    data: JSON.stringify(data),
                    type: "POST",
                    contentType: "application/json"
                });
            }
        }

        this.submit = _submit;
    }
})();

(function () {
    Kobo.$(function () {
        setTimeout(function () {
            // delay load after document ready event to try and make sure all other JS has run (so we can include it in measurements)
            if (DynamicConfiguration && DynamicConfiguration.features && DynamicConfiguration.features.performance) {
                var statsServerUrl = DynamicConfiguration.features.performance.statsServerUrl;
                var renderingServerId = DynamicConfiguration.features.performance.serverId;

                var userStatus = null;
                if (DynamicConfiguration.user) {
                    userStatus = DynamicConfiguration.user.isLoggedIn
                        ? 'Authenticated'
                        : DynamicConfiguration.user.hasPreviouslyAuthenticated
                            ? 'KnownUser'
                            : null;
                }

                var performanceMonitor = new Kobo.PerformanceMonitor(statsServerUrl, renderingServerId, userStatus);
                performanceMonitor.submit();
            }
        }, 0);
    });
})();
