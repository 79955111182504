"use strict";

/*globals Kobo,ko*/
Kobo.Gizmo.AudiobookPlanSwitcher = function () {
  'use strict';

  var self = this;
  var $planSwitchButton;
  self.setDefaults({
    fromPlan: null,
    toPlan: null,
    planDetails: null,
    nextBillingDate: null,
    purchasePathUrl: null,
    creditAmountString: null,
    switchToThisPlanString: null,
    upgradeNowString: null,
    isUpgrade: null
  });
  Kobo.Gizmo.apply(self, arguments);

  function createAudiobookPlanSwitcherView() {
    var $audiobookPlanSwitcherTemplate = Kobo.$('#audiobook-plan-switcher-template');
    return Kobo.$($audiobookPlanSwitcherTemplate.html());
  }

  function showSwitchPlanDialog() {
    var $view = createAudiobookPlanSwitcherView();
    var viewModel = new Kobo.Product.Audiobooks.ViewModels.AudiobookPlanSwitcherVM(self.settings);
    viewModel.closeDialogEvent.subscribe(function () {
      Kobo._modal.close();

      $view.remove();
    });

    Kobo._modal.open($view, {
      customClass: 'audiobook-plan-switcher-wrap'
    });

    ko.applyBindings(viewModel, $view[0]);
  }

  function init() {
    $planSwitchButton = self.$el.find('.switch-plan');

    if (self.options.isUpgrade) {
      $planSwitchButton.text(self.options.upgradeNowString);
    } else {
      $planSwitchButton.text(self.options.switchToThisPlanString);
    }

    $planSwitchButton.on('click', showSwitchPlanDialog);
  }

  init();

  self.destroy = function () {
    $planSwitchButton.off('click', showSwitchPlanDialog);
  };
};

Kobo.Gizmo.AudiobookPlanSwitcher.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);