"use strict";

Kobo.ViewModel.Expandable.PlusButtonGiftCard = function (gizmo, options) {
  var self = this; //self.$header = gizmo.$innerContent.find('> .facet-list-header');
  //gizmo.$gizmo.prepend(self.$header);

  Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
};

Kobo.ViewModel.Expandable.PlusButtonGiftCard.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

Kobo.ViewModel.Expandable.PlusButtonGiftCard.prototype.addButton = function () {
  Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
  this.$button.addClass('gizmo-expandable-button-plus');
};