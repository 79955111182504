"use strict";

/*global Kobo,$,ko */

/* eslint-disable no-unused-vars */
Kobo.Gizmo.DealWidget = function (element, options) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType("DealWidget");

  var self = this,
      init,
      setUpHandlers,
      openTermsModal,
      _closeTermsModal,
      $closeTrigger,
      $dialog = self.$el.find('.long-legal'),
      $dialogTrigger = self.$el.find('.modal-trigger');

  openTermsModal = function openTermsModal() {
    // Copy is necessary because JQuery removes $dialog from the DOM and breaks event listeners
    var $dialogCopy = $dialog.clone();
    $dialogCopy.removeClass('hidden');
    $closeTrigger = $dialogCopy.find('.close');
    $closeTrigger.on('click', _closeTermsModal);

    Kobo._modal.open($dialogCopy);
  };

  _closeTermsModal = function closeTermsModal() {
    Kobo._modal.close();

    $closeTrigger.off('click', _closeTermsModal);
  };

  setUpHandlers = function setUpHandlers() {
    $dialogTrigger.on('click', openTermsModal);
  };

  self.destroy = function () {
    $dialogTrigger.off('click', openTermsModal);

    if ($closeTrigger) {
      $closeTrigger.off('click', _closeTermsModal);
    }
  };

  init = function init() {
    if ($dialog.length && $dialogTrigger.length) {
      setUpHandlers();
    }
  };

  init();
};

Kobo.Gizmo.DealWidget.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);