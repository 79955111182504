"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.BillingInfo = function (element, options) {
  "use strict";

  var self = this,
      BillingAddress,
      BillingAddressField,
      setupHandlers,
      onGetCountry,
      onUpdate,
      onRegularCheckoutMarshallOrderData,
      init,
      isApplicable,
      infoMessage;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("BillingInfo");

  BillingAddressField = function BillingAddressField() {
    var self = this;
    self.name = ko.observable('');
    self.label = ko.observable('');
    self.value = ko.observable('');
    self.possibleValues = ko.observableArray([]);
    self.possibleLabels = ko.observableArray([]);
    self.error = ko.observable('');
    self.visible = ko.observable(true);
  };

  BillingAddress = function BillingAddress() {
    var self = this;
    self.address = new BillingAddressField();
    self.city = new BillingAddressField();
    self.state = new BillingAddressField();
    self.postalCode = new BillingAddressField();
    self.country = new BillingAddressField();
    self.phone = new BillingAddressField();
    self.vatNumber = new BillingAddressField();
  };

  onGetCountry = function onGetCountry(eventName, data) {
    data.detail.country = self.billingAddress.country.value(); // data.detail.country is an output parameter
  };

  onUpdate = function onUpdate(eventName, data) {
    var addressTemplateName = data.detail.addressTemplateName,
        addressFields = data.detail.addressFields || [],
        topLevelError = data.detail.errorMessage;

    if (typeof data.detail.isKid === "boolean") {
      self.isApplicable(!data.detail.isKid);
    }

    Kobo.$.each(addressFields, function () {
      var field = this,
          fieldName = field.Name.charAt(0).toLowerCase() + field.Name.slice(1); // TODO camel case JSON property names on C# side

      self.billingAddress[fieldName].name = fieldName;
      self.billingAddress[fieldName].label(field.Label);
      self.billingAddress[fieldName].visible(field.Visible);
      self.billingAddress[fieldName].error(field.Error);

      if (field.PossibleValues && field.PossibleValues.length === 1 && field.Value === self.billingAddress[fieldName].value()) {
        // special case for DE20765
        return;
      }

      if (field.PossibleValues) {
        self.billingAddress[fieldName].possibleValues.replaceAll(field.PossibleValues);
      }

      if (field.PossibleLabels) {
        self.billingAddress[fieldName].possibleLabels.removeAll();
        Kobo.$.each(field.PossibleLabels, function () {
          self.billingAddress[fieldName].possibleLabels.push(this);
        });
      }

      self.billingAddress[fieldName].value(field.Value);
    });

    if (addressTemplateName && addressTemplateName !== self.billingAddressTemplateName) {
      ko.cleanNode(self.$gizmo[0]);
      self.$gizmo.html(data.detail.addressTemplate); // TODO: destroy old gizmos?

      ko.applyBindings(self, self.$gizmo[0]);

      Kobo._gizmo.initContext(self.$gizmo);

      self.billingAddressTemplateName = addressTemplateName;
    }

    self.error(topLevelError);
    self.fire("invalidate");
  };

  onRegularCheckoutMarshallOrderData = function onRegularCheckoutMarshallOrderData(eventName, data) {
    var orderData = data.detail.orderData; // detail.orderData is an output parameter that will be used by caller upon return
    // TODO camel case JSON property names

    orderData.Address = self.billingAddress.address.value();
    orderData.City = self.billingAddress.city.value();
    orderData.State = self.billingAddress.state.value();
    orderData.Country = self.billingAddress.country.value();
    orderData.PostalCode = self.billingAddress.postalCode.value();
    orderData.Phone = self.billingAddress.phone.value();
  };

  setupHandlers = function setupHandlers() {
    self.register('billingInfo::countryChanged');
    self.register('billingInfo::update');
    self.register('billingInfo::getCountry');
    self.koSubscriptions.push(self.billingAddress.country.value.subscribeChanged(function (newValue, oldValue) {
      if (oldValue !== newValue) {
        self.fire('billingInfo::countryChanged', {
          countryCode: newValue
        });
      }
    }));
    self.koSubscriptions.push(self.error.subscribe(function (errorMessage) {
      if (errorMessage) {
        self.fire('regularCheckout::requestFocus', {
          target: self.gizmo
        });
      }
    }));
    self.subscribe('billingInfo::getCountry', onGetCountry);
    self.subscribe('billingInfo::update', onUpdate);
    self.subscribe('regularCheckout::marshallOrderData', onRegularCheckoutMarshallOrderData);
  };

  init = function init() {
    self.billingAddressTemplateName = '';
    self.billingAddress = new BillingAddress();
    self.error = ko.observable(''); // top-level error message for BillingInfo
    // The same error message can be set twice. Notifications should be sent even if the new value is the same as the previous one

    self.error.extend({
      notify: 'always'
    });
    self.infoMessage = ko.observable('');
    self.koSubscriptions = [];
    self.isApplicable = ko.observable('');
    self.isApplicable(true);
    setupHandlers();
  };

  self.destroy = function () {
    Kobo.$.each(self.koSubscriptions, function () {
      this.dispose();
    });
    self.unSubscribe('regularCheckout::marshallOrderData', onRegularCheckoutMarshallOrderData);
    self.unSubscribe('billingInfo::update', onUpdate);
    self.unSubscribe('billingInfo::getCountry', onGetCountry);
  };

  init();
};

Kobo.Gizmo.BillingInfo.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);