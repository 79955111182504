"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.BillingAddressDisplay = function (el, options) {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("BillingAddressDisplay");
  var viewModel;

  this.init = function () {
    var viewModelOptions = {
      hasSavedBillingAddress: options.HasSavedBillingAddress,
      country: options.Country,
      state: options.State,
      postalCode: options.PostalCode,
      vatNumber: options.VatNumber
    };
    viewModel = new Kobo.BillingAddressDisplay.ViewModels.BillingAddressDisplayVM(viewModelOptions);
    ko.applyBindings(viewModel, el);
  };

  this.init();
};

Kobo.Gizmo.BillingAddressDisplay.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);