"use strict";
/*globals Kobo,ko,el*/

Kobo.Gizmo.ReviewPurchase = function (element, options) {
  "use strict";

  var self = this,
      init,
      completePurchaseCta = element.querySelector('#reviewpurchase-complete-purchase'),
      errorBoxElement,
      timesRun = 0,
      errorMessageBox = element.querySelector('#error-message-box');
  Kobo.Gizmo.apply(this, arguments);
  this.setType("ReviewPurchase");

  self.destroy = function () {
    self.unSubscribe('ReviewPurchase::error', self.showError);
    self.unSubscribe('ReviewPurchase::completePurchase', self.completePurchase);
  };

  self.setupHandlers = function () {
    self.register('ReviewPurchase::error');
    self.register('ReviewPurchase::completePurchase');
    self.subscribe('ReviewPurchase::error', self.showError);
    self.subscribe('ReviewPurchase::completePurchase', self.completePurchase);
  };

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.showError = function (errorMessage) {
    errorMessageBox.innerHTML = errorMessage;
    self.hideSpinner();
    errorBoxElement.addClass("reviewpurchase-error-box-display").hide().fadeIn('slow');
  };

  self.hideError = function () {
    errorBoxElement.fadeOut('fast');
  };

  self.completePurchase = function () {
    self.hideError();
    self.showSpinner();

    if (options.paymentMethods.length == 0) {
      self.showError(options.noPaymentMethodErrorMessage, true);
      return;
    }

    Kobo.Ajax({
      url: options.completePurchaseUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {},
      success: function success(data) {
        if (!data) {
          self.showError(options.genericErrorMessage);
          return;
        }

        checkPurchaseStatus();
      },
      error: function error() {
        self.showError(options.genericErrorMessage);
        return;
      }
    });
  };

  function checkPurchaseStatus() {
    Kobo.Ajax({
      url: options.checkPurchaseStatusUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {},
      success: function success(data) {
        if (!data) {
          self.showError(options.genericErrorMessage);
          return;
        }

        if (data.status == options.successfullStatus) {
          window.location.href = options.thankYouUrl;
          self.hideError();
        } else if (data.status == options.failureStatus) {
          self.showError(data.error);
        } else {
          timesRun += 1;

          if (timesRun === 10) {
            self.showError(options.genericErrorMessage);
            return;
          } else {
            setTimeout(function () {
              checkPurchaseStatus();
            }, 1000);
          }
        }
      },
      error: function error() {
        self.showError(options.genericErrorMessage);
        return;
      }
    });
  }

  self.hasSavedPaymentMethods = function () {
    return options.paymentMethods.length > 0;
  };

  init = function init() {
    var element = self.el;
    errorBoxElement = $('#voucher-error-claim');
    ko.applyBindings(self, element);
    self.setupHandlers();
    completePurchaseCta.addEventListener("click", self.completePurchase);
  };

  init();
};

Kobo.Gizmo.ReviewPurchase.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);