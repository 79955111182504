"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.SavePaymentMethod.ViewModels.SaveButtonVM', SaveButtonViewModel);

  function SaveButtonViewModel(template, options) {
    var self = this;

    this.init = function () {
      self.template = template;
      self.options = options;
    };

    this.init();
  }
})();