"use strict";

/*global Kobo,$,ko*/
Kobo.Gizmo.ScrollingList.MarkupOnly = function (element, options) {
  "use strict";

  var self = this;
  self.defaults = {
    ListViewModelClassName: 'FitListToContainer',
    navViewModel: 'Paging.Static',
    animationMixin: 'VerticalScrollAnimation',
    defaultHeight: '400px',
    scrollOnMouseWheel: false
  };
  Kobo.Gizmo.ScrollingList.apply(this, arguments);
  self.viewModels.listViewModel.isActivated(true);
  self.viewModels.navViewModel.isActivated(true);

  Kobo._gizmo.register("pageChanged");

  Kobo._gizmo.subscribe("pageChanged", function () {
    self.viewModels.listViewModel.items.find('a').attr('tabindex', '-1').attr('aria-hidden', 'true');
    var currentPage = self.viewModels.navViewModel.currentPage();
    var pageSize = self.viewModels.navViewModel.pageSize();
    var pageItems = self.viewModels.listViewModel.items.slice(currentPage * pageSize - pageSize, currentPage * pageSize);
    pageItems.find('a').attr('tabindex', '0').attr('aria-hidden', 'false');
  });

  Kobo._gizmo.fire("pageChanged");
};

Kobo.Gizmo.ScrollingList.MarkupOnly.prototype = Kobo.chainPrototype(Kobo.Gizmo.ScrollingList.prototype);

Kobo.Gizmo.ScrollingList.MarkupOnly.prototype.loadPages = function (num) {};