"use strict";

/*global Kobo,$,ko,document*/

/**
 * Created with IntelliJ IDEA.
 * User: cjardine
 * Date: 13-02-07
 * Time: 8:21 AM
 * To change this template use File | Settings | File Templates.
 */
Kobo.Gizmo.ScrollingList = function (element, options) {
  "use strict";

  var self = this,
      init;
  self.defaults = self.defaults || {
    ListViewModelClassName: 'List',
    ItemViewModelClassName: 'Item',
    navViewModel: 'Paging.Dynamic',
    animationMixin: 'HorizontalScrollAnimation',
    scrollOnMouseWheel: false,
    navigationOptions: {}
  };
  self.settings = Kobo.extend({}, options);
  Kobo.Gizmo.apply(this, arguments);
  self.expandable = null;
  self.currentItemViewModel = ko.observable();
  self.setType("ScrollingList");
  self.viewModels = {
    listViewModel: "",
    navViewModel: ""
  };
  self.element = element;
  self.elementList = Kobo.$(element).find('.list')[0];
  self.elementNav = Kobo.$(element).find('.nav')[0];
  self.QuickViewEnabled = self.settings.QuickView && (self.settings.QuickView === true || self.settings.QuickView.toLowerCase() === "true");
  self.gizmoEvents = {
    onclick: function onclick(e) {
      if (!self.viewModels.listViewModel.isActivated()) {
        if (self.QuickViewEnabled) {
          self.viewModels.listViewModel.isActivated(true);
        }
      }

      self.viewModels.listViewModel.onclick(e);
    },
    onmousewheel: function onmousewheel(e) {
      var NavViewModel = self.viewModels.navViewModel,
          delta = Math.max(-1, Math.min(1, e.originalEvent.wheelDelta || -e.originalEvent.detail));

      if (delta <= 0) {
        if (NavViewModel.existsNextPage()) {
          e.preventDefault();
          self.viewModels.navViewModel.showNext();
        }
      } else {
        if (NavViewModel.existsPreviousPage()) {
          e.preventDefault();
          self.viewModels.navViewModel.showPrevious();
        }
      }
    }
  };

  init = function init() {
    self.unhide(); // instantiate the viewModel and navViewModel, resolve ItemViewModelClassName

    self.instantiateViewModels(); // hook up link between navigation and view model

    self.setupLinkBetweenNavigationAndMainViewModel(self.elementList);

    if (self.QuickViewEnabled) {
      self.setupQuickView();
      self.subscribeToCurrentItemViewModel();
    } // Setup event handlers for onclick and onhover


    self.setupEvents();
    self.addBottomWhiteBorderIfFirstOfMultipleCarousel(); // get 2 pages of data:

    self.loadPages(2);
  };

  init();
};

Kobo.Gizmo.ScrollingList.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.ScrollingList.prototype.addBottomWhiteBorderIfFirstOfMultipleCarousel = function () {
  var self = this;
  self.subscribe("gizmosInitialized", function () {
    var gizmoContainer = self.$gizmo.parent();

    if (self.shouldAddBorder(gizmoContainer)) {
      gizmoContainer.addClass("white-border-bottom");
    }
  });
};

Kobo.Gizmo.ScrollingList.prototype.shouldAddBorder = function (gizmoContainer) {
  if (gizmoContainer.isEmptyObject) {
    return false;
  }

  if (gizmoContainer.data("kobo-widget") === "CarouselWidget" && gizmoContainer.next().data("kobo-widget") === "CarouselWidget" && !gizmoContainer.hasClass("white-border-bottom")) {
    return true;
  }

  return false;
};

Kobo.Gizmo.ScrollingList.prototype.instantiateViewModels = function () {
  "use strict";

  var self = this,
      NavViewModelClass,
      ItemViewModelClass,
      ListViewModelClass,
      settings,
      errorMsg;

  if (this.elementNav) {
    NavViewModelClass = Kobo.Object.resolveKoboNamespace('ViewModel.Navigation.' + this.settings.navViewModel);

    if (NavViewModelClass) {
      this.viewModels.navViewModel = new NavViewModelClass(this.elementNav, Kobo.extend({}, {
        pages: [],
        totalNumberOfItems: this.settings.totalNumberOfItems,
        useCircularScroll: this.settings.useCircularScroll
      }, this.settings.navigationOptions));
    } else {
      this.error('instantiateViewModels', 'ScrollingList', this, 'Cannot instantiate NavViewModel as [' + 'ViewModel.Navigation.' + this.settings.navViewModel + ']');
    }
  }

  ItemViewModelClass = Kobo.Object.resolveKoboNamespace('ViewModel.' + this.settings.ItemViewModelClassName);

  if (this.viewModels.ItemViewModelClass) {
    errorMsg = 'Cannot resolve ItemViewModelClassName as [Kobo.ViewModel.' + this.settings.ItemViewModelClassName + ']';
    this.error('instantiateViewModels', 'ScrollingList', this, errorMsg);
    throw new Error('[Kobo.Gizmo.ScrollingList.instantiateViewModels] ' + errorMsg);
  }

  settings = this.settings;
  settings.ItemViewModelClass = ItemViewModelClass;
  settings.fetchUrl = this.settings.url;
  ListViewModelClass = Kobo.Object.resolveKoboNamespace('ViewModel.' + this.settings.ListViewModelClassName);

  if (!ListViewModelClass) {
    errorMsg = 'Cannot instantiate listViewModel as [' + 'ViewModel.' + this.settings.ListViewModelClassName + ']';
    this.error('instantiateViewModels', 'ScrollingList', this, errorMsg);
    throw new Error('[Kobo.Gizmo.ScrollingList.instantiateViewModels] ' + errorMsg);
  }

  this.viewModels.listViewModel = new ListViewModelClass(this.elementList, settings, self);
};

Kobo.Gizmo.ScrollingList.prototype.setupQuickView = function () {
  "use strict";

  var self = this;
  self.subscribe("gizmosInitialized", function () {
    if (self.expandable === null) {
      var length = self.descendants.length,
          current,
          number,
          i;

      if (length > 0) {
        for (i = 0; i < length; i = i + 1) {
          current = self.descendants[i];

          if (current.type === "Class.Gizmo.Expandable") {
            self.expandable = current;
          }
        }
      } else {
        self.expandable = false;
      }
    }
  });
};

Kobo.Gizmo.ScrollingList.prototype.loadPages = function (num) {
  "use strict";

  var self = this; // Is there prefetched view model data? If so, use it.

  if (self.settings.Prefetch && self.settings.Prefetch !== "null") {
    if (self.settings.Prefetch.length) {
      var items = self.settings.Prefetch;
      self.viewModels.listViewModel.addNewItems(items);
    }
  } else {
    this.viewModels.listViewModel.fetchData(function (newItems) {
      self.viewModels.listViewModel.addNewItems(newItems);
    }, {
      startingIndex: 0,
      endIndex: 9
    }); // changed this to fetch at position 0
  }
};

Kobo.Gizmo.ScrollingList.prototype.subscribeToCurrentItemViewModel = function () {
  "use strict";

  var self = this;

  if (self.viewModels.listViewModel.currentItemViewModel) {
    self.viewModels.listViewModel.currentItemViewModel.subscribe(function (newItemViewModel) {
      if (self.expandable && self.expandable.viewModel) {
        self.expandable.viewModel.showBookInfo(newItemViewModel);
      }
    });
  }
};

Kobo.Gizmo.ScrollingList.prototype.setupLinkBetweenNavigationAndMainViewModel = function (element) {
  "use strict";

  var self = this,
      navViewModel = this.viewModels.navViewModel,
      listViewModel = this.viewModels.listViewModel,
      elementToConvertToTemplate = element;

  if (!navViewModel || !navViewModel.navObject) {
    return;
  }

  Kobo.subscribeObservablesFromTo(navViewModel.navObject.read, listViewModel, null, true);
  Kobo.subscribeObservablesFromTo(listViewModel, navViewModel.navObject.write, {
    scrollPositionIndexCorrected: 'scrollPositionIndexCorrected',
    numberOfItemsToDisplayPerPage: 'pageSize',
    totalNumberOfItems: 'totalNumberOfItems',
    totalPages: 'numberOfAvailPages'
  }, true);
};

Kobo.Gizmo.ScrollingList.prototype.setupEvents = function () {
  "use strict";

  var self = this;
  self.$el.find('.ul-container').on("click", '[data-purchaseUrl], .book-item-image-container, .book-title', self.gizmoEvents.onclick);
  self.$el.find('.ul-container').on('mouseover', self.gizmoEvents.onmouseover);
  self.$el.find('.ul-container').on('mouseout', self.gizmoEvents.onmouseout);
  self.$el.find('.quick-view-content').on("click", '[data-purchaseUrl]', self.gizmoEvents.onclick);

  if (self.settings.scrollOnMouseWheel) {
    self.$el.on("mousewheel", self.gizmoEvents.onmousewheel);
    self.$el.on("DOMMouseScroll", self.gizmoEvents.onmousewheel);
  }
};

Kobo.Gizmo.ScrollingList.prototype.unhide = function () {
  "use strict";

  var self = this;
  self.$el.removeClass('hidden');
};