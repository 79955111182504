"use strict";

/*global Kobo,ko,braintree*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.SavePaymentMethod.ViewModels.SaveBraintreeCreditCardVM', SaveBraintreeCreditCardViewModel);

  function SaveBraintreeCreditCardViewModel(options) {
    "use strict";

    var self = this,
        FailureReason = Kobo.SavePaymentMethod.FailureReason,
        SaveButtonVM = Kobo.SavePaymentMethod.ViewModels.SaveButtonVM,
        _Kobo$PurchasePath$En = Kobo.PurchasePath.Enums,
        PaymentType = _Kobo$PurchasePath$En.PaymentType,
        ConvertPaymentTypeToServer = _Kobo$PurchasePath$En.ConvertPaymentTypeToServer,
        ConvertCardTypeToServer = _Kobo$PurchasePath$En.ConvertCardTypeToServer,
        ThreeDSecure = _Kobo$PurchasePath$En.ThreeDSecure;

    this.handleSubmit = function () {
      var doSubmit = function doSubmit(payload) {
        var paymentMethodData = {
          paymentMethodType: self.paymentMethodType,
          paymentDetails: {
            PaymentType: ConvertPaymentTypeToServer(PaymentType.CreditCard),
            BraintreeCreditCardToken: payload.nonce,
            ExpiryMonth: payload.details.expirationMonth,
            ExpiryYear: payload.details.expirationYear,
            CardType: ConvertCardTypeToServer(payload.details.cardType.toLowerCase()),
            DeviceData: self.deviceData
          }
        };
        return self.savePaymentMethod(paymentMethodData);
      };

      return self.validateHostedFields().then(self.tokenizePaymentMethod).then(doSubmit).then(self.handleSavePaymentMethodSuccess)["catch"](self.handleSavePaymentMethodFailure);
    };

    this.submitWithThreeDSecureVerification = function () {
      var verifyWithThreeDSecure = function verifyWithThreeDSecure(payload) {
        return new Promise(function (resolve, reject) {
          Kobo._mediator.fire('threeDSecure::verifyCard', {
            nonce: payload.nonce,
            bin: payload.details.bin,
            paymentType: PaymentType.BraintreeCreditCard,
            amount: '1.00',
            verifyAction: Kobo.PurchasePath.Enums.ThreeDSecure.VerifyAction.Vault,
            verifyCaller: self.caller,
            successCallback: function successCallback(response) {
              return response.challengeStatus === ThreeDSecure.ChallengeStatus.ChallengeSuccess || response.challengeStatus === ThreeDSecure.ChallengeStatus.NoChallengeSuccess ? resolve(response.nonce) : reject(FailureReason.CardholderAuthenticationFailed);
            },
            canceledCallback: function canceledCallback() {
              return reject(FailureReason.CancelledCardholderAuthentication);
            },
            errorCallback: function errorCallback() {
              return reject(FailureReason.CardholderAuthenticationFailed);
            }
          });
        });
      };

      var doSubmit = function doSubmit(nonce) {
        var paymentMethodData = {
          paymentMethodType: self.paymentMethodType,
          paymentDetails: {
            PaymentType: ConvertPaymentTypeToServer(PaymentType.CreditCard),
            BraintreeCreditCardToken: nonce,
            DeviceData: self.deviceData
          }
        };
        return self.savePaymentMethod(paymentMethodData);
      };

      return self.validateHostedFields().then(self.tokenizePaymentMethod).then(verifyWithThreeDSecure).then(doSubmit).then(self.handleSavePaymentMethodSuccess)["catch"](self.handleSavePaymentMethodFailure);
    };

    this.validateHostedFields = function () {
      return new Promise(function (resolve, reject) {
        Kobo._mediator.fire('braintreeHostedFields::validate', {
          validatedCallback: function validatedCallback(isFormValid) {
            if (!isFormValid) {
              Kobo._mediator.fire('braintreeHostedFields::submittedWithInvalidFields');

              reject(FailureReason.CreditCardInvalid);
              return;
            }

            resolve();
          }
        });
      });
    };

    this.tokenizePaymentMethod = function () {
      return new Promise(function (resolve, reject) {
        self.submitFormEvent.notifySubscribers({
          callback: function callback(err, payload) {
            if (err) {
              reject(FailureReason.CreditCardGenericIssue);
              return;
            }

            resolve(payload);
          }
        });
      });
    };

    this.handleSavePaymentMethodSuccess = function () {
      self.savePaymentMethodSuccessEvent.notifySubscribers();
    };

    this.handleSavePaymentMethodFailure = function (failureReason) {
      if (failureReason === FailureReason.VaultThreeDSecureRequired) {
        self.submitWithThreeDSecureVerification();
        return;
      }

      self.savePaymentMethodFailureEvent.notifySubscribers(failureReason);
    };

    this.clearInvalidErrorMessageIfAllFieldsValid = function () {
      var fetchFormState = function fetchFormState() {
        return new Promise(function (resolve) {
          Kobo._mediator.fire('braintreeHostedFields::fetchFormState', {
            formStateReadyCallback: resolve
          });
        });
      };

      return fetchFormState().then(function (formState) {
        var fields = formState.fields;
        var isFormValid = Object.keys(fields).every(function (field) {
          return fields[field].isValid;
        });
        if (!isFormValid) return;

        Kobo._mediator.fire('kobo-message-box::clearMessageByName', {
          recipientMessageBoxName: 'savePaymentMethod',
          messageName: 'invalidHostedField'
        });
      });
    };

    this.setupEvents = function () {
      self.isValidEvent = new ko.subscribable();
      self.submitFormEvent = new ko.subscribable();
      self.deviceDataReadyEvent = new ko.subscribable();
      self.teardownFormEvent = new ko.subscribable();

      Kobo._mediator.register('braintreeHostedFields::submitRequested');

      Kobo._mediator.subscribe('braintreeHostedFields::submitRequested', self.handleSubmit);

      self.isValidEvent.subscribe(self.clearInvalidErrorMessageIfAllFieldsValid);
      self.deviceDataReadyEvent.subscribe(function (data) {
        self.deviceData = data;
      });
    };

    this.init = function () {
      var paymentMethodType = options.PaymentMethodType,
          icons = options.Icons,
          key = options.braintreeClientToken,
          recordVerifyCardMetricsUrl = options.recordVerifyCardMetricsUrl,
          savePaymentMethod = options.savePaymentMethod,
          savePaymentMethodSuccessEvent = options.savePaymentMethodSuccessEvent,
          savePaymentMethodFailureEvent = options.savePaymentMethodFailureEvent,
          caller = options.caller;
      self.key = key;
      self.recordVerifyCardMetricsUrl = recordVerifyCardMetricsUrl;
      self.deviceData = null;
      self.label = window.DynamicConfiguration.savePaymentMethod.resourceStrings.creditDebitLabel;
      self.paymentMethodType = paymentMethodType;
      self.icons = icons;
      self.caller = caller;
      self.template = 'save-payment-method-braintree-credit-card-template';
      self.button = new SaveButtonVM('save-payment-method-braintree-credit-card-button-template');
      self.savePaymentMethod = savePaymentMethod;
      self.savePaymentMethodSuccessEvent = savePaymentMethodSuccessEvent;
      self.savePaymentMethodFailureEvent = savePaymentMethodFailureEvent;
      self.setupEvents();
    };

    this.init();
  }
})();