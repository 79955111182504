"use strict";

/*global Kobo,ko,window,DynamicConfiguration*/
Kobo.Gizmo.PaymentOptions = function (el, options) {
  "use strict";

  var self = this,
      CreditCard,
      Ideal,
      RakutenSuperpoints,
      Vme,
      setupHandlers,
      setCreditCardFields,
      setIdealFields,
      setRakutenSuperPointsFields,
      setVmeFields,
      setupCreditCardDropdowns,
      setCurrentPaymentMethodError,
      isPaymentMethodAvailable,
      selectDefaultPaymentMethod,
      onUpdate,
      onGAEventTrigger,
      onMarshallOrderData,
      onValidate,
      onValidateIdeal,
      onCreditCardValidated,
      onUpdateSufficientPayment,
      onContinuePurchaseValid,
      onEnableEditMode,
      onDisableEditMode,
      onSetCurrentPaymentMethodError,
      hideSecurityCodeHelp,
      showSecurityCodeHelp,
      getDistanceToViewportBottom,
      setTooltipDirection,
      init,
      braintreeToken = options.braintreeToken,
      merchantAccountId = options.braintreeMerchantAccountId,
      collectDeviceData,
      deviceData = '',
      braintreePaymentConfig,
      isDoNotCaptureCreditCardHolderNameEnabled = options.isDoNotCaptureCreditCardHolderNameEnabled;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("PaymentOptions");
  self.DEFAULT_PAYMENT_METHOD = ''; // View models

  CreditCard = function CreditCard() {
    var self = this;
    self.ccPaymentProvider = null;
    self.isAvailable = ko.observable('');
    self.type = ko.observable('');
    self.expiryYear = ko.observable('');
    self.expiryMonth = ko.observable('');
    self.lastFourDigits = ko.observable('');
    self.fullName = {
      name: ko.observable(''),
      label: ko.observable(''),
      value: ko.observable('')
    }; // Credit card number and cvv are handled by a third-party. We don't get to touch it.
    // Instead, we receive tokens that can be used as proxies for these fields.

    self.creditCardToken = ko.observable('');
    self.cvvToken = ko.observable('');

    self.clearTokens = function () {
      self.creditCardToken('');
      self.cvvToken('');
    }; // whether there is credit card info already saved on the backend, so the user can skip entering credit card details


    self.hasSavedInfo = ko.observable(false); // whether the user wants to use saved info or use different info

    self.useSavedInfo = ko.computed(function () {
      return self.hasSavedInfo() && !self.editMode();
    });
    self.savedInformation = ko.computed(function () {
      return "************" + self.lastFourDigits();
    });
    braintreePaymentConfig = {
      cardType: "",
      key: braintreeToken,
      isOldPP: true,
      createFields: true,
      collectDeviceData: true,
      isDoNotCaptureCreditCardHolderNameEnabled: isDoNotCaptureCreditCardHolderNameEnabled
    };
    self.editMode = ko.observable(false);

    self.enableEditMode = function () {
      Kobo._mediator.fire("braintreeCard::refreshIframe");

      self.editMode(true);
      self.ccPaymentProvider = self.ccPaymentProvider || new Kobo.Purchasing.BraintreePayments(braintreePaymentConfig);

      if (self.ccPaymentProvider) {
        self.creditCard.ccPaymentProvider.collectDeviceDataEvent.subscribe(function (collectedDeviceData) {
          deviceData = collectedDeviceData;
        });
      }
    };

    self.disableEditMode = function () {
      self.editMode(false);
    }; // drop down options for credit card input fields


    self.types = ko.observableArray([]);
    self.expiryMonths = ko.observableArray([]);
    self.expiryYears = ko.observableArray([]);
    self.error = ko.observable(); // The same error message can be set twice. Notifications should be sent even if the new value is the same as the previous one

    self.error.extend({
      notify: 'always'
    });
  }; // a payment method used in the Netherlands


  Ideal = function Ideal() {
    var self = this;
    self.isAvailable = ko.observable();
    self.isSelected = ko.observable();
    self.banks = ko.observableArray();
    self.bank = ko.observable();
    self.error = ko.observable();
    self.error.extend({
      notify: 'always'
    });
  };

  RakutenSuperpoints = function RakutenSuperpoints() {
    var self = this;
    self.isAvailable = ko.observable(false);
    self.isSelected = ko.observable(false);
    self.error = ko.observable(false);
    self.availableBalance = ko.observable();
  };

  Vme = function Vme() {
    var self = this;
    self.isAvailable = ko.observable();
    self.isSelected = ko.observable();
    self.error = ko.observable();
    self.error.extend({
      notify: 'always'
    });
  };

  setIdealFields = function setIdealFields(data) {
    if (!data) {
      return;
    }

    self.ideal.isAvailable(data.IsAvailable);
    self.ideal.isSelected(data.IsSelected);
    self.ideal.banks(data.Banks);
    self.ideal.bank(data.Bank);
  };

  setRakutenSuperPointsFields = function setRakutenSuperPointsFields(data) {
    if (!data) {
      return;
    }

    self.superpoints.isAvailable(data.IsAvailable);
    self.superpoints.error(false);

    if (data.IsAvailable) {
      if (!data.Balance) {
        self.superpoints.error(true);
      } else {
        self.superpoints.availableBalance(data.Balance);
      }
    }
  };

  setVmeFields = function setVmeFields(data) {
    if (!data) {
      return;
    }

    self.vme.isAvailable(data.IsAvailable);
    self.vme.isSelected(data.IsSelected);
  };

  setCreditCardFields = function setCreditCardFields(data) {
    if (!data) {
      return;
    } // TODO camel case JSON property names


    setupCreditCardDropdowns(data.ExpiryMonths, data.ExpiryYears, data.Types);
    self.creditCard.isAvailable(data.IsAvailable);
    self.creditCard.expiryYear(data.ExpiryYear);
    self.creditCard.expiryMonth(data.ExpiryMonth);
    self.creditCard.type(data.Type);
    self.creditCard.lastFourDigits(data.LastFourDigits);
    self.creditCard.creditCardToken(data.CreditCardToken);
    self.creditCard.cvvToken(data.CvvToken);
    self.creditCard.hasSavedInfo(data.HasSavedInfo);
    data.Name = data.Name || {};
    self.creditCard.fullName.name(data.Name.Name);
    self.creditCard.fullName.value(data.Name.Value);
    self.creditCard.fullName.label(data.Name.Label);
  };

  setupCreditCardDropdowns = function setupCreditCardDropdowns(expiryMonths, expiryYears, types) {
    if (expiryMonths && self.creditCard.expiryMonth() === "") {
      self.creditCard.expiryMonths.removeAll();
      Kobo.$.each(expiryMonths, function () {
        self.creditCard.expiryMonths.push(this);
      });
    }

    if (expiryYears && self.creditCard.expiryYear() === "") {
      self.creditCard.expiryYears.removeAll();
      Kobo.$.each(expiryYears, function () {
        self.creditCard.expiryYears.push(this);
      });
    } // have to reload this list if country changes


    if (types) {
      self.creditCard.types.removeAll();
      Kobo.$.each(types, function () {
        self.creditCard.types.push(this);
      });
    }
  };

  hideSecurityCodeHelp = function hideSecurityCodeHelp() {
    self.$gizmo.find('.show-tooltip').removeClass('show-tooltip');
    self.$gizmo.find('.security-code-outer').removeClass('clicked');
  };

  showSecurityCodeHelp = function showSecurityCodeHelp(event) {
    var tooltip = self.$gizmo.find('#security-code-tooltip'),
        helpIcon = self.$gizmo.find('.security-code-outer'),
        distanceToViewportBottom = getDistanceToViewportBottom(helpIcon),
        tooltipClearance = 10,
        maxDistanceToViewportBottomForTopArrowTooltip = tooltip[0].offsetHeight + tooltipClearance,
        showBottomArrowToolip = distanceToViewportBottom < maxDistanceToViewportBottomForTopArrowTooltip;
    setTooltipDirection(tooltip, showBottomArrowToolip);

    if (event.type === 'click') {
      if (helpIcon.hasClass('clicked')) {
        hideSecurityCodeHelp();
      } else {
        helpIcon.addClass('clicked');
        window.setTimeout(function () {
          Kobo.$('body').one('click', hideSecurityCodeHelp);
        }, 0);
        /* activated once only to remove tooltip on click (anywhere) */
      }
    }
  };

  getDistanceToViewportBottom = function getDistanceToViewportBottom(element) {
    var box = element[0].getBoundingClientRect(),
        body = document.body,
        docElem = document.documentElement,
        clientHeight = docElem.clientHeight || body.clientHeight || 0;
    return clientHeight - box.bottom;
  };

  setTooltipDirection = function setTooltipDirection(element, showBottom) {
    element.addClass('show-tooltip');

    if (showBottom) {
      element.addClass('info-message-bottom-arrow').removeClass('info-message-top-arrow');
    } else {
      element.addClass('info-message-top-arrow').removeClass('info-message-bottom-arrow');
    }
  };

  onGAEventTrigger = function onGAEventTrigger(eventName, data) {
    self.$gizmo.append(data.detail.gaEvent);
  };

  onUpdate = function onUpdate(eventName, data) {
    var template = data.detail.paymentOptionsTemplate,
        selectedPaymentMethod = data.detail.selectedPaymentMethod,
        paymentOptions = data.detail.paymentOptions,
        errorMessage = data.detail.errorMessage;

    if (template) {
      ko.cleanNode(self.$gizmo[0]);
      self.$gizmo.html(template); // TODO: destroy old gizmos?

      ko.applyBindings(self, self.$gizmo[0]);

      Kobo._gizmo.initContext(self.$gizmo);
    }

    braintreePaymentConfig = {
      cardType: "",
      key: braintreeToken,
      isOldPP: true,
      createFields: true,
      collectDeviceData: true,
      merchantAccountId: merchantAccountId,
      triggerBraintreeLocalPayment: false,
      isDoNotCaptureCreditCardHolderNameEnabled: isDoNotCaptureCreditCardHolderNameEnabled
    };

    if (selectedPaymentMethod) {
      self.selectedPaymentMethod(selectedPaymentMethod);

      if (selectedPaymentMethod === 'creditcard') {
        self.creditCard.ccPaymentProvider = self.creditCard.ccPaymentProvider || new Kobo.Purchasing.BraintreePayments(braintreePaymentConfig);
        if (self.creditCard.ccPaymentProvider) self.creditCard.ccPaymentProvider.collectDeviceDataEvent.subscribe(function (collectedDeviceData) {
          deviceData = collectedDeviceData;
        });
      }
    }

    if (paymentOptions) {
      // TODO camel case JSON property names
      setCreditCardFields(paymentOptions.CreditCard);
      setIdealFields(paymentOptions.Ideal);
      setRakutenSuperPointsFields(paymentOptions.SuperPoints);
      setVmeFields(paymentOptions.Vme);
    }

    if (!self.selectedPaymentMethod() || !isPaymentMethodAvailable(self.selectedPaymentMethod())) {
      selectDefaultPaymentMethod();
    }

    setCurrentPaymentMethodError(errorMessage);
    self.fire('regularCheckout::updateButton');
  };

  collectDeviceData = function collectDeviceData(eventName, data) {
    var saveDeviceData = data.detail.saveDeviceData;
    saveDeviceData(deviceData);
  };

  isPaymentMethodAvailable = function isPaymentMethodAvailable(paymentMethod) {
    if (paymentMethod === 'creditcard') {
      return self.creditCard.isAvailable();
    }

    if (paymentMethod === 'ideal') {
      return self.ideal.isAvailable();
    }

    if (paymentMethod === 'superpoints') {
      return self.superpoints.isAvailable();
    }

    if (paymentMethod === 'vme') {
      return self.vme.isAvailable();
    }

    Kobo.log('Unrecognized payment method ' + paymentMethod);
    return false;
  };

  selectDefaultPaymentMethod = function selectDefaultPaymentMethod() {
    self.selectedPaymentMethod(self.DEFAULT_PAYMENT_METHOD);
  };

  setCurrentPaymentMethodError = function setCurrentPaymentMethodError(errorMessage) {
    switch (self.selectedPaymentMethod()) {
      case 'creditcard':
        self.creditCard.error(errorMessage);
        break;

      case 'ideal':
        self.ideal.error(errorMessage);
        break;

      case 'vme':
        self.vme.error(errorMessage);
        break;

      default:
        break;
    }

    if (errorMessage) {
      self.fire('regularCheckout::requestFocus', {
        target: self.gizmo
      });
    }
  };

  onValidate = function onValidate(eventName, data) {
    setCurrentPaymentMethodError(null);

    if (self.selectedPaymentMethod() === 'creditcard' && !self.creditCard.useSavedInfo() && !self.sufficientPayment()) {
      if (data && data.detail && data.detail.purchaseMode) {
        self.purchaseMode = data.detail.purchaseMode;
      }

      self.creditCard.clearTokens();
      self.fire('braintreeCard::validate');
    } else if (self.selectedPaymentMethod() === 'ideal' && !self.sufficientPayment()) {
      onValidateIdeal();
    } else if (self.selectedPaymentMethod() === 'superpoints') {
      self.fire('regularCheckout::clearAppliedRakutenSuperpoints');
    } else {
      // nothing to do
      self.fire('paymentOptions::validated', {
        isSuccess: true
      });
    }
  };

  onValidateIdeal = function onValidateIdeal() {
    var bankIsSelected = self.ideal.bank() !== undefined;

    if (!bankIsSelected) {
      self.ideal.error(DynamicConfiguration.resourceStrings.idealMessages.errorNoBankSelected);
    } else {
      self.ideal.error("");
    }

    self.fire('paymentOptions::validated', {
      isSuccess: bankIsSelected
    });
  };

  onCreditCardValidated = function onCreditCardValidated(eventName, data) {
    if (data.detail.isSuccess) {
      self.creditCard.creditCardToken(data.detail.creditCardToken);
      self.creditCard.cvvToken(data.detail.cvvToken);
      self.creditCard.lastFourDigits(data.detail.lastFourCardDigits);
      self.creditCard.expiryMonth(data.detail.expiryMonth);
      self.creditCard.expiryYear(data.detail.expiryYear);
    } else {
      // If credit card is optional and user hasn't entered any information or incorrect information, then don't show an error message.
      if (self.purchaseMode === 'CCNotRequired') {
        // nothing to do
        self.fire('paymentOptions::validated', {
          isSuccess: true
        });
        data.detail.errorMessage = '';
      }
    }

    setCurrentPaymentMethodError(data.detail.errorMessage);
    self.fire('paymentOptions::validated', {
      isSuccess: data.detail.isSuccess
    });
  };

  onMarshallOrderData = function onMarshallOrderData(eventName, data) {
    var orderData = data.detail.orderData,
        // detail.orderData is an output parameter that will be used by caller upon return
    paymentOptionViewModel,
        paymentOption = {};
    orderData.HasSufficientPayment = self.sufficientPayment();

    if (self.sufficientPayment()) {
      return;
    } // TODO camel case JSON property names


    orderData.SelectedPaymentMethod = self.selectedPaymentMethod();

    if (orderData.SelectedPaymentMethod === 'creditcard') {
      paymentOptionViewModel = self.creditCard;
      orderData.CreditCard = paymentOption;
      paymentOption.UseSavedInfo = paymentOptionViewModel.useSavedInfo();

      if (!paymentOption.UseSavedInfo) {
        paymentOption.Type = paymentOptionViewModel.type();
        paymentOption.ExpiryMonth = paymentOptionViewModel.expiryMonth();
        paymentOption.ExpiryYear = paymentOptionViewModel.expiryYear();
        paymentOption.CreditCardToken = paymentOptionViewModel.creditCardToken();
        paymentOption.CvvToken = paymentOptionViewModel.cvvToken();
        paymentOption.Name = {
          Name: paymentOptionViewModel.fullName.name(),
          Label: paymentOptionViewModel.fullName.label(),
          Value: paymentOptionViewModel.fullName.value()
        };
        paymentOption.FullName = paymentOptionViewModel.fullName.value();
      }
    } else if (orderData.SelectedPaymentMethod === 'ideal') {
      paymentOptionViewModel = self.ideal;
      orderData.Ideal = paymentOption;
      orderData.Ideal.Bank = paymentOptionViewModel.bank();
    }
  };

  onUpdateSufficientPayment = function onUpdateSufficientPayment(eventName, data) {
    if (Kobo.Object.typeOf(data.detail.sufficientPayment) === "boolean") {
      self.sufficientPayment(data.detail.sufficientPayment);
    }
  };

  onContinuePurchaseValid = function onContinuePurchaseValid() {
    self.creditCard.disableEditMode();
  };

  onDisableEditMode = function onDisableEditMode() {
    self.creditCard.disableEditMode();
  };

  onEnableEditMode = function onEnableEditMode() {
    self.creditCard.enableEditMode();
  };

  onSetCurrentPaymentMethodError = function onSetCurrentPaymentMethodError(e, data) {
    setCurrentPaymentMethodError(data.detail.errorMessage);
  };

  setupHandlers = function setupHandlers() {
    self.register('paymentOptions::update');
    self.register('paymentOptions::validate');
    self.register('paymentOptions::validated');
    self.register('paymentOptions::gaEventTrigger');
    self.register('paymentOptions::enableEditMode');
    self.register('paymentOptions::disableEditMode');
    self.register('paymentOptions::setCurrentPaymentMethodError');
    self.register('paymentOptions::collectDeviceData');
    self.subscribe('paymentOptions::gaEventTrigger', onGAEventTrigger);
    self.subscribe('paymentOptions::update', onUpdate);
    self.subscribe('paymentOptions::validate', onValidate);
    self.subscribe('creditCard::validated', onCreditCardValidated);
    self.subscribe('braintreeCard::validated', onCreditCardValidated);
    self.subscribe('regularCheckout::marshallOrderData', onMarshallOrderData);
    self.subscribe('regularCheckout::updateSufficientPayment', onUpdateSufficientPayment);
    self.subscribe("regularCheckout::continuePurchaseValid", onContinuePurchaseValid);
    self.subscribe('paymentOptions::enableEditMode', onEnableEditMode);
    self.subscribe('paymentOptions::disableEditMode', onDisableEditMode);
    self.subscribe('paymentOptions::setCurrentPaymentMethodError', onSetCurrentPaymentMethodError);
    self.subscribe('paymentOptions::collectDeviceData', collectDeviceData);
    self.$gizmo.on('click', '.skip-this', function () {
      self.fire('paymentOptions::validate');
    });
    self.koSubscriptions.push(self.selectedPaymentMethod.subscribe(function () {
      self.fire('regularCheckout::updateButton');
    }));
    self.$gizmo.on('click', '.security-code-outer', showSecurityCodeHelp);
    self.$gizmo.on('mouseover', '.security-code-outer', showSecurityCodeHelp);
    self.$gizmo.on('mouseout', '.security-code-outer', hideSecurityCodeHelp);
    self.$gizmo.on('change', '.paymentoptions-headings input[type=radio]', function () {
      self.selectedPaymentMethod(Kobo.$(this).val());
    });
  };

  self.destroy = function () {
    self.$gizmo.off('click', 'input[type=radio]');
    self.$gizmo.off('click', '.security-code-outer', showSecurityCodeHelp);
    self.$gizmo.off('mouseover', '.security-code-outer', showSecurityCodeHelp);
    self.$gizmo.off('mouseout', '.security-code-outer', hideSecurityCodeHelp);
    self.unSubscribe('regularCheckout::marshallOrderData', onMarshallOrderData);
    self.unSubscribe('creditCard::validated', onCreditCardValidated);
    self.unSubscribe('braintreeCard::validated', onCreditCardValidated);
    self.unSubscribe('paymentOptions::validate', onValidate);
    self.unSubscribe('paymentOptions::update', onUpdate);
    self.unSubscribe("regularCheckout::continuePurchaseValid", onContinuePurchaseValid);
    self.unSubscribe("paymentOptions::gaEventTrigger", onGAEventTrigger);
    self.unSubscribe('paymentOptions::enableEditMode', onEnableEditMode);
    self.unSubscribe('paymentOptions::disableEditMode', onDisableEditMode);
    self.unSubscribe('paymentOptions::setCurrentPaymentMethodError', onSetCurrentPaymentMethodError);
    self.unSubscribe('paymentOptions::collectDeviceData', collectDeviceData);
    Kobo.$.each(self.koSubscriptions, function () {
      this.dispose();
    });
  };

  init = function init() {
    self.koSubscriptions = [];
    self.creditCard = new CreditCard();
    self.ideal = new Ideal();
    self.superpoints = new RakutenSuperpoints();
    self.vme = new Vme();
    self.selectedPaymentMethod = ko.observable('');
    self.sufficientPayment = ko.observable(false);
    self.purchaseMode = '';
    setupHandlers();
  };

  init();
};

Kobo.Gizmo.PaymentOptions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);