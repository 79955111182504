"use strict";

/*global Kobo,ko,$,window*/
(function (Kobo) {
  /**
   * Class Kobo.ViewModel.List
   * @param element
   * @param options
   * @param gizmo
   * @constructor
   */
  function ViewModelSearchAutoComplete(element, options) {
    "use strict";

    var _this = this;

    var GET_SUGGESTIONS_DELAY = 250;
    Kobo.ViewModel.Base.apply(this, [element, options]);
    this.setType("Search.AutoComplete");
    this.autocompleteCategories = ["author", "book", "series"];
    this.autocompleteURL = options.autoCompleteUrl;
    this.searchURL = options.itemResultSearchUrl;
    this.query = ko.observable();
    this.items = ko.observableArray();
    this.active = ko.observable(false);
    this.hover = ko.observable(false);
    this.currentSelected = null;
    this.isActive = ko.computed(function () {
      return (_this.active() || _this.hover()) && _this.items().length > 0;
    });
    var onResizeTimeout;
    this.getResults = ko.computed(function () {
      var self = _this;
      self.options.query = self.query();
      window.clearTimeout(onResizeTimeout);
      onResizeTimeout = window.setTimeout(function () {
        if (Kobo.Object.isNullOrEmpty(self.options.query)) {
          self.items.removeAll();
          self.currentSelected = null;
        } else {
          var originalQuery = self.query();
          Kobo.Ajax({
            url: self.autocompleteURL + "?query=" + encodeURIComponent(originalQuery),
            success: function success(results) {
              if (originalQuery === self.query()) {
                self.newResults(results);
              } else {
                Kobo.log("There was a discrepancy: " + originalQuery + " !== " + self.query());

                _this.getResults();
              }
            }
          });
        }
      }, GET_SUGGESTIONS_DELAY);
    });
    this.applyBindings();
  }

  ViewModelSearchAutoComplete.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);

  ViewModelSearchAutoComplete.prototype.unSelectAll = function (isDeselect) {
    if (isDeselect) {
      this.currentSelected = null;
    }

    this.items().forEach(function (_item) {
      _item.unfocus();
    });
  };

  ViewModelSearchAutoComplete.prototype.goToCurrentActive = function () {
    Kobo.$(document).attr("location").href = this.items()[this.currentSelected].url();
  };

  ViewModelSearchAutoComplete.prototype.moveUp = function () {
    if (this.items().length > 0) {
      this.unSelectAll();

      if (!this.currentSelected || this.currentSelected === 0) {
        this.currentSelected = this.items().length - 1;
      } else if (this.currentSelected && this.currentSelected > 0) {
        this.currentSelected -= 1;
      }

      var currentSelectedItem = this.items()[this.currentSelected];
      currentSelectedItem.focus();

      if (currentSelectedItem.endsWith(currentSelectedItem.result().Type, "Header")) {
        this.moveUp();
      }
    }
  };

  ViewModelSearchAutoComplete.prototype.moveDown = function () {
    if (this.items().length > 0) {
      this.unSelectAll();

      if (this.currentSelected === null || this.currentSelected === this.items().length - 1) {
        this.currentSelected = 0;
      } else {
        this.currentSelected += 1;
      }

      var currentSelectedItem = this.items()[this.currentSelected];
      currentSelectedItem.focus();

      if (currentSelectedItem.endsWith(currentSelectedItem.result().Type, "Header")) {
        this.moveDown();
      }
    }
  };

  ViewModelSearchAutoComplete.prototype.newResults = function (newItems) {
    "use strict";

    var _this2 = this;

    this.currentSelected = null;
    this.items.removeAll();

    if (newItems.length > 0) {
      var provider = newItems.some(function (x) {
        return x.Provider == "gsp";
      });
      var sortedItems = [];
      var emptyBars = [];
      this.autocompleteCategories.forEach(function (_category) {
        var categoryItems = newItems.filter(function (_item) {
          return _item.Type === _category;
        });
        categoryItems.forEach(function (_item, index) {
          _item.Description = _item.Type + "Suggestion" + index;
        });

        if (categoryItems.length > 0) {
          sortedItems.push({
            Type: _category + "Header"
          });
        }

        sortedItems = sortedItems.concat(categoryItems);

        if (categoryItems.length > 0) {
          sortedItems.push({
            Type: _category + "Bar",
            Provider: provider ? "gsp" : null
          });
        } else {
          emptyBars.push({
            Type: _category + "Bar",
            Provider: provider ? "gsp" : null
          });
        }
      });
      sortedItems.forEach(function (_newItem) {
        _this2.items.push(new _this2.settings.ItemViewModelClass(_newItem, _this2.options));
      });
      emptyBars.forEach(function (_newItem) {
        _this2.items.push(new _this2.settings.ItemViewModelClass(_newItem, _this2.options));
      });
    }
  };

  ViewModelSearchAutoComplete.prototype.clearResults = function () {
    "use strict";

    this.query(""); // Clear the observable.

    this.items.removeAll();
  };

  function ViewModelSearchAutoCompleteItem(result, options) {
    "use strict";

    var _this3 = this;

    this.result = ko.observable(result);
    this.isSelected = ko.observable(false);
    this.url = ko.computed(function () {
      if (_this3.endsWith(_this3.result().Type, "Header")) {
        return null;
      } else if (_this3.endsWith(_this3.result().Type, "Bar")) {
        var _type = _this3.result().Type.replace("Bar", "");

        var queryString = encodeURIComponent(options.query);
        var url = options.itemResultSearchUrl + "?query=" + queryString + "&ac=1" + "&ac.morein=true";
        if (_this3.result().Provider !== null && _this3.result().Provider !== undefined) url = url + "&ac.provider=" + encodeURIComponent(_this3.result().Provider);

        if (_type === "book") {
          url += "&ac.title=" + queryString;
        } else if (_type === "author") {
          url += "&fcsearchfield=" + _type + "&ac." + _type + "=" + queryString + (options.authorRelevancyTestEnabled ? "" : "&sort=PublicationDateDesc");
        } else {
          url += "&fcsearchfield=" + _type + "&ac." + _type + "=" + queryString;
        }

        return url;
      } else {
        var url = options.itemResultSearchUrl + "?query=" + encodeURIComponent(_this3.result().Query) + "&ac=1&acp=" + encodeURIComponent(options.query);
        if (_this3.result().Provider !== null && _this3.result().Provider !== undefined) url = url + "&ac.provider=" + encodeURIComponent(_this3.result().Provider);

        if (_this3.result().Title !== null && _this3.result().Title !== undefined && _this3.result().Title !== "") {
          url = url + "&ac.title=" + encodeURIComponent(_this3.result().Title);
        }

        if (_this3.result().Series !== null && _this3.result().Series !== undefined && _this3.result().Series !== "") {
          url = url + "&ac.series=" + encodeURIComponent(_this3.result().Series);
        }

        if (_this3.result().Authors !== null && _this3.result().Authors !== undefined && _this3.result().Authors.length > 0) {
          _this3.result().Authors.forEach(function (_author) {
            if (_author !== null && _author !== "") {
              url = url + "&ac.author=" + encodeURIComponent(_author) + (options.authorRelevancyTestEnabled ? "" : "&sort=PublicationDateDesc");
            }
          });
        }

        return url;
      }
    });
    this.attrs = ko.computed(function () {
      if (_this3.result() === null || _this3.result().length === 0) {
        return "";
      } else if (_this3.endsWith(_this3.result().Type, "Header")) {
        return {
          "class": "header"
        };
      } else if (_this3.endsWith(_this3.result().Type, "Bar")) {
        return {
          "class": "more-in"
        };
      } else {
        return {
          "data-track-info": "{\"description\": \"" + _this3.result().Description + "\"}",
          "class": "result"
        };
      }
    });
    this.itemHtml = ko.computed(function () {
      var queryString = options.query.length > 24 ? options.query.substr(0, 24) + "..." : options.query;

      var _type = _this3.result().Type.replace("Header", "").replace("Bar", "");

      var pluralType = _type[_type.length - 1] === "s" ? _type : _type + "s";
      var regex = new RegExp("(?!<[^<>]*)(" + options.query.replace(/[\^\$\(\)\[\]\{\}\*\.\+\?\;\|\\]/gi, "\\$1") + ")(?![^<>]*>)", "gi");

      if (_this3.result() === null || _this3.result().length === 0) {
        return _this3.result();
      } else if (_this3.endsWith(_this3.result().Type, "Header")) {
        return "<text>" + pluralType.toUpperCase() + "</text>";
      } else if (_this3.endsWith(_this3.result().Type, "Bar")) {
        return "<a href=\"" + _this3.url() + "\">" + "<p class=\"" + _this3.result().Type + "\">" + Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.autoCompleteSearchIn, queryString, pluralType.toLowerCase()) + "</p>" + "<svg class=\"moreInIcon\" id=\"Layer_1\" data-name=\"Layer 1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 20\">" + "<defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px;}</style></defs>" + "<title>advanced_search_asset_exploration</title>" + "<rect class=\"cls-1\" x=\"-38.25\" y=\"-309.83\" width=\"4221.08\" height=\"1931.21\"/>" + "<path class=\"cls-2\" d=\"M13,13.68A6.67,6.67,0,1,1,13.68,13L18,17.37,17.37,18ZM8.67,2.9a5.77,5.77,0,1,0,4.08,1.69A5.73,5.73,0,0,0,8.67,2.9Z\"/>" + "</svg>" + "</a>";
      } else {
        return "<a href=\"" + _this3.url() + "\">" + "<div class=\"ellipsis-100 " + _this3.result().Type + "\">" + "<text>" + _this3.result().Display.replace(regex, "</text><text class=\"autocomplete-result-matched\">$1</text><text>") + "</text>" + "</div>" + "</a>";
      }
    });
  }

  ;
  ViewModelSearchAutoCompleteItem.prototype = {};

  ViewModelSearchAutoCompleteItem.prototype.focus = function () {
    this.isSelected(true);
  };

  ViewModelSearchAutoCompleteItem.prototype.unfocus = function () {
    this.isSelected(false);
  };

  ViewModelSearchAutoCompleteItem.prototype.endsWith = function (string, search, this_len) {
    if (string === undefined) {
      return false;
    }

    if (this_len === undefined || this_len > string.length) {
      this_len = string.length;
    }

    return string.substring(this_len - search.length, this_len) === search;
  };

  Kobo.ViewModel.Search = {};
  Kobo.ViewModel.Search.AutoComplete = ViewModelSearchAutoComplete;
  Kobo.ViewModel.Search.AutoComplete.Item = ViewModelSearchAutoCompleteItem;
})(Kobo);