"use strict";

/*global Kobo*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RAT.CookieHelper', cookieHelper);

  function cookieHelper() {
    this.getCookie = function (a) {
      var b = "; " + document.cookie,
          c = b.split("; " + a + "=");
      if (2 === c.length) return c.pop().split(";").shift();
    };

    this.getParameterByName = function (a, b) {
      b || (b = window.location.href), a = a.replace(/[[\]]/g, "\\$&");
      var c = new RegExp("[?&]" + a + "(=([^&#]*)|&|#|$)"),
          d = c.exec(b);
      return d ? d[2] ? decodeURIComponent(d[2].replace(/\+/g, " ")) : "" : null;
    }; //{{Cookie - PrivacyPermissions}} {{Privacy - Cookie - PrivacyPermission}}


    this.getPermissionsCookie = function () {
      return this.getCookie("PrivacyPermissions");
    };
  }
})();