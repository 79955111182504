"use strict";

/*globals Kobo,ko, DynamicConfiguration*/
Kobo.Gizmo.KoboPlusAvailabilityPad = function (el) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  var self = this,
      setupHandlers,
      addBookUnderSubToReadingListCallback = null,
      addBookUnderSubToReadingList = null,
      addingBookUnderSubToReadingList = false,
      isUnauthenticated = this.options.isUserAnonymous,
      isSubbedToKoboPlusRead = this.options.isSubbedToKoboPlusRead,
      isSubbedToKoboPlusListen = this.options.isSubbedToKoboPlusListen,
      isSubbedToKoboPlusCombo = this.options.isSubbedToKoboPlusCombo,
      itemOwnedUnderKoboPlus = this.options.itemOwnedUnderKoboPlus,
      isBook = this.options.isBook;
  this.viewModel = {
    unauthenticated: ko.observable(isUnauthenticated),
    isSubbedToKoboPlusRead: ko.observable(isSubbedToKoboPlusRead),
    isSubbedToKoboPlusListen: ko.observable(isSubbedToKoboPlusListen),
    isSubbedToKoboPlusCombo: ko.observable(isSubbedToKoboPlusCombo),
    itemOwnedUnderKoboPlus: ko.observable(itemOwnedUnderKoboPlus),
    isBook: ko.observable(isBook)
  };

  setupHandlers = function setupHandlers() {
    self.$el.on('click', '.add-to-reading-list-under-subscription-bol', addBookUnderSubToReadingList);
  };

  addBookUnderSubToReadingListCallback = function addBookUnderSubToReadingListCallback(result) {
    addingBookUnderSubToReadingList = false;

    if (result && result.result === 'success') {
      self.viewModel.itemOwnedUnderKoboPlus(true);

      if (self.settings.contentDownloadEventData) {
        Kobo.event.fireDeviceEvent(self.settings.contentDownloadEventData);
      }
    }
  };

  addBookUnderSubToReadingList = function addBookUnderSubToReadingList() {
    if (!addingBookUnderSubToReadingList) {
      addingBookUnderSubToReadingList = true;
      self.fire('productAction::addToReadingListUnderSubscription', {
        actionUrl: self.settings.addBookUnderSubToLibraryUrl,
        productId: self.settings.productId,
        subscriptionId: self.settings.subscriptionId,
        completeCallback: addBookUnderSubToReadingListCallback
      });
    }
  };

  setupHandlers();
  ko.applyBindings(self.viewModel, el);
};

Kobo.Gizmo.KoboPlusAvailabilityPad.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);