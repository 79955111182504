"use strict";

/*globals Kobo */
Kobo.Gizmo.ProductAction = function (gizmo, options) {
  // eslint-disable-line no-unused-vars 
  'use strict';

  var self = this,
      ACTIONLIST = Kobo.Utilities.getProductActionClassList(),
      event,
      productAction,
      init,
      eventData,
      classList,
      addBookUnderSubToReadingListCallback,
      addingBookUnderSubToReadingList = false;
  Kobo.Gizmo.apply(this, arguments);
  self.setType('ProductAction');
  eventData = self.settings || {};
  classList = self.$el.attr('class').split(' ');

  self.getProductAction = function () {
    var i,
        j,
        matches = [];

    if (classList.length && ACTIONLIST.length) {
      for (i = 0; i < classList.length; i++) {
        for (j = 0; j < ACTIONLIST.length; j++) {
          if (classList[i] === ACTIONLIST[j]) {
            matches.push(classList[i]);
          }
        }
      }
    }

    if (matches.length > 1) {
      throw 'You can only have one product action per button';
    }

    productAction = matches[0] || null;
    return productAction;
  };

  self.mapActionToEvent = function () {
    self.getProductAction();

    if (productAction) {
      if (!addingBookUnderSubToReadingList || productAction === 'add-to-cart') {
        // add-to-reading-list-under-subscription
        addingBookUnderSubToReadingList = true;
        event = Kobo.Utilities.productActionEventMapper(productAction);

        if (self.settings.contentDownloadEventData) {
          eventData.completeCallback = addBookUnderSubToReadingListCallback;
        }

        Kobo._mediator.fire(event, eventData);
      }
    } else {
      throw 'There is no action associated with this button';
    }
  };

  addBookUnderSubToReadingListCallback = function addBookUnderSubToReadingListCallback(result) {
    addingBookUnderSubToReadingList = false;

    if (result && result.result === 'success') {
      if (self.settings.contentDownloadEventData) {
        Kobo.event.fireDeviceEvent(self.settings.contentDownloadEventData);
      }
    }
  };

  self.setupHandlers = function () {
    self.$el.on('click', self.mapActionToEvent);
  };

  self.destroy = function () {
    self.$el.off('click', self.mapActionToEvent);
  };

  init = function init() {
    self.setupHandlers();
  };

  init();
};

Kobo.Gizmo.ProductAction.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);