"use strict";

(function (Kobo) {
  function ViewModelExpandableArrowButton(gizmo, options) {
    var _this = this;

    Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
    this.setButtonX(this.returnButtonX());
    gizmo.events.onResize(function (event) {
      _this.setButtonX(_this.returnButtonX());
    });
  }

  ViewModelExpandableArrowButton.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

  ViewModelExpandableArrowButton.prototype.addButton = function () {
    Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
    this.$subButton = Kobo.$('<div class="gizmo-expandable-subbutton">') // Make it Accessible
    .attr('tabindex', '0').attr('role', 'button').attr('aria-label', 'open and close the section to read more');
    this.$button.addClass('gizmo-expandable-button-arrow');
    this.$button.append(this.$subButton);
  };

  ViewModelExpandableArrowButton.prototype.setButtonX = function (xpos) {
    this.$button.css({
      "left": xpos + "px"
    });
  };

  ViewModelExpandableArrowButton.prototype.returnButtonX = function () {
    return this.$el.width() / 2 - this.buttonWidth;
  };

  Kobo.ViewModel.Expandable.ArrowButton = ViewModelExpandableArrowButton;
})(Kobo);