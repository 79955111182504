"use strict";

/*globals Kobo,ko,DynamicConfiguration,console*/
(function () {
  'use strict';

  function ItemPromoOfferBannerVM(options) {
    var self = this;
    var setCookie = options.setCookie;
    this.isDismissed = ko.observable(true);
    this.isHidden = ko.pureComputed(function () {
      if (self.isDismissed()) {
        return true;
      }
    });

    this.dismissBanner = function () {
      self.isDismissed(true);
      setCookie(options.cookieName);
    };

    this.showNewCustomerCreditAppliedBanner = ko.observable(false);
    this.showNewCustomerCreditAvailableBanner = ko.observable(false);

    this.hideItemPromotionAddedToCartNotification = function () {
      self.showNewCustomerCreditAppliedBanner(false);
      self.showNewCustomerCreditAvailableBanner(false);
    };

    this.showItemPromotionAddedToCartNotification = ko.pureComputed(function () {
      return self.showNewCustomerCreditAppliedBanner() || self.showNewCustomerCreditAvailableBanner();
    });
  }

  Kobo.Utilities.assignToNamespace('Kobo.ViewModel.ItemPromoOfferBannerVM', ItemPromoOfferBannerVM);
})();