"use strict";

/*global Kobo, ko */
Kobo.Gizmo.Spotlight = function () {
  "use strict";

  var backgroundColourOptions = [{
    r: 0,
    g: 101,
    b: 189
  }, {
    r: 0,
    g: 191,
    b: 224
  }, {
    r: 99,
    g: 29,
    b: 118
  }, {
    r: 191,
    g: 34,
    b: 150
  }, {
    r: 0,
    g: 98,
    b: 101
  }, {
    r: 0,
    g: 178,
    b: 169
  }, {
    r: 210,
    g: 73,
    b: 42
  }, {
    r: 255,
    g: 109,
    b: 34
  }, {
    r: 145,
    g: 0,
    b: 75
  }, {
    r: 198,
    g: 12,
    b: 48
  }, {
    r: 0,
    g: 152,
    b: 95
  }, {
    r: 105,
    g: 190,
    b: 40
  }, {
    r: 99,
    g: 29,
    b: 118
  }, {
    r: 164,
    g: 77,
    b: 196
  }];
  var $element, $imageContainerSelector;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("Spotlight");
  var self = this;

  function init() {
    $element = self.$el;
    $imageContainerSelector = $element.find('.' + self.options.imageContainer);
    var colour = backgroundColourOptions[Math.floor(Math.random() * backgroundColourOptions.length)];
    $imageContainerSelector[0].style.backgroundColor = "rgb(".concat(colour.r, ",").concat(colour.g, ",").concat(colour.b, ", 0.1)");
  }

  init();
};

Kobo.Gizmo.Spotlight.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);