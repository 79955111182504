"use strict";

/*global Kobo,window*/

/* eslint-disable no-unused-vars */
Kobo.Gizmo.VisaCheckout = function (el, options) {
  'use strict';

  var self = this,
      resourceStrings,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType('VisaCheckout');

  self.onVmeReady = function onVmeReady() {
    var visaCheckout = window.V;
    visaCheckout.init({
      apikey: self.settings.apiKey,
      settings: {
        logoUrl: self.settings.logoUrl,
        displayName: self.settings.displayName,
        websiteUrl: self.settings.websiteUrl,
        shipping: {
          collectShipping: false
        }
      }
    });
    visaCheckout.on('payment.success', function (payment) {
      self.success(payment);
    });
    /*jslint unparam: true*/

    visaCheckout.on('payment.cancel', function (payment) {
      // Docs don't show how to trigger this in the sandbox
      return;
    });
    /*jslint unparam: false*/

    /*jslint unparam: true*/

    visaCheckout.on('payment.error', function (payment, error) {
      self.showError('VisaCheckout.payment.error', 'Visa Checkout Payment Error', error);
    });
    /*jslint unparam: false*/
  };

  self.success = function (payment) {
    self.$gizmo.find(self.settings.errorSelector).hide();
    self.fire('regularCheckout::showSpinner');
    Kobo.Ajax({
      method: 'post',
      url: self.settings.actionUrl,
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      success: self.ajaxSuccess,
      error: self.ajaxError,
      data: {
        purchaseToken: self.settings.purchaseToken,
        callId: payment.callid
      }
    });
  };

  self.ajaxSuccess = function (data) {
    if (data && data.IsSuccess === true) {
      self.fire('regularCheckout::hideSpinner');
      self.fire("regularCheckout::getCheckoutDetails", {
        navigatePurchaseSection: true
      });
    } else {
      self.ajaxError(data);
    }
  };

  self.showError = function (errorType, errorMessage, errorData) {
    self.fire('regularCheckout::hideSpinner');
    self.error(errorType, 'Kobo.Gizmo.VisaCheckout', self, {
      msg: errorMessage,
      dataResult: errorData
    });
    self.fire('paymentOptions::setCurrentPaymentMethodError', {
      errorMessage: errorMessage
    });
  };

  self.ajaxError = function (data) {
    self.showError('Data.Ajax.Failure', data.ErrorMessage || resourceStrings.visaCheckoutGeneralUnexpectedError, data.result);
  };

  init = function init() {
    window.onVmeReady = self.onVmeReady;
  };

  resourceStrings = !window.DynamicConfiguration ? {} : {
    visaCheckoutGeneralUnexpectedError: window.DynamicConfiguration.resourceStrings.visaCheckoutGeneralUnexpectedError
  };
  init();
};

Kobo.Gizmo.VisaCheckout.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);