"use strict";
/*globals Kobo, Modernizr*/

Kobo.Gizmo.ContentPresenterBrowseHistory = function (el, options) {
  Kobo.Gizmo.apply(this, arguments);
  this.setType("ContentPresenterBrowseHistory");
  var $el = Kobo.$(el);
  var gizmo;

  var removeFromBrowseHistory = function removeFromBrowseHistory(eventData) {
    var config = JSON.parse(eventData.delegateTarget.dataset.koboGizmoConfig);
    Kobo.Ajax({
      type: 'POST',
      url: "/ContentPresenterWidget/RemoveFromBrowseHistory",
      dataType: 'json',
      contentType: 'application/json',
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        productId: config.productId,
        workId: config.workId
      }),
      success: function success() {
        var itemToRemove = gizmo.$el.find("[data-product-id='" + config.productId + "']");

        for (var i = 0; i < itemToRemove.length; i++) {
          itemToRemove[i].remove();
        }

        var itemsInCarousel = gizmo.$el.find(".item-container");

        if (itemsInCarousel.length === 0) {
          gizmo.$el.parent().parent().remove();
        }
      },
      error: function error() {},
      complete: function complete() {}
    });
  };

  function init() {
    var $gizmoEl = $el.find("[data-kobo-gizmo='LightweightCarousel']");

    if ($gizmoEl.length == 0) {
      return;
    }

    var gizmoConfigStr = $gizmoEl.attr("data-kobo-gizmo-config");
    var gizmoConfig = JSON.parse(gizmoConfigStr);
    gizmo = new Kobo.Gizmo.LightweightCarousel($gizmoEl, gizmoConfig);
    gizmo.$el.find('.' + options.RemoveButtonClass).on('click', removeFromBrowseHistory);
    $el.find(".carousel-items").addClass(options.BrowseHistoryItemsClass);
  }

  init();

  this.destroy = function () {
    if (gizmo) {
      gizmo.destroy();
      $el.find("." + options.RemoveButtonClass).off('click', removeFromBrowseHistory);
    }
  };
};

Kobo.Gizmo.ContentPresenterBrowseHistory.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);