"use strict";

/*globals Kobo,Cookies*/
Kobo.Gizmo.CountryStoreModal = function () {
  'use strict';

  var self = this,
      init,
      $closeTrigger;
  self.setDefaults({
    cookieDomain: 'kobobooks.com'
  });
  Kobo.Gizmo.apply(self, arguments);

  init = function init() {
    var $countryStoreModalTemplate = self.$el.children();
    $closeTrigger = self.$el.find('.close');
    $closeTrigger.on('click', Kobo._modal.close);

    Kobo._modal.open($countryStoreModalTemplate, {
      customClass: 'country-store-modal-container'
    });

    Cookies.set('persistent', Cookies.get('persistent') + '&' + 'countryStoreModal=0', {
      raw: true,
      path: '/',
      domain: self.settings.cookieDomain
    });
  };

  self.destroy = function () {
    $closeTrigger.off('click', Kobo._modal.close);
  };

  init();
};

Kobo.Gizmo.CountryStoreModal.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);