"use strict";

/*global Kobo,ko,Modernizr,DynamicConfiguration*/

/**
 * Shows gift card options and provides a drop down for gift cards to be applied and removed
 */
Kobo.Gizmo.PromoCode = function () {
  "use strict";

  var self = this,
      submitPromoCode,
      onUpdate,
      onGAEventTrigger,
      onPromoCodeFocus,
      $applyPromoCodeButton,
      $notificationArea,
      notificationTimer,
      $promoCodeField,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("PromoCode"); // Event handlers

  submitPromoCode = function submitPromoCode() {
    if (Kobo.$.trim($promoCodeField.val()) !== "") {
      self.promoCodeNumber = $promoCodeField.val();
      self.fire('promoCode::apply', {
        promoCodeNumber: self.promoCodeNumber
      });
    } else {
      if ($promoCodeField.attr('gaenabled')) {
        Kobo.GoogleAnalytics.trackEvent('OS:PurchaseConfirmation', 'promoCode', 'fail:blank');
      }

      clearTimeout(notificationTimer);
      self.message(DynamicConfiguration.resourceStrings.invalidPromoCode);
      self.hasError(true);
      $promoCodeField.addClass('error');
    }
  }; // handle the submit button


  self.toggleSubmitButtonState = function () {
    if (self.isSubmitEnabled()) {
      $applyPromoCodeButton.prop('disabled', false);
      $applyPromoCodeButton.css({
        opacity: '1'
      });
    } else {
      $applyPromoCodeButton.attr('disabled', 'disabled');
      $applyPromoCodeButton.css({
        opacity: '0.5'
      });
    }
  };

  self.gainFocus = function () {
    self.toggleSubmitButtonState();
    $promoCodeField.on('keyup', function (e) {
      self.toggleSubmitButtonState();

      if (e.keyCode === 13) {
        submitPromoCode();
      }
    });
  };

  self.isSubmitEnabled = function () {
    return $promoCodeField.val() !== "";
  };

  self.setupTriggers = function () {
    $promoCodeField.on('focus', self.gainFocus);
  };

  self.clearTriggers = function () {
    $promoCodeField.off('blur', self.loseFocus);
    $applyPromoCodeButton.off('focus', self.gainFocus);
  };
  /*jslint unparam: true*/


  onUpdate = function onUpdate(eventName, data) {
    var message = data.detail.ResponseMessage,
        success = data.detail.IsSuccess;
    self.fire('promoCode::gaEventTrigger', {
      gaEvent: data.detail.GAEvent
    });

    if (message && success) {
      self.message(data.detail.ResponseMessage);
      self.hasError(false);
      $promoCodeField.removeClass('error');
      $promoCodeField.val('');
      self.toggleSubmitButtonState();
      notificationTimer = setTimeout(function () {
        $notificationArea.fadeOut('slow');
      }, 5000);
    } else {
      self.hasError(true);
      self.message(data.detail.ResponseMessage);
      $promoCodeField.addClass('error');
    }
  };
  /*jslint unparam: false*/


  onPromoCodeFocus = function onPromoCodeFocus() {
    // Part of fix for DE21134
    // This can be removed when the final fix lands in the android app
    var pos = Kobo.$(this).offset().top - 100;
    Kobo.$('html, body').animate({
      scrollTop: pos
    }, 500);
  };
  /*jslint unparam: true*/


  onGAEventTrigger = function onGAEventTrigger(eventName, data) {
    self.$gizmo.append(data.detail.gaEvent);
  };
  /*jslint unparam: false*/


  init = function init() {
    // Select DOM Elements
    $promoCodeField = self.$gizmo.find('input[type=text]');
    $applyPromoCodeButton = self.$gizmo.find('.promo-submit-button');
    $notificationArea = self.$gizmo.find('.notification');
    self.toggleSubmitButtonState();
    self.setupTriggers();
    self.register('promoCode::apply');
    self.register('promoCode::update');
    self.register('promoCode::gaEventTrigger');
    self.subscribe('promoCode::update', onUpdate);
    self.subscribe('promoCode::gaEventTrigger', onGAEventTrigger);
    $applyPromoCodeButton.on('click', submitPromoCode);

    if (Modernizr.touchevents) {
      $promoCodeField.on('focus', onPromoCodeFocus);
    }

    self.message = ko.observable(''); // top-level message for PromoCode

    self.hasError = ko.observable(); // The same  message can be set twice. Notifications should be sent even if the new value is the same as the previous one

    self.message.extend({
      notify: 'always'
    });
    self.koSubscription = self.message.subscribe(function (message) {
      if (message) {
        self.fire('regularCheckout::requestFocus', {
          target: self.gizmo
        });
      }
    });
  };

  self.destroy = function () {
    self.koSubscription.dispose();
    $applyPromoCodeButton.off('click', submitPromoCode);
    self.unSubscribe('promoCode::update', onUpdate);
    self.unSubscribe('promoCode::gaEventTrigger', onGAEventTrigger);
    self.clearTriggers();
  };

  init();
  ko.applyBindings(self, self.el);
};

Kobo.Gizmo.PromoCode.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);