"use strict";

/*global Kobo, $, ko, DynamicConfiguration*/
(function () {
  "use strict";

  Kobo.Gizmo.ThankYouAudiobookSubscription = function () {
    var self = this,
        initiateDeviceDownload;
    Kobo.Gizmo.apply(self, arguments);
    var vm = new Kobo.Purchasing.ViewModels.ThankYouAudiobookSubscriptionVM({
      isInLibrary: Kobo.Object.parseBool(self.settings.isInLibrary),
      appLinks: self.settings.appLinks,
      relatedLinks: self.settings.relatedLinks,
      productAvailable: self.settings.productAvailable,
      isWalmartAffiliate: self.settings.isWalmartAffiliate
    });
    var useCreditButton = self.$el.find('.use-credit');

    var creditRedemptionSuccess = function creditRedemptionSuccess() {
      vm.creditUsed(true);
    };

    initiateDeviceDownload = function initiateDeviceDownload() {
      if (self.settings && self.settings.deviceDownloadUrl) {
        Kobo.event.fireDeviceEvent(self.settings.deviceDownloadUrl);
      }
    };

    var init = function init() {
      ko.applyBindings(vm, self.el);
      useCreditButton.on('click', function () {
        self.fire('creditRedemption::start', {
          creditAmount: self.settings.creditAmount,
          productId: self.settings.productId,
          creditRedemptionSuccess: creditRedemptionSuccess,
          isWalmartAffiliate: self.settings.isWalmartAffiliate
        });
      });
      initiateDeviceDownload();
    };

    init();
  };

  Kobo.Gizmo.ThankYouAudiobookSubscription.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
})();