"use strict";

/*global Kobo, $, ko, DynamicConfiguration, console */
(function () {
  "use strict";

  Kobo.Gizmo.ThankYouKoboPlusSubscription = function () {
    var self = this,
        initiateContentPurchased,
        initiateContentDownload,
        redeemProduct,
        handleReadingListResponseCallback,
        $redeemButton;
    Kobo.Gizmo.apply(self, arguments);
    var vm = new Kobo.Purchasing.ViewModels.ThankYouKoboPlusSubscriptionVM({
      appLinks: self.settings.appLinks
    });

    initiateContentPurchased = function initiateContentPurchased() {
      if (self.settings && self.settings.contentPurchasedUrl) {
        Kobo.event.fireDeviceEvent(self.settings.contentPurchasedUrl);
      }
    };

    initiateContentDownload = function initiateContentDownload() {
      if (self.settings && self.settings.contentDownloadUrl) {
        Kobo.event.fireDeviceEvent(self.settings.contentDownloadUrl);
      }
    };

    redeemProduct = function redeemProduct(e) {
      // eslint-disable-line no-unused-vars 
      self.fire('productAction::addToReadingListUnderSubscription', {
        actionUrl: self.settings.addToMyBooksUrl,
        productId: self.settings.productId,
        subscriptionId: self.settings.subscriptionId,
        completeCallback: handleReadingListResponseCallback
      });
    };

    handleReadingListResponseCallback = function handleReadingListResponseCallback(result) {
      if (result && result.result === 'success') {
        vm.productRedeemed(true);
        initiateContentDownload();
      }
    };

    var init = function init() {
      ko.applyBindings(vm, self.el);
      $redeemButton = self.$el.find('.redeem-button');
      $redeemButton.on('click', redeemProduct);
      initiateContentPurchased(); // WEB-28120 - We added a browse now so don't want a second browse button
      // TODO: Configure the templates so it's not rendered to the client in this scenario

      Kobo.$('.keep-browsing-container').remove();
    };

    init();
  };

  Kobo.Gizmo.ThankYouKoboPlusSubscription.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
})();