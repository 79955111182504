"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.BillingAddressDisplay.ViewModels.CountryStatePostalCodeBillingAddressVM', CountryStatePostalCodeBillingAddressViewModel);

  function CountryStatePostalCodeBillingAddressViewModel(options) {
    var self = this;
    var country = options.country,
        state = options.state,
        postalCode = options.postalCode;
    self.displayType = "CountryStatePostalCode";
    self.country = ko.observable(country);
    self.state = ko.observable(state);
    self.postalCode = ko.observable(postalCode);
  }
})();