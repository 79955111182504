"use strict";

/*global Kobo*/
(function (Kobo) {
  function ExpandableScrollingListButtonVM(gizmo, options) {
    Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments); // move facet header outside of expandable area

    this.$header = this.gizmo.$innerContent.find('> .facet-list-header');
    this.gizmo.$gizmo.prepend(this.$header);
    this.currentSize = this.gizmo.currentBreakpoint(); // bind event handlers

    this.setupHandlers();
  }

  ExpandableScrollingListButtonVM.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

  ExpandableScrollingListButtonVM.prototype.setupHandlers = function () {
    this.handleGizmosInitialized = this.handleGizmosInitialized.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.gizmo.subscribe("gizmosInitialized", this.handleGizmosInitialized);
    this.gizmo.events.onResize(this.handleResize);
  };

  ExpandableScrollingListButtonVM.prototype.handleGizmosInitialized = function () {
    this.gizmo.viewModel.allowTab = true;
    this.handleCollapse = this.handleCollapse.bind(this);
    this.gizmo.viewModel.registerExpandableResizedEvent();
    this.gizmo.subscribe("expandableResized", this.recalculateHeight);
    var collapseOnEvent = this.gizmo.settings.collapseOnEvent;

    if (collapseOnEvent) {
      this.gizmo.register(collapseOnEvent);
      this.gizmo.subscribe(collapseOnEvent, this.handleCollapse);
    }

    this.gizmo.fire("expandableResized");

    Kobo._gizmo.fire("pageChanged");
  };

  ExpandableScrollingListButtonVM.prototype.handleResize = function (event) {
    if (this.currentSize !== event.currentSize) {
      this.currentSize = event.currentSize;

      if (!this.isCollapsed() && (event.currentSize === "small" && this.settings.isCollapsed.small || event.currentSize === "medium" && this.settings.isCollapsed.medium)) {
        this.toggle();
      } else if ((event.currentSize === "large" || event.currentSize === "jumbo") && this.isCollapsed()) {
        this.toggle();
      }
    }
  };

  ExpandableScrollingListButtonVM.prototype.handleCollapse = function (eventName, data) {
    var targetIsCollapsed = data.detail.gizmo.viewModel.isCollapsed;
    var targetIsOurGizmo = data.detail.gizmo.guid === this.gizmo.guid;
    var shouldCollapse = !targetIsOurGizmo && !targetIsCollapsed;

    if (shouldCollapse) {
      this.gizmo.viewModel.toggle(false);
    }
  };

  ExpandableScrollingListButtonVM.prototype.addButton = function () {
    Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
    this.$button.addClass('gizmo-expandable-button-facetlist');
    this.$subButtonWrapper = Kobo.$('<div class="gizmo-expandable-subbutton-wrapper">').appendTo(this.$button);
    this.$subButton = Kobo.$('<div class="gizmo-expandable-subbutton">').appendTo(this.$subButtonWrapper);

    if (typeof this.$header !== 'undefined') {
      this.$header.append(this.$button);
    }
  }; // Exports


  Kobo.ViewModel.Expandable.ScrollingListButton = ExpandableScrollingListButtonVM;
})(Kobo);