"use strict";

/*global Kobo,ko,DynamicConfiguration*/

/**
 * Shows gift card options and provides a drop down for gift cards to be applied and removed
 */
Kobo.Gizmo.GiftCards = function (el, options) {
  "use strict";

  var self = this,
      GiftCard,
      GiftCardType,
      setupHandlers,
      setupExpandables,
      registerExpandableResizedEvent,
      getCardType,
      onSetCurrentGiftCard,
      onGetCurrentGiftCard,
      onResetCurrentGiftCard,
      onReplaceGiftCardTypes,
      onToggleOpen,
      onApplyGiftCardResponse,
      onClearGiftCardResponse,
      onRegularCheckoutDataLoaded,
      onGAEventTrigger,
      toggleGiftCardForm,
      onUpdate,
      init,
      modalOptions = {
    theme: Kobo._modal.themes.LIGHT
  };
  Kobo.Gizmo.apply(this, arguments);
  self.setType("GiftCards"); // View model

  GiftCard = function GiftCard() {
    var self = this;
    self.number = ko.observable('');
    self.cardType = ko.observable(); // a GiftCardType; not just a simple string

    self.pin = ko.observable('');
    self.appliedToOrder = ko.observable('');
    self.balance = ko.observable('');
    self.errorMessage = ko.observable(''); // The same error message can be set twice. Notifications should be sent even if the new value is the same as the previous one

    self.errorMessage.extend({
      notify: 'always'
    });
  };

  GiftCardType = function GiftCardType(id, isPinRequired) {
    var self = this;
    self.id = id;
    self.isPinRequired = isPinRequired;
  }; // Event callbacks


  setupExpandables = function setupExpandables() {
    Kobo.$.each(self.descendants, function () {
      if (this.type.indexOf("Class.Gizmo.Expandable") === 0) {
        self.expandable = this;
      }
    });
  };

  registerExpandableResizedEvent = function registerExpandableResizedEvent(current) {
    if (current.viewModel) {
      current.viewModel.registerExpandableResizedEvent();
      current.subscribe("expandableResized", function () {
        if (!current.viewModel.isCollapsed()) {
          current.viewModel.toggle(true);
        }
      });
    }
  };
  /*jslint unparam: true*/


  onGAEventTrigger = function onGAEventTrigger(eventName, data) {
    var gaEvent = data.detail.gaEvent;
    self.$gizmo.append(gaEvent);
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  onSetCurrentGiftCard = function onSetCurrentGiftCard(eventName, data) {
    var giftCardDetail = data.detail;
    self.currentGiftCard.number(giftCardDetail.number);
    self.currentGiftCard.cardType(getCardType(giftCardDetail.cardTypeId));
    self.currentGiftCard.pin(giftCardDetail.pin);
    self.currentGiftCard.appliedToOrder(giftCardDetail.appliedToOrder);
    self.currentGiftCard.balance(giftCardDetail.balance);
    self.currentGiftCard.errorMessage(giftCardDetail.errorMessage);
    self.showGiftCardSummary(true);
    self.showGiftCardForm(false);
  };
  /*jslint unparam: false*/


  getCardType = function getCardType(id) {
    var cardType = null;

    if (id || id === 0) {
      // 0 can be a valid id
      Kobo.$.each(self.giftCardTypes(), function () {
        if (this.id === id) {
          cardType = this;
          return false;
        }
      });
    }

    if (!cardType) {
      Kobo.log('Unable to find gift card type corresponding to ' + id + '. Check if card types have been set with giftCards::replaceGiftCardTypes');
    }

    return cardType;
  };
  /*jslint unparam: true*/


  onGetCurrentGiftCard = function onGetCurrentGiftCard(eventName, data) {
    var giftCardData = data.detail.giftCard;
    giftCardData.number = self.currentGiftCard.number();
    giftCardData.cardType = self.currentGiftCard.cardType() && self.currentGiftCard.cardType().id;
    giftCardData.pin = self.currentGiftCard.pin();
    giftCardData.hasApplied = self.currentGiftCard.appliedToOrder();
  };
  /*jslint unparam: false*/


  onResetCurrentGiftCard = function onResetCurrentGiftCard() {
    self.currentGiftCard.number('');
    self.currentGiftCard.cardType();
    self.currentGiftCard.pin('');
    self.currentGiftCard.appliedToOrder('');
    self.currentGiftCard.balance('');
    self.currentGiftCard.errorMessage('');
    self.showGiftCardSummary(false);
    self.showGiftCardForm(true);
  };

  toggleGiftCardForm = function toggleGiftCardForm() {
    if (self.currentGiftCard.number()) {
      self.showGiftCardSummary(true);
      self.showGiftCardForm(false);
    } else {
      self.showGiftCardSummary(false);
      self.showGiftCardForm(self.giftCardTypes().length);
    }
  };
  /*jslint unparam: true*/


  onReplaceGiftCardTypes = function onReplaceGiftCardTypes(eventName, data) {
    var cardTypes = data.detail.types;
    self.giftCardTypes.removeAll();

    if (cardTypes) {
      Kobo.$.each(cardTypes, function () {
        self.giftCardTypes.push(new GiftCardType(this.CardType, this.RequiredPin));
      });
    }

    toggleGiftCardForm();
  };
  /*jslint unparam: false*/


  onToggleOpen = function onToggleOpen() {
    var $giftCardForm = self.$gizmo.find('.giftcard-form');

    if (self.expandable && self.expandable.viewModel && self.expandable.viewModel.isCollapsed()) {
      self.expandable.viewModel.toggle(true);
    } else {
      Kobo.$('html, body').animate({
        scrollTop: $giftCardForm.offset().top - Kobo.$window.outerHeight() / 2 + $giftCardForm.outerHeight() / 2
      });
    }
  };

  onRegularCheckoutDataLoaded = function onRegularCheckoutDataLoaded() {
    if (self.$el.is(':visible')) {
      if (self.expandable) {
        self.expandable.toggleExpandable();
        registerExpandableResizedEvent(self.expandable);
      }
    }
  };

  onApplyGiftCardResponse = function onApplyGiftCardResponse(data) {
    self.fire('giftCards::gaEventTrigger', {
      gaEvent: data.GAEvent
    });

    if (data.IsSuccess) {
      self.currentGiftCard.appliedToOrder(data.GiftCard.AppliedToOrder);
      self.currentGiftCard.balance(data.GiftCard.Balance);
      self.showGiftCardSummary(data.IsSuccess);
      self.showGiftCardForm(!data.IsSuccess);
      self.expandable.viewModel.toggle(false);
    }
    /* TODO:[JS] somehow combine these into one function? its also called in regularCheckout->onCheckoutDetailsRetrieved */


    if (data.PriceSummary) {
      self.fire('priceSummary::update', {
        lineItems: data.PriceSummary
      });
    }

    if (data.Total) {
      self.fire('regularCheckout::updateGrandTotal', {
        grandTotal: data.Total
      });
    }

    self.fire('regularCheckout::updateStoreCreditBalance', {
      storeCreditBalance: data.StoreCreditBalance
    });

    if (Kobo.Object.typeOf(data.SufficientAlternativePayment) === "boolean") {
      self.fire('regularCheckout::updateSufficientPayment', {
        sufficientPayment: data.SufficientAlternativePayment
      });
    }

    self.currentGiftCard.errorMessage(data.ErrorMessage);
  };

  onClearGiftCardResponse = function onClearGiftCardResponse(data) {
    self.fire('giftCards::gaEventTrigger', {
      gaEvent: data.GAEvent
    });

    if (data.IsSuccess) {
      self.currentGiftCard.number('');
      self.currentGiftCard.cardType();
      self.currentGiftCard.pin('');
      self.currentGiftCard.appliedToOrder('');
      self.currentGiftCard.balance('');
      self.showGiftCardForm(true);
      self.showGiftCardSummary(false);
    }

    if (data.PriceSummary) {
      self.fire('priceSummary::update', {
        lineItems: data.PriceSummary
      });
    }

    if (data.Total) {
      self.fire('regularCheckout::updateGrandTotal', {
        grandTotal: data.Total
      });
    }

    self.fire('regularCheckout::updateStoreCreditBalance', {
      storeCreditBalance: data.StoreCreditBalance
    });

    if (Kobo.Object.typeOf(data.SufficientAlternativePayment) === "boolean") {
      self.fire('regularCheckout::updateSufficientPayment', {
        sufficientPayment: data.SufficientAlternativePayment
      });
    }

    self.currentGiftCard.errorMessage(data.ErrorMessage);
  };
  /*jslint unparam: true*/


  onUpdate = function onUpdate(eventName, data) {
    var topLevelError = data.detail.errorMessage; // once multiple gift cards are supported, this should be in a top-level property,
    // instead of being associated with the current gift card

    self.currentGiftCard.errorMessage(topLevelError);
  };
  /*jslint unparam: false*/


  setupHandlers = function setupHandlers() {
    self.register('giftCards::setCurrentGiftCard'); // event sent by other gizmos to set a gift card

    self.register('giftCards::getCurrentGiftCard'); // returned data will be set in an output parameter

    self.register('giftCards::resetCurrentGiftCard');
    self.register('giftCards::replaceGiftCardTypes');
    self.register("giftCards::toggleOpen");
    self.register("giftCards::update");
    self.register('giftCards::gaEventTrigger');
    self.subscribe("gizmosInitialized", setupExpandables);
    self.subscribe("giftCards::setCurrentGiftCard", onSetCurrentGiftCard);
    self.subscribe("giftCards::getCurrentGiftCard", onGetCurrentGiftCard);
    self.subscribe("giftCards::resetCurrentGiftCard", onResetCurrentGiftCard);
    self.subscribe("giftCards::replaceGiftCardTypes", onReplaceGiftCardTypes);
    self.subscribe("giftCards::toggleOpen", onToggleOpen);
    self.subscribe("giftCards::update", onUpdate);
    self.subscribe("giftCards::gaEventTrigger", onGAEventTrigger);
    self.subscribe("regularCheckout::dataLoaded", onRegularCheckoutDataLoaded);
    self.koSubscriptions.push(self.giftCardTypes.subscribe(toggleGiftCardForm));
    self.koSubscriptions.push(self.currentGiftCard.cardType.subscribe(function () {
      setTimeout(function () {
        self.fire("expandableResized");
      }, 10);
    }));
    self.koSubscriptions.push(self.currentGiftCard.errorMessage.subscribe(function (errorMessage) {
      if (errorMessage) {
        self.fire('regularCheckout::requestFocus', {
          target: self.gizmo
        });
      }
    }));
  };

  self.destroy = function () {
    Kobo.$.each(self.koSubscriptions, function () {
      this.dispose();
    });
    self.unSubscribe("regularCheckout::dataLoaded", onRegularCheckoutDataLoaded);
    self.unSubscribe("giftCards::update", onUpdate);
    self.unSubscribe("giftCards::toggleOpen", onToggleOpen);
    self.unSubscribe("giftCards::replaceGiftCardTypes", onReplaceGiftCardTypes);
    self.unSubscribe("giftCards::resetCurrentGiftCard", onResetCurrentGiftCard);
    self.unSubscribe("giftCards::getCurrentGiftCard", onGetCurrentGiftCard);
    self.unSubscribe("giftCards::setCurrentGiftCard", onSetCurrentGiftCard);
    self.unSubscribe("gizmosInitialized", setupExpandables);
    self.unSubscribe("giftCards::gaEventTrigger", onGAEventTrigger);
  }; // functions exposed through Knockout


  self.applyGiftCard = function () {
    var result = {};

    Kobo._modal.open("<div class ='ajax-spinner'></div>", modalOptions);

    self.fire('billingInfo::getCountry', result);

    if (result.country) {
      if (self.currentGiftCard.number()) {
        Kobo.Ajax({
          url: self.applyGiftCardUrl,
          method: 'POST',
          dataType: "json",
          headers: {
            '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
          },
          data: {
            CardType: self.currentGiftCard.cardType() && self.currentGiftCard.cardType().id,
            Number: self.currentGiftCard.number(),
            Pin: self.currentGiftCard.pin(),
            CurrentCountry: result.country
          },
          success: function success(data) {
            Kobo._modal.close();

            onApplyGiftCardResponse(data);
          },
          error: function error() {
            Kobo._modal.close();

            self.currentGiftCard.errorMessage(DynamicConfiguration.resourceStrings.giftCardNotApplied);
          }
        });
      } else {
        Kobo._modal.close();

        self.currentGiftCard.errorMessage(DynamicConfiguration.resourceStrings.giftCardMissing);
      }
    } else {
      Kobo._modal.close();

      self.currentGiftCard.errorMessage(DynamicConfiguration.resourceStrings.giftCardSelectCountry);
    }
  };

  self.clearGiftCard = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', modalOptions);

    Kobo.Ajax({
      url: self.clearGiftCardUrl,
      method: 'POST',
      dataType: 'json',
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: {
        CardType: self.currentGiftCard.cardType() && self.currentGiftCard.cardType().id,
        Number: self.currentGiftCard.number()
      },
      success: function success(data) {
        Kobo._modal.close();

        onClearGiftCardResponse(data);
      },
      error: function error() {
        Kobo._modal.close();

        self.currentGiftCard.errorMessage(DynamicConfiguration.resourceStrings.giftCardNotCleared);
      }
    });
  };

  init = function init() {
    self.applyGiftCardUrl = options.applyGiftCardUrl;
    self.clearGiftCardUrl = options.clearGiftCardUrl;
    self.currentGiftCard = new GiftCard();
    self.giftCardTypes = ko.observableArray([]);
    self.showGiftCardForm = ko.observable(false);
    self.showGiftCardSummary = ko.observable(false);
    self.koSubscriptions = [];
    setupHandlers();
  };

  init();
  ko.applyBindings(self, el);
};

Kobo.Gizmo.GiftCards.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);