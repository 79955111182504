"use strict";

/*globals Kobo,ko,DynamicConfiguration,console*/
(function () {
  'use strict';

  function AudiobookCreditBalanceBannerVM(options) {
    var self = this;
    var audiobookCreditSingleMessageTemplate = DynamicConfiguration.resourceStrings.audiobookCreditBanner;
    var audiobookCreditPluralMessageTemplate = DynamicConfiguration.resourceStrings.audiobookCreditsBanner;
    var getMoreCreditsMessageTemplate = DynamicConfiguration.resourceStrings.getMoreCredits;
    var setCookie = options.setCookie;
    this.creditsInitialized = ko.observable(false);
    this.creditBalance = ko.observable(0);
    this.isDismissed = ko.observable(true);
    this.isHidden = ko.pureComputed(function () {
      // always hide the banner if it's been dismissed
      if (self.isDismissed()) {
        return true;
      } else if (!options.hasHadAudiobooksSubscription && !options.hasAudiobooksSubscription) {
        return true;
      } else if (options.hasHadAudiobooksSubscription && self.creditsInitialized() && self.creditBalance() > 0) {
        return false;
      } else if (options.hasAudiobooksSubscription && self.creditsInitialized()) {
        return false;
      }

      return true;
    });

    this.dismissBanner = function () {
      self.isDismissed(true);
      var credits = self.creditBalance();
      var bannerCookieData = {
        isDismissed: true,
        creditBalance: credits
      };
      setCookie(bannerCookieData);
      Kobo.$('.AudiobooksSubscriptionBannerWidget').remove();
    };

    this.iconClass = ko.pureComputed(function () {
      return 'headphone-icon';
    });
    this.link = ko.pureComputed(function () {
      var credits = self.creditBalance();

      if (options.hasAudiobooksSubscription && credits === 0) {
        return options.getMoreCreditsUrl;
      }

      return '';
    });
    this.creditLabel = ko.pureComputed(function () {
      var credits = self.creditBalance();

      if (credits === 0) {
        return getMoreCreditsMessageTemplate.replace('{0}', credits);
      }

      if (credits === 1) {
        return audiobookCreditSingleMessageTemplate.replace('{0}', credits);
      }

      return audiobookCreditPluralMessageTemplate.replace('{0}', credits);
    }, this);
  }

  Kobo.Utilities.assignToNamespace('Kobo.ViewModel.AudiobookCreditBalanceBannerVM', AudiobookCreditBalanceBannerVM);
})();