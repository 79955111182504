"use strict";

Kobo.ViewModel.Expandable.FacetListButton = function (gizmo, options) {
  var self = this; // move facet header outside of expandable area

  self.$header = gizmo.$innerContent.find('> .facet-list-header');
  gizmo.$gizmo.prepend(self.$header);
  Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
  gizmo.register("updateHeight");
  gizmo.subscribe("updateHeight", function () {
    gizmo.viewModel.height(gizmo.viewModel.isCollapsed() ? gizmo.viewModel.settings.height : gizmo.defaultHeight() + "px");
  });

  if (gizmo.settings.collapseOnEvent !== false) {
    gizmo.register(gizmo.settings.collapseOnEvent);
    gizmo.subscribe(gizmo.settings.collapseOnEvent, function (eventName, data) {
      if (!data.detail.gizmo.viewModel.isCollapsed() && data.detail.gizmo.guid !== gizmo.guid) {
        gizmo.viewModel.toggle(false);
      }
    });
  }

  if (gizmo.settings.extendToListItemWidth !== false) {
    self.headerText = self.$header.find(".facet-list-largeview-header");
    self.facetList = gizmo.$gizmo.find('ul.facet-list');

    self.extendToListItemWidth = function () {
      var screensize = Kobo._mediator.determineBreakpoint();

      if (screensize === "large" || screensize === "jumbo") {
        var h = self.headerText.width();
        self.facetList.css("width", "1000px");
        self.facetList.find('li a').each(function () {
          var pDisplay = Kobo.$(this).css("display");
          Kobo.$(this).css("display", "inline-block");

          if (Kobo.$(this).width() > h) {
            h = Kobo.$(this).width();
          }

          Kobo.$(this).css("display", pDisplay);
        });
        self.facetList.css("width", "auto");
        self.headerText.width(h);
      } else {
        self.headerText.css("width", "auto");
      }
    };

    gizmo.events.onResize(self.extendToListItemWidth);
    self.extendToListItemWidth();
  }
};

Kobo.ViewModel.Expandable.FacetListButton.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

Kobo.ViewModel.Expandable.FacetListButton.prototype.addButton = function () {
  var self = this,
      subButton,
      subButtonWrapper;
  Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
  this.$button.addClass('gizmo-expandable-button-facetlist');
  subButtonWrapper = document.createElement("div");
  subButtonWrapper.setAttribute('class', 'gizmo-expandable-subbutton-wrapper');
  this.subButtonWrapper = Kobo.$(subButtonWrapper);
  this.$button.append(this.subButtonWrapper);
  subButton = document.createElement("div");
  subButton.setAttribute('class', 'gizmo-expandable-subbutton');
  this.$subButton = Kobo.$(subButton);
  this.subButtonWrapper.append(this.$subButton);

  if (typeof self.$header !== 'undefined') {
    self.$header.append(this.$button);
  }
};