"use strict";

/*global Kobo*/
Kobo.Gizmo.RippleContainer = function (el, options) {
  'use strict';

  var self = this;
  Kobo.Gizmo.apply(this, arguments);
  var rippleClassName = options.rippleClassName;

  this.init = function () {
    Kobo.event.setRippleEffectEvent(el, rippleClassName);
  };

  self.init();
};

Kobo.Gizmo.RippleContainer.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);