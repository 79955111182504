"use strict";

/*globals Kobo,ko,DynamicConfiguration*/
(function () {
  'use strict';

  function CreditRedemptionVM(data) {
    var self = this,
        credit = DynamicConfiguration.resourceStrings.creditLower,
        credits = DynamicConfiguration.resourceStrings.creditsLower,
        headingFormatString = DynamicConfiguration.resourceStrings.getThisTandy,
        pleaseNoteFnac = DynamicConfiguration.resourceStrings.pleaseNoteFnac,
        pleaseNoteWalmart = DynamicConfiguration.resourceStrings.pleaseNoteWalmart,
        pleaseNote = DynamicConfiguration.resourceStrings.pleaseNote,
        handleError,
        responseCode = {
      unknown: 0,
      unauthenticated: 1,
      insufficientBalance: 2,
      noBillingAddress: 3,
      ineligibleProduct: 4,
      success: 5,
      error: 6,
      productAlreadyOwned: 7
    },
        errorAction = {
      tryAgain: 0,
      updateBilling: 1
    };
    this.showFnacMessaging = ko.observable(DynamicConfiguration.user.currentStorefront === "fr" && document.body.parentElement.getAttribute("lang").toLowerCase() === "fr-fr");
    this.pleaseNoteFnac = pleaseNoteFnac;
    this.pleaseNoteWalmart = pleaseNoteWalmart;
    this.pleaseNote = pleaseNote;
    this.creditAmount = ko.observable(data && data.creditAmount);
    this.redeemSuccess = ko.observable();
    this.closeDialogEvent = new ko.subscribable();

    this.closeDialog = function () {
      self.closeDialogEvent.notifySubscribers();
    };

    this.pleaseNoteMessage = ko.pureComputed(function () {
      if (self.showFnacMessaging()) {
        return self.pleaseNoteFnac;
      }

      if (data.isWalmartAffiliate) {
        return self.pleaseNoteWalmart;
      }

      return self.pleaseNote;
    });
    this.hasError = ko.pureComputed(function () {
      if (self.errorHeading() || self.errorMessage()) {
        return true;
      }

      return false;
    });
    this.errorHeading = ko.observable('');
    this.errorMessage = ko.observable('');
    this.errorActionType = ko.observable(null);

    this.errorActionHandler = function () {
      switch (self.errorActionType()) {
        case errorAction.tryAgain:
          self.confirmRedeem();
          break;

        case errorAction.updateBilling:
          window.location = data.updateBillingUrl;
          break;
      }
    };

    this.errorActionText = ko.pureComputed(function () {
      switch (self.errorActionType()) {
        case errorAction.tryAgain:
          return DynamicConfiguration.resourceStrings.tryAgain;

        case errorAction.updateBilling:
          return DynamicConfiguration.resourceStrings.updateInfo;
      }

      return '';
    });

    this.validateRedeem = function () {
      Kobo.$body.addClass('ajax-spinner-overlay');
      Kobo.Ajax({
        method: 'POST',
        url: data.canRedeemCreditUrl,
        data: {
          productId: data.productId
        },
        dataType: 'json',
        cache: false,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        success: function success(response) {
          if (response.result === 'Unauthenticated') {
            handleError({
              ResponseCode: responseCode.unauthenticated
            });
          } else {
            Kobo.$body.removeClass('ajax-spinner-overlay');

            if (!response.ResponseCode || response.ResponseCode !== responseCode.success) {
              handleError(response);
            }
          }
        },
        error: function error() {
          handleError();
          Kobo.$body.removeClass('ajax-spinner-overlay');
        }
      });
    };

    this.confirmRedeem = function () {
      Kobo.$body.addClass('ajax-spinner-overlay');
      Kobo.Ajax({
        method: 'POST',
        url: data.redeemCreditUrl,
        data: {
          productId: data.productId
        },
        dataType: 'json',
        cache: false,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        success: function success(response) {
          if (response.result === 'Unauthenticated') {
            handleError({
              ResponseCode: responseCode.unauthenticated
            });
          } else if (response.ResponseCode === responseCode.success) {
            data.creditRedemptionSuccess();
            self.closeDialogEvent.notifySubscribers();

            if (response.DeviceEvent) {
              Kobo.event.fireDeviceEvent(response.DeviceEvent);
            }
          } else {
            handleError(response);
          }
        },
        error: handleError,
        complete: function complete() {
          Kobo.$body.removeClass('ajax-spinner-overlay');

          Kobo._mediator.fire('creditRedemption::end');
        }
      });
    };

    this.creditString = ko.pureComputed(function () {
      return self.creditAmount() === 1 ? credit : credits;
    });
    this.heading = ko.pureComputed(function () {
      return Kobo.Utilities.stringFormat(headingFormatString, self.creditAmount(), self.creditString());
    });

    handleError = function handleError(response) {
      switch (response.ResponseCode) {
        case responseCode.unauthenticated:
          window.location = data.signInUrl;
          break;

        case responseCode.insufficientBalance:
          self.errorHeading(DynamicConfiguration.resourceStrings.dontHaveCredits);
          self.errorMessage(DynamicConfiguration.resourceStrings.noCreditsLeft);
          self.errorActionType(null);
          break;

        case responseCode.noBillingAddress:
          self.errorHeading(DynamicConfiguration.resourceStrings.noBillingAddress);
          self.errorMessage(DynamicConfiguration.resourceStrings.couldNotFindBilling);
          self.errorActionType(errorAction.updateBilling);
          break;

        case responseCode.ineligibleProduct:
          self.errorHeading(DynamicConfiguration.resourceStrings.ineligibleForCredit);
          self.errorMessage(DynamicConfiguration.resourceStrings.cantBePurchasedWithCredit);
          self.errorActionType(null);
          break;

        case responseCode.productAlreadyOwned:
          self.errorHeading(DynamicConfiguration.resourceStrings.alreadyOwned);
          self.errorMessage(DynamicConfiguration.resourceStrings.titleAlreadyYours);
          self.errorActionType(null);
          break;

        default:
          self.errorHeading(DynamicConfiguration.resourceStrings.somethingWentWrong);
          self.errorMessage(DynamicConfiguration.resourceStrings.couldntProcessRequest);
          self.errorActionType(errorAction.tryAgain);
      }
    };
  }

  Kobo.Utilities.assignToNamespace('Kobo.Product.Tandy.ViewModels.CreditRedemptionVM', CreditRedemptionVM);
})();