"use strict";

/*global Kobo,$,ko */
Kobo.Gizmo.AddToLibraryButton = function (el, options, gizmo) {
  "use strict";

  var self = this;
  self.defaults = {
    url: '/Book/AddToLibrary',
    checkOwnershipUrl: undefined,
    isInLibrary: false,
    isAddedToLibraryMessage: false,
    toastFailureResourceString: undefined
  };

  if (!options.url) {
    delete options.url;
  }

  Kobo.Gizmo.apply(this, arguments);
  self.setType("AjaxButton"); //technical debt: use Kobo._librarian to handle ajax calls for adding to library

  self.sendAddToLibraryAction = function () {
    //technical debt: use ancestor event when framework feature lands
    //self.fireAncestors("flowViewItem::showSpinner");
    self.$flowViewItem.addClass("flowview-item-spinner");
    Kobo.Ajax({
      method: "post",
      url: self.settings.url,
      success: self.addToLibrarySuccessCallback,
      error: self.addToLibraryErrorCallback,
      complete: self.addToLibraryCompleteCallback
    });
  };

  self.viewModel = {
    isAddedToLibraryMessage: ko.observable(self.settings.isAddedToLibraryMessage),
    isInLibrary: ko.observable(self.settings.isInLibrary),
    sendAction: self.sendAddToLibraryAction
  };

  self.addToLibraryCompleteCallback = function () {
    //self.fireAncestors("flowViewItem::hideSpinner");
    self.$flowViewItem.removeClass("flowview-item-spinner");
  };

  self.addToLibraryErrorCallback = function (data) {
    if (self.settings.toastFailureResourceString && self.settings.toastFailureResourceString in DynamicConfiguration.resourceStrings) {
      self.fire("toast::error", {
        message: DynamicConfiguration.resourceStrings[self.settings.toastFailureResourceString]
      });
    }

    self.error('Data.Ajax.Failure', 'Kobo.Gizmo.AddToLibraryButton', self, {
      msg: 'Cannot add the book to the Library.',
      dataResult: data.result
    });
  }; // Function to display status of library message.


  self.showAddToLibraryMessages = function () {
    self.viewModel.isAddedToLibraryMessage(true);
    self.viewModel.isInLibrary(true);
  };

  self.addToLibrarySuccessCallback = function (data) {
    if (data && data.result === 'success') {
      self.showAddToLibraryMessages();
    } else if (data.result === 'failure') {
      self.addToLibraryErrorCallback(data);
    } else if (data.result === 'redirect') {
      Kobo._tracker.sendResultsSynchronously();

      window.location.href = data.redirectUrl; // required to show preview message on device redirect

      self.showAddToLibraryMessages();
    }
  };

  self.sendCheckOwnershipAction = function () {
    Kobo.Ajax({
      method: "post",
      url: self.settings.checkOwnershipUrl,
      success: self.checkOwnershipAjaxCallback
    });
  };

  self.checkOwnershipAjaxCallback = function (data) {
    if (Kobo.typeOf(data) === "object" && data.result && data.result === "success" && data.data) {
      self.viewModel.isInLibrary(data.data.IsOwned);
      ko.applyBindings(self.viewModel, self.el);
    }
  };

  var init = function init() {
    var errMsg;

    if (!self.settings.url) {
      errMsg = 'ERROR: url is not provided for ' + self.type;
      Kobo.log(errMsg);
      return;
    }

    if (typeof self.settings.checkOwnershipUrl === "undefined") {
      ko.applyBindings(self.viewModel, el);
    } else {
      self.sendCheckOwnershipAction();
    }

    self.$flowViewItem = self.$el.parents('.flowview-item');
  };

  init();
};

Kobo.Gizmo.AddToLibraryButton.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);