"use strict";

/*global Kobo, DynamicConfiguration*/
Kobo.Gizmo.ContentPresenterLoader = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  var self = this,
      workId = self.settings.workId,
      productType = self.settings.productType,
      url = self.settings.url,
      carouselContainer = self.$el.find('.search-item-carousel'),
      toggleButton = self.$el.find('.related-titles-carousel-toggle'),
      seeSimilarTitles = DynamicConfiguration.resourceStrings.seeSimilarTitles,
      hideSimilarTitles = DynamicConfiguration.resourceStrings.hideSimilarTitles,
      toggleCollapsible;

  if (productType !== 'Book') {
    return;
  }

  function _getCarouselMarkup() {
    return Kobo.$.ajax({
      type: 'GET',
      url: url,
      data: {
        workId: workId,
        productType: productType
      }
    });
  }

  function _renderCarousel($markup, $outputContainer) {
    $outputContainer.append($markup);
    var $relatedTitlesCarousel = $outputContainer.find('.carousel-container');

    if ($relatedTitlesCarousel.length) {
      Kobo._gizmo.initGizmo($relatedTitlesCarousel);
    }

    var carousel = $outputContainer.find('[lazy-load-parent]');

    if (carousel.length && carousel.length === 1) {
      Kobo.LazyLoad.addToObserver(carousel[0]);
    }
  }

  function loadRelatedTitles($outputContainer) {
    _getCarouselMarkup().done(function (data) {
      if (data.length > 0) {
        var $carouselMarkup = Kobo.$.parseHTML(data, true);
        toggleButton.removeClass("hidden");

        _renderCarousel($carouselMarkup, $outputContainer);

        dispatchEvent(new Event('loadRelatedBooks'));
      }
    });
  }

  toggleCollapsible = function toggleCollapsible(e) {
    e.stopPropagation();

    Kobo._mediator.fire('collapsible::toggle', {
      name: self.options.name
    });

    if (toggleButton.text().trim() === seeSimilarTitles) {
      toggleButton.attr('aria-expanded', true);
      toggleButton.text(hideSimilarTitles);
    } else {
      toggleButton.attr('aria-expanded', false);
      toggleButton.text(seeSimilarTitles);
    }
  };

  loadRelatedTitles(carouselContainer);
  toggleButton.on('click', toggleCollapsible);
};

Kobo.Gizmo.ContentPresenterLoader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);