"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.PurchaseHistoryActions = function (element) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('PurchaseHistoryActions');
  var self = this;

  function init() {
    var settings = self.settings;
    self.viewModel = {
      id: settings.id,
      submitUrl: settings.submitUrl,
      replace: settings.replace,
      successMessageKey: settings.successMessageKey,
      dialogId: settings.dialogId,
      dialogItemTitle: settings.dialogItemTitle,
      dialogItemAuthor: settings.dialogItemAuthor,
      dialogItemAvailableDate: settings.dialogItemAvailableDate,
      dialogItemImgUrl: settings.dialogItemImgUrl,
      dialogItemImgAlt: settings.dialogItemImgAlt,
      openModal: function openModal() {
        var templateId = self.viewModel.dialogId;
        var modalTemplate = Kobo.$('#' + templateId);
        var modalView = Kobo.$(modalTemplate.html());

        Kobo._modal.open(modalView);

        modalView.closest('#modal-content').addClass("purchase-history-modal").addClass("purchase-history-dialog");
        ko.applyBindings(self.viewModel, modalView[0]);
      },
      closeModal: function closeModal() {
        Kobo._modal.close();

        ko.cleanNode(this.$modalView[0]);
      },
      submitForm: function submitForm(data) {
        Kobo.Ajax({
          type: 'POST',
          url: self.viewModel.submitUrl,
          headers: {
            '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
          },
          data: new FormData(data),
          contentType: false,
          processData: false,
          dataType: 'json'
        }).done(function (data) {
          Kobo._modal.close();

          if (data.IsSuccess) {
            var successMessageText = window.DynamicConfiguration.resourceStrings.purchaseHistory[self.viewModel.successMessageKey];
            var successMessage = document.getElementById("purchase-history-alert-success");
            successMessage.textContent = Kobo.Utilities.stringFormat(successMessageText, self.viewModel.replace);
            successMessage.style.display = 'block';
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          }
        }).fail(function () {
          Kobo._modal.close();
        });
      }
    };
    ko.applyBindings(self.viewModel, element);
  }

  init();
};

Kobo.Gizmo.PurchaseHistoryActions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);