"use strict";

/*global Kobo,ko,bodymovin*/
Kobo.Gizmo.LottiePlayer = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("LottiePlayer");
  var self = this,
      loadAnimation,
      $animationContainer = self.$gizmo.find('#' + self.settings.containerId)[0],
      animationOptions = {
    container: $animationContainer,
    renderer: "svg",
    loop: true,
    autoplay: true
  };

  if (self.settings.dataPath) {
    animationOptions.path = self.settings.dataPath;
  } else {
    animationOptions.animationData = Kobo.LottiePlayer.AnimationObject[self.settings.AnimationName];
  }

  loadAnimation = function loadAnimation() {
    bodymovin.loadAnimation(animationOptions);
  };

  function init() {
    loadAnimation();
  }

  init();
};

Kobo.Gizmo.LottiePlayer.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);