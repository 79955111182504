"use strict";

/**
 * A gizmo to fix the hanging chevron issue potentially caused by translated headers (DE11371).
 * This seems to be impossible to fix yet with pure CSS.
 *
 * @param el
 * @param options
 * @param gizmo
 * @constructor
 */
Kobo.Gizmo.FixHangingChevron = function (el, options, gizmo) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("FixHangingChevron");
  self.settings = {};

  init = function init() {
    if ($el.width() - $el.find('a').width() < 20) {
      $el.css("width", "80%");
    }
  };

  init();
};

Kobo.Gizmo.FixHangingChevron.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);