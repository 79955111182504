"use strict";

/*globals Kobo, Modernizr*/
Kobo.Gizmo.BrowseHistory = function (el, options) {
  Kobo.Gizmo.apply(this, arguments);
  this.setType("BrowseHistory");
  var $el = Kobo.$(el);
  var gizmo;

  function init() {
    Kobo.Ajax({
      url: "/ContentPresenterWidget/RenderBrowseHistoryWidget",
      success: function success(res) {
        var html = Kobo.$.parseHTML(res); // removes <script> tags

        if (!html) {
          return;
        }

        $el.html(html);
        var $gizmoEl = $el.find("[data-kobo-gizmo='LightweightCarousel']");

        if ($gizmoEl.length == 0) {
          return;
        }

        var gizmoConfigStr = $gizmoEl.attr("data-kobo-gizmo-config");
        var gizmoConfig = JSON.parse(gizmoConfigStr);
        gizmo = new Kobo.Gizmo.LightweightCarousel($gizmoEl, gizmoConfig);
        gizmo.$el.find('.' + options.RemoveButtonClass).on('click', removeFromBrowseHistory);
        initRemoveButtons(gizmo.$el);
      },
      error: function error() {},
      complete: function complete() {}
    });
  }

  init();

  var removeFromBrowseHistory = function removeFromBrowseHistory(eventData) {
    var config = JSON.parse(eventData.delegateTarget.dataset.koboGizmoConfig);
    Kobo.Ajax({
      type: 'POST',
      url: "/ContentPresenterWidget/RemoveFromBrowseHistory",
      dataType: 'json',
      contentType: 'application/json',
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        productId: config.productId,
        workId: config.workId
      }),
      success: function success() {
        init();
      },
      error: function error() {},
      complete: function complete() {}
    });
  };

  var initRemoveButtons = function initRemoveButtons($el) {
    $el.find("[data-kobo-gizmo='Collapsible.HasHeader']").each(function () {
      var gizmoConfigStr = $(this).attr("data-kobo-gizmo-config");
      var gizmoConfig = JSON.parse(gizmoConfigStr);
      var newGizmo = new Kobo.Gizmo.Collapsible.HasHeader($(this), gizmoConfig);
      $(this).replaceWith(newGizmo.$el);
    }); // Adding class to carousel to identify BrowseHistory Carousel

    $el.find("." + options.CarouselItemsClass).addClass(options.BrowseHistoryItemsClass);
    $el;
  };

  this.destroy = function () {
    if (gizmo) {
      gizmo.destroy();
      $el.find("." + options.RemoveButtonClass).off('click', removeFromBrowseHistory);
    }
  };
};

Kobo.Gizmo.BrowseHistory.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);