"use strict";

/*globals Kobo,ko*/
Kobo.Gizmo.CreditRedemption = function () {
  'use strict';

  var self = this;
  self.setDefaults({
    creditRedemptionEndpoint: null
  });
  Kobo.Gizmo.apply(self, arguments);

  function createCreditRedemptionViewModel(data) {
    var creditRedemptionVM = new Kobo.Product.Tandy.ViewModels.CreditRedemptionVM({
      creditAmount: data.creditAmount,
      productId: data.productId,
      creditRedemptionSuccess: data.creditRedemptionSuccess,
      isWalmartAffiliate: data.isWalmartAffiliate,
      canRedeemCreditUrl: self.settings.canRedeemCreditUrl,
      redeemCreditUrl: self.settings.redeemCreditUrl,
      signInUrl: self.settings.signInUrl,
      updateBillingUrl: self.settings.updateBillingUrl
    });
    return creditRedemptionVM;
  }

  function createCreditRedemptionView() {
    var $creditRedemptionContentTemplate = Kobo.$('#credit-redemption-content-template');
    return Kobo.$($creditRedemptionContentTemplate.html());
  }

  function showCreditRedemptionDialog(event, data) {
    self.viewModel = createCreditRedemptionViewModel(data.detail);
    var $view = createCreditRedemptionView();
    self.viewModel.validateRedeem();

    Kobo._modal.open($view, {
      customClass: 'credit-redemption-content-wrap'
    });

    self.viewModel.closeDialogEvent.subscribe(function () {
      Kobo._modal.close();

      $view.remove();
    });
    ko.applyBindings(self.viewModel, $view[0]);
  }

  function init() {
    Kobo._mediator.register('creditRedemption::start');

    Kobo._mediator.register('creditRedemption::end');

    Kobo._mediator.subscribe('creditRedemption::start', showCreditRedemptionDialog);
  }

  init();

  self.destroy = function () {
    Kobo._mediator.unSubscribe('creditRedemption::start', showCreditRedemptionDialog);
  };
};

Kobo.Gizmo.CreditRedemption.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);