"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.BraintreeHostedFieldStyles', {
    oldPPStyles: {
      //Add any styles for inside the IFrame here:
      'input': {
        'width': '91%',
        'padding': '.4em .8em',
        'padding-top': '8px',
        'padding-bottom': '8px',
        'box-shadow': 'none',
        'outline': 'none',
        'line-height': 'normal',
        'font-family': '"Trebuchet MS", "Trebuchet", Arial, Helvetica, sans-serif',
        'font-size': '14',
        'margin': '0',
        'background-color': '#FFF'
      },
      'input::-ms-clear': {
        'opacity': '0'
      },
      'input::-ms-reveal': {
        'opacity': '0'
      },
      'expirationMonth': {
        'padding': '0 15px'
      },
      'expirationYear': {
        'padding': '0 15px'
      },
      ':focus': {
        'color': 'black'
      },
      'select': {
        'padding': '0 15px',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        'font-family': '"Trebuchet MS", "Trebuchet", Arial, Helvetica, sans-serif',
        'color': '#595959'
      },
      'select::-ms-expand': {
        'opacity': '0'
      },
      'input::placeholder': {
        'background-color': '#FFF',
        'color': '#000',
        'font-family': '"Trebuchet MS", "Trebuchet", Arial, Helvetica, sans-serif',
        'font-size': '14px'
      }
    },
    newPPStyles: {
      //Add any styles for inside the IFrame here:
      'input': {
        'color': 'black'
      },
      'input::-ms-clear': {
        'opacity': '0'
      },
      'input::-ms-reveal': {
        'opacity': '0'
      },
      'expirationMonth': {
        'padding': '0 15px'
      },
      'expirationYear': {
        'padding': '0 15px'
      },
      ':focus': {
        'color': 'black'
      },
      'select': {
        'padding': '0 15px',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none'
      },
      'select::-ms-expand': {
        'opacity': '0'
      }
    },
    hostedFieldStyles: {
      //Add any styles for inside the IFrame here:
      'input': {
        'color': 'black',
        'font-size': '16px'
      },
      'input::-ms-clear': {
        'opacity': '0'
      },
      'input::-ms-reveal': {
        'opacity': '0'
      },
      'expirationMonth': {
        'padding': '0 15px'
      },
      'expirationYear': {
        'padding': '0 15px'
      },
      ':focus': {
        'color': 'black'
      },
      'select': {
        'padding': '0 15px',
        'font-size': '16px',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        'color': 'black'
      },
      'select::-ms-expand': {
        'opacity': '0'
      }
    }
  });
})();