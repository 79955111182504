"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,$,ko,Modernizr, DynamicConfiguration*/
(function (Kobo) {
  function getAntiForgeryToken() {
    var token = $('input[name="__RequestVerificationToken"]').val();
    return token;
  }

  function GizmoShoppingCartItems(el, options, stageSelectionVM) {
    'use strict';

    Kobo.Gizmo.apply(this, arguments);
    this.setType('ShoppingCartItems');
    this.StageSelectionVM = stageSelectionVM;
    this.modalOptions = {
      theme: "light"
    };
    var fetchItemsUrl = options && options.fetchItemsUrl || "";
    this.fetchItemsUrl = fetchItemsUrl;
    this.shoppingCartList = new ShoppingCartList(this.StageSelectionVM, fetchItemsUrl, options.addItemUrl);
    this.itemAddSucceededCount = 0;
    this.itemAddFailedCount = 0; // Bind callbacks

    this.fireUpdatedEvent = this.fireUpdatedEvent.bind(this);
    this.onFetchItems = this.onFetchItems.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.onAddSeriesItems = this.onAddSeriesItems.bind(this);
    this.setupHandlers();
    ko.applyBindings(this.shoppingCartList, el);
  }

  GizmoShoppingCartItems.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

  GizmoShoppingCartItems.prototype.showError = function (errorMessage) {
    var errorModalOptions = _objectSpread(_objectSpread({}, this.modalOptions), {}, {
      customClass: "error-dialog"
    });

    Kobo._modal.open("<p>" + errorMessage + "</p>", errorModalOptions);

    setTimeout(Kobo._modal.close, 2500);
  };

  GizmoShoppingCartItems.prototype.onFetchItems = function () {
    this.shoppingCartList.fetchItems(this.fetchItemsUrl);
  };

  GizmoShoppingCartItems.prototype.updateCart = function (cartDetails) {
    // We crash when cartItems or deals is not defined. This would be a programmer error
    // with the worst case scenario of user not being presented with an updated cart.
    // we also do not perform an update if nothing has changed.
    var didUpdate = false;

    if (cartDetails.cartItems) {
      this.shoppingCartList.replaceItems(cartDetails.cartItems);
      didUpdate = true;
      var vipMembershipInCart = false;
      var onlyVipMembershipInCart = false; // checking if cart contains KoboLoveVip Membership and if it's the only item in cart..

      if (cartDetails.cartItems.length === 1 && cartDetails.cartItems[0].IsKoboLoveMembership) {
        vipMembershipInCart = true;
        onlyVipMembershipInCart = true;
      } else {
        for (var i = 1; i < cartDetails.cartItems.length; i++) {
          if (this.IsKoboLoveMembership) {
            vipMembershipInCart = true;
            break;
          }
        }
      }

      this.fire('shoppingCartItems::changedKoboLoveMembership', {
        vipMembershipInCart: vipMembershipInCart,
        onlyVipInCart: onlyVipMembershipInCart,
        showKoboLoveVipUpsell: cartDetails.showKoboLoveVipUpsell,
        koboLoveVipUpsellProductId: cartDetails.koboLoveVipUpsellProductId,
        vipMessage: cartDetails.vipMessage
      });
    }

    if (cartDetails.deals) {
      this.shoppingCartList.setDealsSummaryArray(cartDetails.deals);
      didUpdate = true;
    }

    if (cartDetails.priceSummary) {
      this.fire('priceSummary::update', {
        lineItems: cartDetails.priceSummary,
        rakutenSuperPointsEarned: cartDetails.RakutenSuperPointsEarned
      });
    }

    if (cartDetails.Total) {
      this.fire('regularCheckout::updateGrandTotal', {
        grandTotal: cartDetails.Total
      });
    }

    if (didUpdate) {
      this.fireUpdatedEvent();
    }
  };

  GizmoShoppingCartItems.prototype.onUpdate = function (eventName, data) {
    this.updateCart(data.detail);
  };

  GizmoShoppingCartItems.prototype.onAddItem = function (eventName, data) {
    if (Kobo.Object.parseBool(data.detail.ageVerificationRequired)) {
      this.initialAgeVerificationRequired = true;
    }

    this.shoppingCartList.addItem({
      productId: data.detail.productId,
      purchaseToken: data.detail.purchaseToken,
      isKoboLoveMembership: data.detail.isKoboLoveMembership !== undefined ? data.detail.isKoboLoveMembership : false,
      suppressToast: data.detail.suppressToast,
      ageVerificationRequired: data.detail.ageVerificationRequired,
      itemPromotionAvailable: data.detail.itemPromotionAvailable,
      caller: data.detail.caller
    }, this.fireUpdatedEvent);
  };

  GizmoShoppingCartItems.prototype.onAddSeriesItems = function (eventName, data) {
    var _this = this;

    var deferredObject = $.Deferred();
    var promiseChain = deferredObject.promise();
    var addSeriesRunningCount = 0;
    $.each(data.detail.series, function (index, item) {
      promiseChain = promiseChain.then(function () {
        return _this.shoppingCartList.addItem({
          productId: item,
          suppressToast: ++addSeriesRunningCount < data.detail.series.length,
          caller: Kobo.Enums.ShoppingCart.Source.AddSeries
        }, function () {
          _this.itemAddSucceededCount++;

          _this.fireUpdatedEvent();
        }, function (messageType) {
          if (messageType == 'error') {
            _this.itemAddFailedCount++;
          }
        });
      });
    });
    deferredObject.resolve();
    promiseChain.done(function () {
      Kobo._mediator.fire('seriesDrawer::seriesAddComplete');

      _this.fireAddSeriesToast();
    });
  };

  GizmoShoppingCartItems.prototype.fireAddSeriesToast = function () {
    if (this.itemAddSucceededCount > 0 && this.itemAddFailedCount == 0) {
      Kobo._mediator.fire('toast::success', {
        message: Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.addedXItemsToCart, this.itemAddSucceededCount)
      });
    } else if (this.itemAddSucceededCount == 0 && this.itemAddFailedCount > 0) {
      Kobo._mediator.fire("toast::error", {
        message: Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.errorAddedXItemsToCart, this.itemAddFailedCount)
      });
    } else if (this.itemAddSucceededCount > 0 && this.itemAddFailedCount > 0) {
      Kobo._mediator.fire("toast::warning", {
        message: Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.warningAddedXItemsToCart, this.itemAddSucceededCount, this.itemAddFailedCount)
      });
    }

    this.itemAddSucceededCount = 0;
    this.itemAddFailedCount = 0;
  };

  GizmoShoppingCartItems.prototype.fireUpdatedEvent = function () {
    var items = this.shoppingCartList.items().map(function (shoppingCartItem) {
      return {
        recommendationKey: shoppingCartItem.recommendationKey(),
        id: shoppingCartItem.id,
        availableInCurrentGeo: shoppingCartItem.availableInCurrentGeo()
      };
    });
    this.fire('shoppingCartItems::updated', {
      items: items
    });
  };

  GizmoShoppingCartItems.prototype.setupHandlers = function () {
    this.register('shoppingCartItems::fetchItems');
    this.register('shoppingCartItems::addItem');
    this.register('shoppingCartItems::addSeriesItems');
    this.register('shoppingCartItems::update');
    this.register('shoppingCartItems::updated');
    this.register('shoppingCartItems::readyForRecos');
    this.register('shoppingCartItems::changedKoboLoveMembership');
    this.register('shoppingCartItems::removeItem');
    this.register('ageVerificationCheck');
    this.subscribe('shoppingCartItems::fetchItems', this.onFetchItems);
    this.subscribe('shoppingCartItems::update', this.onUpdate);
    this.subscribe('shoppingCartItems::addItem', this.onAddItem);
    this.subscribe('shoppingCartItems::addSeriesItems', this.onAddSeriesItems);
  };

  GizmoShoppingCartItems.prototype.destroy = function () {
    this.unSubscribe('shoppingCartItems::addItem', this.onAddItem);
    this.unSubscribe('shoppingCartItems::update', this.onUpdate);
    this.unSubscribe('shoppingCartItems::fetchItems', this.onFetchItems);
    this.unRegister('shoppingCartItems::fetchItems');
    this.unRegister('shoppingCartItems::addItem');
    this.unRegister('shoppingCartItems::update');
    this.unRegister('shoppingCartItems::updated');
    this.register('shoppingCartItems::readyForRecos');
    this.unRegister('shoppingCartItems::changedKoboLoveMembership');
    this.unRegister('shoppingCartItems::removeItem');
  };
  /* View Models */

  /** A collection of ShoppingCartItem */


  function ShoppingCartList(stageSelectionVM, fetchItemsUrl, addItemUrl) {
    var _this2 = this;

    this.fetchItemsUrl = fetchItemsUrl;
    this.addItemUrl = addItemUrl;
    this.StageSelectionVM = stageSelectionVM;
    this.items = ko.observableArray([]);
    this.dealsSummaryArray = ko.observableArray([]);
    this.regularItems = ko.computed(function () {
      return ko.utils.arrayFilter(_this2.items(), function (item) {
        return item.deal === undefined;
      });
    });
    this.groupedDealItems = ko.computed(function () {
      var groupedDealItems = [];

      if (!_this2.dealsSummaryArray) {
        return groupedDealItems;
      }

      for (var i = 0; i < _this2.dealsSummaryArray().length; i++) {
        var deal = new ShoppingCartDeal(_this2.dealsSummaryArray()[i]);
        deal.items(ko.utils.arrayFilter(_this2.items(), function (item) {
          return item.deal && item.deal.id === deal.id && item.deal.index === deal.index;
        }));
        groupedDealItems.push(deal);
      }

      return groupedDealItems;
    });
    this.containsInvalidItems = ko.computed(function () {
      return ko.utils.arrayFirst(_this2.items(), function (item) {
        return !item.availableInCurrentGeo();
      });
    });
    this.preOrderItems = ko.computed(function () {
      return ko.utils.arrayFilter(_this2.items(), function (item) {
        return item.isPreOrder();
      });
    });
    this.hasPreOrder = ko.computed(function () {
      return _this2.preOrderItems().length > 0;
    });
    this.preOrderMessage = ko.computed(function () {
      return _this2.hasPreOrder() ? _this2.preOrderItems()[0].infoMessage : "";
    });
    this.notification = new DealNotification(); // Bind callback handlers

    this.removeItemInline = this.removeItemInline.bind(this);
  }

  ShoppingCartList.prototype = {};

  ShoppingCartList.prototype.setDealsSummaryArray = function (deals) {
    if (!deals) {
      return;
    }

    this.dealsSummaryArray.removeAll();

    for (var i = 0; i < deals.length; i++) {
      this.dealsSummaryArray.push(deals[i]);
    }
  };

  ShoppingCartList.prototype.addItemWithDetails = function (itemDetails) {
    var newItem = new ShoppingCartItem(itemDetails.Id, itemDetails.PublisherCheckboxCheckedByDefault);
    newItem.CurrentStage = typeof this.StageSelectionVM === 'function' ? this.StageSelectionVM().currentStage : null;
    newItem.title(itemDetails.Title);
    newItem.subTitle(itemDetails.SubTitle);
    newItem.imageUrl(itemDetails.ImageUrl);
    newItem.seriesName(itemDetails.SeriesName);
    newItem.authors(itemDetails.Authors);
    newItem.ProductType(itemDetails.ProductType);
    newItem.publisher(itemDetails.Publisher);
    newItem.offersText(itemDetails.OffersText);
    newItem.publisherCheckboxVisible(itemDetails.PublisherCheckboxVisible);
    newItem.secondaryMessage(itemDetails.SecondaryMessage);
    newItem.recommendationKey(itemDetails.RecommendationKey);
    newItem.restricted(itemDetails.Restricted);
    newItem.errorMessage(itemDetails.ErrorMessage);
    newItem.hasKoboLoveDiscount(itemDetails.HasKoboLoveDiscount);
    newItem.infoMessage(itemDetails.InfoMessage);
    newItem.description(itemDetails.Description);
    newItem.removeItemUrl(itemDetails.RemoveItemUrl);
    newItem.editItemUrl(itemDetails.EditItemUrl);
    newItem.descriptionFullName(itemDetails.DescriptionFullName);
    newItem.isPreOrder(itemDetails.IsPreOrder);
    newItem.isAudiobookSubscription(itemDetails.IsAudiobookSubscription);
    newItem.isMonetaryGift(itemDetails.IsMonetaryGift);
    newItem.configurationId(itemDetails.ConfigurationId);
    newItem.isBookSubscription(itemDetails.IsBookSubscription);
    newItem.isTrial(itemDetails.IsTrial);
    newItem.availableOnDate(itemDetails.AvailableOnDate);
    newItem.isKoboLoveMembership(itemDetails.IsKoboLoveMembership);
    newItem.availableInCurrentGeo(itemDetails.AvailableInCurrentGeo);
    newItem.narrators(itemDetails.Narrators);
    newItem.hasAudiobookSubscriptionDiscount(itemDetails.HasAudiobookSubscriptionDiscount);

    if (itemDetails.Type != null) {
      newItem.type(Kobo.Enums.ShoppingCart.ParseProductTypeFromServer(itemDetails.Type));
    }

    newItem.isAudiobookProduct(Kobo.Enums.ProductType.parseFromServer(itemDetails.Type) === Kobo.Enums.ProductType.Audiobook);
    newItem.isEBookProduct(Kobo.Enums.ProductType.parseFromServer(itemDetails.Type) === Kobo.Enums.ProductType.Book);
    newItem.attributes(_objectSpread({}, itemDetails.Attributes));

    if (itemDetails.DealId) {
      newItem.deal = {
        id: itemDetails.DealId,
        index: itemDetails.DealIndex
      };
    }

    if (itemDetails.ItemDetailUrl) {
      newItem.itemDetailUrl(itemDetails.ItemDetailUrl);
    }

    newItem.updateSubscriptionUrl(itemDetails.UpdateSubscriptionUrl);
    var isNewCustomerPromotionAvailable = false;
    if (itemDetails.AppliedNewCustomerPromotion5Message) isNewCustomerPromotionAvailable = true;

    if (itemDetails.PriceDetails) {
      Kobo.$.each(itemDetails.PriceDetails, function () {
        newItem.addPriceDetail(this.Label, this.Amount, this.IsCredit, this.IsTotal, this.IsTotalSavings, this.IsBookSubSummary, isNewCustomerPromotionAvailable, false);
      });
      if (itemDetails.PriceAfterNewCustomerPromotionCredit) newItem.addPriceDetail('', itemDetails.PriceAfterNewCustomerPromotionCredit, false, false, false, false, false, true);
    }

    newItem.dealsFound(itemDetails.DealsFound);
    newItem.appliedNewCustomerPromotion5Message(itemDetails.AppliedNewCustomerPromotion5Message);
    this.items.push(newItem);
  };

  ShoppingCartList.prototype.fetchItems = function (url) {
    Kobo.Ajax({
      method: 'GET',
      url: url,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: {},
      cache: false,
      headers: {
        '__RequestVerificationToken': getAntiForgeryToken()
      },
      success: function success(data) {
        Kobo._mediator.fire('shoppingCartItems::update', {
          cartItems: data.CartItems,
          deals: data.Deals,
          priceSummary: data.PriceSummary,
          koboLovePricingEnabled: data.KoboLoveDiscountsEnabled,
          koboLovePointsEnabled: data.KoboLovePointsEnabled,
          vipMessage: data.VipMessage
        });
      },
      error: function error() {//TODO
      }
    });
  };

  ShoppingCartList.prototype.replaceItems = function (newItems) {
    var _this3 = this;

    this.items.removeAll();

    if (!newItems) {
      return;
    }

    newItems.forEach(function (item) {
      return _this3.addItemWithDetails(item);
    });
  };

  ShoppingCartList.prototype.fireReadyForRecosEvent = function () {
    if (this.items().length > 0) {
      Kobo._mediator.fire('shoppingCartItems::readyForRecos');
    }
  };

  ShoppingCartList.prototype.addItem = function (data, successCallback, failureCallback) {
    if (Kobo._AgeVerification.isRequired(data.ageVerificationRequired)) {
      Kobo._mediator.fire('ageVerificationCheck', _objectSpread(_objectSpread({}, data), {}, {
        caller: "shoppingCartItems::addItem"
      }));
    } else {
      return this.doAddItem(data, successCallback, failureCallback);
    }
  };

  ShoppingCartList.prototype.doAddItem = function (requestData, successCallback, failureCallback) {
    var _this4 = this;

    return Kobo.Ajax({
      method: 'POST',
      url: this.addItemUrl,
      dataType: 'json',
      headers: {
        '__RequestVerificationToken': getAntiForgeryToken()
      },
      data: JSON.stringify({
        Id: requestData.productId,
        PurchaseToken: requestData.purchaseToken,
        IsKoboLoveMembership: requestData.isKoboLoveMembership,
        Caller: Kobo.Enums.ShoppingCart.Source.convertToServer(requestData.caller)
      }),
      //Purchase token is optional
      contentType: 'application/json; charset=utf-8',
      success: function success(data) {
        if (data.IsSuccess) {
          _this4.addItemWithDetails(data.ItemDetails);

          Kobo._mediator.fire('koboLove::updateDetails', {
            amountSaved: data.KoboLoveDiscountAmount,
            pointsEarned: data.KoboLovePointsEarned,
            basePointsEarned: data.KoboLoveBasePointsEarned,
            bonusPointsEarned: data.KoboLoveBonusPointsEarned,
            pointsBalance: data.KoboLovePointsBalance,
            showPointsEarnedBreakdown: data.KoboLoveSummaryHasBonusPoints,
            vipUpsellText: data.VipUpsellText
          });

          var isKoboLoveMembership = data.ItemDetails && data.ItemDetails.IsKoboLoveMembership,
              isUserVip = data.IsUserVip,
              onDealPage = Kobo.$('[data-kobo-gizmo=DealWidget]').length,
              currentNumberOfDeals = _this4.dealsSummaryArray().length,
              dealCompleted;

          if (_this4.initialAgeVerificationRequired) {
            _this4.fetchItems(_this4.fetchItemsUrl);

            _this4.initialAgeVerificationRequired = false;
          }

          Kobo._mediator.fire('shoppingCartItems::update', {
            cartItems: data.CartItems,
            deals: data.Deals,
            priceSummary: data.PriceSummary,
            total: data.Total,
            koboLovePricingEnabled: data.KoboLoveDiscountsEnabled,
            koboLovePointsEnabled: data.KoboLovePointsEnabled,
            vipMessage: data.VipMessage
          }); // fire ready for recos


          _this4.fireReadyForRecosEvent();

          dealCompleted = _this4.dealsSummaryArray().length > currentNumberOfDeals;
          var eligibleForDeal = data.ItemDetails && data.ItemDetails.DealsFound;
          var suppressToast = requestData.suppressToast;

          if (eligibleForDeal) {
            if (dealCompleted) {
              var completedContainer = Kobo.$('<div>' + DynamicConfiguration.resourceStrings.dealNotificationCompleted + ' </div>'),
                  viewCart = Kobo.$('<span class="primary-link">' + DynamicConfiguration.resourceStrings.viewCart + '</span>');
              completedContainer.append(viewCart);
              viewCart.click(function () {
                Kobo._mediator.fire("shoppingCart::toggle", {
                  toggle: true
                });
              });
              Kobo.Utilities.buildAddToCartNotification.open('deal', completedContainer);
            } else {
              if (!onDealPage) {
                var eligibleContainer = Kobo.$('<div>' + DynamicConfiguration.resourceStrings.dealNotificationEligible + '</div>'),
                    viewDeal;

                if (data.DealLandingPageUrl) {
                  /* Extra span is necessary to add a space */
                  viewDeal = Kobo.$('<span> <a class="primary-link" href="' + data.DealLandingPageUrl + '">' + DynamicConfiguration.resourceStrings.viewDeal + '</a></span>');
                  eligibleContainer.append(viewDeal);
                }

                Kobo.Utilities.buildAddToCartNotification.open('deal', eligibleContainer);
              } else {
                Kobo._mediator.fire('toast::info', {
                  message: DynamicConfiguration.resourceStrings.addedToCart
                });
              }
            }
          } else if (isKoboLoveMembership && !suppressToast) {
            _this4.showVipAddedToCartNotification();
          } else if (!suppressToast) {
            Kobo._mediator.fire('toast::info', {
              message: DynamicConfiguration.resourceStrings.addedToCart
            });
          }

          if (data.Total) {
            Kobo._mediator.fire('regularCheckout::updateGrandTotal', {
              grandTotal: data.Total
            });
          }

          Kobo._mediator.fire('priceSummary::update', {
            lineItems: data.PriceSummary
          });

          if (successCallback) {
            successCallback();
          }

          Kobo._mediator.fire('ItemPromoOfferBanner::showNewCustomerCreditNotification', {
            itemPromotionAvailable: data.itemPromotionAvailable,
            showCreditAppliedBanner: data.ShowCreditAppliedBanner,
            showItemAddedToCartBanner: data.ShowItemAddedToCartBanner
          });
        } else {
          if (failureCallback) {
            failureCallback(data.MessageType);
          }

          if (data.MessageType === "error") {
            Kobo._mediator.fire("toast::error", {
              message: data.ErrorMessage
            });
          } else if (data.MessageType === "info" && !requestData.suppressToast) {
            Kobo._mediator.fire("toast::info", {
              message: data.ErrorMessage
            });
          }
        }
      },
      error: function error() {
        if (failureCallback) {
          failureCallback();
        }

        Kobo._mediator.fire("toast::error", {
          message: DynamicConfiguration.resourceStrings.errorAddedToCart
        });
      }
    });
  };

  ShoppingCartList.prototype.showVipAddedToCartNotification = function () {
    Kobo._mediator.fire('toast::info', {
      message: DynamicConfiguration.resourceStrings.loveNotificationTitle
    });
  };

  ShoppingCartList.prototype.removeItemAjaxRequest = function (shoppingItem) {
    var _this5 = this;

    var removeItemUrl = shoppingItem.removeItemUrl();

    if (!removeItemUrl) {
      Kobo._mediator.fire('shoppingCartItems::removeItem', {
        item: shoppingItem
      });

      return;
    }

    Kobo.Ajax({
      method: 'POST',
      url: removeItemUrl,
      dataType: 'json',
      headers: {
        '__RequestVerificationToken': getAntiForgeryToken()
      },
      data: JSON.stringify({
        Id: shoppingItem.id,
        IsKoboLoveMembership: shoppingItem.isKoboLoveMembership()
      }),
      contentType: 'application/json; charset=utf-8',
      success: function success(data) {
        if (data.IsSuccess) {
          Kobo._mediator.fire('shoppingCartItems::update', {
            cartItems: data.CartItems,
            deals: data.Deals,
            priceSummary: data.PriceSummary,
            total: data.Total,
            koboLovePricingEnabled: data.KoboLoveDiscountsEnabled,
            koboLovePointsEnabled: data.KoboLovePointsEnabled,
            showKoboLoveVipUpsell: data.ShowKoboLoveVipUpsell,
            vipMessage: data.VipMessage
          });

          _this5.fireReadyForRecosEvent();

          Kobo._mediator.fire('regularCheckout::updateStoreCreditBalance', {
            storeCreditBalance: data.StoreCreditBalance
          });

          Kobo._mediator.fire('koboLove::updateDetails', {
            amountSaved: data.KoboLoveDiscountAmount,
            pointsEarned: data.KoboLovePointsEarned,
            basePointsEarned: data.KoboLoveBasePointsEarned,
            bonusPointsEarned: data.KoboLoveBonusPointsEarned,
            pointsBalance: data.KoboLovePointsBalance,
            showPointsEarnedBreakdown: data.KoboLoveSummaryHasBonusPoints,
            vipUpsellText: data.VipUpsellText
          });
        } else {
          shoppingItem.errorMessage(data.ErrorMessage);
        }

        Kobo._mediator.fire('priceSummary::update', {
          lineItems: data.PriceSummary
        });
      },
      error: function error() {// TODO
      }
    });
  };

  ShoppingCartList.prototype.removeItemInline = function (shoppingItem) {
    var _this6 = this;

    if (shoppingItem.isDealItem()) {
      this.warnOnRemovingDeal(shoppingItem).done(function () {
        _this6.removeItemAjaxRequest(shoppingItem);
      });
    } else if (shoppingItem.isKoboLoveMembership()) {
      this.warnOnRemovingKoboLoveMembership().done(function () {
        _this6.removeItemAjaxRequest(shoppingItem);
      });
    } else {
      this.removeItemAjaxRequest(shoppingItem);
    }
  };

  ShoppingCartList.prototype.editItemInline = function (shoppingItem) {
    window.location.href = shoppingItem.editItemUrl();
  };

  ShoppingCartList.prototype.warnOnRemovingDeal = function (shoppingItem) {
    var dealsSummaryArray = this.dealsSummaryArray(),
        deal,
        dealName,
        affectedDealId,
        i,
        deferred = Kobo.$.Deferred();
    affectedDealId = shoppingItem.deal.id;

    for (i = 0; i < dealsSummaryArray.length; i++) {
      deal = dealsSummaryArray[i];

      if (affectedDealId === deal.DealId) {
        dealName = deal.DealName;
      }
    }

    Kobo.Utilities.showDialog({
      title: DynamicConfiguration.resourceStrings.removeDialogTitle,
      content: Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.removeDialogMessage, dealName),
      buttons: [{
        label: DynamicConfiguration.resourceStrings.removeItem,
        action: function action() {
          deferred.resolve();
        }
      }, {
        label: DynamicConfiguration.resourceStrings.keepItem
      }]
    });
    return deferred;
  };

  ShoppingCartList.prototype.warnOnRemovingKoboLoveMembership = function () {
    var deferred = Kobo.$.Deferred();
    Kobo.Utilities.showDialog({
      title: DynamicConfiguration.resourceStrings.vipRemoveTitle,
      content: DynamicConfiguration.resourceStrings.vipRemoveMessage,
      buttons: [{
        label: DynamicConfiguration.resourceStrings.vipRemoveNegativeAction,
        action: function action() {
          deferred.resolve();
        }
      }, {
        label: DynamicConfiguration.resourceStrings.vipRemovePositiveAction
      }]
    });
    return deferred;
  };

  function ShoppingCartItem(id, publisherCheckboxCheckedByDefault) {
    var _this7 = this;

    if (typeof id === 'undefined' || id === null) {
      throw 'An id must be provided for a shopping cart item';
    }

    this.id = id;
    this.title = ko.observable('');
    this.subTitle = ko.observable('');
    this.imageUrl = ko.observable('');
    this.seriesName = ko.observable('');
    this.authors = ko.observable([]);
    this.ProductType = ko.observable('');
    this.type = ko.observable();
    this.publisher = ko.observable('');
    this.hasKoboLoveDiscount = ko.observable('');
    this.isAudiobookSubscription = ko.observable(false);
    this.isMonetaryGift = ko.observable(false);
    this.configurationId = ko.observable('');
    this.isBookSubscription = ko.observable(false);
    this.isTrial = ko.observable(false);
    this.isKoboLoveProduct = ko.observable('');
    this.offersText = ko.observable('');
    this.publisherCheckboxVisible = ko.observable('');
    this.hasOptedInForOffers = ko.observable(publisherCheckboxCheckedByDefault);
    this.isPreOrder = ko.observable(false);
    this.availableOnDate = ko.observable();
    this.narrators = ko.observable([]);
    this.duration = ko.observable('');
    this.secondaryMessage = ko.observable('');
    this.recommendationKey = ko.observable('');
    this.restricted = ko.observable(false);
    this.removeItemUrl = ko.observable(false);
    this.editItemUrl = ko.observable('');
    this.itemDetailUrl = ko.observable(''); // This will always be returned from the server as the product detail url or HOME.

    this.updateSubscriptionUrl = ko.observable('');
    this.errorMessage = ko.observable('');
    this.infoMessage = ko.observable('');
    this.description = ko.observable('');
    this.descriptionFullName = ko.observable('');
    this.priceDetails = ko.observableArray([]);
    this.dealsFound = ko.observable();
    this.isKoboLoveMembership = ko.observable(false);
    this.availableInCurrentGeo = ko.observable(false);
    this.appliedNewCustomerPromotion5Message = ko.observable('');
    this.priceAfterNewCustomerPromotionCredit = ko.observable('');
    this.hasAudiobookSubscriptionDiscount = ko.observable(false);
    this.attributes = ko.observable({});
    this.isAudiobookProduct = ko.observable(false);
    this.isEBookProduct = ko.observable(false);
    this.additionalProductInfo = ko.computed(function () {
      if (_this7.isEBookProduct()) return _this7.additionalBookInfo();
      if (_this7.isAudiobookProduct()) return _this7.additionalAudiobookInfo();
      return {};
    });
    this.additionalBookInfo = ko.computed(function () {
      var _Kobo$Enums = Kobo.Enums,
          ProductFormat = _Kobo$Enums.ProductFormat,
          BookLayout = _Kobo$Enums.BookLayout,
          ProductType = _Kobo$Enums.ProductType;

      var _this7$attributes = _this7.attributes(),
          productFormatType = _this7$attributes.ProductFormatType,
          bookLayoutType = _this7$attributes.BookLayoutType;

      var productType = _this7.type();

      var productFormat = ProductFormat.Extensions.mapToResourceString(ProductFormat.parseFromServer(productFormatType));
      var bookLayout = BookLayout.Extensions.mapToResourceString(BookLayout.parseFromServer(bookLayoutType));
      var productName = ProductType.Extensions.mapToResourceString(productType);
      var combinedFormatLayout = productFormat && bookLayout ? "(".concat(productFormat, ", ").concat(bookLayout, ")") : '';
      var formattedInfo = "".concat(productName, " ").concat(combinedFormatLayout);
      return formattedInfo;
    });
    this.additionalAudiobookInfo = ko.computed(function () {
      var ProductType = Kobo.Enums.ProductType,
          _DynamicConfiguration = DynamicConfiguration,
          resourceStrings = _DynamicConfiguration.resourceStrings;

      var _this7$attributes2 = _this7.attributes(),
          isAbridged = _this7$attributes2.IsAbridged;

      var productType = _this7.type();

      var abridgedStatus = isAbridged ? resourceStrings.abridged : resourceStrings.unabridged;
      var productName = ProductType.Extensions.mapToResourceString(productType);
      var formattedInfo = "".concat(productName, " (").concat(abridgedStatus, ")");
      return formattedInfo;
    });
    this.hasOptedInForOffers.subscribe(function () {
      var _this8 = this;

      Kobo.Ajax({
        method: 'POST',
        url: this.updateSubscriptionUrl(),
        dataType: 'json',
        data: JSON.stringify({
          productId: this.id,
          hasOptedInForRenewals: false,
          hasOptedInForOffers: this.hasOptedInForOffers()
        }),
        contentType: 'application/json; charset=utf-8',
        success: function success(data) {
          if (data.IsSuccess) {
            _this8.errorMessage('');
          } else {
            _this8.errorMessage(data.ErrorMessage);
          }
        },
        error: function error() {// TODO
        }
      });
    });
  }

  ;
  ShoppingCartItem.prototype = {};

  ShoppingCartItem.prototype.addPriceDetail = function (label, amount, isCredit, isTotal, isTotalSavings, isBookSubSummary, isNewCustomerPromotionAvailable, isPriceAfterNewCustomerCredit) {
    this.priceDetails.push(new PriceDetail(label, amount, isCredit, isTotal, isTotalSavings, isBookSubSummary, isNewCustomerPromotionAvailable, isPriceAfterNewCustomerCredit));
  };

  ShoppingCartItem.prototype.isDealItem = function () {
    return !!this.deal;
  };

  ShoppingCartItem.prototype.displayAsAudiobookTrialSubscriptionBanner = function () {
    return this.isAudiobookSubscription() && this.isTrial();
  };

  ShoppingCartItem.prototype.displayAsKoboPlusTrialSubscriptionBanner = function () {
    return this.isBookSubscription() && this.isTrial();
  };

  function ShoppingCartDeal(deal) {
    this.name = ko.observable(deal.DealName);
    this.originalPrice = ko.observable(deal.OriginalPrice);
    this.discountedPrice = ko.observable(deal.DiscountedPrice);
    this.id = deal.DealId;
    this.index = deal.DealIndex;
    this.items = ko.observableArray([]);
  }

  ;

  function PriceDetail(label, amount, isCredit, isTotal, isTotalSavings, isBookSubSummary, isNewCustomerPromotionAvailable, isPriceAfterNewCustomerCredit) {
    this.label = label;
    this.amount = amount;
    this.isCredit = isCredit;
    this.isTotal = isTotal;
    this.isTotalSavings = isTotalSavings;
    this.isBookSubSummary = isBookSubSummary; // TODO: If used exclusively as a knockout binding, refactor to a pureComputed

    this.verboseLabel = function () {
      var formattedPrice = (this.isCredit ? '-' : '') + this.amount;

      if (!this.label) {
        return formattedPrice;
      }

      if (this.isBookSubSummary) {
        return this.label;
      }

      return formattedPrice;
    };

    this.isNewCustomerPromotionAvailable = isNewCustomerPromotionAvailable;
    this.isPriceAfterNewCustomerCredit = isPriceAfterNewCustomerCredit;
  }

  function DealNotification() {}

  DealNotification.prototype = {};

  DealNotification.prototype.buildNotification = function () {
    var $closeButton = Kobo.$("<button class=\"close\" type=\"button\" aria-label=\"".concat(DynamicConfiguration.resourceStrings.close, "\"/>")),
        $notificationTextWrapper = Kobo.$('<div class="deal-notification-text-wrapper"></div>');
    $notificationWrapper = Kobo.$('<div class="deal-notification"><div class="logo-circle"/></div>');
    $notificationText = Kobo.$('<div class="deal-notification-text"></div>');
    $notificationTextWrapper.append($notificationText);
    $notificationTextWrapper.append($closeButton);
    $notificationWrapper.append($notificationTextWrapper);
    $closeButton.click(cartSelf.notification.close);
    Kobo.$body.append($notificationWrapper);
    this.$notificationText = this.$notificationText;
    this.$notificationWrapper = this.$notificationWrapper;
  };

  DealNotification.prototype.open = function (contents) {
    if (!this.$notificationWrapper) {
      buildNotification();
    }

    this.$notificationText.html(contents);
    this.$notificationWrapper.addClass('show');
  };

  DealNotification.prototype.close = function () {
    this.$notificationWrapper.removeClass('show');
  };

  Kobo.Gizmo.ShoppingCartItems = GizmoShoppingCartItems;
})(Kobo);