"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.Behaviors.Braintree.ValidateBraintreeFieldsBehavior', ValidateBraintreeFieldsBehavior);

  function ValidateBraintreeFieldsBehavior(braintreeValidationVM) {
    var self = this;

    function _onValidate(event, data) {
      var validatedCallback = data.detail.validatedCallback;

      Kobo._mediator.fire('braintreeHostedFields::fetchFormState', {
        formStateReadyCallback: _onReceivedFormState
      });

      function _onReceivedFormState(state) {
        // update overall form validity
        var formValidity = Object.keys(state.fields).every(function (key) {
          return state.fields[key].isValid;
        });
        braintreeValidationVM.isPaymentValid(formValidity); // update validity of individual fields

        braintreeValidationVM.isCreditCardValid(state.fields['number'].isValid);
        braintreeValidationVM.isExpirationDateValid(state.fields['expirationMonth'].isValid && state.fields['expirationYear'].isValid);
        braintreeValidationVM.isCVVValid(state.fields['cvv'].isValid);
        validatedCallback(formValidity);
      }
    }

    function _onValidateExpiry(event, data) {
      var _data$detail = data.detail,
          expirationMonth = _data$detail.expirationMonth,
          expirationYear = _data$detail.expirationYear,
          emittedBy = _data$detail.emittedBy,
          dateValidityCallback = _data$detail.dateValidityCallback;
      var isDateValid = expirationMonth.isValid && expirationYear.isValid;
      dateValidityCallback(emittedBy, isDateValid);
    }

    function _updateCreditCardValidStatus(event, isValid) {
      braintreeValidationVM.isCreditCardValid(isValid.detail);
    }

    function _updateCvvValidStatus(event, isValid) {
      braintreeValidationVM.isCVVValid(isValid.detail);
    }

    function _updateExpiryDateValidStatus(event, isValid) {
      braintreeValidationVM.isExpirationDateValid(isValid.detail);
    }

    function _setErrorMessage(event, ErrMsg) {
      braintreeValidationVM.savePaymentInfoErrorMsg(ErrMsg.detail);
      braintreeValidationVM.showSavePaymentErrorMsg(true);
    }

    function _hideErrorMessage() {
      braintreeValidationVM.showSavePaymentErrorMsg(false);
    }

    function init() {
      Kobo._mediator.subscribe('BraintreeForPurchasePath::updateCreditCardValidStatus', _updateCreditCardValidStatus);

      Kobo._mediator.subscribe('BraintreeForPurchasePath::updateCvvValidStatus', _updateCvvValidStatus);

      Kobo._mediator.subscribe('BraintreeForPurchasePath::updateExpiryDateValidStatus', _updateExpiryDateValidStatus);

      Kobo._mediator.subscribe('BraintreeForPurchasePath::setErrorMessage', _setErrorMessage);

      Kobo._mediator.subscribe('BraintreeForPurchasePath::hideErrorMessage', _hideErrorMessage);

      Kobo._mediator.register('braintreeHostedFields::validate');

      Kobo._mediator.subscribe('braintreeHostedFields::validate', _onValidate);

      Kobo._mediator.register('braintreeHostedFields::validateExpiry');

      Kobo._mediator.subscribe('braintreeHostedFields::validateExpiry', _onValidateExpiry);
    }

    self.destroy = function () {
      Kobo._mediator.unSubscribe('BraintreeForPurchasePath::updateCreditCardValidStatus', _updateCreditCardValidStatus);

      Kobo._mediator.unSubscribe('BraintreeForPurchasePath::updateCvvValidStatus', _updateCvvValidStatus);

      Kobo._mediator.unSubscribe('BraintreeForPurchasePath::updateExpiryDateValidStatus', _updateExpiryDateValidStatus);

      Kobo._mediator.unSubscribe('BraintreeForPurchasePath::setErrorMessage', _setErrorMessage);

      Kobo._mediator.unSubscribe('BraintreeForPurchasePath::hideErrorMessage', _hideErrorMessage);

      Kobo._mediator.unSubscribe('braintreeHostedFields::validate', _onValidate);

      Kobo._mediator.unRegister('braintreeHostedFields::validate');
    };

    init();
  }
})();