"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo, ko, Cookies*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.SavePaymentMethod.ViewModels.SavePaymentMethodVM', SavePaymentMethodViewModel);

  function SavePaymentMethodViewModel(options) {
    var _paymentMethodTypeVie;

    var self = this,
        ViewModels = Kobo.SavePaymentMethod.ViewModels,
        PaymentMethodType = Kobo.PurchasePath.Enums.PaymentMethodType,
        MessageBoxType = Kobo.Controls.MessageBox.MessageBoxType,
        FailureReason = Kobo.SavePaymentMethod.FailureReason,
        resourceStrings = window.DynamicConfiguration.savePaymentMethod.resourceStrings,
        internalMessageBoxName = 'savePaymentMethod',
        submitButtonName = 'savePaymentMethodButton',
        paymentMethodAddedMessageKey = 'paymentMethodAdded';
    var paymentMethods = options.paymentMethods,
        braintreeClientToken = options.braintreeClientToken,
        returnUrl = options.returnUrl,
        stripeRedirectUrl = options.stripeRedirectUrl,
        destinationMessageBoxName = options.messageBoxName,
        recordVerifyCardMetricsUrl = options.recordVerifyCardMetricsUrl,
        savePaymentMethod = options.savePaymentMethod,
        executeHCaptcha = options.executeHCaptcha,
        caller = options.caller;

    this.savePaymentMethod = function (paymentMethodData) {
      Kobo._mediator.fire('kobo-message-box::clearAll', {
        recipientMessageBoxName: internalMessageBoxName
      });

      Kobo._mediator.fire('kobo-loading-submit-button::loading', {
        recipientButtonName: submitButtonName
      });

      var onCaptchaSuccess = function onCaptchaSuccess(_ref) {
        var captchaToken = _ref.response;
        return savePaymentMethod(_objectSpread(_objectSpread({}, paymentMethodData), {}, {
          captchaToken: captchaToken
        }));
      };

      var onCaptchaFailure = function onCaptchaFailure() {
        return Promise.reject(FailureReason.CaptchaError);
      };

      return executeHCaptcha().then(onCaptchaSuccess, onCaptchaFailure);
    };

    var paymentMethodTypeViewModelMap = (_paymentMethodTypeVie = {}, _defineProperty(_paymentMethodTypeVie, PaymentMethodType.BraintreeCreditCard, function (options) {
      return new ViewModels.SaveBraintreeCreditCardVM(_objectSpread({
        braintreeClientToken: braintreeClientToken,
        recordVerifyCardMetricsUrl: recordVerifyCardMetricsUrl
      }, options));
    }), _defineProperty(_paymentMethodTypeVie, PaymentMethodType.BraintreePayPal, function (options) {
      return new ViewModels.SaveBraintreePayPalVM(_objectSpread({
        braintreeClientToken: braintreeClientToken
      }, options));
    }), _defineProperty(_paymentMethodTypeVie, PaymentMethodType.SepaStripe, function (options) {
      return new ViewModels.SaveIdealSepaVM(_objectSpread({
        stripeRedirectUrl: stripeRedirectUrl
      }, options));
    }), _paymentMethodTypeVie);

    this.handleItemSelected = function (item) {
      Kobo._mediator.fire('kobo-message-box::clearAll', {
        recipientMessageBoxName: internalMessageBoxName
      });

      var button = item.content.viewModel.button;
      self.saveButton(button);
    };

    function buildPaymentMethodViewModels() {
      return paymentMethods.map(function (paymentMethod) {
        var viewModel = paymentMethodTypeViewModelMap[paymentMethod.PaymentMethodType];
        if (!viewModel) throw new Error("Missing view model mapping for payment method of type ".concat(paymentMethod.paymentMethodType, "."));

        var options = _objectSpread(_objectSpread({}, paymentMethod), {}, {
          caller: caller,
          savePaymentMethod: self.savePaymentMethod,
          savePaymentMethodSuccessEvent: self.savePaymentMethodSuccessEvent,
          savePaymentMethodFailureEvent: self.savePaymentMethodFailureEvent
        });

        return viewModel(options);
      });
    }

    this.setErrorForFailureReason = function (failureReason) {
      var mapToMessageBoxErrorContent = Kobo.SavePaymentMethod.FailureReason.Extensions.mapToMessageBoxErrorContent;

      Kobo._mediator.fire('kobo-loading-submit-button::loaded', {
        recipientButtonName: submitButtonName
      });

      Kobo._mediator.fire('kobo-message-box::clearAll', {
        recipientMessageBoxName: internalMessageBoxName
      });

      var errorMessage = _objectSpread(_objectSpread({
        messageType: MessageBoxType.Error
      }, mapToMessageBoxErrorContent(failureReason)), {}, {
        recipientMessageBoxName: internalMessageBoxName,
        messageBoxTemplate: 'kobo-icon-message-template'
      });

      Kobo._mediator.fire('kobo-message-box::add', {
        message: errorMessage
      });
    };

    this.onSavePaymentMethodSuccess = function () {
      var successMessage = {
        messageKey: paymentMethodAddedMessageKey,
        message: {
          messageType: MessageBoxType.Success,
          headline: resourceStrings.savePaymentMethodSuccess,
          recipientMessageBoxName: destinationMessageBoxName,
          messageBoxTemplate: 'kobo-icon-close-message-template'
        }
      };

      Kobo._mediator.fire('kobo-message-box::storeMessage', successMessage);

      self.navigateToReturnUrl();
    };

    this.navigateToReturnUrl = function () {
      Kobo.Utilities.navigateTo(returnUrl);
    };

    this.setErrorForFailureReasonAfterRedirect = function () {
      if (!Cookies.get('SavePaymentMethodFailureReason')) return;
      var failureReason = Cookies.get('SavePaymentMethodFailureReason');
      Cookies.remove('SavePaymentMethodFailureReason');
      window.addEventListener('load', function () {
        self.setErrorForFailureReason(failureReason);
      });
    };

    this.init = function () {
      self.savePaymentMethodSuccessEvent = new ko.subscribable();
      self.savePaymentMethodSuccessEvent.subscribe(self.onSavePaymentMethodSuccess);
      self.savePaymentMethodFailureEvent = new ko.subscribable();
      self.savePaymentMethodFailureEvent.subscribe(self.setErrorForFailureReason);
      self.setErrorForFailureReasonAfterRedirect();
      self.paymentMethods = buildPaymentMethodViewModels();
      self.saveButton = ko.observable();
    };

    this.init();
  }
})();