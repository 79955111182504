"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.PriceSummary = function (element, options) {
  "use strict";

  var self = this,
      LineItem,
      onUpdate,
      setupHandlers,
      allowSuperPoints,
      pointsToEarn,
      hasEasyId,
      isFeatureTurnedOn,
      superPointsEarnMessage,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("PriceSummary"); // A line of the price summary

  LineItem = function LineItem() {
    this.label = ko.observable();
    this.subLabel = ko.observable();
    this.amount = ko.observable();
    this.isCredit = ko.observable();
    this.isSuperPoints = ko.observable();
    this.isTotal = ko.observable();
    this.isTotalSavings = ko.observable();
    this.isFirstPurchasePromotion = ko.observable(false);
  };

  onUpdate = function onUpdate(eventName, data) {
    self.lineItems.removeAll();

    if (data.detail.lineItems) {
      Kobo.$.each(data.detail.lineItems, function () {
        var lineItem = new LineItem();
        lineItem.label(this.Label); // this refers to the an object in data.detail.lineItems

        lineItem.subLabel(this.SubLabel);
        lineItem.amount(this.Amount);
        lineItem.isCredit(this.IsCredit);
        lineItem.isSuperPoints(this.IsSuperPoints);
        lineItem.isTotal(this.IsTotal);
        lineItem.isTotalSavings(this.IsTotalSavings);
        lineItem.isFirstPurchasePromotion(this.IsFirstPurchasePromotion);

        if (this.IsTotal) {
          self.fire('regularCheckout::updateGrandTotal', {
            grandTotal: this.Amount
          });

          if (self.allowSuperPoints() && typeof data.detail.rakutenSuperPointsEarned !== "undefined") {
            self.pointsToEarn(superPointsEarnMessage.replace("{0}", data.detail.rakutenSuperPointsEarned));
          }
        }

        self.lineItems.push(lineItem);
      });
    }
  };

  self.setupResourceStrings = function () {
    var dynamicConfiguration = window.DynamicConfiguration;

    if (!dynamicConfiguration) {
      return;
    }

    superPointsEarnMessage = dynamicConfiguration.resourceStrings.superPointsEarn;
  };

  setupHandlers = function setupHandlers() {
    self.register('priceSummary::update');
    self.subscribe('priceSummary::update', onUpdate);
  };

  self.destroy = function () {
    self.unSubscribe('priceSummary::update', onUpdate);
  };

  init = function init() {
    self.lineItems = ko.observableArray();
    self.allowSuperPoints = ko.observable(options.isFeatureTurnedOn && options.isFeatureTurnedOn.toLowerCase() === 'true');
    self.pointsToEarn = ko.observable("");
    self.setupResourceStrings();
    setupHandlers();
    ko.applyBindings(self, element);
  };

  init();
};

Kobo.Gizmo.PriceSummary.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);