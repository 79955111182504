"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.EGiftingRedemption = function (element) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('EGiftingRedemption');
  var self = this,
      strings = window.DynamicConfiguration.resourceStrings.eGiftingRedemption,
      genericErrorMessage = window.DynamicConfiguration.resourceStrings.GenericErrorMessage,
      buildTemplate,
      redeem,
      handleRedemption,
      handleError;

  buildTemplate = function buildTemplate(vmSettings, vmStrings) {
    return {
      summaryVisible: vmSettings.summaryVisible,
      redeemable: vmSettings.redeemable,
      redeemed: vmSettings.redeemed,
      title: vmStrings.title,
      body: vmStrings.body,
      ctas: vmSettings.ctaLinks.map(function (url, index) {
        return {
          label: vmStrings.ctaLabels[index],
          url: url
        };
      })
    };
  };

  redeem = function redeem() {
    self.viewModel.error('');
    Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
    Kobo.Ajax({
      type: 'POST',
      url: self.settings.redemptionUrl,
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      dataType: 'json'
    }).done(function (data) {
      data.IsSuccess ? handleRedemption() : handleError();
    }).fail(function () {
      handleError();
    }).always(function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
    });
  };

  handleRedemption = function handleRedemption() {
    self.viewModel.template(buildTemplate(self.settings.postRedemption, strings.postRedemption));
  };

  handleError = function handleError() {
    self.viewModel.error(genericErrorMessage);
    var alert = document.querySelectorAll("section[data-bind='visible: error']")[0];
    alert.setAttribute('tabindex', '0');
    alert.focus();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  function init() {
    self.viewModel = {
      template: ko.observable(buildTemplate(self.settings.initial, strings.initial)),
      redeem: redeem,
      error: ko.observable('')
    };
    ko.applyBindings(self.viewModel, element);
  }

  init();
};

Kobo.Gizmo.EGiftingRedemption.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);