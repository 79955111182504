"use strict";

/*global Kobo*/
(function (Kobo) {
  function ExpandableTwoStepButtonVM(gizmo, options) {
    Kobo.ViewModel.Expandable.ArrowButton.apply(this, arguments); // bind event handlers

    this.toggle = this.toggle.bind(this); // init

    this.registerEvents();
  }

  ExpandableTwoStepButtonVM.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.ArrowButton.prototype);

  ExpandableTwoStepButtonVM.prototype.registerEvents = function () {
    this.gizmo.events.onResize(this.handleResize);
  };

  ExpandableTwoStepButtonVM.prototype.handleResize = function () {
    this.setButtonX(this.returnButtonX());
  };

  ExpandableTwoStepButtonVM.prototype.toggle = function () {
    this.isCollapsed(!this.isCollapsed());

    if (Modernizr.csstransitions) {
      this.recalculateHeight();
    } else {
      this.gizmo.$gizmo.animate("height");
    }
  };

  ExpandableTwoStepButtonVM.prototype.addButton = function () {
    Kobo.ViewModel.Expandable.ArrowButton.prototype.addButton.call(this);
    this.buttonWidth = this.$button.width() / 2;
    this.$button.addClass('gizmo-expandable-button-twostep');
  }; // Exports


  Kobo.ViewModel.Expandable.TwoStepExpandableButton = ExpandableTwoStepButtonVM;
})(Kobo);