"use strict";

/*jslint browser: true, this */

/*global jQuery, Kobo, $, ga, dataLayer */
(function () {
  function Talkable() {
    this.arr_primary = ["AU", "CA", "GB", "IE", "IT", "NZ", "US"];
    this.arr_row = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AN", "AO", "AQ", "AR", "AS", "AW", "AX", "AZ", "BA", "BB", "BD", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BS", "BT", "BV", "BW", "BY", "BZ", "CC", "CD", "CF", "CG", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CS", "CU", "CV", "CX", "CZ", "DJ", "DM", "DO", "DZ", "EC", "EG", "EH", "ER", "ET", "FJ", "FK", "FM", "FO", "GA", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GS", "GT", "GU", "GW", "GY", "HM", "HN", "HR", "HT", "HU", "ID", "IL", "IM", "IO", "IQ", "IR", "IS", "JE", "JM", "JO", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MU", "MV", "MW", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NP", "NR", "NU", "OM", "PA", "PE", "PF", "PG", "PK", "PL", "PM", "PN", "PR", "PS", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SG", "SH", "SJ", "SL", "SM", "SN", "SO", "SR", "ST", "SV", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TT", "TV", "TZ", "UA", "UG", "UM", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "YE", "YT", "ZM", "ZW"];
  }

  Talkable.prototype.getUserMerchCountry = function () {
    var _merchCountry = "";

    var _sessionCookie;

    if (document.cookie && document.cookie.indexOf("session=") > -1) {
      _sessionCookie = document.cookie.split("session=")[1].split("; ")[0];

      if (_sessionCookie.toLowerCase().indexOf("merchcountry=") > -1) {
        _merchCountry = _sessionCookie.toLowerCase().split("merchcountry=")[1].split("&")[0];
      }
    }

    return _merchCountry.trim();
  };

  Talkable.prototype.isWalmartAffiliate = function () {
    var isAffiliate = false;

    if (document.cookie.indexOf("partnerid=00000000-0000-0000-0000-000000000020") > -1 && document.cookie.indexOf("affiliateid=4aab9ec4-1c76-44a4-8eec-26f94869acbd") > -1) {
      isAffiliate = true;
    }

    return isAffiliate;
  };

  Talkable.prototype.isPlatformDesktopApp = function () {
    var isDesktopApp = false;
    var desktopAppPlatformIds = ["00000000-0000-0000-0000-000000000001", "00000000-0000-0000-0000-000000000002", "00000000-0000-0000-0000-000000000003", "00000000-0000-0000-0000-000000000004", "00000000-0000-0000-0000-000000000005", "00000000-0000-0000-0000-000000000010", "00000000-0000-0000-0000-000000000011", "00000000-0000-0000-0000-000000000012"];

    if (document.cookie.indexOf("platformid") > -1) {
      var platformIdCookie = document.cookie.split("platformid=")[1].split("&")[0];
      isDesktopApp = desktopAppPlatformIds.includes(platformIdCookie);
    }

    return isDesktopApp;
  };

  Talkable.prototype.getTalkableCampaignCountryID = function () {
    var _this = this;

    var _r = "";

    var _country = this.getUserMerchCountry().toUpperCase();

    var c_persistent = Cookies.get("persistent");

    if (_this.arr_primary.indexOf(_country) > -1) {
      _r = _country;

      if (_country === "CA" && c_persistent && c_persistent.toLowerCase().indexOf("language_locale=fr-ca") > -1) {
        _r = "QC"; // Quebec user override.
      }
    } else if (_this.arr_row.indexOf(_country) > -1) {
      _r = "ROW"; // Rest of World user override.
    }

    return _r;
  };

  Talkable.prototype.build = function (geo) {
    var username = $(".user-name span").text().trim();

    if (!username && $('#userNameId')[0]) {
      username = $('#userNameId')[0].value;
    }

    var shareURL = "";
    var shareCopy = "";

    if (geo === "QC") {
      shareURL = "https://www.kobo.com/ca/fr/p/share";
      shareCopy = "Soyez récompensé";
    } else if (geo === "IE") {
      shareURL = "https://www.kobo.com/ie/en/p/share";
      shareCopy = "Earn Rewards";
    } else if (geo === "IT") {
      shareURL = "https://www.kobo.com/it/it/p/share";
      shareCopy = "Guadagna Premi";
    } else if (geo === "GB") {
      shareURL = "https://www.kobo.com/gb/en/p/share";
      shareCopy = "Earn Rewards";
    } else if (geo.match(/^(AU|CA|NZ|US|ROW)$/)) {
      shareURL = "https://www.kobo.com/p/share";
      shareCopy = "Earn Rewards";
    }

    if (document.cookie.indexOf("uid=cC00ppxFsPRpF7DxNSjqB6Tb5EQ") === -1 && username.length > 0 && username.length <= 7) {
      shareCopy += ", " + username + "!";
    } // Web


    if (!this.isPlatformDesktopApp()) {
      var desktopTemplate = "<a aria-label=\"" + shareCopy + "\" class=\"sp-referral rich-header-referral\" alt=\"Referral\" href=\"" + shareURL + "\">" + shareCopy + "</a>";
      $(desktopTemplate).insertBefore(".rich-header-secondary-link-bar");
    } // Mobile


    var mobileTemplate = "<li class=\"tab main-nav-referrallink\"> <a aria-label=\"" + shareCopy + "\" class=\"sp-mobilereferral referrallink\" data-track-info=\"{\"description\":\"Referral\"}\" href=\"" + shareURL + "\">" + shareCopy + "</a></li>";
    $(".rich-header-main-nav").append(mobileTemplate);
  };

  Talkable.prototype.gaTracking = function () {
    $(".sp-referral").on("mousedown", function () {
      dataLayer.push({
        "event": "kobo.gaEvent",
        "eventCategory": "Acquisitions",
        "eventAction": "Talkable",
        "eventLabel": "Click Get Credit in Header"
      });
    });
  };

  window.Kobo_SP = window.Kobo_SP || {};
  window.Kobo_SP.Optimizely = window.Kobo_SP.Optimizely || {};
  window.Kobo_SP.Optimizely.Talkable = window.Kobo_SP.Optimizely.Talkable || Talkable;
  $(document).ready(function () {
    if (!document.querySelector("body").classList.contains("Store-ThankYou") || !document.querySelector("body").classList.contains("Store-Checkout")) {
      var talkable = new window.Kobo_SP.Optimizely.Talkable();
      var userGeo = talkable.getTalkableCampaignCountryID(); // Geo must be on either primary or ROW list

      if (userGeo !== "" && talkable.isWalmartAffiliate() === false) {
        talkable.build(userGeo);
        talkable.gaTracking();
      }
    }
  });
})();