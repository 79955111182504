"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/*global Kobo*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RAT.PageHelper', pageHelper);

  function pageHelper() {
    var self = this;

    this.getPageLanguageFilter = function () {
      var url = new URL(window.location.href);
      var params = new URLSearchParams(url.search);
      return params.get('fclanguages');
    };

    this.getRequestId = function () {
      var idField = Kobo.$('#ratRequestId');
      var id = null;
      if (idField != null && idField.length > 0 && idField[0].value !== '0') id = idField[0].value;
      return id;
    };

    this.getLanguageLocale = function () {
      var locale = $('#ratCountryCode')[0].value;

      if (locale === 'IN' || locale === 'CA' || locale === 'ZA' || locale === 'SI' || locale === 'SE') {
        locale = 'en-' + locale;
      } else if (locale === 'AT' || locale === 'LU') {
        locale = 'de-' + locale;
      } else if (locale === 'CY' || locale === 'GR') {
        locale = 'el-' + locale;
      } else if (locale === 'DK') {
        locale = 'da-' + locale;
      } else if (locale === 'EE') {
        locale = 'et-' + locale;
      } else if (locale === 'BR') {
        locale = 'pt-' + locale;
      } else if (locale === 'WW') {
        locale = 'en-CA';
      }

      return locale;
    }; //function converts string of any provided locale to a number of ####.## format. 


    this.convertToNumber = function (num) {
      var locale = self.getLanguageLocale();

      var _Intl$NumberFormat = new Intl.NumberFormat(locale),
          format = _Intl$NumberFormat.format;

      var _$exec = /^0(.)1$/.exec(format(0.1)),
          _$exec2 = _slicedToArray(_$exec, 2),
          decimalSign = _$exec2[1];

      return +num.replace(new RegExp("[^".concat(decimalSign, "\\d]"), 'g'), '').replace(decimalSign, '.');
    }; //{{Search - Page Number}}


    this.getSearchPageNumber = function () {
      if (window.location.href.indexOf('pageNumber=') > -1) {
        return parseInt(window.location.href.split('&pageNumber=')[1].split('&')[0]);
      } else if (document.querySelector('.pagination .page-link.active') !== null) {
        return parseInt(document.querySelector('.pagination .page-link.active').innerText);
      } else {
        return 1;
      }
    }; //{{Search - product id - List}}


    this.getSearchProductIdList = function () {
      var ratProductIdList = [];
      document.querySelectorAll('.item-wrapper:not(#sponsored-ad)').forEach(function (el) {
        ratProductIdList.push(JSON.parse(el.dataset.trackInfo).productId);
      });
      return ratProductIdList.join(',');
    }; //{{Search - price - List}}


    this.getSearchPriceList = function () {
      var ratPriceList = [];
      var mRegx = /([0-9,]+(\.[0-9]+)?)/g;
      document.querySelectorAll('.item-wrapper:not(#sponsored-ad) .item-detail .price').forEach(function (el) {
        var priceSpan = el.querySelector('span span span span:not(.currency)');

        if (el.classList.contains('free') || priceSpan === null) {
          ratPriceList.push(0);
        } else {
          var matchedRegx = mRegx.exec(el.querySelector('span span span span:not(.currency)').innerText.trim().replace(/\s/g, ''));
          mRegx.lastIndex = 0;
          var formatPrice = parseFloat(self.convertToNumber(matchedRegx[0]));
          ratPriceList.push(formatPrice);
        }
      });
      return ratPriceList.join(',');
    }; //{{Search - Total Search Results}}


    this.getTotalSearchResults = function () {
      var pageNumberEl = document.querySelectorAll(".search-results-summary .show-page-numbers .figures");
      if (pageNumberEl.length === 3) return parseInt(pageNumberEl[2].innerText);else if (document.querySelectorAll(".search-results-summary .summary .figures:nth-of-type(3)").length > 0) return parseInt(document.querySelectorAll(".search-results-summary .summary .figures:nth-of-type(3)")[0].innerText);else if (document.querySelectorAll(".search-results-summary .summary-page-numbers .figures:nth-of-type(3)").length > 0) return parseInt(document.querySelectorAll(".search-results-summary .summary-page-numbers .figures:nth-of-type(3)")[0].innerText);
      return 0;
    }; //{{RAT - Search - Sort By}}


    this.getSearchSortBy = function () {
      if (window.location.href.indexOf('sort=') > -1) {
        var sortBy = window.location.href.split('sort=')[1].split('&')[0].toLowerCase();

        switch (sortBy) {
          case 'temperature':
            return 1;

          case 'priceasc':
            return 2;

          case 'pricedesc':
            return 3;

          case 'publicationdatedesc':
            return 4;

          case 'averagerating':
            return 5;

          case 'publicationdateasc':
            return 6;

          case 'titleasc':
            return 7;

          case 'titledesc':
            return 8;

          default:
            return 1;
        }
      } else {
        return 1;
      }
    }; //{{Sub Categories - Total Search Results}}


    this.getSubCategoryTotalResults = function () {
      return document.querySelector('.facet-list[data-field-name="fcCategories"] .facet-list-view-widget[data-field-selected="True"] a em').innerText.replace(/[()]/g, '').trim();
    }; //{{RAT - Other Pages - JS}}


    this.getOtherPageName = function () {
      var pagePath = window.location.pathname;
      var categoryPageName;

      switch (true) {
        case /..\/..\/library/.test(pagePath):
          return 'library';

        case /(\/ebooks((\?|#)(.*))?|\/ebooks\/((\?|#)(.*))?)$/.test(pagePath):
          return 'ebooks';

        case /(\/audiobooks((\?|#)(.*))?|\/audiobooks\/((\?|#)(.*))?)$/.test(pagePath):
          return 'audiobooks';

        case /..\/..\/ebooks\/[^?][a-zA-Z]*/.test(pagePath):
          categoryPageName = pagePath.split('ebooks/')[1];
          return 'ebooks/' + categoryPageName;

        case /..\/..\/audiobooks\/[^?][a-zA-Z]*/.test(pagePath):
          categoryPageName = pagePath.split('audiobooks/')[1];
          return 'audiobooks/' + categoryPageName;

        default:
          return null;
      }
    }; //{{ RAT - Page Name - RegEx Table}}


    this.getPageName = function () {
      var pagePath = window.location.href;
      var domain = window.location.hostname;

      switch (true) {
        case new RegExp("(.*" + domain + "(/|/../..|/../../)?$)", "g").test(pagePath):
          return 'home';

        case new RegExp("(.*" + domain + "(/|/../../audiobook.*|#.*)?$)", "g").test(pagePath):
          return 'item';

        case new RegExp("(.*" + domain + "(/|/../../audiobooks.*|#.*)?$)", "g").test(pagePath):
          return 'category';

        case new RegExp("(.*" + domain + "(/|/../../ebook.*|#.*)?$)", "g").test(pagePath):
          return 'item';

        case new RegExp("(.*" + domain + "(/|/../../ebooks.*|#.*)?$)", "g").test(pagePath):
          return 'category';

        default:
          return 'other';
      }
    };
  }
})();