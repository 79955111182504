"use strict";

/*global Kobo,ko,window,document,setTimeout*/
Kobo.Gizmo.RegularCheckout = function (el, options) {
  "use strict";

  var self = this,
      setupHandlers,
      extractHash,
      registerEvents,
      getCheckoutDetails,
      onGAEventTrigger,
      onUpdateButton,
      onCountryChange,
      onContinuePurchase,
      onPromoCodeApply,
      onShoppingCartItemsUpdated,
      onOrderDataValidated,
      onGrandTotalUpdated,
      onStoreCreditBalanceUpdated,
      onSufficientPaymentUpdated,
      onCheckoutDetailsRetrieved,
      // 3DS is an additional security measure to verify credit card payments
  showThreeDsPopup,
      onThreeDsSuccess,
      onThreeDsError,
      onThreeDsCancel,
      notifyThreeDsState,
      onRequestFocus,
      setupResourceStrings,
      genericErrorMessage,
      timeoutErrorMessage,
      initialErrorMessage,
      showUncancelableError,
      locationChangeMessage,
      showError,
      init,
      autoScroller,
      showSpinner,
      hideSpinner,
      updateSufficientSuperPoints,
      onRakutenSuperPointsApply,
      addPaddingForSoftKeyboard,
      fireShoppingCartUpdateEvent,
      createKoboLoveUpdateSavingsModel;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType("RegularCheckout");

  createKoboLoveUpdateSavingsModel = function createKoboLoveUpdateSavingsModel(data) {
    return {
      amountSaved: data.KoboLoveDiscountAmount,
      pointsEarned: data.KoboLovePointsEarned,
      pointsBalance: data.KoboLovePointsBalance,
      basePointsEarned: data.KoboLoveBasePointsEarned,
      bonusPointsEarned: data.KoboLoveBonusPointsEarned
    };
  };

  extractHash = function extractHash(url) {
    if (url) {
      url = url.toString();

      if (url.charAt(0) === '#') {
        return url.substring(1);
      }

      return url.substring(url.indexOf('#') + 1, url.length);
    }

    return '';
  }; // TECH DEBT - This should probably be using the Dialog.js code. Unfortunately
  // that is currently EPD-only so it needs to be converted to work on web as well.


  showError = function showError(message) {
    var errorModalOptions = {},
        closeModalButton,
        errorMessage = message || genericErrorMessage,
        clickTrackInfo = Kobo._tracker.getTrackAttribute() + '=\'{ "section": "errorDialog" }\'';
    errorModalOptions.customClass = 'error-dialog';

    Kobo._modal.open('<div class="error-content" ' + clickTrackInfo + '><button class="close-button"></button><p>' + errorMessage + '</p></div>', errorModalOptions);

    closeModalButton = document.querySelector('.close-button');
    closeModalButton.addEventListener('click', Kobo._modal.close);
  };

  showUncancelableError = function showUncancelableError(errorMessage, timeout) {
    var errorModalOptions = {};
    errorModalOptions.customClass = 'error-dialog';

    Kobo._modal.open('<div class="error-content">' + errorMessage + '</div>', errorModalOptions);

    if (timeout) {
      setTimeout(Kobo._modal.close, timeout);
    }
  };

  showSpinner = function showSpinner() {
    Kobo._modal.open("<div class ='ajax-spinner'></div>", {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  hideSpinner = function hideSpinner() {
    Kobo._modal.close();
  };

  getCheckoutDetails = function getCheckoutDetails(eventName, data) {
    var navigatePurchaseSection = data && data.detail && data.detail.navigatePurchaseSection;

    if (self.currentStatusUrl) {
      showSpinner();
      Kobo.Ajax({
        method: "GET",
        cache: false,
        url: self.currentStatusUrl,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        success: function success(data) {
          onCheckoutDetailsRetrieved(data, navigatePurchaseSection);
        },
        complete: function complete() {
          if (!self.inBuyProcess()) {
            Kobo._modal.close();

            if (options.showInitialError === 'True') {
              showError(initialErrorMessage);
            }
          }
        },
        error: function error() {}
      });
    }
  };

  onCheckoutDetailsRetrieved = function onCheckoutDetailsRetrieved(data, navigatePurchaseSection) {
    if (data.loadRakutenSuperpoints) {
      self.loadRakutenSuperpoints(data.loadRakutenSuperpoints);
      self.haveSuperPointsBeenApplied = data.RakutenSuperPointsApplied && data.RakutenSuperPointsApplied > 0;
      self.fire('regularCheckout::loadRakutenSuperpoints', {
        pointsBalance: data.SuperPoints.Balance,
        pointsTimeSensitive: data.SuperPoints.TimeSensitivePoints,
        pointsCash: data.SuperPoints.CashPoints,
        minimumRedeemable: data.SuperPoints.MinimumRedeemable,
        pointsRedeemed: data.RakutenSuperPointsApplied
      });
    }

    if (data.PaymentOptions && data.PaymentOptions.CreditCard) {
      self.isCreditCardAvailable(data.PaymentOptions.CreditCard.IsAvailable && data.PaymentOptions.CreditCard.HasSavedInfo);
    }

    if (data.StoreCreditApplied) {
      self.storeCreditApplied(data.StoreCreditApplied);
    }

    if (data.StoreCreditBalance) {
      self.fire('regularCheckout::updateStoreCreditBalance', {
        storeCreditBalance: data.StoreCreditBalance
      });
    }

    if (data.BillingAddressFields) {
      self.fire('billingInfo::update', {
        addressFields: data.BillingAddressFields
      });
    }

    if (data.GiftCardTypes) {
      self.giftCardFormVisible(data.GiftCardTypes.length > 0 ? true : false);
      self.fire('giftCards::replaceGiftCardTypes', {
        types: data.GiftCardTypes
      });
    }

    if (data.GiftCard) {
      self.fire('giftCards::setCurrentGiftCard', {
        number: data.GiftCard.Number,
        appliedToOrder: data.GiftCard.AppliedToOrder,
        balance: data.GiftCard.Balance,
        cardTypeId: data.GiftCard.CardType,
        errorMessage: data.GiftCard.ErrorMessage
      });
    }

    if (data.PaymentOptions) {
      self.availablePaymentMethods = data.PaymentOptions;
      self.fire('paymentOptions::update', {
        paymentOptions: data.PaymentOptions
      });
    }

    fireShoppingCartUpdateEvent(data);

    if (data.PriceSummary) {
      self.fire('priceSummary::update', {
        lineItems: data.PriceSummary,
        rakutenSuperPointsEarned: data.RakutenSuperPointsEarned
      });
    }

    self.fire('koboLove::updateDetails', createKoboLoveUpdateSavingsModel(data));

    if (data.BuyUrl) {
      self.buyUrl = data.BuyUrl;
    }

    if (data.PollingUrl) {
      self.pollPurchaseProcess(data.PollingUrl);
    }

    if (data.Total) {
      self.fire('regularCheckout::updateGrandTotal', {
        grandTotal: data.Total
      });
    }

    if (Kobo.Object.typeOf(data.SufficientAlternativePayment) === "boolean") {
      self.fire('regularCheckout::updateSufficientPayment', {
        sufficientPayment: data.SufficientAlternativePayment
      });
    }

    if (navigatePurchaseSection) {
      var showBillingInfoSection = Kobo.Object.parseBool(data.ShowBillingInfoSection);

      if (showBillingInfoSection) {
        self.updatePurchaseSection('paymentMethods');
      } else {
        self.updatePurchaseSection('purchaseConfirmation');
      }
    }

    self.hasKoboLoveMemberships(data.IsPurchasingKoboLoveMembership);
    self.fire('invalidate');
    self.fire('regularCheckout::dataLoaded');
  };

  onCountryChange = function onCountryChange(source, event) {
    if (self.changeCountryUrl) {
      var giftCardData = {};

      Kobo._mediator.fire("giftCards::getCurrentGiftCard", {
        giftCard: giftCardData
      }); // giftCardData is populated at the end of this call


      if (!giftCardData.hasApplied) {
        // don't submit any gift card data if it has not been applied yet
        giftCardData = {};
      }

      Kobo.Ajax({
        method: "POST",
        url: self.changeCountryUrl,
        dataType: "json",
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        data: {
          CountryCode: event.detail.countryCode,
          GiftCardNumber: giftCardData.number,
          // [thoon@kobo.com] is sending gift card data necessary when changing country?
          GiftCardType: giftCardData.cardType,
          GiftCardPin: giftCardData.pin
        },
        success: self.onCountryChanged,
        error: function error() {} //TODO

      });
    }
  };

  fireShoppingCartUpdateEvent = function fireShoppingCartUpdateEvent(data) {
    var updateData = {
      showKoboLoveVipUpsell: data.ShowKoboLoveVipUpsell,
      koboLoveVipUpsellProductId: data.KoboLoveVipUpsellProductId,
      vipMessage: data.VipMessage
    };

    if (data.Deals && !data.CheckoutItems) {
      updateData.deals = data.Deals;
    } else if (!data.Deals && data.CheckoutItems) {
      updateData.cartItems = data.CheckoutItems;
    } else if (data.Deals && data.CheckoutItems) {
      updateData.cartItems = data.CheckoutItems;
      updateData.deals = data.Deals;
    } // An update event is not fired when nothing changes, thus handling the final case of both
    // CheckoutItems and Carts being falsy.


    if (updateData.deals || updateData.cartItems) {
      self.fire('shoppingCartItems::update', updateData);
    }
  };

  self.addCardDetailsToHeader = function (data) {
    if (data.ShortName && data.LastFourDigits) {
      var $paymentTypeHeaderElement = self.$gizmo.find('.payment-type'),
          $subEl = $paymentTypeHeaderElement.find('span'),
          cardType = data.ShortName,
          formattedCardNumber = '.... ' + data.LastFourDigits;

      if (!$subEl.length) {
        $subEl = Kobo.$('<span>' + formattedCardNumber + '</span>').addClass(cardType);
        $paymentTypeHeaderElement.html($subEl);
      } else {
        $subEl.html(formattedCardNumber);
        $subEl.removeClass().addClass(cardType);
      }
    }
  };

  self.onCountryChanged = function (data) {
    self.fire('regularCheckout::gaEventTrigger', {
      gaEvent: data.GAEvent
    });
    self.fire('billingInfo::update', {
      addressFields: data.BillingAddressFields,
      addressTemplateName: data.BillingTemplateName,
      addressTemplate: data.BillingHtml,
      errorMessage: data.BillingInfoErrorMessage,
      isKid: data.IsKid
    });
    self.fire('paymentOptions::update', {
      paymentOptionsTemplate: data.PaymentOptionsHtml,
      paymentOptions: data.PaymentOptions,
      selectedPaymentMethod: data.SelectedPaymentMethod
    });

    if (data.PaymentOptions && data.PaymentOptions.CreditCard) {
      // get card type to set class for image. Add formated last four digits of card to markup.
      self.addCardDetailsToHeader(data.PaymentOptions.CreditCard);
    }

    if (data.GiftCardCleared) {
      self.fire('giftCards::resetCurrentGiftCard');
    }

    if (data.GiftCardTypes) {
      self.fire('giftCards::replaceGiftCardTypes', {
        types: data.GiftCardTypes
      });
    }

    if (data.GiftCard) {
      self.fire('giftCards::setCurrentGiftCard', {
        number: data.GiftCard.Number,
        appliedToOrder: data.GiftCard.AppliedToOrder,
        balance: data.GiftCard.Balance,
        cardTypeId: data.GiftCard.CardType,
        errorMessage: data.GiftCard.ErrorMessage
      });
    }

    self.fire('koboLove::updateDetails', createKoboLoveUpdateSavingsModel(data));
    self.fire('koboLove::updateState', {
      showKoboLove: data.ShowKoboLove,
      showDiscount: data.ShowKoboLoveDiscounts,
      showPointsEarned: data.ShowKoboLovePoints,
      showPointsBalance: data.ShowKoboLoveBalance,
      showPointsEarnedBreakdown: data.KoboLoveSummaryHasBonusPoints
    });
    fireShoppingCartUpdateEvent(data);
    self.fire('giftCards::update', {
      errorMessage: data.GiftCardErrorMessage
    });

    if (data.RakutenSuperPointsApplied) {
      self.fire('regularCheckout::updateAppliedRakutenSuperpoints', {
        RakutenSuperPointsApplied: data.RakutenSuperPointsApplied
      });
    }

    if (data.Total) {
      self.grandTotal(data.Total);
    }

    if (data.PriceSummary) {
      self.fire('priceSummary::update', {
        lineItems: data.PriceSummary
      });
    }

    if (data.TopLevelWarningMessages) {
      self.topLevelWarningMessages(data.TopLevelWarningMessages);
    }

    self.fire('invalidate');
  };

  self.continuePurchase = function () {
    // step 1: do simple JS validation. Currently limited to checking payment options
    self.fire('paymentOptions::validate', {
      purchaseMode: self.purchaseMode
    });
  };

  onContinuePurchase = function onContinuePurchase() {
    self.continuePurchase();
  };

  onOrderDataValidated = function onOrderDataValidated(eventName, data) {
    if (self.continuePurchaseUrl) {
      var orderData = {};

      if (!data.detail.isSuccess) {
        return;
      } // step 2: do server validation


      orderData.Mode = self.purchaseMode;
      self.fire('regularCheckout::marshallOrderData', {
        orderData: orderData
      }); // synchronous call

      showSpinner();
      Kobo.Ajax({
        method: "POST",
        url: self.continuePurchaseUrl,
        dataType: "json",
        contentType: "application/json",
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        data: JSON.stringify(orderData),
        success: self.onContinuePurchaseResponse,
        error: function error() {} // TODO

      });
    }
  };

  self.onContinuePurchaseResponse = function (data) {
    var continueValid = true,
        continueLink = self.$gizmo.find('.continue-link').attr('href'); // step 3: check server response
    // always send back the error messages so knockout can handle showing or hiding them

    self.fire('billingInfo::update', {
      errorMessage: data.BillingAddressErrorMessage
    });
    self.fire('giftCards::update', {
      errorMessage: data.GiftCardErrorMessage
    });
    self.fire('paymentOptions::update', {
      errorMessage: data.PaymentOptionsErrorMessage
    });
    self.fire('regularCheckout::gaEventTrigger', {
      gaEvent: data.GAEvent
    });
    self.fire('vatNumber::updateError', {
      errorMessage: data.VatNumberErrorMessage
    });

    if (data.BillingAddressErrorMessage) {
      Kobo.log(data.BillingAddressErrorMessage);
      continueValid = false;
    }

    if (data.GiftCardErrorMessage) {
      Kobo.log(data.GiftCardErrorMessage);
      continueValid = true;
    }

    if (data.PaymentOptionsErrorMessage) {
      Kobo.log(data.PaymentOptionsErrorMessage);
      continueValid = false;
    }

    if (data.VatNumberErrorMessage) {
      Kobo.log(data.VatNumberErrorMessage);
      continueValid = false;
    }

    if (continueValid === true) {
      self.fire("regularCheckout::continuePurchaseValid");

      if (self.continueValidRedirectUrl) {
        window.location.href = self.continueValidRedirectUrl;
        return;
      }

      if (data.PaymentOptions) {
        self.fire('paymentOptions::update', {
          paymentOptions: data.PaymentOptions
        });

        if (data.PaymentOptions.CreditCard) {
          self.isCreditCardAvailable(data.PaymentOptions.CreditCard.IsAvailable && data.PaymentOptions.CreditCard.HasSavedInfo);
          self.addCardDetailsToHeader(data.PaymentOptions.CreditCard);
        }
      }

      if (data.PriceSummary) {
        self.fire('priceSummary::update', {
          lineItems: data.PriceSummary
        });
      }

      fireShoppingCartUpdateEvent(data);

      if (data.Total) {
        self.grandTotal(data.Total);
      }

      self.updatePurchaseSection(extractHash(continueLink));
      self.fire('regularCheckout::requestFocus', {
        target: self.gizmo.querySelector('#paymentMethods')
      });
      self.fire('rakutenSuperPoints::toggleOpen');
      updateSufficientSuperPoints();
    }

    Kobo._modal.close();
  };

  onShoppingCartItemsUpdated = function onShoppingCartItemsUpdated(s, data) {
    if (data.detail.items.length === 0) {
      self.disabled(true);
    } else {
      self.disabled(false);
    }
  };

  onStoreCreditBalanceUpdated = function onStoreCreditBalanceUpdated(s, data) {
    self.storeCreditBalance(data.detail.storeCreditBalance);
  };

  onGrandTotalUpdated = function onGrandTotalUpdated(s, data) {
    if (data.detail.grandTotal) {
      self.grandTotal(data.detail.grandTotal);
    }

    updateSufficientSuperPoints();
  };

  onSufficientPaymentUpdated = function onSufficientPaymentUpdated(s, data) {
    if (Kobo.Object.typeOf(data.detail.sufficientPayment) === "boolean") {
      self.sufficientPayment(data.detail.sufficientPayment);
      updateSufficientSuperPoints();
    }

    self.fire('regularCheckout::updateRakutenSuperpointsNeededState', {
      sufficientPayment: data.detail.sufficientPayment
    });
  };

  onPromoCodeApply = function onPromoCodeApply(eventName, data) {
    Kobo.Ajax({
      url: self.applyPromoCodeUrl,
      method: 'POST',
      dataType: "json",
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: {
        promoCode: data.detail.promoCodeNumber
      },
      success: function success(data) {
        if (data.IsSuccess) {
          onCheckoutDetailsRetrieved(data);
        }

        self.fire('promoCode::update', {
          ResponseMessage: data.ResponseMessage,
          IsSuccess: data.IsSuccess,
          GAEvent: data.GAEvent
        });
        self.fire('regularCheckout::updateAppliedRakutenSuperpoints', {
          RakutenSuperPointsApplied: data.RakutenSuperPointsApplied
        });

        if (Kobo.Object.typeOf(data.SufficientAlternativePayment) === "boolean") {
          self.fire('regularCheckout::updateSufficientPayment', {
            sufficientPayment: data.SufficientAlternativePayment
          });
        }
      },
      error: function error() {} // TODO

    });
  };

  onThreeDsSuccess = function onThreeDsSuccess() {
    notifyThreeDsState('/Purchase/RedirectSuccess'); //TODO don't hard-code
  };

  onThreeDsError = function onThreeDsError() {
    notifyThreeDsState('/Purchase/RedirectFailure'); //TODO don't hard-code
  };

  onThreeDsCancel = function onThreeDsCancel() {
    notifyThreeDsState('/Purchase/RedirectCancel'); //TODO don't hard-code
  };

  notifyThreeDsState = function notifyThreeDsState(baseUrl) {
    var url;

    if (!self.purchaseToken || !self.purchaseId) {
      Kobo.log('purchaseToken and purchaseId need to be defined to notify the server of the state of 3DS credit card verification');
    }

    url = baseUrl + '?' + Kobo.$.param({
      purchaseToken: self.purchaseToken,
      purchaseId: self.purchaseId
    });
    Kobo.Utilities.navigateTo(url);
  };

  self.startBuyNowProcess = function (location) {
    self.inBuyProcess(true);
    showSpinner();
    var deviceData;
    self.fire('paymentOptions::collectDeviceData', {
      saveDeviceData: function saveDeviceData(collectedDeviceData) {
        deviceData = collectedDeviceData;
      }
    });
    Kobo.Ajax({
      method: "POST",
      url: self.buyUrl,
      dataType: "json",
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: {
        location: location,
        deviceData: deviceData
      },
      success: function success(data) {
        if (data.IsSuccess) {
          setTimeout(function () {
            self.pollPurchaseProcess(data.PollingUrl);
          }, self.pollingInterval);
        } else {
          self.fire('regularCheckout::gaEventTrigger', {
            gaEvent: data.GAEvent
          });

          Kobo._modal.close();

          var isPaymentOptionSuperpoints = self.availablePaymentMethods && self.availablePaymentMethods.SuperPoints && self.availablePaymentMethods.SuperPoints.IsAvailable;

          if (data.CheckSufficientPaymentsApplied && isPaymentOptionSuperpoints) {
            updateSufficientSuperPoints(true);
          } else if (data.IsKid) {
            showError(data.ErrorMessage);
          } else {
            var errorMessage = data.ErrorMessage || "";
            showError(errorMessage);
          }
        }
      },
      error: function error() {
        Kobo._modal.close();

        showError();
      }
    });
  };

  self.pollPurchaseProcess = function (url) {
    var threeDsURL;
    self.inBuyProcess(true);
    showSpinner();
    Kobo.Ajax({
      method: "GET",
      cache: false,
      url: url,
      dataType: "json",
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      success: function success(data) {
        if (data.Status === "Complete" || data.Status === "Redirect") {
          window.location.href = data.RedirectUrl;
        } else if (data.Status === "Failed") {
          Kobo.log('Payment failed: ' + (data.Errors && data.Errors.length ? data.Errors : 'empty error response'));
          self.fire('regularCheckout::gaEventTrigger', {
            gaEvent: data.GAEvent
          });
          var errorMessages = [];

          if (data.Errors && data.Errors.length) {
            Kobo.$.each(data.Errors, function () {
              errorMessages.push('<li>' + this.ErrorMessage + '</li>');
            });
          }

          if (errorMessages.length) {
            showUncancelableError('<ul>' + errorMessages.join('') + '</ul>', 5000);
          } else {
            showError();
          }
        } else if (data.Status === "Pending") {
          self.pollingCount++;

          if (self.pollingCount * self.pollingInterval < self.pollingTimeout * 1000) {
            setTimeout(function () {
              self.pollPurchaseProcess(url);
            }, self.pollingInterval);
          } else {
            self.pollingCount = 0;
            Kobo.log('Polling timeout error, taking longer than ' + self.pollingTimeout + ' seconds to finish processing the payment');
            Kobo.Ajax({
              method: "POST",
              url: self.logPurchaseTimeoutUrl,
              headers: {
                '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
              },
              data: {
                purchaseToken: self.purchaseToken,
                timeoutLength: self.pollingTimeout
              }
            });
            showUncancelableError(timeoutErrorMessage);
          }
        } else if (data.Status === 'Use3dSec') {
          threeDsURL = data.RedirectUrl;
          self.purchaseId = data.PurchaseId;
          self.pollingUrl = data.PollingUrl;
          showThreeDsPopup(threeDsURL);
        } else if (data.Status === "Cancelled") {
          Kobo._modal.close();
        }
      },
      error: function error() {
        showError();
      }
    });
  };

  showThreeDsPopup = function showThreeDsPopup(threeDSecURL) {
    var iframe = Kobo._purchaseBroker.buildThreeDSecIFrame(threeDSecURL),
        $popup = Kobo.$('<div></div>'),
        $closeButton = Kobo.$('<button class="close-button"></button>');

    $popup.append($closeButton).append(iframe);

    Kobo._modal.open($popup.get(0), {
      customClass: 'three-ds'
    });

    $closeButton.on('click', onThreeDsCancel);
  };

  self.updatePurchaseSection = function (activeSection) {
    if (activeSection === 'paymentMethods') {
      self.fire('collapsible::close', {
        name: 'purchaseConfirmation'
      });
      self.fire('collapsible::open', {
        name: activeSection
      });
    } else {
      self.fire('collapsible::close', {
        name: 'paymentMethods'
      });
      self.fire('collapsible::open', {
        name: activeSection
      });
    }
  };

  onRequestFocus = function onRequestFocus(eventName, data) {
    var targetElement = data.detail.target,
        SCROLL_DURATION = 200,
        SCROLL_PADDING = 56;

    if (!targetElement) {
      return;
    } // The new scroll position is the top of targetElement, minus some padding so that it is not
    // too close to the top edge of the viewport


    autoScroller.scrollTo(Kobo.$(targetElement).offset().top - SCROLL_PADDING, SCROLL_DURATION);
  };

  onGAEventTrigger = function onGAEventTrigger(eventName, data) {
    self.$gizmo.append(data.detail.gaEvent);
  };

  onUpdateButton = function onUpdateButton(eventName, data) {
    if (data.source.selectedPaymentMethod() === 'vme') {
      self.vmeSelected(true);
    } else {
      self.vmeSelected(false);
    }
  }; // Manages scrolling to different parts of the checkout page in small view,
  // in order to focus error messages.
  // Behaviour when scrollTo is called again before a previous scroll completes:
  //   - If the new scroll position is higher than the previous one,
  //     then the scrolling will continue to the higher position
  //   - If the new scroll position is lower or equal to the previous one,
  //     then the call has no effect. Scrolling will continue to the old position


  autoScroller = function () {
    var isAnimating = false,
        targetY = 0;
    return {
      scrollTo: function scrollTo(newTargetY, duration) {
        if (self.currentBreakpoint() !== 'small') {
          return;
        }

        if (isAnimating && newTargetY > targetY) {
          return;
        } // start a new scroll animation


        targetY = Math.max(newTargetY, 0);
        isAnimating = true;
        Kobo.$body.animate({
          scrollTop: targetY
        }, {
          duration: duration,
          complete: function complete() {
            isAnimating = false;
          }
        });
      }
    };
  }();

  updateSufficientSuperPoints = function updateSufficientSuperPoints(ignorehaveSuperPointsBeenApplied) {
    var isPaymentOptionSuperpoints = self.availablePaymentMethods && self.availablePaymentMethods.SuperPoints && self.availablePaymentMethods.SuperPoints.IsAvailable;

    if (ignorehaveSuperPointsBeenApplied !== undefined && ignorehaveSuperPointsBeenApplied === true) {
      if (isPaymentOptionSuperpoints && !self.isCreditCardAvailable() && !self.sufficientPayment()) {
        self.sufficientSuperPoints(false);
      } else {
        self.sufficientSuperPoints(true);
      }
    } else {
      // only show the error message if user selected SP as payment method but doesn't have credit card info and the
      // redeemed SP doesn't cover the total amount.
      if (isPaymentOptionSuperpoints && self.haveSuperPointsBeenApplied && !self.isCreditCardAvailable() && !self.sufficientPayment()) {
        self.sufficientSuperPoints(false);
      } else {
        self.sufficientSuperPoints(true);
      }
    }
  };

  onRakutenSuperPointsApply = function onRakutenSuperPointsApply(s, data) {
    if (data && data.detail.appliedSuperPoints !== undefined) {
      self.haveSuperPointsBeenApplied = data.detail.appliedSuperPoints > 0;
      updateSufficientSuperPoints();
    }
  };

  registerEvents = function registerEvents() {
    self.register('regularCheckout::dataLoaded');
    self.register('regularCheckout::marshallOrderData');
    self.register('regularCheckout::paymentSectionCompleted');
    self.register('regularCheckout::updateGrandTotal');
    self.register('regularCheckout::updateStoreCreditBalance');
    self.register('regularCheckout::updateSufficientPayment');
    self.register('regularCheckout::updateButton');
    self.register('promoCode::apply');
    self.register("regularCheckout::continuePurchaseValid");
    self.register("regularCheckout::requestFocus");
    self.register('regularCheckout::gaEventTrigger');
    self.register('regularCheckout::loadRakutenSuperpoints');
    self.register("regularCheckout::showSpinner");
    self.register("regularCheckout::hideSpinner");
    self.register("regularCheckout::continuePurchase");
    self.register("regularCheckout::getCheckoutDetails");
    self.register('rakutenSuperPoints::apply');
    self.register('regularCheckout::updateRakutenSuperpointsNeededState');
    self.register("regularCheckout::updateAppliedRakutenSuperpoints");
    self.register("regularCheckout::clearAppliedRakutenSuperpoints");
  };

  setupResourceStrings = function setupResourceStrings() {
    var dynamicConfiguration = window.DynamicConfiguration;

    if (!dynamicConfiguration) {
      return;
    }

    genericErrorMessage = dynamicConfiguration.resourceStrings.genericPurchasingError;
    timeoutErrorMessage = dynamicConfiguration.resourceStrings.purchasingTimeoutMessage;
    initialErrorMessage = dynamicConfiguration.resourceStrings.initialPurchasingErrorMessage;
    locationChangeMessage = dynamicConfiguration.resourceStrings.locationChangeMessage;
  };

  addPaddingForSoftKeyboard = function addPaddingForSoftKeyboard() {
    // Part of fix for DE21134
    // This can be removed when the final fix lands in the android app
    if ((self.currentBreakpoint() === 'small' || self.currentBreakpoint() === 'medium') && self.loadRakutenSuperpoints) {
      Kobo.$(".kobo-main").addClass("add-padding-for-soft-keyboard");
    }
  };

  setupHandlers = function setupHandlers() {
    var $giftCardLinks = self.$gizmo.find('.giftcard-link');
    self.subscribe('billingInfo::countryChanged', onCountryChange);
    self.subscribe('promoCode::apply', onPromoCodeApply);
    self.subscribe('paymentOptions::validated', onOrderDataValidated);
    self.subscribe('regularCheckout::gaEventTrigger', onGAEventTrigger);
    self.subscribe('regularCheckout::updateButton', onUpdateButton);
    self.subscribe('shoppingCartItems::updated', onShoppingCartItemsUpdated);
    self.subscribe('regularCheckout::updateGrandTotal', onGrandTotalUpdated);
    self.subscribe('regularCheckout::updateStoreCreditBalance', onStoreCreditBalanceUpdated);
    self.subscribe('regularCheckout::updateSufficientPayment', onSufficientPaymentUpdated);
    self.subscribe('regularCheckout::requestFocus', onRequestFocus);
    self.subscribe('regularCheckout::continuePurchase', onContinuePurchase);
    self.subscribe('creditCard::threeDsSuccess', onThreeDsSuccess);
    self.subscribe('creditCard::threeDsError', onThreeDsError);
    self.subscribe('rakutenSuperPoints::apply', onRakutenSuperPointsApply);
    self.subscribe('regularCheckout::showSpinner', showSpinner);
    self.subscribe('regularCheckout::hideSpinner', hideSpinner);
    self.subscribe('regularCheckout::getCheckoutDetails', getCheckoutDetails);
    self.$gizmo.on('click', '.section-link', function (event) {
      event.preventDefault();
      self.updatePurchaseSection(extractHash(this.href));
    });
    $giftCardLinks.click(function () {
      self.fire("giftCards::toggleOpen");
    });
  };

  self.destroy = function () {
    var $giftCardLinks = self.$gizmo.find('.giftcard-link');
    $giftCardLinks.off('click');
    self.$gizmo.off('click', '.section-link');
    self.unSubscribe('creditCard::threeDsError', onThreeDsError);
    self.unSubscribe('creditCard::threeDsSuccess', onThreeDsSuccess);
    self.unSubscribe('regularCheckout::requestFocus', onRequestFocus);
    self.unSubscribe('regularCheckout::updateSufficientPayment', onSufficientPaymentUpdated);
    self.unSubscribe('regularCheckout::updateStoreCreditBalance', onStoreCreditBalanceUpdated);
    self.unSubscribe('regularCheckout::updateGrandTotal', onGrandTotalUpdated);
    self.unSubscribe('regularCheckout::showSpinner', showSpinner);
    self.unSubscribe('regularCheckout::hideSpinner', hideSpinner);
    self.unSubscribe('regularCheckout::continuePurchase', onContinuePurchase);
    self.unSubscribe('shoppingCartItems::updated', onShoppingCartItemsUpdated);
    self.unSubscribe('paymentOptions::validated', onOrderDataValidated);
    self.unSubscribe('promoCode::apply', onPromoCodeApply);
    self.unSubscribe('billingInfo::countryChanged', onCountryChange);
    self.unSubscribe('regularCheckout::getCheckoutDetails', getCheckoutDetails);
    self.unSubscribe('rakutenSuperPoints::apply', onRakutenSuperPointsApply);
  };

  init = function init() {
    var topLevelWarningMessagesElement = el.querySelector('.error.high-level'),
        grandTotalElement = el.querySelector('.grand-total'),
        checkoutElement = el.querySelector('.checkout'),
        editPaymentInfoElement = el.querySelector('.edit-payment-info'),
        giftCardLinkElement = el.querySelector('.promo-code-giftcard-link'),
        promoCodeContainerElement = el.querySelector('#promoCodeContainer'),
        priceContainerElement = el.querySelector('#priceContainer'),
        superpointsErrorElement = el.querySelector('.insufficient-superpoints-error'),
        rakutenSuperPointsPanel = el.querySelector('#rakutenSuperPointsPanel');
    self.currentStatusUrl = options.currentStatusUrl;
    self.applyGiftCardUrl = options.applyGiftCardUrl;
    self.changeCountryUrl = options.changeCountryUrl;
    self.continuePurchaseUrl = options.continuePurchaseUrl;
    self.applyPromoCodeUrl = options.applyPromoCodeUrl;
    self.applyRgiSuperPointsUrl = options.applyRgiSuperPointsUrl;
    self.logPurchaseTimeoutUrl = options.logPurchaseTimeoutUrl;
    self.continueValidRedirectUrl = options.continueValidRedirectUrl;
    self.loadRakutenSuperpoints = ko.observable(false);
    self.buyUrl = '';
    self.pollingUrl = '';
    self.pollingInterval = 1000;
    self.pollingCount = 0;
    self.pollingTimeout = 90; //90 seconds

    self.purchaseToken = options.purchaseToken;
    self.emptyCartRedirectUrl = options.emptyCartRedirectUrl;
    self.inBuyProcess = ko.observable(false);
    self.disabled = ko.observable(false);
    self.grandTotal = ko.observable();
    self.giftCardFormVisible = ko.observable(false);
    self.storeCreditApplied = ko.observable();
    self.storeCreditBalance = ko.observable();
    self.sufficientPayment = ko.observable(false);
    self.hasKoboLoveMemberships = ko.observable(false);
    self.vmeSelected = ko.observable(false);
    self.sufficientSuperPoints = ko.observable(true);
    self.availablePaymentMethods = [];
    self.isCreditCardAvailable = ko.observable(false);
    self.haveSuperPointsBeenApplied = false;
    self.purchaseMode = options.PurchaseMode;
    self.isKwlPromotion = ko.observable(options.IsKwlPromotion !== 'undefined' && String(options.IsKwlPromotion).toLowerCase() === 'true');
    self.topLevelWarningMessages = ko.observableArray(); // top-level error message. For example, if overall purchase failed

    setupResourceStrings();
    registerEvents();
    setupHandlers();
    addPaddingForSoftKeyboard();
    self.fire("regularCheckout::getCheckoutDetails", {
      navigatePurchaseSection: true
    });

    if (topLevelWarningMessagesElement) {
      ko.applyBindings(self, topLevelWarningMessagesElement);
    }

    if (grandTotalElement) {
      ko.applyBindings(self, grandTotalElement);
    }

    if (checkoutElement) {
      ko.applyBindings(self, checkoutElement);
    }

    if (editPaymentInfoElement) {
      ko.applyBindings(self, editPaymentInfoElement);
    }

    if (promoCodeContainerElement) {
      ko.applyBindings(self, promoCodeContainerElement);
    }

    if (priceContainerElement) {
      ko.applyBindings(self, priceContainerElement);
    }

    if (superpointsErrorElement) {
      ko.applyBindings(self, superpointsErrorElement);
    }

    if (rakutenSuperPointsPanel) {
      ko.applyBindings(self, rakutenSuperPointsPanel);
    }

    Kobo.$('.continue-area').each(function () {
      ko.applyBindings(self, this);
    });
  };

  init();
};

Kobo.Gizmo.RegularCheckout.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);