"use strict";
/*global Kobo,$,console */

Kobo.Gizmo.PurchaseHistoryControl = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("PurchaseHistoryControl");
  this.defaults = {
    "filterChip": null,
    "filterButton": null,
    "filterList": null,
    "filterItem": null,
    "purchaseListId": null,
    "sortListId": null,
    "pageSizeListId": null
  };
  var self = this;

  function toggleFilterChip($btn) {
    var closeList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var $chip = $btn.closest('.' + self.settings.filterChip);
    var $list = $($chip.find('.' + self.settings.filterList));

    if ($list.hasClass('open') || closeList) {
      $btn.removeClass('selected');
      $list.removeClass('open');
      $btn.attr("aria-expanded", "false");
      $list.attr("aria-hidden", "true");
    } else {
      $btn.addClass('selected');
      $list.addClass('open');
      $btn.attr("aria-expanded", "true");
      $list.attr("aria-hidden", "false");
    }
  }

  function setUpHandlers() {
    var $btn = $('.' + self.settings.filterButton);
    var $list = $('.' + self.settings.filterList);
    var $filterItem = $('.' + self.settings.filterItem);
    $btn.on('click', function () {
      toggleFilterChip($(this));
    });
    $btn.on('focusout', function (event) {
      if (event.relatedTarget === null || !$(event.relatedTarget).hasClass(self.settings.filterItem)) {
        toggleFilterChip($(this), true);
      }
    });
    $btn.on('keydown', function (event) {
      var $btn = $(this);

      if (event.keyCode === Kobo.KeyCodes.ESC) {
        toggleFilterChip($btn, true);
      }
    });
    $list.on('focusout keydown', function (event) {
      var $btn = $(this).prevAll('.' + self.settings.filterButton).first();
      $btn.trigger(event);
    });
    $filterItem.on('focus mouseenter', function () {
      var focusedAnchorElement = this;
      var parentListItemElement = focusedAnchorElement.parentElement;
      var parentListElement = parentListItemElement.parentElement;

      if (focusedAnchorElement.tagName.toLowerCase() === 'a' && parentListItemElement.tagName.toLowerCase() === 'li' && parentListElement.tagName.toLowerCase() === 'ul') {
        $(parentListElement).attr("aria-activedescendant", parentListItemElement.id);
      }
    });
    $filterItem.on('blur mouseleave', function () {
      var focusedAnchorElement = this;
      var parentListItemElement = focusedAnchorElement.parentElement;
      var parentListElement = parentListItemElement.parentElement;

      if (focusedAnchorElement.tagName.toLowerCase() === 'a' && parentListItemElement.tagName.toLowerCase() === 'li' && parentListElement.tagName.toLowerCase() === 'ul') {
        $(parentListElement).attr("aria-activedescendant", "");
      }
    });
  } // Generate a CSS selector for the child element based on its parent


  var generateChildSelector = function generateChildSelector(parent, child) {
    var parentSelector = '#' + parent.id; // Find the index of the child element among its siblings with the same inner text

    var children = Array.from(parent.children).filter(function (sibling) {
      return sibling.innerText === child.innerText;
    }); // Not found

    if (children.length !== 1) return null;
    var childSelector = children[0].tagName.toLowerCase();
    var index = Array.from(parent.children).indexOf(children[0]) + 1; // Append the index to the child selector

    childSelector += ':nth-child(' + index + ')';
    return parentSelector + ' > ' + childSelector;
  };

  var getLocalStorageFocusedElementKey = function getLocalStorageFocusedElementKey() {
    return 'purchaseHist_focus_selector';
  };

  var getLocalStorageKeyboardFlagKey = function getLocalStorageKeyboardFlagKey() {
    return 'purchaseHist_key_reload';
  }; // Function to get the closest <ul> parent for an item inside a <ul>


  var getClosestListParent = function getClosestListParent(item) {
    var parent = item.parentElement;

    while (parent) {
      if (parent.tagName.toLowerCase() === 'ul') {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null; // Return null if no <ul> parent is found
  };

  var isFilterList = function isFilterList(element) {
    return element.id === self.settings.purchaseListId || element.id === self.settings.sortListId || element.id === self.settings.pageSizeListId;
  };

  var setupFocusedElementInLocalStorage = function setupFocusedElementInLocalStorage() {
    window.addEventListener('keydown', function () {
      var keyFlagKey = getLocalStorageKeyboardFlagKey();
      localStorage.setItem(keyFlagKey, 'true');
    }, true);
    window.addEventListener('beforeunload', function () {
      var keyFlagKey = getLocalStorageKeyboardFlagKey();
      var keyboardTriggeredReload = localStorage.getItem(keyFlagKey);

      if (keyboardTriggeredReload) {
        var focusedElement = document.activeElement;
        var closestListParent = getClosestListParent(focusedElement);

        if (closestListParent && isFilterList(closestListParent)) {
          var selector = generateChildSelector(closestListParent, focusedElement);

          if (selector) {
            var key = getLocalStorageFocusedElementKey();
            localStorage.setItem(key, selector);
          }
        }
      }
    });
  };

  var setupFocusedElementFromLocalStorage = function setupFocusedElementFromLocalStorage() {
    window.addEventListener('load', function () {
      var key = getLocalStorageFocusedElementKey();
      var lastFocusedElementSelector = localStorage.getItem(key);
      var keyFlagKey = getLocalStorageKeyboardFlagKey();
      var keyboardTriggeredReload = localStorage.getItem(keyFlagKey);

      if (lastFocusedElementSelector && keyboardTriggeredReload) {
        var focusElement = document.querySelector(lastFocusedElementSelector);

        if (focusElement) {
          var closestListParent = getClosestListParent(focusElement);

          if (closestListParent && isFilterList(closestListParent)) {
            // Find the button element within the parent and open it
            var buttonElement = closestListParent.previousElementSibling;

            if (buttonElement && buttonElement.matches('button.filter-btn')) {
              toggleFilterChip($(buttonElement)); // Focus the element that triggered the reload

              focusElement.focus();
              focusElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
          }
        }

        localStorage.removeItem(key);
        localStorage.removeItem(keyFlagKey);
      }
    });
  };

  var setupFocusedElement = function setupFocusedElement() {
    setupFocusedElementFromLocalStorage();
    setupFocusedElementInLocalStorage();
  };

  function init() {
    setUpHandlers();
    setupFocusedElement();
  }

  init();
};

Kobo.Gizmo.PurchaseHistoryControl.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);