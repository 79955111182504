"use strict";

/*global Kobo, Cookies, ko*/
Kobo.Gizmo.RakutenTracking = function (element, options) {
  "use strict";

  var cartItems = [];

  function isMallCookieSet() {
    if (document.cookie.split(';').filter(function (item) {
      return item.trim().indexOf('_mall_uuid=') === 0;
    }).length) {
      return true;
    } else {
      return false;
    }
  }

  function checkoutStep() {
    var regularCheckoutElement = $(".regular-checkout");
    var regularCheckoutViewModel = ko.dataFor(regularCheckoutElement[0]);

    if (regularCheckoutViewModel) {
      cartItems = regularCheckoutViewModel.shoppingCartVM().cartItems();
      return regularCheckoutViewModel.stageSelectionVM().currentStage();
    }

    return '';
  }

  function setupCheckoutReview() {
    if (checkoutStep() === 'review' && $('#ratCheckout').prop("defaultValue") !== '30') {
      var checkoutHelper = new Kobo.RAT.CheckoutHelper();
      Kobo.$('#ratCheckout')[0].value = '30';
      Kobo.$('#ratItemId')[0].value = checkoutHelper.getItemIds(cartItems);
      Kobo.$('#ratItemPrice')[0].value = checkoutHelper.getItemPrices(cartItems);
      Kobo.$('#ratItemCount')[0].value = checkoutHelper.getItemCount(cartItems);
      $(element).append("<input type=\"hidden\" name=\"rat\" id=\"ratTotalPrice\" value=\"" + checkoutHelper.getTotalPrice() + "\">");
      Kobo.$('#ratPageName')[0].value = 'review';
      return;
    }
  }

  function setupCheckoutPayment() {
    if (checkoutStep() === 'payment' && $('#ratCheckout').prop("defaultValue") !== '40') {
      var checkoutHelper = new Kobo.RAT.CheckoutHelper();
      Kobo.$('#ratCheckout')[0].value = '40';
      Kobo.$('#ratPageName')[0].value = 'payment';
      Kobo.$('#ratItemId')[0].value = checkoutHelper.getItemIds(cartItems);
      Kobo.$('#ratItemPrice')[0].value = checkoutHelper.getItemPrices(cartItems);
      Kobo.$('#ratItemCount')[0].value = checkoutHelper.getItemCount(cartItems);

      if (Kobo.$('#ratTotalPrice').length > 0) {
        Kobo.$('#ratTotalPrice')[0].remove();
      }

      return;
    }
  }

  function setSearchPageScript() {
    var itemsToAppend = "<script>" + "    (function () {" + "      document.querySelectorAll('.item-wrapper').forEach(function (el) {" + "      var _productId = JSON.parse(el.dataset.trackInfo).productId; " + "        el.setAttribute(\"data-ratunit\", \"item\"); " + "        el.setAttribute(\"data-ratid\", _productId); " + "    }); " + "}) (); " + "</script>";
    $(element).append(itemsToAppend);
  }

  function isSearchPage() {
    var regex = /(\/search\?.*query=)/gi;
    var found = document.location.href.match(regex);
    if (found) return true;
    return false;
  }

  function isThemaSearchPage() {
    var regex = /(\/search\?.*thema=)/gi;
    var found = document.location.href.match(regex);
    if (found) return true;
    return false;
  }

  function isSubcategoryPage() {
    var regex = /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/ebooks\/|\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/audiobooks\/)/g;
    var found = document.location.href.match(regex);

    if (Kobo.$body) {
      if (found && Kobo.$body.hasClass("Store-Browse-SubCategory")) return true;
    }

    return false;
  }

  function isAuthorPage() {
    var regex = /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/author\/|^\/author\/)/g;
    var found = document.location.pathname.match(regex);
    if (found) return true;
    return false;
  }

  function isSeriesPage() {
    var regex = /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/series\/|^\/series\/)/g;
    var found = document.location.pathname.match(regex);
    if (found) return true;
    return false;
  }

  function isNarratorPage() {
    var regex = /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/narrator\/|^\/narrator\/)/g;
    var found = document.location.pathname.match(regex);
    if (found) return true;
    return false;
  }

  function isOtherPage() {
    var pagePath = window.location.pathname;

    if (Kobo.$body) {
      if ($('body[class*="Store-Browse-CategoryStore"]').length > 0) {
        switch (true) {
          case /\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/ebooks\//.test(pagePath):
            return true;

          case /\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/audiobooks\//.test(pagePath):
            return true;

          default:
            return false;
        }
      }
    }

    if (/\/library/.test(pagePath)) {
      return true;
    }

    if (/(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/audiobooks)$/.test(pagePath) || /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/audiobooks\/)$/.test(pagePath)) {
      return true;
    }

    if (/(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/ebooks)$/.test(pagePath) || /(\/[a-zA-Z]{2}\/[a-zA-Z]{2}\/ebooks\/)$/.test(pagePath)) {
      return true;
    }

    return false;
  }

  function isAddToLibraryUnderSubscription(url, status, responseJSON) {
    return url.indexOf('/itemdetailprimarymetadatawidget/addtolibraryundersubscription') !== -1 && status === 200 && responseJSON.result === 'success';
  }

  function isAddToLibraryFreeBook(url, status, responseJSON) {
    var pricingTitle = Kobo.$('.pricing-title');
    var bookState = pricingTitle.attr('data-book-state');
    return url.indexOf('/ebook/AddToLibrary/') !== -1 && bookState === 'Free' && status === 200 && responseJSON.result === 'success';
  }

  function isRedeemCredit(url, status, responseJSON) {
    var successResponseCode = 5;
    return url.indexOf('/checkout/redeemcredit') !== -1 && status === 200 && responseJSON.ResponseCode === successResponseCode;
  }

  function isAddToWishlist(url, status, responseJSON) {
    return url.indexOf('/product/addtowishlist') !== -1 && status === 200 && responseJSON.result === 'success';
  }

  function getItemDetailPageName(url, status, responseJSON) {
    if (isAddToLibraryUnderSubscription(url, status, responseJSON)) {
      return 'library/koboplus';
    }

    if (isRedeemCredit(url, status, responseJSON)) {
      return 'library/audiobook';
    }

    if (isAddToLibraryFreeBook(url, status, responseJSON)) {
      return 'library/free';
    }

    if (isAddToWishlist(url, status, responseJSON)) {
      return 'library/wishlist';
    }

    return null;
  }

  function createItemDetailScriptOptions(url, status, responseJSON, options) {
    var itemId = Kobo.$('#ratItemId')[0].value;
    var pageName = getItemDetailPageName(url, status, responseJSON);
    return {
      itemId: itemId,
      storeFront: options.storeFront,
      currency: options.currency,
      language: options.language,
      pageName: pageName
    };
  }

  function setRatScript() {
    var ratScriptAdded = $(element).has('script[src$="//r.r10s.jp/com/rat/js/rat-main.js"]').length > 0;

    if (!ratScriptAdded) {
      $(element).append("<script type=\"text/javascript\" src=\"//r.r10s.jp/com/rat/js/rat-main.js\"></script>");
    }
  }

  function init() {
    if (!options.featureEnabled || !options.CheckStoreFront) return;
    var ratTagHelper = new Kobo.RAT.RatTagHelper();
    $(document).ajaxComplete(function (e, xhr, settings) {
      if (settings.url.indexOf('shoppingcartwidget/remove') !== -1 && xhr.status === 200) {
        Kobo.$(element).prepend(ratTagHelper.itemScript('remove', options));
      }

      if (settings.url.indexOf('shoppingcartwidget/add') !== -1 && xhr.status === 200) {
        if (!xhr.responseJSON.IsSuccess) {
          Kobo.$(element).prepend(ratTagHelper.itemScript('already', options));
        } else {
          Kobo.$(element).prepend(ratTagHelper.itemScript('add', options));
        }
      }

      if (isAddToLibraryUnderSubscription(settings.url, xhr.status, xhr.responseJSON) || isAddToLibraryFreeBook(settings.url, xhr.status, xhr.responseJSON) || isRedeemCredit(settings.url, xhr.status, xhr.responseJSON) || isAddToWishlist(settings.url, xhr.status, xhr.responseJSON)) {
        var itemDetailOptions = createItemDetailScriptOptions(settings.url, xhr.status, xhr.responseJSON, options);
        Kobo.$(element).prepend(ratTagHelper.itemDetailScript(itemDetailOptions));
      }

      setupCheckoutPayment();
      setupCheckoutReview();
      return;
    });
    $(document).ready(function () {
      setupCheckoutPayment();
      setupCheckoutReview();

      if (isSearchPage() || isThemaSearchPage() || isAuthorPage() || isSeriesPage() || isNarratorPage()) {
        setSearchPageScript();
      }

      setRatScript();
      return;
    });

    if (!isMallCookieSet()) {
      var hashUID = Math.random().toString(36).substr(2, 20) + '-' + Math.random().toString(36).substr(2, 20) + '-' + Math.random().toString(36).substr(2, 20);
      var d = new Date();
      d.setTime(d.getTime() + 1068 * 24 * 60 * 60 * 1000);
      var expiryDate = d.toUTCString();
      document.cookie = '_mall_uuid=' + hashUID + ';expires=' + expiryDate + ';path=/';
    }

    if (Kobo.$('#ratPageLayout').length > 0) {
      var browserDetailsHelper = new Kobo.RAT.BrowserDetailsHelper();
      Kobo.$('#ratPageLayout')[0].value = browserDetailsHelper.getPageLayout();
    }

    var pageHelper = new Kobo.RAT.PageHelper();
    var isOnSearchPage = isSearchPage();
    var isOnThemaPage = isThemaSearchPage();
    var isOnAuthorPage = isAuthorPage();
    var isOnSeriesPage = isSeriesPage();
    var isOnSubCategoryPage = isSubcategoryPage();
    var isOnNarratorPage = isNarratorPage();

    if (isOnSearchPage || isOnSubCategoryPage || isOnThemaPage || isOnAuthorPage || isOnSeriesPage || isOnNarratorPage) {
      Kobo.$('#ratItemId')[0].value = pageHelper.getSearchProductIdList();
      Kobo.$('#ratPrice')[0].value = pageHelper.getSearchPriceList();
      var totalResults;
      var pageCustomParameters = ["{"];

      if (isOnSearchPage) {
        totalResults = pageHelper.getTotalSearchResults();
        Kobo.$('#ratPageName')[0].value = "search";
      }

      if (isOnThemaPage) {
        totalResults = pageHelper.getTotalSearchResults();
        Kobo.$('#ratPageName')[0].value = "thema";
      }

      if (isOnAuthorPage) {
        totalResults = pageHelper.getTotalSearchResults();
        Kobo.$('#ratPageName')[0].value = "author";
      }

      if (isOnSeriesPage) {
        totalResults = pageHelper.getTotalSearchResults();
        Kobo.$('#ratPageName')[0].value = "series";
      }

      if (isOnSubCategoryPage) {
        totalResults = pageHelper.getSubCategoryTotalResults();
        Kobo.$('#ratPageName')[0].value = pageHelper.getOtherPageName();
      }

      if (isOnNarratorPage) {
        totalResults = pageHelper.getTotalSearchResults();
        Kobo.$('#ratPageName')[0].value = "narrator";
      }

      pageCustomParameters.push("\"totalresults\":" + totalResults);
      pageCustomParameters.push(",\"hits\":12,\"sort\":" + pageHelper.getSearchSortBy());
      pageCustomParameters.push(",\"rpgn\":" + pageHelper.getSearchPageNumber());
      var requestId = pageHelper.getRequestId();
      if (requestId !== null) pageCustomParameters.push(",\"queryId\":\"" + requestId + "\"");
      var filteredLang = pageHelper.getPageLanguageFilter();
      if (filteredLang !== null && filteredLang !== undefined) pageCustomParameters.push(",\"filteredLang\":\"" + filteredLang + "\"");
      pageCustomParameters.push("}");
      Kobo.$('#ratCustomParameters')[0].value = pageCustomParameters.join("");
      return;
    }

    if (isOtherPage()) {
      Kobo.$('#ratPageName')[0].value = pageHelper.getOtherPageName();
    }
  }

  init();
};

Kobo.Gizmo.RakutenTracking.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);