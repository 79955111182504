"use strict";

Kobo.ViewModel.FitListToContainer = function (element, options, gizmo) {
  "use strict";

  var self = this,
      init;
  Kobo.ViewModel.Base.apply(self, [element, options]);
  this.options = options;
  this.$gizmo = gizmo.$el;
  this.$nav = self.$gizmo.find('.nav');
  this.items = self.$gizmo.find('li');
  this.listEl = self.$gizmo.find('.list:first');

  this.getMaxDesiredHeight = function () {
    var heightCueEl,
        containerHeight = self.$gizmo.outerHeight(),
        gizmoHeight = self.$gizmo.parent().outerHeight(),
        height;

    if (self.options.heightCue) {
      heightCueEl = Kobo.$body.find(self.options.heightCue);

      if (heightCueEl.length && heightCueEl.height() > 0) {
        var h = heightCueEl.height();

        if (h < self.listItemHeightWithPadding() || h <= gizmoHeight) {
          Kobo.log("[FitListtoContainer] warning! heightCue container is too small to show any items");
        } else {
          height = h;
        }
      }
    }

    if (gizmoHeight === containerHeight) {
      height = self.options.defaultHeight;
    }

    if (!height) {
      return containerHeight;
    }

    self.$gizmo.height(height);
    return parseInt(height);
  };

  this.getHeightOfNav = function () {
    return self.$nav.outerHeight(true);
  };

  this.getListOffset = function () {
    return self.listEl.offset().top - self.$gizmo.offset().top;
  };

  this.listItemHeightWithPadding = function () {
    return self.items.first().outerHeight(true);
  };

  this.getListContainerHeight = function () {
    return self.$gizmo.find(".ul-container").outerHeight();
  };

  this.setListViewPortHeight = function () {
    var listViewPortHeight = self.listItemHeightWithPadding() * self.numberOfItemsToDisplayPerPage();
    self.listEl.parent().height(listViewPortHeight);
  };

  this.isActivated = ko.observable(true);
  this.scrollPositionIndex = ko.observable(0);
  this.totalNumberOfItems = ko.observable();
  this.totalPages = ko.observable(0);
  this.numberOfItemsToDisplayPerPage = ko.computed(function () {
    var containerHeight = self.getMaxDesiredHeight(),
        navHeight = self.getHeightOfNav(),
        listOffset = self.getListOffset(),
        listItemHeight = self.listItemHeightWithPadding(),
        listContainerHeight = self.getListContainerHeight(),
        maxHeightForList,
        numItems;
    maxHeightForList = containerHeight - navHeight - listOffset - listContainerHeight;
    numItems = Math.floor(maxHeightForList / listItemHeight);
    return numItems;
  }); // calculate newTop from element heights

  this.getOffsetOfAListItem = function (i) {
    return -self.items.eq(i).position().top;
  };

  this.animate = function () {
    if (options && options.animationMixin) {
      var mixin = Kobo.Mixin[options.animationMixin];

      if (!mixin) {
        self.gizmo.error('MixinWrongClassName', 'Kobo.ViewModel.FitListToContainer', self, 'Cannot find mixin ' + self.options.animationMixin);
        return;
      }

      if (typeof mixin === 'function') {
        self.animationMixin = new Kobo.Mixin[self.options.animationMixin](element, self.settings.animationMixinOptions);
      } else {
        self.animationMixin = mixin;
      }

      return function (options) {
        options.newTop = self.getOffsetOfAListItem(options.scrollPositionIndex);
        self.animationMixin.animate(options);
      };
    }

    return function () {
      Kobo.log('[Kobo.ViewModel.List]: NO ANIMATION DEFINED');
    };
  }();

  this.showNewPage = function () {
    self.animate({
      scrollPositionIndex: self.scrollPositionIndex(),
      itemFullHeight: self.listItemHeightWithPadding(),
      totalNumberOfItems: this.totalNumberOfItems
    });
  };

  this.scrollPositionIndex.subscribe(function (newValue) {
    self.showNewPage();
  });

  this.addNewItems = function (newItems) {
    var i;

    for (i = 0; i < newItems.length; i++) {
      self.items.push(new self.ItemViewModelClass(newItems[i]));
    }
  };

  this.onhover = function (e) {};

  this.onclick = function (e) {};

  init = function init() {
    var numItems;
    self.setListViewPortHeight();
    numItems = self.numberOfItemsToDisplayPerPage();

    if (numItems > 0) {
      self.totalNumberOfItems(self.items.length);
      self.totalPages(Math.ceil(self.totalNumberOfItems() / numItems));
      self.applyBindings();
    }
  };

  init();
};

Kobo.ViewModel.FitListToContainer.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);

Kobo.ViewModel.Dummy = function (data) {
  "use strict";
};