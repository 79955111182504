"use strict";

Kobo.ViewModel.Expandable.ExternalTrigger = function (gizmo, options) {
  var self = this;
  self.$el = gizmo.$gizmo;
  Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
};

Kobo.ViewModel.Expandable.ExternalTrigger.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype); // Overriding the function below allows us to use an existing element as the trigger, vs inserting a new div

Kobo.ViewModel.Expandable.ExternalTrigger.prototype.addButton = function () {
  "use strict";

  var button = this.$el.find('.external-trigger')[0];
  button.setAttribute('class', 'gizmo-expandable-button');
  button.setAttribute('data-bind', 'css: {"gizmo-expandable-button-collapsed": isCollapsed()}');
  this.$el.attr('data-bind', 'css: {"gizmo-expandable-collapsed": isCollapsed()}');
  this.$button = Kobo.$(button);
  this.buttonWidth = this.$button.width() / 2;
  this.$el.prepend(this.$button); // This is the bit that forces the KO bindings to refresh themselves, allowing the data-bind to be useful

  this.$button.addClass('external-trigger');
  this.$button.addClass('gizmo-expandable-button-fixed-chevron');
};