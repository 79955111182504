"use strict";

/*global Kobo*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RAT.BrowserDetailsHelper', browserDetailsHelper);

  function browserDetailsHelper() {
    this.browserWidth = function () {
      var viewPortWidth;

      if (typeof window.innerWidth != 'undefined') {
        viewPortWidth = window.innerWidth;
      } else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0) {
        // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        viewPortWidth = document.documentElement.clientWidth;
      } else {
        // older versions of IE
        viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
      }

      return viewPortWidth;
    }; //{{RAT - Page Layout - Device}}


    this.getPageLayout = function () {
      var viewPortWidth = this.browserWidth();
      var spmatch = /^([0-4][0-9][0-9]|5[0-5][0-9]|56[0-8])$/g;
      var tbmatch = /^(569|5[7-9][0-9]|[6-7][0-9][0-9]|8[0-7][0-9]|880)$/g;
      if (viewPortWidth.toString().match(spmatch)) return "SP";
      if (viewPortWidth.toString().match(tbmatch)) return "TB";
      return "PC";
    };
  }
})();