"use strict";

/*global Kobo, ko*/
Kobo.Gizmo.InteractiveRecommendationsWidget = function (element) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  var self = this,
      url = this.options.interactiveRecommendationsUrl,
      resetViewModel,
      scriptLoadSuccess,
      scriptLoadFailure,
      loadAppBundle;

  resetViewModel = function resetViewModel() {
    self.viewModel.showSpinner(true);
    self.viewModel.showFailure(false);
    self.viewModel.showSuccess(false);
  };

  scriptLoadSuccess = function scriptLoadSuccess() {
    self.viewModel.showSpinner(false);
    self.viewModel.showSuccess(true);
    self.viewModel.showFailure(false);
  };

  scriptLoadFailure = function scriptLoadFailure() {
    self.viewModel.showSpinner(false);
    self.viewModel.showSuccess(false);
    self.viewModel.showFailure(true);
  };

  loadAppBundle = function loadAppBundle() {
    resetViewModel();
    var script = document.createElement('script');
    script.onload = scriptLoadSuccess;
    script.onerror = scriptLoadFailure;
    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
  };

  this.viewModel = {
    fadeIn: ko.observable(true),
    showSpinner: ko.observable(false),
    showFailure: ko.observable(false),
    showSuccess: ko.observable(false),
    loadAppBundle: loadAppBundle,
    resetViewModel: resetViewModel,
    scriptLoadSuccess: scriptLoadSuccess,
    scriptLoadFailure: scriptLoadFailure
  };
  loadAppBundle();
  ko.applyBindings(this.viewModel, element);
};

Kobo.Gizmo.InteractiveRecommendationsWidget.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);