"use strict";

/*global Kobo,$,ko,Modernizr*/
(function (Kobo) {
  function ShoppingCart() {
    this.itemLength = ko.observable(0);
    this.containsKoboLoveMembership = ko.observable(false);
    this.priceAndPointsEnabled = ko.observable(false);
    this.vipMessage = ko.observable("");
    this.shoppingCartNavigatorPopupVM = ko.observable({
      cssClass: 'large-view',
      links: [{
        text: DynamicConfiguration.resourceStrings.closeShoppingCartNavigateToHeader,
        destinationId: 'shopping-cart-button-area',
        callBack: function callBack() {
          Kobo._mediator.fire('richHeader::hideShoppingCart');

          return true;
        }
      }, {
        text: DynamicConfiguration.resourceStrings.closeShoppingCartNavigateToContent,
        destinationId: 'main-content',
        callBack: function callBack() {
          Kobo._mediator.fire('richHeader::hideShoppingCart');

          return true;
        }
      }]
    });
  }

  ShoppingCart.prototype = {};

  function GizmoShoppingCart(el, options) {
    "use strict";

    Kobo.Gizmo.apply(this, arguments);
    this.setType("ShoppingCart");
    this.cartExpandable = null;
    this.$cartToggleButton = Kobo.$('.rich-header-shopping-cart');
    this.$cartDrawer = Kobo.$('.cart-drawer');
    this.$checkoutButton = Kobo.$('.cart-actions .primary-button');
    this.$closeCart = this.$gizmo.find('.close-cart'); // bind callback functions

    this.onToggle = this.onToggle.bind(this);
    this.onSizeAdjusted = this.onSizeAdjusted.bind(this);
    this.onRecommendationsUpdated = this.onRecommendationsUpdated.bind(this);
    this.onGizmosInitialized = this.onGizmosInitialized.bind(this);
    this.onCartUpdate = this.onCartUpdate.bind(this);
    this.onCartUpdated = this.onCartUpdated.bind(this);
    this.onKoboLoveMembershipChanged = this.onKoboLoveMembershipChanged.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onClose = this.onClose.bind(this);
    this.shoppingCart = new ShoppingCart();
    this.registerEvents();
    this.setupHandlers();
    ko.applyBindings(this, el);
  }

  GizmoShoppingCart.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
  /*jslint unparam: true*/

  GizmoShoppingCart.prototype.onToggle = function (event, data) {
    var _this = this;

    var toggle,
        scrollPage,
        needsToScroll,
        cartCurrentlyOpen,
        cartToggling,
        cartCurrentlyOpen = !this.cartExpandable.viewModel.isCollapsed();

    if (data && data.detail && data.detail.toggle !== undefined && Kobo.Object.typeOf(data.detail.toggle) === "boolean") {
      toggle = data.detail.toggle;
    } else {
      toggle = !cartCurrentlyOpen;
    } // allow caller to set whether the page should scroll or not. True by default.


    scrollPage = true;

    if (data && data.detail && data.detail.scrollPage !== undefined && Kobo.Object.typeOf(data.detail.scrollPage) === "boolean") {
      scrollPage = data.detail.scrollPage;
    }

    needsToScroll = Kobo.$window.scrollTop() > this.$el.offset().top + this.$el.outerHeight();
    cartToggling = toggle !== cartCurrentlyOpen;

    if (scrollPage && needsToScroll) {
      this.animationComplete = false;
      Kobo.$('html, body').animate({
        "scrollTop": 0
      }, {
        duration: 400,
        complete: function complete() {
          if (_this.animationComplete === false) {
            _this.animationComplete = true;

            _this.cartExpandable.viewModel.toggle(toggle);
          }
        }
      });
    } else {
      this.cartExpandable.viewModel.toggle(toggle);
    }

    if (toggle && cartToggling) {
      this.fire("shoppingCart::opening");
      this.$cartDrawer.addClass('open');
      this.$cartToggleButton.addClass("cart-open");
    } else if (!toggle && cartToggling) {
      this.fire("shoppingCart::closing");
      this.$cartDrawer.removeClass('open');
      this.$cartToggleButton.removeClass("cart-open");
    }
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  GizmoShoppingCart.prototype.onCartUpdate = function (event, data) {
    if (data.detail.koboLovePointsEnabled !== undefined && data.detail.koboLovePricingEnabled !== undefined) {
      this.shoppingCart.priceAndPointsEnabled(data.detail.koboLovePointsEnabled && data.detail.koboLovePricingEnabled);
    }
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  GizmoShoppingCart.prototype.onCartUpdated = function (event, data) {
    if (data.detail.priceLineItems) {
      this.shoppingCart.priceLineItems(data.detail.priceLineItems);
    }

    this.shoppingCart.itemLength(data.detail.items.length);

    if (!this.cartExpandable.viewModel.isCollapsed()) {
      this.cartExpandable.viewModel.toggle(true);
    }
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  GizmoShoppingCart.prototype.onKoboLoveMembershipChanged = function (event, data) {
    if (data.detail !== undefined && data.detail.vipMembershipInCart !== undefined) {
      this.shoppingCart.containsKoboLoveMembership(data.detail.vipMembershipInCart);
      this.shoppingCart.vipMessage(data.detail.vipMessage);
    }
  };
  /*jslint unparam: false*/


  GizmoShoppingCart.prototype.onRecommendationsUpdated = function () {
    if (!this.cartExpandable.viewModel.isCollapsed()) {
      this.cartExpandable.viewModel.toggle(true);
    }
  };

  GizmoShoppingCart.prototype.onGizmosInitialized = function () {
    for (var i = 0; i < this.ancestors.length; i++) {
      var current = this.ancestors[i];

      if (current.type.indexOf("Class.Gizmo.Expandable") === 0) {
        this.cartExpandable = current;
        break;
      }
    }

    this.fire('shoppingCartItems::fetchItems'); // populate the cart
  };

  GizmoShoppingCart.prototype.onClose = function (event) {
    event.preventDefault();
    this.fire("shoppingCart::toggle", {
      toggle: false
    });
  };

  GizmoShoppingCart.prototype.onSizeAdjusted = function () {
    if (!this.cartExpandable.viewModel.isCollapsed()) {
      this.cartExpandable.viewModel.toggle(true);
    }
  };

  GizmoShoppingCart.prototype.onResize = function (event) {
    if (this.cartExpandable && this.cartExpandable.viewModel && !this.cartExpandable.viewModel.isCollapsed()) {
      this.cartExpandable.viewModel.toggle(true);
    }
  };

  GizmoShoppingCart.prototype.registerEvents = function () {
    this.register('shoppingCart::toggle');
    this.register('shoppingCart::opening');
    this.register('shoppingCart::closing');
    this.register('shoppingCart::adjustsize');
  };

  GizmoShoppingCart.prototype.setupHandlers = function () {
    this.subscribe('shoppingCart::toggle', this.onToggle);
    this.subscribe('shoppingCart::adjustsize', this.onSizeAdjusted);
    this.subscribe('cartRecommendations::updated', this.onRecommendationsUpdated);
    this.subscribe('gizmosInitialized', this.onGizmosInitialized);
    this.subscribe('shoppingCartItems::update', this.onCartUpdate);
    this.subscribe('shoppingCartItems::updated', this.onCartUpdated);
    this.subscribe('shoppingCartItems::changedKoboLoveMembership', this.onKoboLoveMembershipChanged);
    this.events.onResize(this.onResize);
    this.$closeCart.on('click', this.onClose);
  };

  GizmoShoppingCart.prototype.destroy = function () {
    this.unSubscribe('shoppingCart::toggle', this.onToggle);
    this.unSubscribe('shoppingCart::adjustsize', this.onSizeAdjusted);
    this.unSubscribe('cartRecommendations::updated', this.onRecommendationsUpdated);
    this.unSubscribe('gizmosInitialized', this.onGizmosInitialized);
    this.unSubscribe('shoppingCartItems::update', this.onCartUpdate);
    this.unSubscribe('shoppingCartItems::updated', this.onCartUpdated);
    this.unSubscribe('shoppingCartItems::changedKoboLoveMembership', this.onKoboLoveMembershipChanged);
    this.events.offResize(this.onResize);
    this.$closeCart.off('click', this.onClose);
    this.unRegister('shoppingCart::toggle');
    this.unRegister('shoppingCart::opening');
    this.unRegister('shoppingCart::closing');
    this.unRegister('shoppingCart::adjustsize');
  };

  Kobo.Gizmo.ShoppingCart = GizmoShoppingCart;
})(Kobo);