"use strict";

/*globals Kobo */
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.LottiePlayer.AnimationObject', {
    'red-loading-spinner': {
      "v": "5.6.5",
      "fr": 24,
      "ip": 0,
      "op": 24,
      "w": 1000,
      "h": 1000,
      "nm": "Loading loop",
      "ddd": 0,
      "assets": [],
      "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "loading Outlines",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [{
              "i": {
                "x": [0.667],
                "y": [1]
              },
              "o": {
                "x": [0.989],
                "y": [0]
              },
              "t": 0,
              "s": [0]
            }, {
              "t": 24,
              "s": [360]
            }],
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [500, 500, 0],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [500, 500, 0],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [122.2, 122.2, 100],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [{
          "ty": "gr",
          "it": [{
            "ind": 0,
            "ty": "sh",
            "ix": 1,
            "ks": {
              "a": 0,
              "k": {
                "i": [[0, 0], [0, -199.025]],
                "o": [[199.025, 0], [0, 0]],
                "v": [[-180.183, -180.184], [180.184, 180.184]],
                "c": false
              },
              "ix": 2
            },
            "nm": "Path 1",
            "mn": "ADBE Vector Shape - Group",
            "hd": false
          }, {
            "ty": "st",
            "c": {
              "a": 0,
              "k": [0.749019607843, 0, 0, 1],
              "ix": 3
            },
            "o": {
              "a": 0,
              "k": 100,
              "ix": 4
            },
            "w": {
              "a": 0,
              "k": 110,
              "ix": 5
            },
            "lc": 1,
            "lj": 1,
            "ml": 10,
            "bm": 0,
            "nm": "Stroke 1",
            "mn": "ADBE Vector Graphic - Stroke",
            "hd": false
          }, {
            "ty": "tr",
            "p": {
              "a": 0,
              "k": [680.183, 319.816],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [0, 0],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [96.448, 96.448],
              "ix": 3
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 6
            },
            "o": {
              "a": 0,
              "k": 100,
              "ix": 7
            },
            "sk": {
              "a": 0,
              "k": 0,
              "ix": 4
            },
            "sa": {
              "a": 0,
              "k": 0,
              "ix": 5
            },
            "nm": "Transform"
          }],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }],
        "ip": 0,
        "op": 24,
        "st": 0,
        "bm": 0
      }],
      "markers": []
    }
  });
})();