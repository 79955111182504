"use strict";

/*global Kobo, $, window*/
Kobo.Gizmo.ResultDisplayController = function (el, options) {
  // eslint-disable-line no-unused-vars 
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      initialView,
      currentView,
      currentPageWidth = Kobo.$(window).width(),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("ResultDisplayController");

  self.setViewSettings = function (event, input) {
    // eslint-disable-line no-unused-vars 
    var postbackRequired = input.detail.searchView === 'list' && initialView !== 'list'; // we will need to retrieve the synopses

    self.persistDisplayPreferences({
      resultsFormat: input.detail.searchView
    });

    if (postbackRequired) {
      Kobo.Utilities.reload();
    } else {
      $el.removeClass(currentView).addClass(input.detail.searchView);
      self.removeEllisions(); // Remove any exisitng ellisions.

      currentView = input.detail.searchView;
      $el.data('view', currentView);

      Kobo._mediator.fire('searchLayout::viewChanged', {
        searchView: currentView
      });
    }
  };

  self.hasOverflowed = function (element) {
    if (element.offsetHeight + 1 < element.scrollHeight) {
      // +1 added to cope with vagaries of rendering on Android < 4.4 - mmh, 29-Dec-14
      return true;
    }

    return false;
  };

  self.setReadMore = function () {
    $el.find('.synopsis').each(function () {
      if (self.hasOverflowed(this)) {
        Kobo.$(this).addClass('read-more');
      } else {
        Kobo.$(this).removeClass('read-more');
      }
    });
  };

  self.setReadMore = function () {
    $el.find('.synopsis-contributors').each(function () {
      if (self.hasOverflowed(this)) {
        Kobo.$(this).addClass('read-more');
      } else {
        Kobo.$(this).removeClass('read-more');
      }
    });
  };

  self.setEllisions = function () {
    $el.find('[data-ellide=true]').each(function () {
      var limit = Kobo.$(this).data('ellideOnly');

      if (limit !== 'undefined' && limit !== currentView) {
        return;
      }

      if (self.hasOverflowed(this)) {
        Kobo.$(this).addClass('ellided');
      } else {
        Kobo.$(this).removeClass('ellided');
      }
    });
  };

  self.removeEllisions = function () {
    $el.find('[data-ellide=true]').each(function () {
      Kobo.$(this).removeClass('ellided');
    });
  };

  self.updateDisplay = function () {
    self.setReadMore();
    self.setEllisions();
  };

  self.bindActions = function () {
    $el.on('click', '[data-action-event]', null, function () {
      var event = Kobo.$(this).attr('data-action-event'),
          params = Kobo.$(this).attr('data-action-params');

      Kobo._mediator.fire(event, JSON.parse(params));
    });
  };

  init = function init() {
    Kobo._mediator.register('searchLayout::viewChangeRequested');

    Kobo._mediator.subscribe('searchLayout::viewChangeRequested', self.setViewSettings);

    Kobo._mediator.register('searchLayout::viewInitialised');

    Kobo._mediator.subscribe('searchLayout::viewInitialised', self.updateDisplay);

    Kobo._mediator.register('searchLayout::viewChanged');

    Kobo._mediator.subscribe('searchLayout::viewChanged', self.updateDisplay);

    Kobo._mediator.register('searchLayout::viewResized');

    Kobo._mediator.subscribe('searchLayout::viewResized', self.updateDisplay);

    currentView = $el.data('view');
    initialView = currentView;

    Kobo._mediator.fire('searchLayout::viewInitialised', {
      searchView: currentView
    });

    self.bindActions();

    if (options.isUsingNarrowDownFilterTheme) {
      Kobo.$('aside.column-aside.content-aside').addClass('narrow-down-filters');
    }
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('searchLayout::viewChangeRequested', self.setViewSettings);

    Kobo._mediator.unRegister('searchLayout::viewChangeRequested');

    Kobo._mediator.unRegister('searchLayout::viewInitialised');

    Kobo._mediator.unSubscribe('searchLayout::viewInitialised', self.updateDisplay);

    Kobo._mediator.unRegister('searchLayout::viewChanged');

    Kobo._mediator.unSubscribe('searchLayout::viewChanged', self.updateDisplay);

    Kobo._mediator.unRegister('searchLayout::viewResized');

    Kobo._mediator.unSubscribe('searchLayout::viewResized', self.updateDisplay);
  };

  init();
  Kobo.$(window).resize(function () {
    var windowWidth = Kobo.$(window).width();

    if (currentPageWidth !== windowWidth) {
      currentPageWidth = windowWidth;

      Kobo._mediator.fire('searchLayout::viewResized');
    }
  });
};

Kobo.Gizmo.ResultDisplayController.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);