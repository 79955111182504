"use strict";

Kobo.ViewModel.Expandable.FacetListButtonNested = function (gizmo, options) {
  var self = this; // move facet header outside of expandable area

  self.$header = gizmo.$innerContent.find('> .facet-list-header');
  gizmo.$gizmo.prepend(self.$header);
  Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
  self.childExpandables = [];
  self.currentSize = gizmo.currentBreakpoint();
  gizmo.viewModel.allowTab = true;
  gizmo.subscribe("gizmosInitialized", function () {
    var length = gizmo.descendants.length,
        current,
        i;

    if (length > 0) {
      var selfHeight = function selfHeight() {
        var childHeight = self.childExpandables.reduce(function (prev, cur) {
          return prev + parseInt(cur.viewModel.height());
        }, 0);
        var parentHeight = parseInt(self.getGizmoHeight());
        return childHeight + parentHeight + 'px';
      };

      for (i = 0; i < length; i++) {
        current = gizmo.descendants[i];

        if (current.type === "Class.Gizmo.Expandable") {
          self.childExpandables.push(current);
          current.viewModel.registerExpandableResizedEvent();
          current.viewModel.allowTab = true;
        }
      }

      gizmo.subscribe("expandableResized", function () {
        self.$contentLinks = Kobo.Utilities.findAllFocusableElements(gizmo.$innerContent);

        if (self.isCollapsed()) {
          //collapse all children
          self.childExpandables.map(function (child) {
            if (!child.viewModel.isCollapsed()) {
              child.viewModel.toggle();
            }
          });
          self.height('0px');
          self.$contentLinks.attr('tabindex', '-1').attr('aria-hidden', true);
        } else {
          self.height(selfHeight());
          self.$contentLinks.attr('tabindex', '0').removeAttr('aria-hidden');
          self.childExpandables.map(function (child) {
            if (child.viewModel.isCollapsed()) {
              var nestedContentLinks = child.viewModel.$contentLinks;
              nestedContentLinks.attr('tabindex', '-1').attr('aria-hidden', true);
            }
          });
        }
      });
    }

    gizmo.fire("expandableResized");
  });
  gizmo.events.onResize(function (event) {
    var childExpandable,
        childExpandablesLength = self.childExpandables.length,
        smallCollapsed = false,
        mediumCollapsed = false,
        largeCollapsed = false,
        jumboCollapsed = false;

    if (typeof self.settings.isCollapsed !== "boolean") {
      smallCollapsed = self.settings.isCollapsed.small ? self.settings.isCollapsed.small : false;
      mediumCollapsed = self.settings.isCollapsed.medium ? self.settings.isCollapsed.medium : false;
      largeCollapsed = self.settings.isCollapsed.large ? self.settings.isCollapsed.large : false;
      jumboCollapsed = self.settings.isCollapsed.jumbo ? self.settings.isCollapsed.jumbo : false;
    }

    if (self.currentSize !== event.currentSize) {
      // In the following check, self.isCollapsed() has the casting vote. If it is true,
      // the values of smallCollapsed and mediumCollapsed are ignored. See ViewModel.Expandable.js
      // for the isCollapsed() function.
      if ((event.currentSize === "small" && smallCollapsed || event.currentSize === "medium" && mediumCollapsed) && !self.isCollapsed()) {
        while (childExpandablesLength--) {
          childExpandable = self.childExpandables[childExpandablesLength];

          if (!childExpandable.viewModel.isCollapsed()) {
            childExpandable.viewModel.toggle();
          }
        }

        self.toggle();
      } else if ((event.currentSize === "medium" && !mediumCollapsed || event.currentSize === "large" && !largeCollapsed || event.currentSize === "jumbo" && !jumboCollapsed) && self.isCollapsed()) {
        while (childExpandablesLength--) {
          childExpandable = self.childExpandables[childExpandablesLength];

          if (childExpandable.viewModel.isCollapsed()) {
            childExpandable.viewModel.toggle();
          }
        }

        self.toggle();
      }
    }

    self.currentSize = event.currentSize;
  });

  if (gizmo.settings.collapseOnEvent !== false) {
    gizmo.register(gizmo.settings.collapseOnEvent);
    gizmo.subscribe(gizmo.settings.collapseOnEvent, function (eventName, data) {
      if (!data.detail.gizmo.viewModel.isCollapsed() && data.detail.gizmo.guid !== gizmo.guid) {
        gizmo.viewModel.toggle(false);
      }
    });
  }
};

Kobo.ViewModel.Expandable.FacetListButtonNested.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

Kobo.ViewModel.Expandable.FacetListButtonNested.prototype.addButton = function () {
  var self = this,
      subButton,
      subButtonWrapper;
  Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
  this.$button.addClass('gizmo-expandable-button-facetlist');
  subButtonWrapper = document.createElement("div");
  subButtonWrapper.setAttribute('class', 'gizmo-expandable-subbutton-wrapper');
  this.subButtonWrapper = Kobo.$(subButtonWrapper);
  this.$button.append(this.subButtonWrapper);
  subButton = document.createElement("div");
  subButton.setAttribute('class', 'gizmo-expandable-subbutton');
  this.$subButton = Kobo.$(subButton);
  this.subButtonWrapper.append(this.$subButton);

  if (typeof self.$header !== 'undefined') {
    self.$header.append(this.$button);
  }
};