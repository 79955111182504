"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*globals Kobo,resources*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.Behaviors.Braintree.ThreeDSecureBehavior', ThreeDSecureBehavior);

  function ThreeDSecureBehavior(urls) {
    var self = this,
        challengeCanceled,
        threeDSecureStrings,
        braintreeInstance;

    function _onSetupThreeDSecureClient(_, data) {
      var _data$detail = data.detail,
          clientInstance = _data$detail.clientInstance,
          readyCallback = _data$detail.readyCallback; // add event handlers for 3ds-defined events

      clientInstance.on('authentication-modal-render', _onChallengeStarted);
      clientInstance.on('customer-canceled', _onChallengeCanceled);
      clientInstance.on('lookup-complete', _onLookupComplete);
      braintreeInstance = clientInstance;
      readyCallback({
        clientInstance: clientInstance
      });
    }

    function _onChallengeCanceled() {
      challengeCanceled = true;
      sendThreeDSecureTrackingMessage(threeDSecureStrings.ChallengeStatus.ChallengeCanceled);
    }

    function _onChallengeStarted() {
      sendThreeDSecureTrackingMessage(threeDSecureStrings.ChallengeStatus.ChallengeStarted);
    }

    function _onLookupComplete(_, next) {
      next();
    }

    function _onVerifyCard(_, data) {
      var _data$detail2 = data.detail,
          amount = _data$detail2.amount,
          nonce = _data$detail2.nonce,
          bin = _data$detail2.bin,
          paymentType = _data$detail2.paymentType,
          successCallback = _data$detail2.successCallback,
          errorCallback = _data$detail2.errorCallback,
          canceledCallback = _data$detail2.canceledCallback,
          captchaToken = _data$detail2.captchaToken,
          verifyAction = _data$detail2.verifyAction,
          verifyCaller = _data$detail2.verifyCaller;
      var clientInstance = data.detail.threeDSecureInstance || braintreeInstance;
      clientInstance.verifyCard({
        amount: amount,
        nonce: nonce,
        bin: bin
      }, function (err, response) {
        if (err) {
          console.error('3DS verifyCard error:', err);
          err.ErrorMessage = resources.getString("threeDSecureFrontError");
          errorCallback(err);
          throw err;
        }

        if (challengeCanceled) {
          challengeCanceled = false; // reset

          canceledCallback();
          return;
        }

        var challengeStatus = getChallengeStatus(response),
            nonce = response.nonce,
            binData = response.binData,
            issuingBank = binData.issuingBank,
            metricsData = {
          paymentType: paymentType,
          challengeStatus: challengeStatus,
          issuingBank: issuingBank,
          verifyAction: verifyAction,
          verifyCaller: verifyCaller
        };
        recordVerifyCardMetrics(metricsData)["catch"](function (err) {
          console.error('3DS record verifyCard metrics error:', err);
        });
        var verifyCardResponse = {
          challengeStatus: challengeStatus,
          issuingBank: issuingBank,
          nonce: nonce,
          captchaToken: captchaToken
        };
        successCallback(verifyCardResponse);
      });
    }

    function getChallengeStatus(response) {
      var result;
      var authenticatedByPopup = response.rawCardinalSDKVerificationData != null;
      var authenticationSucceeded = response.threeDSecureInfo.liabilityShifted;

      if (authenticationSucceeded) {
        result = authenticatedByPopup ? threeDSecureStrings.ChallengeStatus.ChallengeSuccess : threeDSecureStrings.ChallengeStatus.NoChallengeSuccess;
      } else {
        result = authenticatedByPopup ? threeDSecureStrings.ChallengeStatus.ChallengeFailure : threeDSecureStrings.ChallengeStatus.NoChallengeFailure;
      }

      return result;
    }

    function recordVerifyCardMetrics(metricsData) {
      var payload = _objectSpread(_objectSpread({}, metricsData), {}, {
        paymentType: Kobo.PurchasePath.Enums.ConvertPaymentTypeToServer(metricsData.paymentType)
      });

      return Kobo.$.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        url: urls.recordVerifyCardMetricsUrl,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        data: JSON.stringify(payload)
      });
    }

    function sendThreeDSecureTrackingMessage(challengeStatus) {
      var trackingData = {
        type: threeDSecureStrings.Name,
        trackables: {
          challengeStatus: challengeStatus
        }
      };

      Kobo._mediator.fire('webEventTracker::sendTrackingMessage', trackingData);
    }

    function setupHandlers() {
      Kobo._mediator.register('threeDSecure::setupClientInstance');

      Kobo._mediator.subscribe('threeDSecure::setupClientInstance', _onSetupThreeDSecureClient);

      Kobo._mediator.register('threeDSecure::verifyCard');

      Kobo._mediator.subscribe('threeDSecure::verifyCard', _onVerifyCard);
    }

    function init() {
      challengeCanceled = false;
      threeDSecureStrings = Kobo.PurchasePath.Enums.ThreeDSecure;
      setupHandlers();
    }

    self.destroy = function () {
      Kobo._mediator.unSubscribe('threeDSecure::setupClientInstance', _onSetupThreeDSecureClient);

      Kobo._mediator.unRegister('threeDSecure::setupClientInstance');

      Kobo._mediator.unSubscribe('threeDSecure::verifyCard', _onVerifyCard);

      Kobo._mediator.unRegister('threeDSecure::verifyCard');
    };

    init();
  }
})();