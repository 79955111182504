"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.BillingAddressDisplay.ViewModels.CountryVatBillingAddressVM', CountryVatBillingAddressViewModel);

  function CountryVatBillingAddressViewModel(options) {
    var self = this;
    var country = options.country,
        vatNumber = options.vatNumber;
    self.displayType = "CountryVat";
    self.country = ko.observable(country);
    self.vatNumber = ko.observable(vatNumber);
  }
})();