"use strict";

/*global Kobo, $, ko, DynamicConfiguration*/
(function () {
  "use strict";

  function ThankYouAudiobookSubscriptionVM(options) {
    var self = this;
    self.creditUsed = ko.observable(false);
    self.appLinks = options.appLinks;
    self.relatedLinks = options.relatedLinks;
    self.productAvailable = options.productAvailable;
    self.isWalmartAffiliate = options.isWalmartAffiliate;
  }

  Kobo.Utilities.assignToNamespace('Kobo.Purchasing.ViewModels.ThankYouAudiobookSubscriptionVM', ThankYouAudiobookSubscriptionVM);
})();