"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// TODO (James): This control has really grown as we've re-implemented lightweight behavior versions of the normal carousel.
// Decompose this into view, viewmodel & knockout bindings and add js unit test coverage. Tracked in WEB-17032.
Kobo.Gizmo.LightweightCarousel = function (el, options) {
  Kobo.Gizmo.apply(this, arguments);
  this.setType("LightweightCarousel");
  var settings = Kobo.$.extend({
    isNavigationButtonsEnabled: false,
    navButtonScrollSpeed: 400
  }, options);
  var $el = Kobo.$(el);
  var buttonContainerSelector = '.button-container';
  var buttonAreaContainerSelector = '.navigation-button-container';
  var verticalBufferSelector = '.vertical-buffer, .cta-vertical-buffer';
  var $nextButton = $el.find('.next');
  var $previousButton = $el.find('.previous');
  var $items = $el.find('.item-container');
  var $viewport = $el.find('.carousel-items');
  var setNavigationButtonVisibilityOnViewportScroll;
  var setNavigationButtonVisibilityOnWindowResize;
  var autoAdjustItemMarginsOnWindowResize;
  var isScrolling = false;
  var rightEdgeOffscreenClippingTolerence = 6; // px

  var outlineCutFix = 6; // px | is added to fix cut outline on :focus-visible on left and right

  var carouselLinksAndButtons = $viewport.find('a, button');
  var carouselHeading = $el.find('.primary-heading').text().trim();
  var intersectionObserver = null;
  var impressionIntersectionObserver = null;

  function init() {
    _initNavigationButtons();

    if (settings.isNavigationButtonsEnabled) {
      if (Modernizr.flexbox === false) {
        _alignNavigationButtons();
      }
    }

    intersectionObserver = _buildObserver($viewport[0]);
    impressionIntersectionObserver = _buildImpressionObserver();

    _setTabOrderForItems();

    _setImpressionObserverForItems();

    if (settings.isAutoMarginsEnabled) {
      _initAutoMargins();
    }
  }

  init();

  this.destroy = function () {
    $nextButton.off('click', _scrollToNext);
    $previousButton.off('click', _scrollToPrevious);
    $viewport.off('scroll', setNavigationButtonVisibilityOnViewportScroll);
    Kobo.$window.off('resize', setNavigationButtonVisibilityOnWindowResize);
    Kobo.$window.off('resize', autoAdjustItemMarginsOnWindowResize);

    _removeTabOrderForItems();

    _removeImpressionObserverForItems();
  };

  var handleButtonKeyPressClick = function handleButtonKeyPressClick(event, scrollToDirection) {
    if (event.type === 'click' || event.type === 'keypress' && (event.keyCode == Kobo.KeyCodes.RETURN || event.keyCode == Kobo.KeyCodes.SPACE)) {
      scrollToDirection();
    }
  };

  function _initNavigationButtons() {
    $nextButton.on('keypress click', function (event) {
      handleButtonKeyPressClick(event, _scrollToNext);
    });
    $previousButton.on('keypress click', function (event) {
      handleButtonKeyPressClick(event, _scrollToPrevious);
    });

    _setNavigationButtonVisibility();

    setNavigationButtonVisibilityOnViewportScroll = Kobo.Utilities.Events.debounce(_setNavigationButtonVisibility, 50);
    $viewport.on('scroll', setNavigationButtonVisibilityOnViewportScroll);
    setNavigationButtonVisibilityOnWindowResize = Kobo.Utilities.Events.debounce(_setNavigationButtonVisibility, 200);
    Kobo.$window.on('resize', setNavigationButtonVisibilityOnWindowResize);
  }

  function _alignNavigationButtons() {
    _alignButton($previousButton);

    _alignButton($nextButton);

    function _alignButton($button) {
      var $buttonContainer = $button.closest(buttonContainerSelector);
      var $buttonAreaContainer = $button.closest(buttonAreaContainerSelector);
      var $buffers = $buttonAreaContainer.find(verticalBufferSelector);
      var areaHeight = $buttonAreaContainer.height();
      var bufferHeight = 0;
      $buffers.each(function () {
        bufferHeight += $(this).outerHeight(true);
      });
      var buttonContainerHeight = areaHeight - bufferHeight;
      $buttonContainer.css('height', buttonContainerHeight + 'px');
    }
  }

  function _initAutoMargins() {
    _autoAdjustItemMargins();

    autoAdjustItemMarginsOnWindowResize = Kobo.Utilities.Events.debounce(_autoAdjustItemMargins, 200);
    Kobo.$window.on('resize', autoAdjustItemMarginsOnWindowResize);
  }

  function _scrollToNext() {
    _scroll('next');
  }

  function _scrollToPrevious() {
    _scroll('previous');
  }

  function _scroll(direction) {
    if (isScrolling) {
      return;
    }

    var $visibleItems = _getVisibleItems();

    if (!$visibleItems.length) {
      return;
    }

    var numberOfVisibleItems = $visibleItems.length;
    var $firstVisibleItem = $visibleItems.eq(0);
    var indexOfFirstVisibleItem = $items.index($firstVisibleItem);
    var indexOfItemToScrollTo;

    if (direction === 'next') {
      indexOfItemToScrollTo = Math.min(indexOfFirstVisibleItem + numberOfVisibleItems, $items.length - 1);
    } else if (direction === 'previous') {
      indexOfItemToScrollTo = Math.max(indexOfFirstVisibleItem - numberOfVisibleItems, 0);
    } else {
      throw new Error('Invalid scroll direction: ' + direction);
    }

    var $element = $items.eq(indexOfItemToScrollTo);

    _scrollTo($element, direction);
  }

  function _buildObserver(viewport) {
    var defaultOptions = {
      rootMargin: '0px',
      threshold: 0.9
    };
    return new IntersectionObserver(function (entries, observer) {
      [].slice.call(entries).forEach(function (entry) {
        if (entry.isIntersecting && entry.intersectionRatio > 0.9) {
          entry.target.setAttribute('aria-hidden', false);
          entry.target.setAttribute('tabindex', 0);
        } else {
          entry.target.setAttribute('aria-hidden', true);
          entry.target.setAttribute('tabindex', -1);
        }
      });
    }, _objectSpread(_objectSpread({}, defaultOptions), {}, {
      root: viewport
    }));
  }

  function _buildImpressionObserver() {
    var visibleTimeStart;
    var impressionObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
          visibleTimeStart = Date.now();
          var target = entry.target;
          setTimeout(function () {
            if (Date.now() - visibleTimeStart >= 2000) {
              var impressionEvent = new CustomEvent('impression', {
                bubbles: true,
                detail: {
                  event: 'impression',
                  carouselName: carouselHeading
                }
              });
              target.dispatchEvent(impressionEvent);
            }
          }, 2000);
        }
      });
    }, {
      threshold: 0.8
    });
    return impressionObserver;
  } // Marks only visible items as being tabbable


  function _setTabOrderForItems() {
    carouselLinksAndButtons.toArray().forEach(function (element) {
      intersectionObserver.observe(element);
    });
  }

  function _removeTabOrderForItems() {
    carouselLinksAndButtons.toArray().forEach(function (element) {
      intersectionObserver.unobserve(element);
    });
  }

  function _setImpressionObserverForItems() {
    impressionIntersectionObserver.observe($viewport[0]);
  }

  function _removeImpressionObserverForItems() {
    impressionIntersectionObserver.unobserve($viewport[0]);
  }

  function _getVisibleItems() {
    var visibleItems = $items.toArray().filter(function (item) {
      return item.querySelector('a[aria-hidden=false]');
    });
    return $(visibleItems);
  }

  function _scrollTo($element, direction) {
    isScrolling = true;
    var leftEdgeOfItem = $element.position().left + $element.outerWidth(true) - $element.width();
    $viewport.animate({
      scrollLeft: $viewport.scrollLeft() + leftEdgeOfItem - outlineCutFix
    }, {
      duration: settings.navButtonScrollSpeed,
      always: function always() {
        isScrolling = false; // Used by tests (like the UI automation tests) to verify that the carousel scrolled to the right place

        $el.trigger('carouselScrollComplete');

        _focusOnCarouselItem(direction);
      }
    });
  }

  function _focusOnCarouselItem(scrollDirection) {
    var carouselItems = Kobo.Utilities.findAllFocusableElements($viewport);

    var focusableElements = _toConsumableArray(carouselItems).filter(function (element) {
      return element.ariaHidden === "false" && element.classList.contains('carousel-tab-navigation-target');
    });

    if (scrollDirection === 'next') {
      focusableElements[0].focus();
    } else if (scrollDirection === 'previous') {
      focusableElements[focusableElements.length - 1].focus();
    }
  }

  function _setNavigationButtonVisibility() {
    var isPreviousButtonVisible = $viewport.scrollLeft() > 0;
    $previousButton.prop('disabled', !isPreviousButtonVisible);
    var isNextButtonVisible = $viewport.scrollLeft() + $viewport.innerWidth() < $viewport[0].scrollWidth;
    $nextButton.prop('disabled', !isNextButtonVisible);
  }

  function _autoAdjustItemMargins() {
    // Fixes bug in firefox where on page refresh the scrollbar remembers it's last position (messed up margin calculations)
    $viewport.scrollLeft(0);
    var $itemsToApplyMarginAdjustment = $items.slice(1); // don't apply margin to first item

    $itemsToApplyMarginAdjustment.css('marginLeft', ''); // clear any previously set margins

    var $visibleItems = _getVisibleItems();

    if (!$visibleItems.length) {
      // empty content presenter (or so small that nothing can be seen)
      return;
    }

    var $lastVisibleItem = $visibleItems.eq($visibleItems.length - 1);
    var indexOfLastVisibleItem = $items.index($lastVisibleItem);
    var indexOfFirstOffscreenItem = indexOfLastVisibleItem + 1;

    if (indexOfFirstOffscreenItem >= $items.length) {
      // all are visible (its a short list in a big container), no need to adjust margins
      _setNavigationButtonVisibility();

      return;
    }

    var $item = $items.eq(indexOfFirstOffscreenItem);
    var distanceToMove = $viewport.width() - $item.position().left;
    var numVisibleItems = $visibleItems.length - 1; // margin is not applied to first item

    var currentMargin = $lastVisibleItem.outerWidth(true) - $lastVisibleItem.width();
    var newMargin = currentMargin + Math.ceil(distanceToMove / numVisibleItems);
    $itemsToApplyMarginAdjustment.css('marginLeft', newMargin + 'px');
  }
};

Kobo.Gizmo.LightweightCarousel.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);