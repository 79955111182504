"use strict";

/*globals Kobo*/
(function () {
  'use strict';

  Kobo.Gizmo.Carousel = function () {
    var self = this,
        $carousel,
        $nextButton,
        $previousButton,
        tabNavigationTargetClass = 'carousel-tab-navigation-target',
        tabNavigationTargetSelector = '.' + tabNavigationTargetClass,
        initCarousel,
        initCarouselAccessibility,
        onImageLoaded,
        assignNavigationClickTracking,
        alignItems,
        alignNavigationArrows,
        getRenderedItems,
        getVisibleItems,
        isNavigationButtonDisabled,
        init;
    self.setDefaults({
      slidesToShow: 6
    });
    Kobo.Gizmo.apply(self, arguments);
    self.setType('Carousel');

    initCarousel = function initCarousel() {
      var previousLabel = window.DynamicConfiguration.resourceStrings.previous,
          nextLabel = window.DynamicConfiguration.resourceStrings.next,
          imagesToLazyLoad;
      $carousel = self.$el.find('.carousel');
      imagesToLazyLoad = $carousel.find('.cover-image[data-lazy]');
      $carousel.on('init setPosition', function () {
        $nextButton = $carousel.find('.next');
        $previousButton = $carousel.find('.previous');
        imagesToLazyLoad.on('load', onImageLoaded);
        alignItems();
        alignNavigationArrows();
        assignNavigationClickTracking();
      });
      $carousel.slick({
        accessibility: false,
        infinite: false,
        waitForAnimate: false,
        respondTo: 'min',
        lazyload: 'ondemand',
        slidesToShow: self.settings.slidesToShow,
        slidesToScroll: self.settings.slidesToShow,
        prevArrow: '<button type="button" class="previous" aria-label="' + previousLabel + '"></button>',
        nextArrow: '<button type="button" class="next" aria-label="' + nextLabel + '"></button>',
        responsive: [{
          breakpoint: 810,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 670,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }, {
          breakpoint: 410,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }]
      });
    };

    initCarouselAccessibility = function initCarouselAccessibility() {
      // Skip links are used to make keyboard navigation easier, pressing enter when the link is focused will
      // shift focus to an element that appears right after the carousel.
      // We can have multiple carousels on the page, so the skip link for each needs to have a unique, autogenerated destionation id.
      var initSkipLink = function initSkipLink() {
        var skipLinkDestinationId = 'endofcarousel-' + self.guid;
        self.$el.find('.skip-carousel').attr('href', '#' + skipLinkDestinationId);
        self.$el.find('.skip-carousel-target').attr('id', skipLinkDestinationId);
      };

      initSkipLink(); // Marks only visible items as being tabbable (by default slick was letting us tab through offscreen items, which was undesirable/ugly).

      var setTabOrderForItems = function setTabOrderForItems() {
        var $allItems = getRenderedItems(); // before getting items by 'tab navigation' selector, add the selector to ProductActionButtons in cta-container

        $allItems.find('.cta-container a, .cta-container button').addClass(tabNavigationTargetClass);
        $allItems.find(tabNavigationTargetSelector).attr('tabindex', -1);
        var $visibleItems = getVisibleItems();
        $visibleItems.find(tabNavigationTargetSelector).attr('tabindex', 0);
      };

      setTabOrderForItems(); // Navigating to the beginning/end of the list hides the navigation arrows which on some browsers (Chrome) will
      // cause focus to be reset to the top of the page. To make keyboard navigation easier, if the keyboard was being used
      // to scroll the list we keep focus within the list and set it to the first/last item in the carousel.

      var keepFocusWithinCarouselWhenKeyboardNavigatesToEndOfList = function keepFocusWithinCarouselWhenKeyboardNavigatesToEndOfList() {
        var onKeyPress = function onKeyPress(event) {
          if (event.which === 13) {
            // Enter key pressed
            // Important to wait will scrolling is finished so we don't interrupt the scroll animation
            $carousel.on('afterChange.keyboardFocus', function () {
              $carousel.off('afterChange.keyboardFocus');
              var $navigationButton = Kobo.$(event.target);

              if (isNavigationButtonDisabled($navigationButton)) {
                event.data.$focusTargetWhenNavArrowDisabled.focus();
              }
            });
          }
        };

        $nextButton.on('keypress', null, {
          $focusTargetWhenNavArrowDisabled: self.$el.find(tabNavigationTargetSelector).last()
        }, onKeyPress);
        $previousButton.on('keypress', null, {
          $focusTargetWhenNavArrowDisabled: self.$el.find(tabNavigationTargetSelector).first()
        }, onKeyPress);
      };

      keepFocusWithinCarouselWhenKeyboardNavigatesToEndOfList();

      var updateAccessibilityOnStateChange = function updateAccessibilityOnStateChange() {
        $carousel.on('init', function () {
          setTabOrderForItems();
          keepFocusWithinCarouselWhenKeyboardNavigatesToEndOfList();
        }); // When we scroll we want to mark invisible items as being not tabbable

        $carousel.on('afterChange', setTabOrderForItems);
      };

      updateAccessibilityOnStateChange();
    };

    onImageLoaded = function onImageLoaded(event) {
      // When an image is loaded it fires a load event, even if that image is a data uri
      // We don't care about data uris in this case, since we are waiting for the actual image to be loaded
      // So we just ignore any load events that are using a data uri as the image src
      if (event.target.src.substring(0, 4) !== 'data') {
        Kobo.$(this).parents('.image-container').removeClass('lazy');
      }
    };

    assignNavigationClickTracking = function assignNavigationClickTracking() {
      var trackerAttribute = Kobo._tracker.getTrackAttribute();

      $previousButton.attr(trackerAttribute, '{"Description": "Previous"}');
      $nextButton.attr(trackerAttribute, '{"Description": "Next"}');
    };

    alignItems = function alignItems() {
      // Carousel items can have a details area of varying height (some products have more details available than others).
      // Carousel items can have CTA buttons of varying height as well
      // To support this the carousel will add a buffer below each item such that their item details areas are top aligned.
      var $items = getRenderedItems(),
          maxDetailsHeight = 0,
          maxCTAHeight = 0,
          maxBufferHeight = 0; // find Math.max of all element heights

      $items.each(function () {
        var $item = Kobo.$(this),
            currentDetailsHeight = $item.find('.book-details').outerHeight(true) || 0,
            currentCTAHeight = $item.find('.cta-container:visible').outerHeight(true) || 0;
        maxDetailsHeight = Math.max(maxDetailsHeight, currentDetailsHeight);
        maxCTAHeight = Math.max(maxCTAHeight, currentCTAHeight);
      });
      maxBufferHeight = maxDetailsHeight + maxCTAHeight; // apply max height to each element

      $items.each(function () {
        var $item = Kobo.$(this),
            currentDetailsHeight = $item.find('.book-details').outerHeight(true) || 0,
            currentCTAHeight = $item.find('.cta-container:visible').outerHeight(true) || 0,
            finalBufferHeight = maxBufferHeight - currentDetailsHeight - currentCTAHeight;

        if ($item.find('.book-details-container').length) {
          //add buffer to book-details above cta
          $item.find('.book-details-container').css('padding-bottom', finalBufferHeight);
        } else {
          // no book-details, just add buffer to item
          $item.css({
            paddingTop: finalBufferHeight + 1,
            // Add 1 to deal with rounding issues
            top: -finalBufferHeight
          });
        }
      });
    };

    alignNavigationArrows = function alignNavigationArrows() {
      // Vertically aligns navigation arrows in the center of the tallest item image in the carousel.
      var itemDetailsHeight = $carousel.find('.book-details').outerHeight(true);
      var itemDetailsOffset = itemDetailsHeight / 2;
      $nextButton.css('margin-bottom', itemDetailsOffset);
      $previousButton.css('margin-bottom', itemDetailsOffset);
    };

    getRenderedItems = function getRenderedItems() {
      return $carousel.find('.item-container');
    };

    getVisibleItems = function getVisibleItems() {
      return $carousel.find('.slick-active');
    };

    isNavigationButtonDisabled = function isNavigationButtonDisabled($navigationButton) {
      return $navigationButton.hasClass('slick-disabled');
    };

    init = function init() {
      initCarousel();
      initCarouselAccessibility();
    };

    init(); // Public API

    self.destroy = function () {
      $carousel.slick('unslick');
    };
  };

  Kobo.Gizmo.Carousel.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
})();