"use strict";

(function () {
  if (document.body.classList.contains("Store-Browse-Recommendations") && document.cookie.indexOf("platformid=00000000-0000-0000-0000-ffffffff0000") > -1) {
    document.getElementsByTagName("body")[0].classList.add("sp_optly_recommendations");
    var banner_title = 'Get Better Recommendations.';
    var banner_subtext_1 = 'You can now click the';
    var banner_subtext_2 = 'to remove books you’ve already read, or aren’t interested in.';
    var banner_gotit = 'Okay, got it.';
    var already_read = 'Already Read';
    var not_interested = 'Not Interested';
    var thank_you = "Thanks!";
    var we_will_update_your_recos = "We'll update your recommendations.";
    var all_items_removed_text = "We'll update this page with new recommendations for you soon.";
    var go_to_next_page_text = ' For now, try the next page.';
    var next_page_link_text = ' Go to next page > ';
    var cookie = document.cookie;
    var res = cookie.match(/language_locale=[e][n]-[a-zA-Z]{2}/g);

    if (null === res) {
      var language = "en";

      if (cookie.toLowerCase().indexOf("language_locale=") > -1) {
        language = cookie.split("language_locale=")[1].split("&")[0].toLowerCase();
      }

      document.getElementsByTagName("body")[0].classList.add(language);

      switch (language) {
        case "de-de":
          banner_title = 'Erhalten Sie bessere Empfehlungen.';
          banner_subtext_1 = 'Sie können nun auf das';
          banner_subtext_2 = ' klicken, um Bücher zu entfernen, die Sie bereits gelesen haben oder die Sie nicht interessieren. ';
          banner_gotit = 'Okay, alles klar.';
          already_read = 'Bereits gelesen';
          not_interested = 'Kein Interesse';
          thank_you = "Vielen Dank!";
          we_will_update_your_recos = "Wir werden Ihre Empfehlungen aktualisieren.";
          all_items_removed_text = "Wir werden diese Seite bald mit neuen Empfehlungen für Sie aktualisieren.";
          go_to_next_page_text = ' Probieren Sie erst einmal die nächste Seite.';
          next_page_link_text = ' Zur nächten Seite > ';
          break;

        case "fr-fr":
          banner_title = 'Obtenez des recommandations plus pertinentes.';
          banner_subtext_1 = 'Vous pouvez désormais cliquer sur';
          banner_subtext_2 = ' pour supprimer les livres que vous avez déjà lus ou qui ne vous intéressent pas.';
          banner_gotit = 'Ok, j\'ai compris.';
          already_read = 'Déjà lu';
          not_interested = 'Pas intéressé(e)';
          thank_you = "Merci!";
          we_will_update_your_recos = "Nous mettrons à jour vos recommandations.";
          all_items_removed_text = "Cette page sera bientôt mise à jour avec de nouvelles recommandations pour vous.";
          go_to_next_page_text = ' Pour le moment, rendez-vous sur la page suivante.';
          next_page_link_text = 'Allez vers la page suivante > ';
          break;

        case "fr-ca":
          banner_title = 'Obtenez des recommandations plus pertinentes.';
          banner_subtext_1 = 'Vous pouvez désormais cliquer sur';
          banner_subtext_2 = ' pour supprimer les livres que vous avez déjà lus ou qui ne vous intéressent pas.';
          banner_gotit = 'Ok, j\'ai compris.';
          already_read = 'Déjà lu';
          not_interested = 'Pas intéressé(e)';
          thank_you = "Merci!";
          we_will_update_your_recos = "Nous mettrons à jour vos recommandations.";
          all_items_removed_text = "Cette page sera bientôt mise à jour avec de nouvelles recommandations pour vous.";
          go_to_next_page_text = ' Pour le moment, rendez-vous sur la page suivante.';
          next_page_link_text = 'Allez vers la page suivante. > ';
          break;

        case "it-it":
          banner_title = 'Non perdere i suggerimenti migliori.';
          banner_subtext_1 = 'Puoi cliccare sulla ';
          banner_subtext_2 = 'per rimuovere i libri già letti o quelli che non ti interessano.';
          banner_gotit = 'OK, ricevuto.';
          already_read = 'Già letto';
          not_interested = 'Non mi interessa';
          thank_you = "Grazie!";
          we_will_update_your_recos = "Aggiorneremo i suggerimenti.";
          all_items_removed_text = "Tra poco aggiorneremo questa pagina con nuovi suggerimenti per te.";
          go_to_next_page_text = ' Per il momento, passa alla pagina successiva.';
          next_page_link_text = ' Vai alla pagina successiva > ';
          break;

        case "nl-nl":
          banner_title = 'Krijg betere aanbevelingen.';
          banner_subtext_1 = 'Je kunt nu op de ';
          banner_subtext_2 = 'klikken om boeken te verwijderen die je al hebt gelezen of waar je niet in bent geïnteresseerd.';
          banner_gotit = 'Oké, ik snap het.';
          already_read = 'Al gelezen';
          not_interested = 'Geen interesse';
          thank_you = "Hartelijk dank!";
          we_will_update_your_recos = "We zullen je aanbevelingen bijwerken.";
          all_items_removed_text = "We zullen deze pagina binnenkort bijwerken met nieuwe aanbevelingen.";
          go_to_next_page_text = ' Voor nu kun je de volgende pagina bekijken.';
          next_page_link_text = ' Ga naar de volgende pagina > ';
          break;

        case "es-es":
          banner_title = 'Obtén mejores recomendaciones.';
          banner_subtext_1 = 'Ahora puedes hacer clic en la ';
          banner_subtext_2 = ' para eliminar libros que ya has leído, o que no te interesan.';
          banner_gotit = 'Vale, entendido.';
          already_read = 'Ya lo he leído';
          not_interested = 'No me interesa';
          thank_you = "¡Gracias!";
          we_will_update_your_recos = "Actualizaremos tus recomendaciones.";
          all_items_removed_text = "Pronto actualizaremos esta página con nuevas recomendaciones para ti.";
          go_to_next_page_text = ' Por ahora, prueba la siguiente página.';
          next_page_link_text = ' Ir a la siguiente página > ';
          break;

        case "es-mx":
          banner_title = 'Obtén mejores recomendaciones.';
          banner_subtext_1 = 'Ahora puedes hacer clic en la ';
          banner_subtext_2 = ' para borrar libros que ya has leído o que no te interesan.';
          banner_gotit = 'Bien, lo tengo.';
          already_read = 'Leído';
          not_interested = 'No me interesa';
          thank_you = "¡Gracias!";
          we_will_update_your_recos = "Actualizaremos tus recomendaciones.";
          all_items_removed_text = "Pronto actualizaremos esta página con nuevas recomendaciones para ti.";
          go_to_next_page_text = ' Por ahora, prueba la siguiente página.';
          next_page_link_text = ' Ir a la siguiente página > ';
          break;

        case "pt-pt":
          banner_title = 'Obter melhores recomendações.';
          banner_subtext_1 = 'Pode agora clicar no ';
          banner_subtext_2 = 'para remover livros que já leu ou pelos quais não tem interesse.';
          banner_gotit = 'OK, percebido.';
          already_read = 'Já lido';
          not_interested = 'Não estou interessado';
          thank_you = "Obrigado!";
          we_will_update_your_recos = "Vamos atualizar as suas recomendações.";
          all_items_removed_text = "Em breve, atualizaremos esta página com novas recomendações para si.";
          go_to_next_page_text = ' Por agora, tente a página seguinte.';
          next_page_link_text = ' Vá para a página seguinte > ';
          break;

        case "pt-br":
          banner_title = 'Receba recomendações melhores.';
          banner_subtext_1 = 'Agora, você pode clicar no ';
          banner_subtext_2 = 'para remover livros que já leu ou nos quais não está interessado. ';
          banner_gotit = 'Ok, entendi.';
          already_read = 'Já li';
          not_interested = 'Não estou interessado';
          thank_you = "Obrigado!";
          we_will_update_your_recos = "Atualizaremos suas recomendações.";
          all_items_removed_text = "Em breve, atualizaremos esta página com novas recomendações para você.";
          go_to_next_page_text = ' Por enquanto, experimente a próxima página.';
          next_page_link_text = ' Vá para a próxima página > ';
          break;

        case "tr-tr":
          banner_title = 'Daha İyi Tavsiyeler Alın.';
          banner_subtext_1 = 'Zaten okumuş olduğunuz kitapları veya ilgilenmediklerinizi kaldırmak için ';
          banner_subtext_2 = 'düğmesine tıklayabilirsiniz.';
          banner_gotit = 'Tamam, anladım.';
          already_read = 'Okunanlar';
          not_interested = 'İlgilenmiyorum';
          thank_you = "Teşekkürler!";
          we_will_update_your_recos = "Tavsiyelerinizi güncelleyeceğiz.";
          all_items_removed_text = "Bu sayfayı yakında sizin için yeni tavsiyelerle güncelleyeceğiz.";
          go_to_next_page_text = ' Şimdilik, sonraki sayfayı deneyin.';
          next_page_link_text = ' Sonraki sayfaya gidin > ';
          break;

        case "jp-jp":
          banner_title = 'おすすめ商品の精度を上げる';
          banner_subtext_1 = 'すでに読んだ本や興味のない本は、';
          banner_subtext_2 = 'をクリックして削除できます。';
          banner_gotit = 'OK';
          already_read = '読書済み';
          not_interested = '興味なし';
          thank_you = "ありがとうございます！";
          we_will_update_your_recos = "おすすめを更新します。";
          all_items_removed_text = "まもなく新しいおすすめを表示します。";
          go_to_next_page_text = ' 更新されるまで、次ページをご覧ください。';
          next_page_link_text = ' 次ページへ > ';
          break;
      }
    } else {
      document.getElementsByTagName("body")[0].classList.add('en');
      banner_title = 'Get Better Recommendations.';
      banner_subtext_1 = 'You can now click the';
      banner_subtext_2 = 'to remove books you’ve already read, or aren’t interested in.';
      banner_gotit = 'Okay, got it.';
      already_read = 'Already Read';
      not_interested = 'Not Interested';
      thank_you = "Thanks!";
      we_will_update_your_recos = "We'll update your recommendations.";
      all_items_removed_text = "We'll update this page with new recommendations for you soon.";
      go_to_next_page_text = 'For now, try the next page.';
      next_page_link_text = ' Go to next page > ';
    }

    var _recos = {
      loadScripts: function loadScripts() {
        var velocityJS = document.createElement('script');
        velocityJS.setAttribute('src', 'https://cdn.kobo.com/merch-assets/magento/store_performance/user_recommendations/velocity.min.js');
        document.head.appendChild(velocityJS);
      },
      bakeItemCookie: function bakeItemCookie(name, content, productID, time) {
        var date = new Date();
        date.setTime(date.getTime() + time * 60 * 60 * 1000);
        var expires = "expires=" + date.toUTCString();
        document.cookie = name + content + productID + '; ' + expires + '; path=/';
      },
      modifyPageHTML: function modifyPageHTML() {
        var nextPageURL;

        if (Kobo.$('.page-link.active.final').length || Kobo.$('.pagination').length == 0) {
          Kobo.$('.result-items').addClass('sp_final_page');
        } else {
          nextPageURL = Kobo.$('.pagination .next').attr('href');
        }

        Kobo.$('.result-items').prepend('<div class="sp_no_items_banner" style="display: none;">' + all_items_removed_text + '<span> ' + go_to_next_page_text + '</span> <a href="' + nextPageURL + '">' + next_page_link_text + '</a></div>');
        Kobo.$('.item-wrapper').append('<div class="item-overlay" style="display: none;"></div>');
        Kobo.$("<div class='sp_recos_feedback-close'><button class='sp_recos_feedback-close-button' aria-label='" + window.DynamicConfiguration.resourceStrings.close + "' aria-expanded='false'></button><div class='sp_recos_feedback_options_container'><div class='sp_recos_arrow'></div><ul data-force-track='' data-track-info='{&quot;section&quot;:&quot;recommendationsFeedback&quot;}'><li data-force-track='' data-track-info='{&quot;description&quot;:&quot;RecommendationsFeedbackAlreadyRead&quot;}'><button>" + already_read + "</button></li><li class='sp_recos_not_interested_feedback' data-force-track='' data-track-info='{&quot;description&quot;:&quot;RecommendationsFeedbackNotInterested&quot;}'><button>" + not_interested + "</button></li></ul> </div></div>").prependTo(".item-info");

        if (document.cookie.toLowerCase().indexOf('_sp-recos-banner-close') > -1) {
          document.getElementsByTagName("body")[0].classList.add("sp_hide_recos_banner");
        }

        Kobo.$('.kwp-notable-recos.kwp-bg-green.kwp-recos-stars').after("<div data-step='1' data-intro='This is a tooltip!' class='sp_recos_banner inner-wrap'><div class='sp_recos_banner_inner'><h1>" + banner_title + "</h1><p>" + banner_subtext_1 + " <span></span> " + banner_subtext_2 + "</p><a class='sp_recos_banner_close'> " + banner_gotit + " </a></div></div>");
        Kobo.$('.kwp-notable-recos.kwp-bg-green.kwp-recos-stars').remove();
      },
      storeItem: function storeItem(productID) {
        var cookieContent = "";

        if (document.cookie.toLowerCase().indexOf("sprecos_removal") > -1) {
          cookieContent = document.cookie.toLowerCase().split("_sprecos_removal=")[1].split(";")[0] + "&";
        }

        _recos.bakeItemCookie("_spRecos_Removal=", cookieContent, productID, 8);
      },
      notInterestedFeedBackMessage: function notInterestedFeedBackMessage(productId) {
        var request = {
          productId: productId
        };
        Kobo.$.ajax({
          type: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          url: '/TrackRecommendationNotInterestedFeedback',
          data: JSON.stringify(request),
          headers: {
            '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
          }
        });
      },
      noBooksCheck: function noBooksCheck() {
        if (Kobo.$(".item-wrapper:visible:not(.item-wrapper.sp_recos_removed)").length === 0) {
          Kobo.$('.sp_no_items_banner').show();
        }
      },
      removeItems: function removeItems() {
        var cookieContent;

        if (document.cookie.toLowerCase().indexOf("sprecos_removal") > -1) {
          cookieContent = document.cookie.toLowerCase().split("_sprecos_removal=")[1].split(";")[0].split("&");
        }

        if (cookieContent) {
          Kobo.$.each(cookieContent, function (index, el) {
            Kobo.$('[data-track-info*=' + el + '] .item-overlay').show();
            Kobo.$('[data-track-info*=' + el + ']').addClass('sp_recos_removed').hide();
          });
        }

        Kobo.$('.sp_recos_removed').detach().appendTo('.result-items');
        this.noBooksCheck();
      },
      setEvents: function setEvents() {
        Kobo.$('.sp_recos_feedback-close-button').on('click', function () {
          Kobo.$(".sp_recos_feedback_options_container").not($(this).siblings('.sp_recos_feedback_options_container')).hide();
          Kobo.$(this).siblings(".sp_recos_feedback_options_container").toggle();
          Kobo.$(this).toggleClass('selected');
          Kobo.$(this).attr('aria-expanded', Kobo.$(this).hasClass('selected'));
        });
        var allClose = Kobo.$('.sp_recos_feedback-close');

        for (var index = 0; index < allClose.length; index++) {
          var listContainerId = 'reco-action-list-' + jQuery.parseJSON(jQuery(allClose[index]).closest('.item-wrapper').attr('data-track-info')).productId;
          Kobo.$(allClose[index]).find('ul').attr('id', listContainerId);
          Kobo.$(allClose[index]).find('.sp_recos_feedback-close-button').attr('aria-control', listContainerId);
        }

        Kobo.$('body').on('click', function () {
          Kobo.$(".sp_recos_feedback_options_container").hide();
          Kobo.$('.sp_recos_feedback-close-button').removeClass('selected').attr('aria-expanded', false);
        }).on('click', '.sp_recos_feedback-close-button', function (e) {
          e.stopPropagation();
        });
        var setCookie = this.bakeItemCookie;
        Kobo.$('.sp_recos_banner_close').on('click', function () {
          Kobo.$('.sp_recos_banner.inner-wrap').addClass('collapse_recos_banner');
          setCookie("_sp-recos-banner-close=", "true", "", 30 * 24);
          document.getElementsByTagName("body")[0].classList.add("sp_hide_recos_banner");
          Kobo.$('.kobo-main .two-column-left-full-aside .inner-wrap .columns-wrapper').addClass("sp_margin_20_slide");
        });
        var storeItem = this.storeItem;
        var notInterestedFeedBackMessage = this.notInterestedFeedBackMessage;
        var noBooksCheck = this.noBooksCheck; //animation for removing items

        Kobo.$("body").on("click", '.sp_recos_feedback_options_container', function () {
          var productID = jQuery.parseJSON(jQuery(this).closest('.item-wrapper').attr('data-track-info')).productId;
          var feedback_elem = this;
          var el_image = jQuery(this).parents('.detail-flipper').siblings('.item-image');
          storeItem(productID);
          Kobo.$(this).parents('.detail-flipper').fadeOut(400, function () {
            el_image.after('<h1 class="sp_recos_thank_you" style="padding-top: 60px;">' + thank_you + '</h1> <p class="sp_recos_thank_you">' + we_will_update_your_recos + '</p>');
          });
          el_image.addClass('sp_scale_down');

          function animate(elem) {
            Kobo.$(elem).parents('.item-wrapper:eq(0)').fadeOut(500, function () {
              Kobo.$(elem).closest('.item-wrapper').next('.item-wrapper').css('marginTop', '179px');
              Kobo.$(elem).closest('.item-wrapper').next('.item-wrapper').velocity({
                marginTop: "0px"
              }, {
                duration: 200,
                easing: "easeOutSine"
              });
              noBooksCheck();

              if (Kobo.$(".item-wrapper:visible:not(.item-wrapper.sp_recos_removed)").length === 0) {
                var action = 'Feedback Recommendation';
                var category = 'A/B Testing';
                dataLayer.push({
                  "ecommerce": undefined,
                  "event": "kobo.gaEvent",
                  "eventCategory": category,
                  "eventAction": action,
                  "eventLabel": "Removed All Items on Page",
                  "eventValue": undefined
                });
              }

              Kobo.$(elem).closest('.item-wrapper').remove();
            });
          }

          window.setTimeout(animate, 2500, feedback_elem);
        });
        Kobo.$("body").on("click", '.sp_recos_not_interested_feedback', function () {
          var productID = jQuery.parseJSON(jQuery(this).closest('.item-wrapper').attr('data-track-info')).productId;
          var send_RecoMessage = document.cookie.toLowerCase().indexOf('feature.recommendation') > -1;

          if (send_RecoMessage) {
            notInterestedFeedBackMessage(productID);
          }
        });
      },
      init: function init() {
        this.loadScripts();
        this.modifyPageHTML();
        this.removeItems();
        this.setEvents();
      }
    };

    _recos.init();
  }

  ;
})();