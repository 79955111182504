"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.PaymentMethodsDisplay = function (el, options) {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("PaymentMethodsDisplay");
  var self = this;
  var viewModel;

  this.removePaymentMethod = function (paymentMethod) {
    var data = {
      paymentMethodId: paymentMethod.paymentMethodId,
      paymentMethodType: paymentMethod.paymentMethodType
    };
    return new Promise(function (resolve, reject) {
      Kobo.$.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: options.DeletePaymentMethodUrl,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        data: JSON.stringify(data),
        success: resolve,
        error: reject
      });
    });
  };

  this.init = function () {
    var viewModelOptions = {
      paymentMethods: options.PaymentMethods,
      removePaymentMethod: self.removePaymentMethod,
      messageBoxName: options.MessageBoxName
    };
    viewModel = new Kobo.PaymentMethodsDisplay.ViewModels.PaymentMethodsDisplayVM(viewModelOptions);
    ko.applyBindings(viewModel, el);
  };

  this.init();
};

Kobo.Gizmo.PaymentMethodsDisplay.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);