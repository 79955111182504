"use strict";

/*global Kobo*/
Kobo.Gizmo.AddSubItemToReadingList = function (element, options) {
  "use strict";

  var self = this,
      addToReadingList,
      redirectToItemPage,
      setupTriggers,
      init,
      $addButton,
      errorMessage = window.DynamicConfiguration.resourceStrings.addToReadingListError,
      spinnerContainerClass = '.' + options.widgetContainerClass;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("AddSubItemToReadingList");

  addToReadingList = function addToReadingList() {
    Kobo.Spinner.showSpinnerOverlay(Kobo.$(spinnerContainerClass));
    Kobo.Ajax({
      method: "POST",
      cache: false,
      url: options.addToReadingListUrl,
      success: function success() {
        element.className += ' success';

        if (self.settings.contentDownloadEventData) {
          Kobo.event.fireDeviceEvent(self.settings.contentDownloadEventData);
        }
      },
      error: function error() {
        Kobo._mediator.fire("toast::error", {
          message: errorMessage
        });
      },
      complete: function complete() {
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerContainerClass));
      }
    });
  };

  redirectToItemPage = function redirectToItemPage() {
    if (options && options.itemPageRedirectUrl) {
      window.location.href = options.itemPageRedirectUrl;
    }
  };

  setupTriggers = function setupTriggers() {
    if (options && options.addToReadingListUrl) {
      $addButton.on('click', addToReadingList);
    } else if (options && options.itemPageRedirectUrl) {
      $addButton.on('click', redirectToItemPage);
    }
  };

  init = function init() {
    if (options && options.isWidgetVisible) {
      $addButton = self.$gizmo.find('.' + self.options.buttonClass);
      setupTriggers();
    }
  };

  init();
};

Kobo.Gizmo.AddSubItemToReadingList.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);