"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo, ko, Cookies*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PaymentMethodsDisplay.ViewModels.PaymentMethodDisplayUpdateVM', PaymentMethodDisplayUpdateViewModel);

  function PaymentMethodDisplayUpdateViewModel(options) {
    var _paymentMethodTypeTem;

    var self = this,
        messageBoxName = options.messageBoxName,
        MessageBoxType = Kobo.Controls.MessageBox.MessageBoxType,
        resourceStrings = window.DynamicConfiguration.paymentMethodsDisplay.resourceStrings,
        ViewModels = Kobo.PaymentMethodsDisplay.ViewModels,
        PaymentMethodType = Kobo.PurchasePath.Enums.PaymentMethodType;
    var paymentMethodTypeTemplateMap = (_paymentMethodTypeTem = {}, _defineProperty(_paymentMethodTypeTem, PaymentMethodType.BraintreeCreditCard, {
      template: "payment-methods-display-braintree-credit-card-inline-template",
      viewModel: ViewModels.BraintreeCreditCardDisplayItemVM
    }), _defineProperty(_paymentMethodTypeTem, PaymentMethodType.BraintreePayPal, {
      template: "payment-methods-display-braintree-paypal-template",
      viewModel: ViewModels.BraintreePayPalDisplayItemVM
    }), _defineProperty(_paymentMethodTypeTem, PaymentMethodType.SepaStripe, {
      template: "payment-methods-display-sepa-bank-account-template",
      viewModel: ViewModels.SepaBankAccountDisplayItemVM
    }), _paymentMethodTypeTem);

    this.setSuccessMessageAfterRedirect = function () {
      if (!Cookies.get("SavePaymentMethodSuccess")) return;
      var savePaymentMethodSucceeded = Cookies.get("SavePaymentMethodSuccess").toLowerCase() === 'true';
      Cookies.remove('SavePaymentMethodSuccess');
      if (!savePaymentMethodSucceeded) return;

      Kobo._mediator.fire('kobo-message-box::clearAll', {
        recipientMessageBoxName: messageBoxName
      });

      var successMessage = {
        messageType: MessageBoxType.Success,
        headline: resourceStrings.savePaymentMethodSuccess,
        recipientMessageBoxName: messageBoxName,
        messageBoxTemplate: 'kobo-icon-close-message-template'
      }; // wait for message box to be set up before sending the success message

      return new Promise(function (resolve) {
        setTimeout(function addSuccessMessage() {
          if (Kobo._mediator && Kobo._events['kobo-message-box::add'] && Kobo._events['kobo-message-box::add'] === 'registered') {
            Kobo._mediator.fire('kobo-message-box::add', {
              message: successMessage
            });

            return resolve();
          }

          setTimeout(addSuccessMessage, 100);
        }, 0);
      });
    };

    this.getPaymentMethodDisplayTemplate = function (paymentMethod) {
      var paymentMethodType = paymentMethod.paymentMethodType();
      var template = paymentMethodTypeTemplateMap[paymentMethodType].template;
      if (!template) throw new Error("Missing template mapping for payment method of type ".concat(paymentMethodType, "."));
      return template;
    };

    function buildPaymentMethodViewModels(paymentMethods) {
      return paymentMethods.map(function (paymentMethod) {
        var viewModel = paymentMethodTypeTemplateMap[paymentMethod.PaymentMethodType].viewModel;
        if (!viewModel) throw new Error("Missing view model mapping for payment method of type ".concat(paymentMethod.paymentMethodType, "."));
        return new viewModel(paymentMethod);
      });
    }

    this.init = function () {
      var paymentMethodVMs = buildPaymentMethodViewModels(options.paymentMethods);
      self.setSuccessMessageAfterRedirect();
      self.paymentMethods = ko.observableArray(paymentMethodVMs);
    };

    this.init();
  }
})();