"use strict";

/*global Kobo,document*/

/**
  Replaces existing select elements with custom div controls

  Usage:
  <div class="kobo-gizmo" data-kobo-gizmo="DropDownSelect">
    <select aria-busy="true" name="some-dropdown">
      <option>Apples</option>
      <option>Oranges</option>
    </select>
  </div>

  Width of the entire control can be set through CSS on the container div.
  The button width can be made smaller/larger as described below.

  Multiple <select> can also be enclosed.
  <div class="kobo-gizmo" data-kobo-gizmo="DropDownSelect">
    <select name="some-dropdown">
      <option>Apples</option>
      <option>Oranges</option>
    </select>
    <select name="another-dropdown">
      <option>Bananas</option>
      <option>Pineapples</option>
    </select>
    <select name="yet-another-dropdown">
      <option>Strawberries</option>
      <option>Kumquats</option>
    </select>
  </div>
 */
Kobo.Gizmo.DropDownSelect = function (gizmo, options) {
  'use strict';

  var self = this,
      init,
      isValid,
      onSelectValueChanged,
      appendDropDownArrow,
      addSingleOptionBehaviour,
      BUTTON_CLASS_NAME = 'drop-down-select'; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  this.setType('DropDownSelect');
  self.settings = Kobo.extend({
    hideDropdownWhenSingleOption: false
  }, options);

  init = function init() {
    var $selectElements,
        $gizmo = Kobo.$(gizmo),
        dropDownOptions = {};

    if ($gizmo.is('select')) {
      $selectElements = $gizmo;
    } else {
      $selectElements = $gizmo.find('select');
    }

    if (!isValid($selectElements)) {
      return;
    }

    self.register('dropDownSelectChange');
    self.register('invalidate'); // TODO register in a framework JS file

    self.subscribe('invalidate', function () {
      self.onInvalidate($selectElements);
    }); // In case JS is disabled, having 'kobo-input-select' class on the container div
    // of a <select> ensures the <select> still gets some basic styling.
    // Here, we remove this class (if present) because nicer styles will be applied

    $gizmo.removeClass('kobo-input-select');
    dropDownOptions.customClass = BUTTON_CLASS_NAME;
    self.generateDropDown($selectElements, dropDownOptions);
    $selectElements.change(onSelectValueChanged);
    appendDropDownArrow();
    addSingleOptionBehaviour($selectElements); //setting aria-busy to false once dropdown is generated and all options are populated

    $selectElements.attr('aria-busy', false);
  };

  isValid = function isValid($selectElements) {
    var allSelectHaveNames = true;
    $selectElements.each(function () {
      if (!this.hasAttribute('name')) {
        allSelectHaveNames = false;
        return false;
      }
    });
    return allSelectHaveNames;
  };

  onSelectValueChanged = function onSelectValueChanged() {
    // 'this' is the <select> associated with the event
    // Given a selected <option value="option1">Bananas</option>
    // The event properties are { value: "option1", label: "Bananas" }
    // The label does not refer to the HTML label attribute.
    self.fire('dropDownSelectChange', {
      selectName: this.getAttribute('name'),
      value: this.value,
      label: this.selectedIndex >= 0 ? this.options[this.selectedIndex].innerHTML : ''
    });
  };

  appendDropDownArrow = function appendDropDownArrow() {
    Kobo.$('.' + BUTTON_CLASS_NAME).each(function () {
      var dropDownArrow = document.createElement('span'),
          container = document.createElement('span'); // check if arrow has been added before

      if (this.querySelector('.drop-down-arrow-container')) {
        return true;
      }

      container.className = 'drop-down-arrow-container';
      container.appendChild(dropDownArrow);
      dropDownArrow.className = 'drop-down-arrow';
      this.appendChild(container);
    });
  };

  addSingleOptionBehaviour = function addSingleOptionBehaviour($selectElements) {
    $selectElements.each(function () {
      this.invalidateSingleOption = self.settings.hideDropdownWhenSingleOption ? function () {
        var $opt1 = $(this).find('option[value!=""]'),
            $arrowContainer = $(this).next().find('.drop-down-arrow-container');

        if ($opt1.length === 1) {
          $(this).val($opt1.val());
          $(this).prop('disabled', true);
          $arrowContainer.hide();
        } else {
          $(this).prop('disabled', false);
          $arrowContainer.show();
        }
      } : function () {};
    });
  };

  init();
};

Kobo.Gizmo.DropDownSelect.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype); // code interfacing with the external plugin goes here

Kobo.Gizmo.DropDownSelect.prototype.generateDropDown = function ($selectElements, dropDownOptions) {
  'use strict';

  $selectElements.customSelect(dropDownOptions);
  $selectElements.each(function () {
    this.repaint = this.onChanged; // map function for repainting the custom dropdown
  }); // customSelect.js sets the width of certain elements
  // Undo that because the elements should be responsive

  $selectElements.css({
    height: '',
    width: '',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  });
  $selectElements.parent().find('.' + dropDownOptions.customClass + 'Inner').css('width', '');
};
/*
 * Redraws the custom drop down
 * Useful when the select element is changed programmatically through JS, and the UI needs to be
 * updated. (DOM change events are not fired when values are set programatically)
 */


Kobo.Gizmo.DropDownSelect.prototype.onInvalidate = function ($selectElements) {
  'use strict';

  $selectElements.each(function () {
    this.invalidateSingleOption();
    this.repaint();
  });
};