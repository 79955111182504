"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,$,ko,Cookies*/
Kobo.Gizmo.EGiftingCustomization = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("EGiftingCustomization");
  var self = this,
      setupHandlers,
      $emailTemplates = self.$gizmo.find('.email-template'),
      $collapsibleSection = self.$gizmo.find('#egifting-collapsible-section'),
      $collapseButton = self.$gizmo.find('.toggle-button-hitbox'),
      $form = self.$gizmo.find("#" + self.settings.formId),
      $buyNowButton = self.$gizmo.find("#" + self.settings.buyNowButtonId),
      $updateButton = self.$gizmo.find("#" + self.settings.updateButtonId),
      submitForm,
      bindModel,
      toggleCollapsibleBasedOnScreenSize,
      addSubmitFormBehaviour,
      watchForElementWithId,
      egcVM,
      isCaptchaEnabled = self.settings.isCaptchaEnabled,
      MessageBoxType = Kobo.Controls.MessageBox.MessageBoxType,
      FailureReason = Kobo.EGiftingCustomization.FailureReason;

  setupHandlers = function setupHandlers() {
    $emailTemplates.on('click', function () {
      var selectedTemplate = $(this);
      $emailTemplates.removeClass("selected");
      selectedTemplate.addClass("selected");
      selectedTemplate.find("input").attr("checked", "checked");
    });

    if ($buyNowButton.length === 0 && $updateButton.length !== 1) {
      // buyNowButton is not present on the page -- likely coming from remote application
      // need to wait for the element to be added to the page so the click handler can be bound
      watchForElementWithId(self.settings.buyNowButtonId, document.getElementById('gifting-customization-container-mfe-container'), addSubmitFormBehaviour);
    } else {
      addSubmitFormBehaviour($buyNowButton);
    }

    addSubmitFormBehaviour($updateButton);
    self.events.onResize(toggleCollapsibleBasedOnScreenSize);
  };

  addSubmitFormBehaviour = function addSubmitFormBehaviour(elem) {
    elem.on('click', function (event) {
      event.preventDefault();
      submitForm($(this), $form);
    });
  };

  watchForElementWithId = function watchForElementWithId(id, parentElement, callback) {
    parentElement = parentElement || document;
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(function (node) {
            if (!node) return;
            var foundElement = node.id === id ? node // current node is the one we're looking for
            : node.querySelector("#".concat(id)); // current node is a parent of the one we're looking for

            if (foundElement) {
              callback($(foundElement));
              observer.disconnect();
            }
          });
        }
      });
    });
    observer.observe(parentElement, {
      childList: true,
      subtree: true
    });
  };

  submitForm = function submitForm(button, form) {
    Kobo._mediator.fire('kobo-message-box::clearAll', {
      recipientMessageBoxName: 'egifting-customization'
    });

    if (!egcVM.canSubmit()) {
      setErrorForFailureReason(FailureReason.InvalidInput);
      return;
    }

    switchButtonToLoadingState(button);
    return executeHCaptchaAsync().then(function (_ref) {
      var captchaToken = _ref.response;
      if (captchaToken) form.append("<input type=\"hidden\" name=\"captchaToken\" value=\"".concat(captchaToken, "\" />"));
      form.submit();
    })["catch"](function (error) {
      console.error(error);
      setErrorForFailureReason(FailureReason.InvalidCaptcha);
      switchButtonToLoadedState(button);
    });
  };

  function executeHCaptchaAsync() {
    return new Promise(function (resolve, reject) {
      if (!isCaptchaEnabled) return resolve({
        response: null
      });
      var data = {
        containerId: 'egifting-customization-captcha',
        successCallback: resolve,
        failureCallback: reject
      };

      Kobo._mediator.fire('hcaptcha::executeAsync', data);
    });
  }

  function switchButtonToLoadingState(button) {
    // handles mfe button loading state
    var giftingLoadingEvent = new CustomEvent('gifting::loading');
    window.dispatchEvent(giftingLoadingEvent); // handles non-mfe button loading state

    button.attr("disabled", true);
    button.find(".button-content").addClass("loading");
  }

  function switchButtonToLoadedState(button) {
    // handles mfe button loaded state
    var giftingLoadedEvent = new CustomEvent('gifting::loaded');
    window.dispatchEvent(giftingLoadedEvent); // handles non-mfe button loaded state

    button.attr("disabled", false);
    button.find(".button-content").removeClass("loading");
  }

  bindModel = function bindModel() {
    var props = {
      amount: self.settings.amount,
      email: self.settings.email,
      confirmEmail: self.settings.confirmEmail,
      recipientName: self.settings.recipientName,
      senderName: self.settings.senderName,
      messageText: self.settings.messageText,
      delivery: self.settings.delivery,
      rules: self.settings.rules
    };
    egcVM = new Kobo.EGiftingCustomization.EGiftingCustomizationVM(props);
    ko.validation.init({
      errorElementClass: 'has-error',
      decorateInputElement: true
    }, true);
    ko.applyBindings(egcVM, self.el);
  }; // Collapsible gizmo is set to isOpen for large and jumbo screens
  // The following is solely to get hide the collapsible button and associated border-styling


  toggleCollapsibleBasedOnScreenSize = function toggleCollapsibleBasedOnScreenSize() {
    var mediumScreenWidth = 880;

    if (window.innerWidth > mediumScreenWidth) {
      $collapsibleSection.css("border-bottom-width", "0");
      $collapseButton.css("display", "none");
    } else {
      $collapsibleSection.removeAttr("style");
      $collapseButton.removeAttr("style");
    }
  };

  function setErrorForFailureReason(failureReason) {
    var mapToMessageBoxErrorContent = FailureReason.Extensions.mapToMessageBoxErrorContent;

    Kobo._mediator.fire('kobo-message-box::clearAll', {
      recipientMessageBoxName: 'egifting-customization'
    });

    var errorMessage = _objectSpread(_objectSpread({
      messageType: MessageBoxType.Error
    }, mapToMessageBoxErrorContent(failureReason)), {}, {
      recipientMessageBoxName: 'egifting-customization',
      messageBoxTemplate: 'kobo-icon-message-template'
    });

    Kobo._mediator.fire('kobo-message-box::add', {
      message: errorMessage
    });
  }

  function setErrorForFailureReasonAfterRedirect() {
    if (!self.settings.failureReason) return;
    var failureReason = FailureReason.parseFromServer(self.settings.failureReason);
    if (!failureReason) return;
    window.addEventListener('load', function () {
      setErrorForFailureReason(failureReason);
    });
  }

  self.init = function () {
    bindModel();
    setupHandlers();
    toggleCollapsibleBasedOnScreenSize();
    setErrorForFailureReasonAfterRedirect();
  };

  self.init();
};

Kobo.Gizmo.EGiftingCustomization.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);