"use strict";

/*global Kobo,window,console,alert,ko */
Kobo.Gizmo.BuyPad = function (gizmo) {
  'use strict';

  var self = this,
      addFreeBookToLibrary,
      addRemovedBookToLibrary,
      addToLibraryCallback,
      addingFreeBook = false,
      addingRemovedBook = false,
      addToLibrarySelector = gizmo.addToLibrarySelector || '.add-to-library',
      undoRemoveFromLibrarySelector = gizmo.undoRemoveFromLibrarySelector || '.undo-remove',
      $loveNoteToggle,
      $preOrderMessageToggle,
      init; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  self.setType("BuyPad");
  self.isInLibrary = ko.observable(Kobo.Object.parseBool(self.settings.isInLibrary));
  self.isPreordered = ko.observable(Kobo.Object.parseBool(self.settings.isPreordered));
  self.isRemoved = ko.observable(Kobo.Object.parseBool(self.settings.isRemoved));

  addFreeBookToLibrary = function addFreeBookToLibrary(event) {
    event.preventDefault();

    if (!addingFreeBook) {
      addingFreeBook = true;
      self.fire('library::add', {
        actionUrl: self.settings.addToLibraryUrl,
        completeCallback: addToLibraryCallback
      });
    }
  };

  addRemovedBookToLibrary = function addRemovedBookToLibrary(event) {
    event.preventDefault();

    if (!addingRemovedBook) {
      addingRemovedBook = true;
      self.fire('library::addRemoved', {
        actionUrl: self.settings.undoRemoveFromLibraryUrl,
        completeCallback: addToLibraryCallback
      });
    }
  };

  addToLibraryCallback = function addToLibraryCallback(result) {
    if (result && result.result === 'success') {
      self.isRemoved(false);
      self.isInLibrary(true);
    }
  };

  self.bindCollapsibleToggle = function ($element, collapsibleName) {
    if ($element.length === 1) {
      $element.on('click', function (event) {
        var $toggleButton = Kobo.$(event.target),
            $toggleButtonAriaExpanded = Kobo.$(event.target).attr('aria-expanded');

        if ($toggleButtonAriaExpanded === 'true') {
          $toggleButton.attr('aria-expanded', false);
        } else {
          $toggleButton.attr('aria-expanded', true);
        }

        $toggleButton.toggleClass('open');

        Kobo._mediator.fire('collapsible::toggle', {
          name: collapsibleName
        });
      });
    }
  };

  self.loveToggleHandler = function (e) {
    Kobo.$(e.target).toggleClass('open');

    Kobo._mediator.fire('collapsible::toggle', {
      name: 'KoboLovePointsExtra'
    });
  };

  self.redeemWithPoints = function () {
    Kobo._mediator.fire('koboLove::redeemStart', {
      productId: self.settings.itemId
    });
  };

  self.destroy = function () {
    if ($loveNoteToggle.length) {
      $loveNoteToggle.off('click');
    }

    if ($preOrderMessageToggle.length) {
      $preOrderMessageToggle.off('click');
    }
  };

  init = function init() {
    $loveNoteToggle = self.$el.find('.toggle-love-message');
    $preOrderMessageToggle = Kobo.$('.toggle-price-guarantee');
    self.$el.find('.redeem-button').on('click', self.redeemWithPoints);
    self.$el.find(addToLibrarySelector).on('click', addFreeBookToLibrary);
    self.$el.find(undoRemoveFromLibrarySelector).on('click', addRemovedBookToLibrary);
    self.bindCollapsibleToggle($loveNoteToggle, 'KoboLovePointsExtra');
    self.bindCollapsibleToggle($preOrderMessageToggle, 'PreorderPriceGuarantee');
    ko.applyBindings(self, gizmo);
  };

  init();
};

Kobo.Gizmo.BuyPad.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);