"use strict";

/*global Kobo,ko,$*/
Kobo.Gizmo.ModalButtonTrigger = function (gizmo, options) {
  // eslint-disable-line no-unused-vars 
  "use strict";

  var self = this;
  Kobo.Gizmo.apply(this, arguments);
  self.modalTrigger = self.$gizmo.find('.modal-trigger');
  self.modalClose = self.$gizmo.find('.modal-close');
  self.modalContent = self.$gizmo.find('.pottermore-content');

  self.init = function () {
    self.modalTrigger.on("click", function () {
      // remove the displat none css
      self.modalContent.removeClass("hidden");

      Kobo._modal.open(self.modalContent.get(0), {
        customClass: "pottermore"
      });
    });
    self.modalClose.on("click", function () {
      // add the display none css
      self.modalContent.addClass("hidden");

      Kobo._modal.close();
    });
  };

  self.init();
};

Kobo.Gizmo.ModalButtonTrigger.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);