"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.KoboLoveSummary = function (element, options) {
  "use strict";

  var self = this,
      onUpdateDetails,
      onUpdateState,
      setupHandlers,
      hidePointBalanceSummaryElements,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("KoboLoveSummary");
  /*jslint unparam: true*/

  onUpdateDetails = function onUpdateDetails(eventName, data) {
    self.amountSaved(data.detail.amountSaved);
    self.pointsEarned(data.detail.pointsEarned);
    self.basePointsEarned(data.detail.basePointsEarned);
    self.showPointsEarnedBreakdown(data.detail.showPointsEarnedBreakdown);
    self.bonusPointsEarned(data.detail.bonusPointsEarned);
    self.pointsBalance(data.detail.pointsBalance);
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  onUpdateState = function onUpdateState(eventName, data) {
    self.initialShowDiscountState = data.detail.showDiscount;
    self.showLove(data.detail.showKoboLove);
    self.showDiscount(data.detail.showDiscount);
    self.showPointsEarned(data.detail.showPointsEarned);
    self.showPointsEarnedBreakdown(data.detail.showPointsEarnedBreakdown);
    self.showPointsEarnedMessage(data.detail.showPointsEarned);
    self.showPointsBalance(data.detail.showPointsBalance);
  };
  /*jslint unparam: false*/

  /*jslint unparam: true*/


  hidePointBalanceSummaryElements = function hidePointBalanceSummaryElements(event, data) {
    var vipInCart, onlyVipInCart, vipMessage;

    if (data.detail && data.detail.vipMembershipInCart) {
      vipInCart = data.detail.vipMembershipInCart;
      onlyVipInCart = data.detail.onlyVipInCart;
      vipMessage = data.detail.vipMessage;

      if (vipInCart) {
        self.vipMessage(vipMessage);
      }

      if (onlyVipInCart) {
        self.showDiscount(false);
        self.showPointsEarnedMessage(false);
        self.vipMessage("");
      }
    } else {
      self.showDiscount(self.initialShowDiscountState);
      self.vipMessage("");
    }
  };
  /*jslint unparam: false*/


  setupHandlers = function setupHandlers() {
    self.register('koboLove::updateDetails');
    self.register('koboLove::updateState');
    self.subscribe('koboLove::updateDetails', onUpdateDetails);
    self.subscribe('koboLove::updateState', onUpdateState);
    self.subscribe('shoppingCartItems::changedKoboLoveMembership', hidePointBalanceSummaryElements);
  };

  self.destroy = function () {
    self.unSubscribe('koboLove::updateDetails', onUpdateDetails);
    self.unSubscribe('koboLove::updateState', onUpdateState);
    self.unSubscribe('shoppingCartItems::changedKoboLoveMembership', hidePointBalanceSummaryElements);
  };

  init = function init() {
    self.amountSaved = ko.observable("");
    self.pointsEarned = ko.observable("");
    self.showPointsEarnedBreakdown = ko.observable(false);
    self.basePointsEarned = ko.observable("");
    self.bonusPointsEarned = ko.observable("");
    self.pointsBalance = ko.observable("");
    self.bonusPointsDealName = ko.observable("");
    self.showLove = ko.observable(options.showKoboLove === "True");
    self.showDiscount = ko.observable(options.showKoboLoveDiscounts === "True");
    self.showPointsEarned = ko.observable(options.showKoboLovePoints === "True");
    self.showPointsEarnedMessage = ko.observable(options.showKoboLovePoints === "True"); // Added as separate observable to uncouple the message from the line item. only used when KL marketing item is sole item in cart.

    self.vipMessage = ko.observable("");
    self.showPointsBalance = ko.observable(options.showKoboLovePointsBalance === "True");
    setupHandlers();
    ko.applyBindings(self, element);
  };

  init();
};

Kobo.Gizmo.KoboLoveSummary.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);