"use strict";

/*global Kobo,$,ko,Modernizr*/
Kobo.Gizmo.MagazinePrintedSubscription = function (gizmo, options) {
  'use strict';

  var self = this,
      init; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  self.setType('MagazinePrintedSubscription');
  self.settings = Kobo.extend({}, options);
  self.GENERIC_SUCCESS_MESSAGE = DynamicConfiguration.resourceStrings.SubscriptionNumberSubmitted;
  self.GENERIC_ERROR_MESSAGE = DynamicConfiguration.resourceStrings.GenericErrorMessage;
  self.SYSTEM_ERROR_RESULT = 'Error';
  self.SUBSCRIPTION_CODE_ERROR_RESULT = 'Failure';
  self.SUCCESS_RESULT = 'Success';
  self.$inputSubscriptionNumber = self.$gizmo.find('.printed-subscription-number-input');
  self.$submitButton = self.$gizmo.find('.printed-subscription-input-button');
  self.$messageLarge = self.$gizmo.find('.printed-subscription-message-large');
  self.$messageSmall = self.$gizmo.find('.printed-subscription-message-small');

  self.submitPrintedSubscriptionNumber = function () {
    var subscriptionNumber = self.$inputSubscriptionNumber ? self.$inputSubscriptionNumber.val() : '',
        publicationId = self.settings.publicationId;
    var response = Kobo.Ajax({
      url: '/Magazine/AuthorizePrintSubscription?publicationId=' + publicationId + '&subscriptionNumber=' + subscriptionNumber,
      success: function success(results) {
        self.handleResponse(results);
      },
      fail: function fail(jqxhr, textStatus, error) {
        var err = textStatus + ', ' + error;
        self.showSystemErrorMessage(err);
      }
    });
  };

  self.handleResponse = function (response) {
    if (!response || !response.Result) {
      self.showSystemErrorMessage(self.GENERIC_ERROR_MESSAGE);
    } else {
      switch (response.Result.toString()) {
        case self.SUCCESS_RESULT:
          self.showInfoMessage(response.Message);
          break;

        case self.SUBSCRIPTION_CODE_ERROR_RESULT:
          self.showErrorMessage(response.Message);
          break;

        case self.SYSTEM_ERROR_RESULT:
          self.showSystemErrorMessage(response.Message);
          break;

        default:
          self.showSystemErrorMessage(self.GENERIC_ERROR_MESSAGE);
          break;
      }
    }
  };

  self.hideMessages = function () {
    self.$messageLarge.addClass('hide-printed-subscription-message');
    self.$messageSmall.addClass('hide-printed-subscription-message');
    self.$inputSubscriptionNumber.removeClass('printed-subscription-number-input-error');
    self.adjustContentHeight();
  };

  self.showErrorMessage = function (message) {
    message = message && message != '' ? message : self.GENERIC_ERROR_MESSAGE;
    self.$messageLarge.text(message);
    self.$messageLarge.removeClass('hide-printed-subscription-message');
    self.$messageLarge.addClass('error-message-left-arrow');
    self.$messageSmall.text(message);
    self.$messageSmall.removeClass('hide-printed-subscription-message');
    self.$messageSmall.addClass('error-message-top-arrow');
    self.$inputSubscriptionNumber.addClass('printed-subscription-number-input-error');
    self.adjustContentHeight();
  };

  self.showSystemErrorMessage = function (message) {
    message = message && message != '' ? message : self.GENERIC_ERROR_MESSAGE;
    self.showErrorMessage(message);
    self.$inputSubscriptionNumber.val('');
  };

  self.showInfoMessage = function (message) {
    message = message && message != '' ? message : self.GENERIC_SUCCESS_MESSAGE;
    self.$messageLarge.text(message);
    self.$messageLarge.removeClass('hide-printed-subscription-message');
    self.$messageLarge.removeClass('error-message-left-arrow');
    self.$messageLarge.addClass('info-message-left-arrow');
    self.$messageSmall.text(message);
    self.$messageSmall.removeClass('hide-printed-subscription-message');
    self.$messageSmall.removeClass('error-message-top-arrow');
    self.$messageSmall.addClass('info-message-top-arrow');
    self.$inputSubscriptionNumber.removeClass('printed-subscription-number-input-error');
    self.adjustContentHeight();
  };

  self.adjustContentHeight = function () {
    // for the moment only resize for small view
    var screenSize = Kobo._mediator.determineBreakpoint();

    if (screenSize === "small" || screenSize === "medium") {
      var innerMasker = self.$gizmo.find('.kobo-widget.magazineitem-printed-subscription.kobo-gizmo.gizmo-expandable .gizmo-expandable-inner-masker');
      var newHeight = self.$gizmo.find('.gizmo-expandable-inner-content').height();
      innerMasker.css('height', newHeight);
    }
  };

  self.disableSubmitAndCleanIfEmpty = function () {
    if (self.$inputSubscriptionNumber && self.$inputSubscriptionNumber.val() != '') {
      self.$submitButton.prop('disabled', false);
    } else {
      self.$submitButton.attr('disabled', true);
      self.hideMessages();
    }
  };

  init = function init() {
    self.disableSubmitAndCleanIfEmpty();
    self.$submitButton.on('click', self.submitPrintedSubscriptionNumber);
    self.$inputSubscriptionNumber.on('input', self.disableSubmitAndCleanIfEmpty);
    self.$inputSubscriptionNumber.on("keypress", function (e) {
      if (e.which == 13) {
        self.submitPrintedSubscriptionNumber();
        e.preventDefault();
      }
    });
  };

  init();
};

Kobo.Gizmo.MagazinePrintedSubscription.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);