"use strict";

Kobo.ViewModel.Expandable.FilterListButton = function (gizmo, options) {
  Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
  var self = this; // move facet header outside of expandable area

  self.$header = gizmo.$innerContent.find('> .facet-list-header');
  gizmo.$gizmo.prepend(self.$header);
  gizmo.events.onResize(function (event) {
    // register to the expandableResized event for all the sub expandables???
    if (event.currentSize === "large" && self.isCollapsed()) {
      self.toggle();
    }
    /*if (event.currentSize !== "small") {
        if (self.isCollapsed()) {
            self.toggle();
        }
    }*/

  });
};

Kobo.ViewModel.Expandable.FilterListButton.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

Kobo.ViewModel.Expandable.FilterListButton.prototype.addButton = function () {
  var self = this,
      subButton,
      subButtonWrapper;
  Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
  this.$button.addClass('gizmo-expandable-button-sort');
  subButtonWrapper = document.createElement("div");
  subButtonWrapper.setAttribute('class', 'gizmo-expandable-sort-subbutton-wrapper');
  this.subButtonWrapper = Kobo.$(subButtonWrapper);
  this.$button.append(this.subButtonWrapper);
  subButton = document.createElement("div");
  subButton.setAttribute('class', 'gizmo-expandable-sort-subbutton');
  this.$subButton = Kobo.$(subButton);
  this.subButtonWrapper.append(this.$subButton);
  self.$header.append(this.$button);
};