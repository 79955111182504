"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo, braintree, ko, resources, DynamicConfiguration*/

/* eslint-disable no-unused-vars */
Kobo._mediator.register('braintreeCard::validate');

Kobo._mediator.register('braintreeCard::validated');

Kobo._mediator.register('braintreeCard::refreshIframe');

Kobo._mediator.register('braintreeCard::triggerBraintreeLocalPaymentMethods');

Kobo._mediator.register('braintreeCard::startThreeDSecure');

Kobo._mediator.register('braintreeCard::reGenerateNonce');

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.Purchasing.BraintreePayments', BraintreePayments);

  function BraintreePayments(config, injectedBraintree) {
    var self = this,
        braintree,
        braintreeiFrameSubmitEvent,
        deviceData,
        localPaymentInstance,
        fullNameElId = config.isOldPP ? '#FullName' : '#card_username',
        iconContainer = $('#card-image'),
        localPaymentId,
        isDoNotCaptureCreditCardHolderNameEnabled = config.isDoNotCaptureCreditCardHolderNameEnabled,
        cardHolderfullName = "... ...";
    this.saveRequestEvent = new ko.subscribable();
    this.isValidEvent = new ko.subscribable();
    this.collectDeviceDataEvent = new ko.subscribable();
    this.braintreeClientReadyEvent = new ko.subscribable();
    this.submitFormEvent = new ko.subscribable();
    this.teardownFormEvent = new ko.subscribable();
    braintree = injectedBraintree || window.braintree;

    Kobo._mediator.subscribe('braintreeCard::validate', _onValidate);

    Kobo._mediator.subscribe('braintreeCard::reGenerateNonce', function (event, data) {
      var options = {
        cardholderName: isDoNotCaptureCreditCardHolderNameEnabled === false ? document.querySelector(fullNameElId).value : cardHolderfullName
      };
      self.submitFormEvent.notifySubscribers({
        options: options,
        callback: data.detail.onTokenizedPayloadReGenerated
      });
    });

    if (config.triggerBraintreeLocalPayment) {
      Kobo._mediator.subscribe('braintreeCard::triggerBraintreeLocalPaymentMethods', createLocalPaymentClickListener);
    }

    function _onValidate(event, deferred) {
      // this is a bad name -- not actually what this is doing
      var cardholderName = isDoNotCaptureCreditCardHolderNameEnabled === false ? document.querySelector(fullNameElId).value : cardHolderfullName;
      var options = {
        cardholderName: cardholderName
      };
      self.submitFormEvent.notifySubscribers({
        options: options,
        callback: _onTokenizedPayload
      });

      function _onTokenizedPayload(err, payload) {
        if (err) {
          console.error(err);
          return;
        }

        payload.cardholderName = cardholderName;
        var isMock = config.key.indexOf('sandbox_') !== -1;
        var payloadObj = config.isOldPP ? {
          creditCardToken: isMock ? payload.details.bin + '-' + payload.details.lastFour + '-' + payload.details.cardType + '-' + payload.details.expirationMonth + '-' + payload.details.expirationYear : payload.nonce,
          lastFourCardDigits: payload.details.lastFour,
          expiryMonth: payload.details.expirationMonth,
          expiryYear: payload.details.expirationYear,
          isSuccess: true
        } : {
          payload: payload,
          deferred: deferred
        };

        Kobo._mediator.fire('braintreeCard::validated', payloadObj);
      }
    }

    Kobo._mediator.subscribe('braintreeCard::validated', function (event, data) {
      var payloadObj = config.isOldPP ? {
        details: {
          isSuccess: data.detail.isSuccess,
          creditCardToken: data.detail.creditCardToken,
          lastFourCardDigits: data.detail.lastFourCardDigits
        },
        nonce: data.detail.creditCardToken
      } : {
        details: data.detail.payload.details,
        cardholderName: data.detail.payload.cardholderName,
        nonce: data.detail.payload.nonce,
        deferred: data.detail.deferred.detail
      };
      braintreeiFrameSubmitEvent(payloadObj);
    });

    Kobo._mediator.subscribe('braintreeCard::refreshIframe', function () {
      self.teardownFormEvent.notifySubscribers();
    });

    braintree.client.create({
      authorization: config.key
    }, function (err, clientInstance) {
      if (err) {
        console.error(err);
        return;
      }

      braintree.threeDSecure.create({
        version: 2,
        client: clientInstance
      }, function (threeDSecureErr, threeDSecureInstance) {
        if (threeDSecureErr) {
          console.error(threeDSecureErr);
          return;
        }

        var setupConfig = {
          clientInstance: threeDSecureInstance,
          readyCallback: _onThreeDSecureClientInstanceReady
        };

        Kobo._mediator.fire('threeDSecure::setupClientInstance', setupConfig);
      });

      if (config.createFields) {
        createHostedFields(clientInstance);
      }

      if (config.collectDeviceData) {
        braintree.dataCollector.create({
          client: clientInstance,
          kount: true
        }, function (err, dataCollectorInstance) {
          if (err) {
            console.error(err);
            return;
          }

          deviceData = dataCollectorInstance.deviceData;
          self.collectDeviceDataEvent.notifySubscribers(deviceData);
        });
      }

      if (config.triggerBraintreeLocalPayment && config.merchantAccountId) {
        // Create a local payment component.
        braintree.localPayment.create({
          client: clientInstance,
          merchantAccountId: config.merchantAccountId
        }, function (localPaymentErr, paymentInstance) {
          // Stop if there was a problem creating local payment component.
          // This could happen if there was a network error or if it's incorrectly
          // configured.
          if (localPaymentErr) {
            Kobo._mediator.fire('PurchasePath::showErrorMessageForPurchasePath', {
              message: localPaymentErr
            });

            return;
          }

          localPaymentInstance = paymentInstance;
        });
      }
    });

    function _onThreeDSecureClientInstanceReady(data) {
      self.braintreeClientReadyEvent.notifySubscribers(data);
    }

    function createLocalPaymentClickListener(event, options) {
      event.preventDefault();
      localPaymentInstance.startPayment({
        paymentType: options.detail.type,
        amount: options.detail.amount,
        fallback: {
          // We do not use the fallback for now however we have to keep this for initiation of local payment instance. 
          url: DynamicConfiguration.resourceStrings.braintreeLocalPaymentMethodsFallbackUrl,
          buttonText: DynamicConfiguration.resourceStrings.braintreeLocalPaymentMethodsFallbackButtonText
        },
        currencyCode: options.detail.currencyCode,
        address: {
          countryCode: options.detail.countryCode
        },
        onPaymentStart: function onPaymentStart(data, start) {
          // NOTE: It is critical here to store data.paymentId on your server
          //       so it can be mapped to a webhook sent by Braintree once the
          //       buyer completes their payment. See Start the payment
          //       section for details.
          localPaymentId = data.paymentId;
          var request = {
            localPaymentId: data.paymentId
          };
          Kobo.$.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: options.detail.urls.storeLocalPaymentIdUrl,
            data: JSON.stringify(request),
            error: function error(_error) {
              Kobo._mediator.fire('PurchasePath::showErrorMessageForPurchasePath', {
                message: _error.errorMsg
              });
            }
          });
          sendLocalPaymentMethodTrackingMessage(Kobo.PurchasePath.Enums.AlternativePaymentMethod.Status.Started, options.detail.type);
          start();
        }
      }, function (startPaymentError, payload) {
        if (startPaymentError) {
          sendLocalPaymentMethodTrackingMessage(Kobo.PurchasePath.Enums.AlternativePaymentMethod.Status.Failure, options.detail.type);

          if (startPaymentError.code === 'LOCAL_PAYMENT_POPUP_CLOSED') {
            console.error(DynamicConfiguration.resourceStrings.CustomerClosedLocalPaymentPopup);
          } else {
            console.error('Error!', startPaymentError);
          }
        } else {
          // Send the nonce to server to create a transaction
          var request = {
            local_payment: {
              payment_id: localPaymentId,
              payment_method_nonce: payload.nonce
            }
          };
          Kobo.$.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: options.detail.urls.localPaymentCompleteUrl,
            data: JSON.stringify(request),
            complete: function complete(data) {
              Kobo.Utilities.navigateTo(data.responseJSON.RedirectUrl);
            }
          });
          sendLocalPaymentMethodTrackingMessage(Kobo.PurchasePath.Enums.AlternativePaymentMethod.Status.Success, options.detail.type);
        }
      });
    }

    function sendLocalPaymentMethodTrackingMessage(status, paymentType) {
      var trackingData = {
        type: Kobo.PurchasePath.Enums.AlternativePaymentMethod.Name,
        trackables: {
          status: status,
          paymentType: paymentType
        }
      };

      Kobo._mediator.fire('webEventTracker::sendTrackingMessage', trackingData);
    }

    braintreeiFrameSubmitEvent = function braintreeiFrameSubmitEvent(payload) {
      var req = config.isOldPP ? {
        details: payload.details,
        paymentNonce: payload.nonce
      } : {
        details: payload.details,
        cardholderName: payload.cardholderName,
        paymentNonce: payload.nonce,
        deferred: payload.deferred
      };
      self.saveRequestEvent.notifySubscribers(req);
    };

    function createHostedFields(clientInstance) {
      var _fields;

      braintree.hostedFields.create({
        client: clientInstance,
        styles: config.isOldPP ? Kobo.PurchasePath.BraintreeHostedFieldStyles.oldPPStyles : Kobo.PurchasePath.BraintreeHostedFieldStyles.newPPStyles,
        fields: (_fields = {}, _defineProperty(_fields, Kobo.PurchasePath.Enums.BraintreeHostedFields.CreditCard.Number, {
          selector: '#ccNum',
          placeholder: config.isOldPP ? resources.getString('CardNumber') : ''
        }), _defineProperty(_fields, Kobo.PurchasePath.Enums.BraintreeHostedFields.CreditCard.Cvv, {
          selector: '#ccCVV',
          placeholder: config.isOldPP ? resources.getString('CvvToken') : ''
        }), _defineProperty(_fields, Kobo.PurchasePath.Enums.BraintreeHostedFields.CreditCard.ExpirationMonth, {
          selector: '#expiry-date-month',
          select: {
            options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          },
          placeholder: 'MM',
          internalLabel: DynamicConfiguration.resourceStrings.expiryDateMonth
        }), _defineProperty(_fields, Kobo.PurchasePath.Enums.BraintreeHostedFields.CreditCard.ExpirationYear, {
          selector: '#expiry-date-year',
          select: true,
          placeholder: 'YYYY',
          internalLabel: DynamicConfiguration.resourceStrings.expiryDateYear
        }), _fields)
      }, function (createErr, hostedFieldsInstance) {
        if (createErr) {
          console.error(createErr);
          return;
        }

        var hostedFieldsOptions = {
          hostedFieldsInstance: hostedFieldsInstance,
          isValidEvent: self.isValidEvent,
          submitFormEvent: self.submitFormEvent,
          teardownFormEvent: self.teardownFormEvent,
          cardType: config.cardType,
          iconContainer: iconContainer
        };
        new Kobo.PurchasePath.Behaviors.Braintree.HandleHostedFieldEventsBehavior(hostedFieldsOptions);
      });
    }
  }
})();