"use strict";

/*globals Kobo,ko,DynamicConfiguration,Cookies */
Kobo.Gizmo.ItemPromoOfferBanner = function (el, options) {
  'use strict';

  var self = this,
      init,
      viewModel,
      isBannerDismissed,
      getCookie,
      setCookie,
      setupHandlers,
      showItemPromotionAddedToCartNotification;
  Kobo.Gizmo.apply(self, arguments);
  this.setType('ItemPromoOfferBanner');

  getCookie = function getCookie(cookieName) {
    var cookie;

    if (cookieName) {
      cookie = Cookies.get(cookieName);
    }

    return cookie ? JSON.parse(cookie) : null;
  };

  setCookie = function setCookie(cookieName) {
    var data = {
      isDismissed: true
    };
    var cookieOptions = {
      expires: 365,
      path: '/',
      raw: true
    };
    Cookies.set(cookieName, JSON.stringify(data), cookieOptions);
  };

  isBannerDismissed = function isBannerDismissed(cookieName) {
    var setting = getCookie(cookieName);
    return !!setting && setting.isDismissed;
  };

  showItemPromotionAddedToCartNotification = function showItemPromotionAddedToCartNotification(eventName, data) {
    if (data.detail.itemPromotionAvailable && data.detail.showItemAddedToCartBanner) {
      viewModel.showNewCustomerCreditAppliedBanner(data.detail.showCreditAppliedBanner);
      viewModel.showNewCustomerCreditAvailableBanner(!data.detail.showCreditAppliedBanner);
      setTimeout(viewModel.hideItemPromotionAddedToCartNotification, 5000);
    }
  };

  setupHandlers = function setupHandlers() {
    self.register('ItemPromoOfferBanner::showNewCustomerCreditNotification');
    self.subscribe('ItemPromoOfferBanner::showNewCustomerCreditNotification', showItemPromotionAddedToCartNotification);
  };

  viewModel = new Kobo.ViewModel.ItemPromoOfferBannerVM({
    cookieName: options.cookieName,
    isBannerDismissed: options.isBannerDismissed,
    setCookie: setCookie
  });

  init = function init() {
    var bannerDismissed = isBannerDismissed(options.cookieName);
    viewModel.isDismissed(bannerDismissed);
    setupHandlers();
  };

  ko.applyBindings(viewModel, self.el);
  init();
};

Kobo.Gizmo.ItemPromoOfferBanner.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);