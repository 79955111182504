"use strict";

/*global Kobo,ko,$,console*/
if (!Kobo.ViewModel.Navigation) {
  Kobo.ViewModel.Navigation = {};
}

Kobo.ViewModel.Navigation.Paging = {};

Kobo.ViewModel.Navigation.Paging.Base = function (element, options, gizmo) {
  "use strict";

  var self = this,
      init;
  this.setDefaults({
    noInit: false,
    pageSize: 6,
    totalNumberOfItems: 0,
    useCircularScroll: true
  });
  Kobo.ViewModel.Base.apply(self, [element, options]);
  self.setType("Navigation.Paging.Base");
  self.scrollPositionIndex = ko.observable(0).extend({
    notify: 'always'
  });
  self.isActivated = ko.observable(false);
  self.pageSize = ko.observable(self.settings.pageSize);
  self.totalNumberOfItems = ko.observable(self.settings.totalNumberOfItems);
  self.isCircular = self.settings.useCircularScroll;
  self.numberOfAvailPages = ko.observable();
  self.numberOfAvailPages.subscribe(function () {
    self.updatePageObservables();
  });
  self.prevButton = ko.observable();
  self.nextButton = ko.observable();
  self.numberOfPages = ko.computed(function () {
    return Math.ceil(self.totalNumberOfItems() / self.pageSize());
  });

  init = function init() {
    ko.applyBindings(this, this.el);
    this.updatePageObservables('initial');
  };

  if (!self.existsNextPage) {
    self.existsNextPage = ko.observable(false);
  }

  if (!self.existsPreviousPage) {
    self.existsPreviousPage = ko.observable(false);
  }

  if (!options.noInit) {
    init.call(this);
  }
};

Kobo.ViewModel.Navigation.Paging.Base.prototype = Kobo.chainPrototype(Kobo.ViewModel.Base.prototype);
Kobo.ViewModel.Navigation.Paging.Base.prototype.navObject = {};

Kobo.ViewModel.Navigation.Paging.Dynamic = function (element, options, gizmo) {
  "use strict";

  var self = this,
      init;
  options = options || {};
  options.noInit = true;
  Kobo.ViewModel.Navigation.Paging.Base.apply(this, arguments); // isCircular is not an observable but if we need to switch it to then combine the following declarations of
  // existsNextPage to one having if statement inside the ko.computed.

  if (this.isCircular) {
    this.existsNextPage = ko.computed(function () {
      return this.pageSize() < this.totalNumberOfItems();
    }, this);
  } else {
    this.existsNextPage = ko.computed(function () {
      return this.scrollPositionIndex() + 1 + this.pageSize() < this.totalNumberOfItems();
    }, this);
  } // Index is corrected when either new items were added to the beginning of the list or some items were removed
  // from the beginning. List view model tells Nav about this.


  this.scrollPositionIndexCorrected = ko.observable(this.scrollPositionIndex());
  this.navObject = {
    read: {
      scrollPositionIndex: this.scrollPositionIndex,
      isActivated: this.isActivated
    },
    write: {
      scrollPositionIndexCorrected: this.scrollPositionIndexCorrected,
      pageSize: this.pageSize,
      totalNumberOfItems: this.totalNumberOfItems
    }
  };

  init = function init() {
    ko.applyBindings(this, this.el);
    this.updatePageObservables('initial');
  };

  init.call(this);
};

Kobo.ViewModel.Navigation.Paging.Dynamic.prototype = Kobo.chainPrototype(Kobo.ViewModel.Navigation.Paging.Base.prototype);

Kobo.ViewModel.Navigation.Paging.Dynamic.prototype.showNext = function () {
  "use strict";

  if (!this.isActivated()) {
    this.isActivated(true);
  }

  if (this.existsNextPage()) {
    this.goByPage(1);
  }
};

Kobo.ViewModel.Navigation.Paging.Dynamic.prototype.goByPage = function (pageAmount) {
  "use strict";

  var scrollPositionIndex = this.scrollPositionIndex(),
      scrollPositionIndexCorrected = this.scrollPositionIndexCorrected(),
      newScrollPositionIndex = scrollPositionIndexCorrected + pageAmount * this.pageSize(); // decrease index if there is not enough items for the page:

  if (!this.isCircular && newScrollPositionIndex + this.pageSize() - 1 > this.totalNumberOfItems()) {
    newScrollPositionIndex = this.totalNumberOfItems() - this.pageSize();
  } // must be positive:


  if (newScrollPositionIndex < 0) {
    newScrollPositionIndex = 0;
  }

  this.scrollPositionIndexCorrected(newScrollPositionIndex);
  this.scrollPositionIndex(newScrollPositionIndex);
  this.updatePageObservables();
};

Kobo.ViewModel.Navigation.Paging.Dynamic.prototype.showPrevious = function () {
  "use strict";

  if (!this.isActivated()) {
    this.isActivated(true);
  }

  if (this.existsPreviousPage()) {
    this.goByPage(-1);
  }
};

Kobo.ViewModel.Navigation.Paging.Dynamic.prototype.updatePageObservables = function (initial) {
  "use strict";

  if (this.isCircular) {
    this.existsPreviousPage(!initial && this.pageSize() < this.totalNumberOfItems());
  } else {
    this.existsPreviousPage(this.scrollPositionIndex() !== 0);
  }
};

Kobo.ViewModel.Navigation.Paging.Static = function (element, options, gizmo) {
  "use strict";

  this.currentPage = ko.observable(1);
  this.currentPage.subscribe(function () {
    Kobo._gizmo.fire("pageChanged");
  });
  Kobo.ViewModel.Navigation.Paging.Base.apply(this, arguments);
  this.navObject = {
    read: {
      scrollPositionIndex: this.scrollPositionIndex,
      isActivated: this.isActivated
    },
    write: {
      pageSize: this.pageSize,
      numberOfAvailPages: this.numberOfAvailPages
    }
  };
  this.currentPage.subscribe(function (newValue) {
    var newScrollPositionIndex = (newValue - 1) * this.pageSize();

    if (newScrollPositionIndex < 0) {
      newScrollPositionIndex = 0;
    }

    this.scrollPositionIndex(newScrollPositionIndex);
    this.updatePageObservables();
  }, this);
  this.pageSize.subscribe(function (newValue) {
    if (newValue > 0) {
      var newCurrentPage = Math.round((this.scrollPositionIndex() - 1) / newValue + 1);
      this.currentPage(newCurrentPage);
    }
  }, this);
};

Kobo.ViewModel.Navigation.Paging.Static.prototype = Kobo.chainPrototype(Kobo.ViewModel.Navigation.Paging.Base.prototype);

Kobo.ViewModel.Navigation.Paging.Static.prototype.updatePageObservables = function () {
  "use strict";

  this.existsPreviousPage(this.currentPage() > 1);
  this.existsNextPage(this.currentPage() < this.numberOfAvailPages());
};

Kobo.ViewModel.Navigation.Paging.Static.prototype.showNext = function () {
  "use strict";

  if (!this.isActivated()) {
    this.isActivated(true);
  }

  var currentPage = this.currentPage(),
      newPage = currentPage + 1;

  if ((this.existsNextPage() || this.isCircular) && this.numberOfAvailPages() > 1) {
    this.currentPage(newPage);
  }
};

Kobo.ViewModel.Navigation.Paging.Static.prototype.showPrevious = function () {
  "use strict";

  if (!this.isActivated()) {
    this.isActivated(true);
  }

  if ((this.existsPreviousPage() || this.isCircular) && this.numberOfAvailPages() > 1) {
    var currentPage = this.currentPage();
    this.currentPage(currentPage - 1);
  }
};