"use strict";

/*global Kobo*/
(function (Kobo) {
  function ExpandablePlusButtonVM(gizmo, options) {
    Kobo.ViewModel.Expandable.BaseButton.apply(this, arguments);
    this.currentSize = gizmo.currentBreakpoint();
    this.gizmo = gizmo; // bind event handlers

    this.handleResize = this.handleResize.bind(this);
    this.handleGizmosInitialized = this.handleGizmosInitialized.bind(this); // init

    this.registerEvents();
  }

  ExpandablePlusButtonVM.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

  ExpandablePlusButtonVM.prototype.registerEvents = function () {
    this.gizmo.events.onResize(this.handleResize);
    this.gizmo.subscribe('gizmosInitialized', this.handleGizmosInitialized);
  };

  ExpandablePlusButtonVM.prototype.addButton = function () {
    Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(this);
    this.$button.addClass('gizmo-expandable-button-plus');
  };

  ExpandablePlusButtonVM.prototype.setButtonX = function () {};

  ExpandablePlusButtonVM.prototype.handleResize = function (event) {
    if (this.currentSize !== event.currentSize) {
      if (event.currentSize === "small" && !this.isCollapsed()) {
        this.toggle(); //self.$button.show();
      }

      if (event.currentSize !== "small") {
        if (this.isCollapsed()) {
          this.toggle();
        } //self.$button.hide();

      }
    }

    this.currentSize = event.currentSize;
  };

  ExpandablePlusButtonVM.prototype.handleGizmosInitialized = function () {
    this.gizmo.viewModel.registerExpandableResizedEvent();
    this.gizmo.subscribe("expandableResized", this.recalculateHeight);
    this.gizmo.fire("expandableResized");
  }; // Exports


  Kobo.ViewModel.Expandable.PlusButton = ExpandablePlusButtonVM;
})(Kobo);