"use strict";

/*global Kobo,window,DynamicConfiguration*/
Kobo.Gizmo.VatNumber = function (gizmo, options) {
  'use strict';

  var self = this,
      hideSection,
      showSection,
      hideError,
      showError,
      updateErrorStatus,
      updateVatNumberStatus,
      onRegularCheckoutMarshallOrderData,
      setupHandlers,
      init; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  self.setType('VatNumber');

  hideSection = function hideSection() {
    Kobo.$(gizmo).addClass('hide');
  };

  showSection = function showSection() {
    Kobo.$(gizmo).removeClass('hide');
  };

  hideError = function hideError() {
    if (self.errorElem === undefined) {
      return;
    }

    self.errorElem.addClass('hide');
  };

  showError = function showError(errorMessage) {
    if (self.errorElem === undefined) {
      return;
    }

    if (errorMessage === '' || typeof errorMessage !== 'string') {
      hideError();
    } else {
      self.errorElem.html(errorMessage);
      self.errorElem.removeClass('hide');
    }
  };

  updateErrorStatus = function updateErrorStatus(eventName, data) {
    if (data !== undefined && data.detail !== undefined && data.detail.errorMessage != undefined) {
      showError(data.detail.errorMessage);
    } else {
      hideError();
    }
  };

  updateVatNumberStatus = function updateVatNumberStatus(eventName, data) {
    // The returned value (data) will tell us whether to display the section or not.
    // It will also tell us whether an error occurred.
    if (data !== undefined && data.detail !== undefined && data.detail.addressFields !== undefined) {
      var vatNumberField = null;
      Kobo.$.each(data.detail.addressFields, function (key, value) {
        if (value.Name == 'VatNumber') {
          vatNumberField = value;
          return false;
        }
      });

      if (vatNumberField !== null) {
        if (!vatNumberField.Visible) {
          hideSection();
        } else {
          // if user has it's vat number saved then load it.
          if (vatNumberField.Value !== '' && typeof vatNumberField.Value === 'string') {
            self.inputElem.val(vatNumberField.Value);
          } // update the placeholder value


          if (vatNumberField.Label !== '' && typeof vatNumberField.Label === 'string') {
            self.inputElem.attr('placeholder', vatNumberField.Label);
          } // show error message if an error occurred.


          if (vatNumberField.Error !== undefined && vatNumberField.Error !== null) {
            showError(vatNumberField.Error);
          } else {
            hideError();
          }

          showSection();
        }
      }
    }
  };

  onRegularCheckoutMarshallOrderData = function onRegularCheckoutMarshallOrderData(eventName, data) {
    var orderData = data.detail.orderData;
    orderData.VatNumber = self.inputElem.val();
  };

  setupHandlers = function setupHandlers() {
    self.register('vatNumber::updateError');
    self.subscribe('vatNumber::updateError', updateErrorStatus);
    self.subscribe('billingInfo::update', updateVatNumberStatus);
    self.subscribe('regularCheckout::marshallOrderData', onRegularCheckoutMarshallOrderData);
  };

  self.destroy = function () {
    self.unSubscribe('vatNumber::updateError', updateErrorStatus);
    self.unSubscribe('billingInfo::update', updateVatNumberStatus);
    self.unSubscribe('regularCheckout::marshallOrderData', onRegularCheckoutMarshallOrderData);
  };

  init = function init() {
    self.inputElem = Kobo.$('.' + options.inputSelector);
    self.errorElem = Kobo.$(gizmo).find('.' + options.errorSelector);
    setupHandlers();
  };

  init();
};

Kobo.Gizmo.VatNumber.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);