"use strict";

/*globals Kobo*/
(function (Kobo) {
  function GizmoRichHeaderNewUserOptions(gizmo, options) {
    'use strict';

    Kobo.Gizmo.apply(this, arguments);
  }

  ;
  GizmoRichHeaderNewUserOptions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
  Kobo.Gizmo.RichHeaderNewUserOptions = GizmoRichHeaderNewUserOptions;
})(Kobo);