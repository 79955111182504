"use strict";

(function () {
  if (document.body.classList.contains('Store-HomePage') && document.cookie.indexOf("platformid=00000000-0000-0000-0000-ffffffff0000") > -1) {
    var eventSkinnyAfterLoaded = new CustomEvent("kwp_skinny_after_state_loaded", {
      detail: {},
      bubbles: true,
      cancelable: true
    }); // Fire Window 'kwp_multiSkinny_loaded' Event.

    window.dispatchEvent(eventSkinnyAfterLoaded);
  }
})();