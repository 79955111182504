"use strict";

/*globals Kobo,Collapsible*/
Kobo.Gizmo.Dismissable = function () {
  'use strict';

  var self = this,
      init,
      dismiss,
      collapsible;
  Kobo.Gizmo.apply(self, arguments);

  dismiss = function dismiss() {
    collapsible.close();
  };

  self.destroy = function () {
    collapsible = null;
    self.$el.find('.dismiss').off('click', dismiss);
  };

  init = function init() {
    collapsible = new Collapsible(self.$el);
    self.$el.find('.dismiss').on('click', dismiss);
  };

  init();
};

Kobo.Gizmo.Dismissable.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);