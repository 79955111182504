"use strict";

/*global Kobo,ko,Modernizr*/

/**
 * Shows Ratuken SuperPoints options to appropriate users 
 */
Kobo.Gizmo.RakutenSuperPoints = function (el, options) {
  "use strict";

  var self = this,
      init,
      superpointsCashMessage,
      superpointsToRedeemMessage,
      clearAppliedRakutenSuperpoints,
      rakutenSuperPointsMessage,
      cannotClearPointsMessage,
      cannotUsePointsMessage,
      notEnoughPointsMessage,
      belowMinimumMessage,
      genericInvalidSuperpointsMessage,
      superpointsInvalidNumberErrorMessage,
      amountAlreadyCoveredMessage,
      onSuperpointsFocus,
      modalOptions = {
    theme: Kobo._modal.themes.LIGHT
  };
  Kobo.Gizmo.apply(this, arguments);

  self.applyPoints = function () {
    self.promoCodeReset(false);
    var toApply = self.pointsToApply();

    if (toApply != parseInt(toApply)) {
      updateError(superpointsInvalidNumberErrorMessage, true);
      return;
    }

    toApply = parseInt(toApply);

    if (toApply > parseInt(self.balanceCarriedOver())) {
      updateError(notEnoughPointsMessage, true);
      return;
    }

    if (toApply < parseInt(self.minimumPoints())) {
      updateError(belowMinimumMessage.replace("{0}", self.minimumPoints()), true);
      return;
    }

    updateError('', false); // technical debt: use show spinner event here

    Kobo._modal.open("<div class ='ajax-spinner'></div>", modalOptions);

    Kobo.Ajax({
      url: "/Purchase/ApplyRakutenSuperPoints",
      method: "POST",
      dataType: "json",
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: {
        superAppliedToPurchase: toApply,
        PurchaseToken: self.purchaseToken
      },
      success: function success(data) {
        if (data.IsSuccess) {
          updatePriceSummarySection(data.PriceSummary);
          updateSuperpointsAppliedValues(data.RakutenSuperPointsApplied, data.RakutenSuperPointsApplied, true);
          updateSufficientPayment(data.SufficientAlternativePayment);
          self.fire('rakutenSuperPoints::apply', {
            appliedSuperPoints: data.RakutenSuperPointsApplied
          });

          if (data.ErrorMessage) {
            updateError(data.ErrorMessage, true);
          }
        } else {
          updateError(data.ErrorMessage, true);
        }

        Kobo._modal.close();

        self.fixExpandable();
      },
      error: function error() {
        Kobo._modal.close();

        updateError(cannotUsePointsMessage, true);
      }
    });
  };

  self.clearAppliedPoints = function (s, e) {
    updateError('', false);

    if (!self.pointsHaveBeenApplied()) {
      self.fire('paymentOptions::validated', {
        isSuccess: true
      });
      return;
    } // technical debt: use show spinner event here


    Kobo._modal.open("<div class ='ajax-spinner'></div>", modalOptions);

    Kobo.Ajax({
      url: "/Purchase/ClearRakutenSuperPoints",
      method: "POST",
      dataType: "json",
      headers: {
        '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
      },
      data: {
        PurchaseToken: self.purchaseToken
      },
      success: function success(data) {
        if (data.IsSuccess) {
          updatePriceSummarySection(data.PriceSummary);
          updateSuperpointsAppliedValues(0, 0, false);
          updateSufficientPayment(data.SufficientAlternativePayment);
          self.fixExpandable();
          self.fire('rakutenSuperPoints::apply', {
            appliedSuperPoints: 0
          });
        } else {
          updateError(data.ErrorMessage, true);
        }

        Kobo._modal.close();
      },
      error: function error() {
        Kobo._modal.close();

        updateError(cannotClearPointsMessage, true);
      },
      complete: function complete() {
        self.fire('paymentOptions::validated', {
          isSuccess: true
        });
      }
    });
  };

  clearAppliedRakutenSuperpoints = function clearAppliedRakutenSuperpoints() {
    self.clearAppliedPoints();
  };

  self.toggleAboutPanel = function () {
    self.showAboutPanel(!self.showAboutPanel());
    self.fixExpandable();
  };

  self.toggleSuperpointsNeeded = function (s, data) {
    // sufficientPayment is true only when the price of the
    // product is covered by some payment method.
    // If superpoints have been applied then we still consider
    // them as "needed" (i.e. we don't disable the panel)
    if (self.pointsHaveBeenApplied()) {
      self.superpointsNeeded(true);
    } else {
      self.superpointsNeeded(!data.detail.sufficientPayment);

      if (!self.superpointsNeeded()) {
        updateError(amountAlreadyCoveredMessage, false);
      }
    }
  };

  var onToggleSuperPointsPanel = function onToggleSuperPointsPanel() {
    if (self.superPointsExpandable && self.superPointsExpandable.viewModel && self.superPointsExpandable.viewModel.isCollapsed()) {
      self.superPointsExpandable.viewModel.toggle(true);
    }
  };

  var onPromoCodeApplied = function onPromoCodeApplied(s, data) {
    if (!data.detail.errorMessage && self.superpointsNeeded && self.pointsHaveBeenApplied()) {
      self.promoCodeReset(true);
      onToggleSuperPointsPanel();
    } else {
      self.promoCodeReset(false);
    }
  };

  var updatePriceSummarySection = function updatePriceSummarySection(newPriceSummaryValue) {
    self.fire("priceSummary::update", {
      lineItems: newPriceSummaryValue
    });
  };

  var updateSufficientPayment = function updateSufficientPayment(newSufficientPaymentValue) {
    if (Kobo.Object.typeOf(newSufficientPaymentValue) === "boolean") {
      self.fire('regularCheckout::updateSufficientPayment', {
        sufficientPayment: newSufficientPaymentValue
      });
    }
  };

  var updateSuperpointsBalanceValues = function updateSuperpointsBalanceValues(s, data) {
    self.balanceCarriedOver(data.detail.pointsBalance);
    self.balanceTimeSensitive(data.detail.pointsTimeSensitive);
    self.balanceCash(data.detail.pointsCash);
    self.minimumPoints(data.detail.minimumRedeemable || 50);

    if (data.detail.pointsRedeemed == 0) {
      updateSuperpointsAppliedValues(0, 0, false);
    } else {
      updateSuperpointsAppliedValues(data.detail.pointsRedeemed, data.detail.pointsRedeemed, true);
    }
  };

  var updateSuperpointsAppliedValues = function updateSuperpointsAppliedValues() {
    if (arguments.length == 3) {
      updateSuperpointsAppliedValues.fromDirectValues.apply(this, arguments);
    }

    if (arguments.length == 2) {
      updateSuperpointsAppliedValues.fromReturnedObject.apply(this, arguments);
    }
  };

  updateSuperpointsAppliedValues.fromReturnedObject = function (s, data) {
    updateSuperpointsAppliedValues.fromDirectValues(data.detail.RakutenSuperPointsApplied, data.detail.RakutenSuperPointsApplied, typeof data.detail.RakutenSuperPointsApplied !== 'undefined' && data.detail.RakutenSuperPointsApplied != 0);
  };

  updateSuperpointsAppliedValues.fromDirectValues = function (toApply, applied, haveBeenApplied) {
    if (typeof toApply === 'undefined') {
      toApply = 0;
    }

    if (typeof applied === 'undefined') {
      applied = 0;
    }

    self.pointsToApply(toApply);
    self.pointsApplied(applied);
    self.pointsHaveBeenApplied(haveBeenApplied);
  };

  var updateError = function updateError(errorMessage, errorState) {
    self.error(errorMessage);
    self.errorState(errorState);
    self.fixExpandable();
  };

  var setupSuperPointsExpandable = function setupSuperPointsExpandable() {
    Kobo.$.each(self.descendants, function (index, current) {
      if (this.type.indexOf('Class.Gizmo.Expandable') === 0) {
        self.superPointsExpandable = this;
        registerSuperPointsExpandableResizedEvent(current);
      }
    });
  };

  var registerSuperPointsExpandableResizedEvent = function registerSuperPointsExpandableResizedEvent(current) {
    if (current.viewModel) {
      current.viewModel.registerExpandableResizedEvent();
      current.subscribe('expandableResized', function () {
        if (!current.viewModel.isCollapsed()) {
          current.viewModel.toggle(true);
        }
      });
    }
  };

  self.fixExpandable = function () {
    self.fire("expandableResized");
  };

  self.setupResourceStrings = function () {
    var dynamicConfiguration = window.DynamicConfiguration;

    if (!dynamicConfiguration) {
      return;
    }

    superpointsCashMessage = dynamicConfiguration.resourceStrings.superpointsCash;
    superpointsToRedeemMessage = dynamicConfiguration.resourceStrings.superpointsToRedeem;
    rakutenSuperPointsMessage = dynamicConfiguration.resourceStrings.rakutenSuperPoints;
    cannotClearPointsMessage = dynamicConfiguration.resourceStrings.cannotClearPoints;
    cannotUsePointsMessage = dynamicConfiguration.resourceStrings.cannotUsePoints;
    notEnoughPointsMessage = dynamicConfiguration.resourceStrings.notEnoughPoints;
    belowMinimumMessage = dynamicConfiguration.resourceStrings.belowMinimum;
    genericInvalidSuperpointsMessage = dynamicConfiguration.resourceStrings.genericInvalidSuperpoints;
    superpointsInvalidNumberErrorMessage = dynamicConfiguration.resourceStrings.superpointsInvalidNumberError;
    amountAlreadyCoveredMessage = dynamicConfiguration.resourceStrings.amountAlreadyCovered;
  };

  onSuperpointsFocus = function onSuperpointsFocus() {
    // Part of fix for DE21134
    // This can be removed when the final fix lands in the android app
    var pos = Kobo.$(this).offset().top - 100;
    Kobo.$('html, body').animate({
      scrollTop: pos
    }, 500);
  };

  self.setupHandlers = function () {
    // technical debt: use variable for $("#superpointsToRedeem")
    self.register('rakutenSuperPoints::toggleOpen');
    self.subscribe("gizmosInitialized", setupSuperPointsExpandable);
    self.subscribe("regularCheckout::loadRakutenSuperpoints", updateSuperpointsBalanceValues);
    self.subscribe('regularCheckout::updateAppliedRakutenSuperpoints', updateSuperpointsAppliedValues);
    self.subscribe('regularCheckout::updateRakutenSuperpointsNeededState', self.toggleSuperpointsNeeded);
    self.subscribe('promoCode::update', onPromoCodeApplied);
    self.subscribe("regularCheckout::clearAppliedRakutenSuperpoints", clearAppliedRakutenSuperpoints);
    self.subscribe('rakutenSuperPoints::toggleOpen', onToggleSuperPointsPanel);
    $("#superpointsToRedeem").keypress(function (event) {
      if (event.keyCode == 13) {
        self.applyPoints();
        self.fixExpandable();
        return false;
      } else {
        self.promoCodeReset(false);
      }
    }); // remove error panel if user clears field

    $("#superpointsToRedeem").change(function () {
      if ($('#superpointsToRedeem').length > 0 && $('#superpointsToRedeem')[0].value.length < 1) {
        if (self.errorState()) {
          self.errorState(false);
          self.fixExpandable();
        }

        if (self.promoCodeReset()) {
          self.promoCodeReset(false);
          self.fixExpandable();
        }
      }
    });

    if (Modernizr.touchevents) {
      $("#superpointsToRedeem").on('focus', onSuperpointsFocus);
    }
  };

  self.destroy = function () {
    self.unSubscribe("regularCheckout::loadRakutenSuperpoints", updateSuperpointsBalanceValues);
    self.unSubscribe('regularCheckout::updateAppliedRakutenSuperpoints', updateSuperpointsAppliedValues);
    self.unSubscribe('regularCheckout::updateRakutenSuperpointsNeededState', self.toggleSuperpointsNeeded);
    self.unSubscribe('rakutenSuperPoints::toggleOpen', onToggleSuperPointsPanel);
    self.unSubscribe("regularCheckout::clearAppliedRakutenSuperpoints", clearAppliedRakutenSuperpoints);
    self.unSubscribe("gizmosInitialized", setupSuperPointsExpandable);
  };

  init = function init() {
    self.setupResourceStrings();
    self.balanceCarriedOver = ko.observable(0);
    self.balanceTimeSensitive = ko.observable(0);
    self.balanceCash = ko.observable(0);
    self.pointsToBeEarned = ko.observable(0);
    self.pointsToApply = ko.observable(0);
    self.pointsApplied = ko.observable(0);
    self.pointsHaveBeenApplied = ko.observable(false);
    self.errorState = ko.observable(false);
    self.error = ko.observable("");
    self.superpointsNeeded = ko.observable(true);
    self.promoCodeReset = ko.observable(false);
    self.showPanel = ko.observable(typeof options.isFeatureTurnedOn !== 'undefined' && options.isFeatureTurnedOn.toLowerCase() == 'true');
    self.showAboutPanel = ko.observable(false);
    self.minimumPoints = ko.observable(0);
    self.superPointsToRedeem = ko.computed(function () {
      return superpointsToRedeemMessage.replace("{0}", self.minimumPoints());
    }, this);
    self.purchaseToken = options.purchaseToken;
    self.setupHandlers();
    $('.gizmo-superpoints').removeClass('hidden');
    setTimeout(function () {
      self.fixExpandable();
    }, 2500);
  };

  init();
  ko.bindingHandlers.fadeVisible = {
    init: function init(element) {
      $(element).toggle(self.showAboutPanel());
    },
    update: function update(element) {
      self.showAboutPanel() ? $(element).fadeIn() : $(element).fadeOut();
    }
  };
  ko.applyBindings(self, el);
};

Kobo.Gizmo.RakutenSuperPoints.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);