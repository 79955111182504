"use strict";

/*global Kobo, $, ko, DynamicConfiguration*/
(function () {
  "use strict";

  function ThankYouKoboPlusSubscriptionVM(options) {
    var self = this;
    self.appLinks = options.appLinks;
    self.productRedeemed = ko.observable(false);
  }

  Kobo.Utilities.assignToNamespace('Kobo.Purchasing.ViewModels.ThankYouKoboPlusSubscriptionVM', ThankYouKoboPlusSubscriptionVM);
})();