"use strict";

/*global Kobo*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RAT.RatTagHelper', ratTagHelper);

  function ratTagHelper() {
    this.checkoutHelper = new Kobo.RAT.CheckoutHelper();
    this.pageHelper = new Kobo.RAT.PageHelper();

    this.itemScript = function (action, options) {
      var cartType = action;
      var cartStatus = 'success';
      if (action === 'add') cartType = this.checkoutHelper.getShoppingCartType();

      if (action === 'already') {
        cartType = this.checkoutHelper.getShoppingCartType();
        cartStatus = 'already';
      }

      return "<script>" + "(function () {" + "if (window.RAT && typeof RAT.addCustomEvent == 'function') {" + "var eventData = { };" + "eventData.accountId = 1055;" + "eventData.serviceId = 1;" + "eventData.eventType = 'click';" + "eventData.pData = {" + "'pgt': 'cart_modify'," + "'pgn': '" + this.pageHelper.getPageName() + "'," + "'country': '" + options.storeFront + "'," + "'cycode': '" + options.currency + "'," + "'lang': '" + options.language + "'," + "'itemid': '" + this.checkoutHelper.getShoppingCartProductIds() + "'," + "'price': '" + this.checkoutHelper.getShoppingCartPrices() + "'," + "'ni': '" + this.checkoutHelper.getShoppingCartQtyArray() + "'" + "};" + "eventData.cpData = {" + "'cart_type': '" + cartType + "'," + "'cart_status': '" + cartStatus + "'" + "};" + "eventData.options = ['url', 'ua', 'ref'];" + "RAT.addCustomEvent(eventData);" + "}" + "})();" + "</script>";
    };

    this.itemDetailScript = function (options) {
      return "\n<script>\n    (function () {\n        if (window.RAT && typeof RAT.addCustomEvent == 'function') {\n            var eventData = {};\n            eventData.accountId = 1055;\n            eventData.serviceId = 1;\n            eventData.eventType = \"pv\";\n            eventData.pData = {\n                'pgt': 'other',\n                'pgn': '".concat(options.pageName, "',\n                'country': '").concat(options.storeFront, "',\n                'cycode': '").concat(options.currency, "',\n                'lang': '").concat(options.language, "',\n                'itemid': ['").concat(options.itemId, "'],\n            };\n            eventData.options = ['url', 'ua', 'ref'];\n            RAT.addCustomEvent(eventData);\n        }\n    })();\n</script>");
    };
  }
})();