"use strict";

/*globals Kobo,Cookies*/
Kobo.Gizmo.CountryStoreHeader = function (el, options) {
  'use strict';

  var self = this,
      init,
      existsBanner,
      createBanner,
      constrainContent,
      open,
      close,
      showBanner,
      $closeTrigger,
      parseOptions,
      $bannerDiv,
      $bannerContentDiv,
      stopPropagation,
      BANNER_ID = 'top-banner',
      BANNER_CONTENT_ID = 'top-banner-content';
  self.setDefaults({
    cookieDomain: 'kobo.com'
  });
  Kobo.Gizmo.apply(self, arguments);

  open = function open(contents, options) {
    createBanner();
    options = options || {};
    parseOptions(options);

    if (contents) {
      $bannerContentDiv.html(contents);
      showBanner();
    }
  };

  close = function close() {
    Kobo.$('#' + BANNER_ID).remove();
    Kobo.$body.removeClass(BANNER_ID);
    Cookies.set('persistent', Cookies.get('persistent') + '&' + 'countryStoreModal=0', {
      raw: true,
      path: '/',
      domain: self.settings.cookieDomain
    });
  };

  stopPropagation = function stopPropagation(event) {
    event.stopPropagation();
  };

  parseOptions = function parseOptions(options) {
    // Remove any previously set themes and/or custom classes
    $bannerDiv.removeClass();
    $bannerContentDiv.removeClass();

    if (options.isCancellable) {
      $bannerContentDiv.on('click', stopPropagation);
      $bannerDiv.on('click', Kobo._modal.close);
    } else {
      $bannerContentDiv.off('click', stopPropagation);
      $bannerDiv.off('click', Kobo._modal.close);
    }

    if (options.theme) {
      $bannerDiv.addClass(options.theme);
    }

    if (options.customClass) {
      $bannerContentDiv.addClass(options.customClass);
    }

    if (options.role) {
      $bannerContentDiv.attr('role', options.role);
    }
  };

  existsBanner = function existsBanner() {
    return $bannerDiv;
  };

  constrainContent = function constrainContent() {
    if ($bannerContentDiv.outerHeight(true) > Kobo.$window.height()) {
      $bannerContentDiv.css({
        verticalAlign: 'top'
      });
    } else {
      $bannerContentDiv.css({
        verticalAlign: ''
      });
    } // Set the width to auto so that any overflow
    // content will take up it's uncontrained width


    $bannerContentDiv.css('width', 'auto');
    var modalContentWidth = $bannerContentDiv.outerWidth(true); // If the unconstrained width is less than the window it means
    // there is no overflow, so we can reset back to the default css
    // otherwise we just leave as auto and allow the overflow css to
    // handle adding scrollbars

    if (modalContentWidth <= Kobo.$window.width()) {
      $bannerContentDiv.css('width', '');
    }
  };

  createBanner = function createBanner() {
    var clickTrackInfo = '';

    if (Kobo._tracker) {
      clickTrackInfo = Kobo._tracker.getTrackAttribute() + '=\'{ "section": "modal" }\'';
    }

    if (existsBanner()) {
      return;
    }

    $bannerDiv = Kobo.$('<div id="' + BANNER_ID + '" ' + clickTrackInfo + '></div>');
    $bannerContentDiv = Kobo.$('<div id="' + BANNER_CONTENT_ID + '" role="dialog"></div>');
    $bannerDiv.append($bannerContentDiv);
    Kobo.$(".rich-header-spacer").before($bannerDiv);
  };

  showBanner = function showBanner() {
    constrainContent();
    Kobo.$body.addClass(BANNER_ID);
  };

  init = function init() {
    var $countryStoreBannerTemplate = self.$el.children();

    if (options.slimHeader === true) {
      BANNER_ID += '-slim';
      BANNER_CONTENT_ID += '-slim';
    }

    $closeTrigger = self.$el.find('.close');
    $closeTrigger.on('click', close);
    open($countryStoreBannerTemplate, {
      customClass: 'country-store-top-banner-container'
    });
  };

  init();
};

Kobo.Gizmo.CountryStoreHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);