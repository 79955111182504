"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,$,console,window */
(function (Kobo) {
  function GizmoSearchBar(el, options) {
    "use strict"; // Inherits from base class

    Kobo.Gizmo.apply(this, arguments);
    this.setType("SearchBar");
    this.$el = Kobo.$(el);
    this.$primaryInputField = Kobo.$("#" + options.primaryInputId);
    this.$secondaryInputField = Kobo.$("#" + options.secondaryInputId);
    this.$inputField = this.$gizmo.find("input.search-field");
    this.$autoComplete = this.$gizmo.find("ul.search-autocomplete");
    this.$submitButton = this.$gizmo.find("input[type=submit]");
    this.$deleteIcon = this.$gizmo.find("button.deleteicon");
    this.$searchToggleButton = Kobo.$(".search-bar-toggle");
    this.$searchBarWrap = Kobo.$(".search-bar-wrap");
    this.currentScreenSize = Kobo._mediator.determineBreakpoint(); // Helper functions
    // clear field

    this.expandables = [];
    this.searchExpandable = null;
    this.$searchSection = this.$gizmo.find(".search-section");
    this.$form = this.$gizmo.find("form.search-form"); // Bind callback functions to this instance

    this.onClearInput = this.onClearInput.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onToggleSearchHero = this.onToggleSearchHero.bind(this);
    this.onGizmosInitialized = this.onGizmosInitialized.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onViewResized = this.onViewResized.bind(this);
    this.onSyncInputs = this.onSyncInputs.bind(this);
    this.onResizeHack = this.onResizeHack.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.loseFocus = this.loseFocus.bind(this);
    this.gainFocus = this.gainFocus.bind(this);
    this.triggerClearEvent = this.triggerClearEvent.bind(this);
    this.init();
    Kobo.$(window).on('resize', this.onResizeHack);
  }

  GizmoSearchBar.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype); // For some reason, on breakpoint switching, the events bound to the search input box are (spontaneously?) unbound.
  // To work around this, on resize, rebind the events using this.reset. Hopefully, fixing this properly will mean this
  // is short-term tech debt. - mmh, 28-Jan-2015

  GizmoSearchBar.prototype.onResizeHack = function () {
    var screenSize = Kobo._mediator.determineBreakpoint();

    if (this.currentScreenSize !== screenSize) {
      this.currentScreenSize = screenSize;

      Kobo._mediator.fire("searchInput::viewResized", {
        guid: this.guid
      });
    }
  };

  GizmoSearchBar.prototype.triggerClearEvent = function () {
    Kobo._mediator.fire("searchInput::clearInput", {
      guid: this.guid
    }); // This gizmo can appear more than once on the same page, so ensure we know which one is firing the event.

  };

  GizmoSearchBar.prototype.onClearInput = function (event, data) {
    this.$inputField.val(""); // We clear all search widget input fields when this is called, even if there is more than one.

    if (data.detail.guid === this.guid) {
      this.$inputField.focus(); // But we only focus on the active one.
    }
  };

  GizmoSearchBar.prototype.onToggle = function (event, data) {
    var isCollapsed = data.detail.isCollapsed;

    if (isCollapsed) {
      this.$searchToggleButton.addClass("is-collapsed");
    } else {
      this.$searchToggleButton.removeClass("is-collapsed");
      this.$searchSection.find("input.search-field").focus();
    }
  };

  GizmoSearchBar.prototype.disableSubmitButton = function () {
    if (this.isSubmitEnabled()) {
      this.$submitButton.prop("disabled", false);
      this.$deleteIcon.removeClass("clear-field-display-none");
    } else {
      this.$submitButton.attr("disabled", "disabled");
      this.$deleteIcon.addClass("clear-field-display-none");
    }
  }; // Test whether submit enabled


  GizmoSearchBar.prototype.isSubmitEnabled = function () {
    return this.$inputField.val() !== "";
  };

  GizmoSearchBar.prototype.loseFocus = function () {
    this.disableSubmitButton();
    this.$inputField.off("keyup", this.disableSubmitButton);

    Kobo._mediator.fire("searchInput::syncInputs", {
      guid: this.guid
    });
  };

  GizmoSearchBar.prototype.gainFocus = function () {
    this.disableSubmitButton();
    this.$inputField.on("keyup", this.disableSubmitButton); // Quick fix for bug in IPHONE with position fixed on focus of search field.

    this.$inputField.on("keydown", function (event) {
      if (event.keyCode === Kobo.KeyCodes.BACKSPACE) {
        Kobo.$(window).scrollTop();
      }
    });
  };

  GizmoSearchBar.prototype.setupTriggers = function () {
    this.$inputField.on("blur", this.loseFocus);
    this.$inputField.on("focus", this.gainFocus);
    this.$deleteIcon.on("click", this.triggerClearEvent);
  };

  GizmoSearchBar.prototype.clearTriggers = function () {
    this.$inputField.off("blur", this.loseFocus);
    this.$inputField.off("focus", this.gainFocus);
    this.$deleteIcon.off("click", this.triggerClearEvent);
  };

  GizmoSearchBar.prototype.onToggleSearchHero = function (eventName, data) {
    var toggle;

    if (data && data.detail && typeof data.detail.toggle !== "undefined") {
      toggle = data.detail.toggle;
    }

    if (this.searchExpandable.viewModel) {
      this.searchExpandable.viewModel.toggle(toggle);
    }
  };

  GizmoSearchBar.prototype.onGizmosInitialized = function () {
    var _this = this;

    var current;

    if (this.expandables.length === 0) {
      if (this.descendants.length > 0) {
        this.descendants.forEach(function (descendant) {
          current = descendant;

          if (current.type.indexOf("Class.Gizmo.Expandable") === 0) {
            _this.expandables.push(current);
          }
        });
        this.searchExpandable = this.expandables[0];
      } else {
        this.expandables = false;
      }
    }

    if ((this.currentScreenSize === "large" || this.currentScreenSize === "jumbo") && Kobo.$("body").hasClass("Store-HomePage") && this.searchExpandable && this.searchExpandable.viewModel && !this.searchExpandable.viewModel.isCollapsed()) {
      // US12837 autofocus on home page only (has to be done after expandables are initialized)
      this.$inputField.focus();
      this.$submitButton.css({
        "opacity": "1"
      });
    }

    if (this.searchExpandable && this.searchExpandable.viewModel) {
      this.fire("isSearchBarExpanded", {
        "isExpanded": this.searchExpandable.viewModel.isCollapsed()
      });
    }

    if (this.searchExpandable && this.searchExpandable.viewModel !== null && this.searchExpandable.viewModel.isCollapsed()) {
      this.$searchToggleButton.addClass("is-collapsed");
    } else {
      this.$searchToggleButton.removeClass("is-collapsed");
    }
  };

  GizmoSearchBar.prototype.onResize = function () {
    if (this.searchExpandable && this.searchExpandable.viewModel !== null && Kobo.typeOf(this.searchExpandable.viewModel) === "object") {
      this.searchExpandable.viewModel.recalculateHeight();
    }
  };

  GizmoSearchBar.prototype.onViewResized = function () {
    this.reset();
  }; // Set up event handlers


  GizmoSearchBar.prototype.setupHandlers = function () {
    Kobo._mediator.register("searchInput::clearInput");

    Kobo._mediator.subscribe("searchInput::clearInput", this.onClearInput);

    Kobo._mediator.register("searchBar::toggle");

    Kobo._mediator.subscribe("searchBar::toggle", this.onToggle);

    this.register("toggleSearchHero");
    this.subscribe("toggleSearchHero", this.onToggleSearchHero);
    this.subscribe("gizmosInitialized", this.onGizmosInitialized);
    this.events.onResize(this.onResize);
  };

  GizmoSearchBar.prototype.reset = function () {
    this.clearTriggers();
    this.setupTriggers();

    Kobo._mediator.fire("searchInput::syncInputs", {
      guid: this.guid,
      reset: true
    });
  };

  GizmoSearchBar.prototype.onSyncInputs = function (event, data) {
    // Last updated should be duplicated across both.
    // On resize from small to large, small duplicated
    // On resize from large to small, large duplicated
    // primaryInputId is only visible on large view
    if (data && data.detail && typeof data.detail.reset !== "undefined") {
      //Triggered by resize event
      if (data.detail.reset && data.detail.guid !== this.guid) {
        if (this.$primaryInputField.is(":visible")) {
          // Moving from medium to large
          this.$primaryInputField.val(this.$secondaryInputField.val());
        } else if (this.currentScreenSize === "large") {
          // Moving from large to medium
          this.$secondaryInputField.val(this.$primaryInputField.val());
        }
      }
    } else if (data.detail.guid !== this.guid) {
      if (this.settings.primaryInputId !== this.settings.inputId) {
        this.$secondaryInputField.val(this.$primaryInputField.val()); // replace small with value of large
      } else {
        this.$primaryInputField.val(this.$secondaryInputField.val()); // replace large with value of small
      }
    }
  };

  GizmoSearchBar.prototype.init = function () {
    this.setupHandlers();
    this.setupTriggers();
    this.disableSubmitButton();

    if (this.settings.autoCompleteEnabled) {
      this.setupAutocomplete();
    }

    Kobo._mediator.register("searchInput::viewResized");

    Kobo._mediator.subscribe("searchInput::viewResized", this.onViewResized); // We only need to sync input fields where there is more than one.


    if (this.$primaryInputField.length > 0 && this.$secondaryInputField.length > 0) {
      Kobo._mediator.register("searchInput::syncInputs");

      Kobo._mediator.subscribe("searchInput::syncInputs", this.onSyncInputs);
    } // It is possible that the input field is already on focus before JavaScript is loaded.
    // If so, we need to fire the 'focus' event manually,
    // as the JS will not detect the state trasition from 'unfocused' to 'focused'.


    if (this.currentScreenSize !== "small" && this.$inputField.is(':focus')) {
      this.$inputField.focus();
    }
  };

  GizmoSearchBar.prototype.destroy = function () {
    this.clearTriggers();

    Kobo._mediator.unSubscribe("searchInput::viewResized", this.reset);

    Kobo._mediator.unRegister("searchInput::viewResized");

    Kobo._mediator.unSubscribe("searchInput::clearInput", this.onClearInput);

    Kobo._mediator.unRegister("searchInput::clearInput");

    Kobo._mediator.unSubscribe("searchBar::toggle", this.onToggle);

    Kobo._mediator.unRegister("searchBar::toggle");
  };

  GizmoSearchBar.prototype.positionAutoComplete = function () {
    var _this2 = this;

    // CJ - To calculate the height correctly the animation needs to be completed,
    // there is no callback hook "onAnimationComplete" unfortunately, so the setTimeout is required
    setTimeout(function () {
      var padding = 10,
          width = _this2.$inputField.outerWidth(),
          windowHeight = Kobo.$window.height(),
          maxHeight = windowHeight - 4 * padding;

      var inputFieldDocumentOffset = _this2.$inputField.offset();

      var breakpoint = _this2.currentBreakpoint();

      if (breakpoint === "small" || breakpoint === "medium") {
        //set position to relative if small or medium
        _this2.$searchBarWrap.css({
          "position": "relative"
        });
      } else {
        _this2.$searchBarWrap.css({
          "position": "static"
        });
      }

      _this2.$autoComplete.css({
        "left": inputFieldDocumentOffset.left,
        "max-height": maxHeight,
        "width": width
      });
    }, 1500);
  };

  GizmoSearchBar.prototype.setupAutocompleteHandlers = function () {
    var _this3 = this;

    Kobo._mediator.register("searchInput::clearInput");

    Kobo._mediator.subscribe("searchInput::clearInput", function (event, data) {
      _this3.viewModels.AutoCompleteViewModel.clearResults();
    });

    this.events.onResize(function () {
      _this3.positionAutoComplete();
    });
    this.$inputField.on('blur', function () {
      _this3.viewModels.AutoCompleteViewModel.active(false);

      _this3.viewModels.AutoCompleteViewModel.unSelectAll(true);

      _this3.positionAutoComplete();
    });
    this.$inputField.on('focus', function () {
      _this3.viewModels.AutoCompleteViewModel.active(true);

      _this3.positionAutoComplete();
    });
    this.subscribe("toggleSearchHero", function () {
      _this3.positionAutoComplete();
    });
    this.$autoComplete.on({
      "mouseover": function mouseover() {
        _this3.viewModels.AutoCompleteViewModel.hover(true);
      },
      "mouseout": function mouseout() {
        _this3.viewModels.AutoCompleteViewModel.hover(false);
      }
    });
    this.$inputField.on({
      "keyup": function keyup() {
        _this3.viewModels.AutoCompleteViewModel.query(_this3.$inputField.val());
      },
      "keydown": function keydown(event) {
        switch (event.keyCode) {
          case Kobo.KeyCodes.RETURN:
            if (_this3.viewModels.AutoCompleteViewModel.currentSelected !== null && _this3.viewModels.AutoCompleteViewModel.isActive()) {
              _this3.viewModels.AutoCompleteViewModel.goToCurrentActive();

              event.preventDefault();
            } else {
              _this3.$form.trigger("submit");
            }

            break;

          case Kobo.KeyCodes.UP:
            _this3.viewModels.AutoCompleteViewModel.moveUp();

            event.preventDefault();
            break;

          case Kobo.KeyCodes.DOWN:
            _this3.viewModels.AutoCompleteViewModel.moveDown();

            event.preventDefault();
            break;
        }
      }
    });
  };

  GizmoSearchBar.prototype.setupAutocomplete = function () {
    "use strict";

    this.viewModels = {
      AutoCompleteViewModel: null
    };
    this.setupAutocompleteHandlers();
    this.instantiateViewModels();
  };

  GizmoSearchBar.prototype.instantiateViewModels = function () {
    "use strict";

    var autoCompleteEnabled = this.settings.autoCompleteEnabled;

    if (autoCompleteEnabled) {
      var ItemViewModel = Kobo.Object.resolveKoboNamespace("ViewModel.Search.AutoComplete.Item");

      var viewModelSettings = _objectSpread(_objectSpread({}, this.settings), {}, {
        ItemViewModelClass: ItemViewModel
      });

      var AutoCompleteViewModel = Kobo.Object.resolveKoboNamespace("ViewModel.Search.AutoComplete");

      if (!AutoCompleteViewModel) {
        var errorMsg = "Cannot instantiate listViewModel as [ViewModel.Search.AutoComplete]";
        this.error("instantiateViewModels", "SearchBar", this, errorMsg);
        throw new Error("[Kobo.Gizmo.ScrollingList.instantiateViewModels] " + errorMsg);
      }

      this.viewModels.AutoCompleteViewModel = new AutoCompleteViewModel(this.el, viewModelSettings);
      this.viewModels.AutoCompleteViewModel.query(this.$inputField.val());
    }
  };

  Kobo.Gizmo.SearchBar = GizmoSearchBar;
})(Kobo);