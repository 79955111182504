"use strict";

/*globals Kobo*/
Kobo.Gizmo.Slider = function (el) {
  'use strict';

  var self = this,
      $el = $(el),
      init;
  Kobo.Gizmo.apply(self, arguments);

  var createSlider = function createSlider() {
    $el.wrap("<span class='gizmo-slider'></span>");
    $el.after("<span class='slider-container'><span class='bar'><span></span></span><span class='bar-btn'><span></span></span></span>");
    $el.on('change', function () {
      Kobo.Gizmo.Slider.updateSlider($(this));
    }).off('mousedown touchstart').on('mousedown touchstart', function () {
      $(this).data('isMoving', true);
    }).off('mouseup touchend').on('mouseup touchend', function () {
      $(this).data('isMoving', false);
    });
    return $el;
  };

  init = function init() {
    createSlider();
  };

  init();
};

Kobo.Gizmo.Slider.updateSlider = function (obj) {
  var value = obj.val();
  var min = obj.attr('min');
  var max = obj.attr('max');
  var range = Math.round(max - min);
  var percentage = ((value - min) * 100 / range).toFixed(4);
  var nextObj = obj.next();
  var scrubber = nextObj.find('span.bar-btn');
  var rangeFilledLine = nextObj.find('span.bar > span');
  scrubber.css('left', "calc(".concat(percentage, "% - ").concat(scrubber.width() / 2, "px)"));
  rangeFilledLine.css('width', percentage + '%');
};

Kobo.Gizmo.Slider.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);