"use strict";

/*global Kobo,ko */
// singleton
Kobo._AgeVerification = function () {
  'use strict';

  var init,
      performConfirmation,
      completeConfirmation,
      setUpHandlers,
      displayHiddenContent,
      showAgeVerificationDialog,
      ageVerificationAjaxCall,
      hasVerifiedInSession = false,
      mediator = Kobo._mediator;

  performConfirmation = function performConfirmation(e, item) {
    showAgeVerificationDialog(item.detail);
  };

  showAgeVerificationDialog = function showAgeVerificationDialog(data) {
    var viewModel = new Kobo.AgeVerification.ViewModels.AgeVerificationVM();
    var $ageVerificationContentTemplate = Kobo.$('#age-verification-template');
    var $view = Kobo.$($ageVerificationContentTemplate.html());

    function cleanupModal() {
      Kobo._modal.close();

      $view.remove();
    }

    Kobo._modal.open($view, {
      theme: Kobo._modal.themes.PITCH_BLACK
    });

    viewModel.closeDialog.subscribe(function () {
      cleanupModal();
    });
    viewModel.confirmAgeVerification.subscribe(function () {
      cleanupModal();
      ageVerificationAjaxCall();
      completeConfirmation(data);
    });
    ko.applyBindings(viewModel, $view[0]);
  };

  completeConfirmation = function completeConfirmation(data) {
    hasVerifiedInSession = true;
    data.ageVerificationRequired = false;
    mediator.fire(data.caller, data);
  };

  ageVerificationAjaxCall = function ageVerificationAjaxCall() {
    Kobo.$.ajax({
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      url: "/AgeVerification/Confirm"
    }).done(function () {
      displayHiddenContent();
    });
  };

  displayHiddenContent = function displayHiddenContent() {
    document.body.classList.remove('hide-adult-content');
  };

  setUpHandlers = function setUpHandlers() {
    mediator.subscribe('ageVerificationCheck', performConfirmation);
    mediator.register('ageVerificationDialog::open', showAgeVerificationDialog);
  };

  init = function init() {
    setUpHandlers();
  };

  init();
  return {
    isRequired: function isRequired(isVerificationRequired) {
      if (isVerificationRequired === undefined || isVerificationRequired === 'false' || !isVerificationRequired) {
        return false;
      }

      return !hasVerifiedInSession;
    },
    completeConfirmation: completeConfirmation
  };
}();