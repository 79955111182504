"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/*global Kobo,ko*/
Kobo.Gizmo.ThankYou = function (element, options) {
  "use strict";

  var self = this,
      initiateDeviceDownload,
      init,
      initItemMessageHeight,
      itemMessageContainers = element.querySelectorAll(".item-message"),
      _getMaxItemMessageHeight,
      _setItemMessageHeight,
      targetContainer = element.querySelector(".thank-you-next-actions-container");

  Kobo.Gizmo.apply(this, arguments);
  self.setType("ThankYou");
  /**
  * @desc Initiate content download on a device, when gizmo is initialized
  * @param {string}[url] When thank you page loads, fires the kobo.event.firedeviceevent method if a device download URL is set on the gizmo.
  */

  initiateDeviceDownload = function initiateDeviceDownload() {
    if (options && options.deviceDownloadUrl) {
      Kobo.event.fireDeviceEvent(options.deviceDownloadUrl);
    }
  };
  /**
  * @desc Initiate .item-message elements height to make sure the web reader button and app download button are aligned on new thankyou page
  */


  initItemMessageHeight = function initItemMessageHeight() {
    var expectedHeight = _getMaxItemMessageHeight();

    if (expectedHeight) {
      _setItemMessageHeight(expectedHeight);
    }
  };

  _setItemMessageHeight = function _setItemMessageHeight(expectedHeight) {
    _toConsumableArray(itemMessageContainers).forEach(function (item) {
      item.style.height = "".concat(expectedHeight, "px");
    });
  };

  _getMaxItemMessageHeight = function _getMaxItemMessageHeight() {
    var containerHeights = [],
        expectedHeight;

    if (itemMessageContainers.length !== 0) {
      _toConsumableArray(itemMessageContainers).forEach(function (item) {
        containerHeights.push(item.clientHeight);
      });

      expectedHeight = Math.max.apply(Math, containerHeights);
    }

    return expectedHeight;
  };

  init = function init() {
    initiateDeviceDownload();
    initItemMessageHeight();
    Kobo.event.setRippleEffectEvent(targetContainer, 'button');
  };

  init();
};

Kobo.Gizmo.ThankYou.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);