"use strict";

/*global Kobo, $, window*/
// This gizmo creates a pull down drawer, activated by a handle. On opening, the contents of the handle slide out to the
// left of the screen, while the drawer contents slide in from the left. On closing, the reverse occurs. The handle and
// the drawer class identifiers are passed in via data-kobo-gizmo-config.
Kobo.Gizmo.SeriesDrawer = function (el, options) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      handle,
      drawer,
      destroy,
      series,
      addSeriesToCart,
      addSeriesComplete,
      init; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  self.setType('SeriesDrawer');

  self.openDrawer = function () {
    $el.addClass('open');
  };

  self.closeDrawer = function () {
    $el.removeClass('open');
  };

  self.triggerDrawerChange = function (event) {
    if (Kobo.$(event.target).parents('a').size() == 0) {
      // if there's an <a> in the tree, it takes priority.
      if (!$el.hasClass('open')) {
        Kobo._mediator.fire('seriesDrawer::openDrawer');
      } else {
        Kobo._mediator.fire('seriesDrawer::closeDrawer');
      }
    }
  };

  self.addSeriesToCart = function (event) {
    $el.find('.series-add-to-cart-text').html(DynamicConfiguration.resourceStrings.addingSeriesToCart);
    $el.find('.series-add-to-cart').addClass('series-adding-to-cart').removeClass('series-add-to-cart');
    $el.find('.spinner').addClass('animated-spinner-icon').addClass('spinner-vector');

    Kobo._mediator.fire('shoppingCartItems::addSeriesItems', {
      series: series
    });
  };

  self.addSeriesComplete = function (event) {
    $el.find('.series-add-to-cart-text').html(DynamicConfiguration.resourceStrings.addSeriesToCart);
    $el.find('.series-adding-to-cart').addClass('series-add-to-cart').removeClass('series-adding-to-cart');
    $el.find('.spinner').removeClass('animated-spinner-icon').removeClass('spinner-vector');
  };

  init = function init() {
    var errMsg;

    if (!self.settings.handle || !self.settings.drawer) {
      errMsg = 'ERROR: parameters missing for ' + self.type;
      Kobo.log(errMsg);
      return;
    }

    $el.find('.series-add-to-cart').on('click', self.addSeriesToCart);
    handle = $el.find('.' + self.settings.handle);
    drawer = $el.find('.' + self.settings.drawer);
    var seriesString = self.settings.seriesItems;
    series = seriesString.split(',').filter(function (item) {
      return item;
    });

    Kobo._mediator.register('seriesDrawer::openDrawer');

    Kobo._mediator.subscribe('seriesDrawer::openDrawer', self.openDrawer);

    Kobo._mediator.register('seriesDrawer::closeDrawer');

    Kobo._mediator.subscribe('seriesDrawer::closeDrawer', self.closeDrawer);

    Kobo._mediator.register('seriesDrawer::seriesAddComplete');

    Kobo._mediator.subscribe('seriesDrawer::seriesAddComplete', self.addSeriesComplete);

    $el.on('click', self.triggerDrawerChange);
  };

  destroy = function destroy() {
    Kobo._mediator.unSubscribe('seriesDrawer::openDrawer', self.openDrawer);

    Kobo._mediator.unRegister('seriesDrawer::openDrawer');

    Kobo._mediator.unSubscribe('seriesDrawer::closeDrawer', self.closeDrawer);

    Kobo._mediator.unRegister('seriesDrawer::closeDrawer');

    Kobo._mediator.unRegister('seriesDrawer::seriesAddComplete');

    Kobo._mediator.unSubscribe('seriesDrawer::seriesAddComplete', self.addSeriesComplete);

    $el.find('.series-add-to-cart').off('click', self.addSeriesToCart);
    $el.off('click', self.triggerDrawerChange);
  };

  init();
};

Kobo.Gizmo.SeriesDrawer.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);