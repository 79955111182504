"use strict";

/*global Kobo,ko*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.BillingAddressDisplay.ViewModels.CountryOnlyBillingAddressVM', CountryOnlyBillingAddressViewModel);

  function CountryOnlyBillingAddressViewModel(options) {
    var self = this;
    var country = options.country;
    self.displayType = "CountryOnly";
    self.country = ko.observable(country);
  }
})();