"use strict";

(function (Kobo) {
  function ViewModelExpandableDrawer(gizmo) {
    "use strict";

    var _this = this;

    Kobo.ViewModel.Expandable.apply(this, arguments);
    this.marginTop = "-" + gizmo.$innerContent.css('height');
    this.gizmo = gizmo;

    if (this.isCollapsed()) {
      gizmo.$innerMasker.css({
        'height': 0
      });
      gizmo.$innerContent.css({
        'margin-top': this.marginTop
      });
    } else {
      gizmo.$innerMasker.css({
        'height': gizmo.$innerContent.outerHeight() + 'px'
      });
      gizmo.$innerContent.css({
        'margin-top': 0
      });
    } // Bind event handlers


    this.toggle = this.toggle.bind(this);
    setTimeout(function () {
      return _this.applyTransitions;
    }, 600);
  }

  ViewModelExpandableDrawer.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.prototype); // This is a hack to get the parent class's `.toggle` method. Should definitely find a cleaner
  // way.

  ViewModelExpandableDrawer.prototype._superToggle = ViewModelExpandableDrawer.prototype.toggle;

  ViewModelExpandableDrawer.prototype.toggle = function (toggle, callback) {
    this.marginTop = "-" + this.gizmo.$innerContent.css('height');

    if (Kobo.Object.typeOf(toggle) === "boolean") {
      this._superToggle(toggle, callback);
    } else {
      this._superToggle(this.isCollapsed() ? this.gizmo.$innerContent.outerHeight() + 'px' : 0, callback);
    }

    if (this.isCollapsed()) {
      if (Modernizr.csstransitions) {
        this.gizmo.$innerMasker.css({
          'height': 0
        });
      } else {
        this.gizmo.$innerMasker.animate({
          height: 0
        });
      }

      if (Modernizr.csstransitions) {
        this.gizmo.$innerContent.css({
          'margin-top': this.marginTop
        });
      } else {
        this.gizmo.$innerContent.animate({
          marginTop: this.marginTop
        });
      }
    } else {
      if (Modernizr.csstransitions) {
        this.gizmo.$innerContent.css({
          'margin-top': 0
        });
      } else {
        this.gizmo.$innerContent.animate({
          marginTop: 0
        });
      }
    }
  };

  ViewModelExpandableDrawer.prototype.applyTransitions = function () {
    if (Modernizr.csstransitions) {
      this.gizmo.$innerContent.css(Modernizr.prefixed("transition", this.gizmo.$innerContent.prop('style'), false), "margin-top " + this.settings.duration);
    }
  };

  Kobo.ViewModel.Expandable.Drawer = ViewModelExpandableDrawer;
})(Kobo);