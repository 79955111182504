"use strict";

/*globals Kobo,ko*/
Kobo.Gizmo.OrangeRedirect = function () {
  'use strict';

  var self = this;
  Kobo.Gizmo.apply(self, arguments);

  function showActivatedOrangeWidget() {
    var $template = Kobo.$('#credit-from-orange-template');
    var $view = Kobo.$($template.html());
    self.viewModel.closeDialog.subscribe(function () {
      Kobo._modal.close();

      $view.remove();
    });

    Kobo._modal.open($view, {
      customClass: 'activated-offer-content-wrap'
    });

    ko.applyBindings(self.viewModel, $view[0]);
  }

  this.showWidgetOnMatchedQueryString = function (queryString) {
    if (queryString.indexOf("orangeSuccess=true") !== -1) {
      showActivatedOrangeWidget();
    }
  };

  this.viewModel = {
    closeDialog: ko.observable()
  };

  function init() {
    self.showWidgetOnMatchedQueryString(window.location.search);
  }

  init();
};

Kobo.Gizmo.OrangeRedirect.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);