"use strict";

/*global Kobo*/

/**
 * Drop-down button to trigger Kobo events
 *
 * The menu items must be set in the gizmo-config, before the gizmo is initialized.
 * The menu items are an array of objects containing the following properties:
 * {
 *   label: Human readable name for the menu item
 *   actionUrl: A url associated with the menu item. Optional, except for "navigate" events
 *   eventName: The Kobo event that is fired when the menu item is clicked. Required.
 *              A special event, "navigate", can be used to navigate the browser to the actionUrl,
 *              instead of firing a Kobo event.
 *   eventData: An object containing additional data to be fired with the event. Optional.
 *   trackingPayload: The data that gets sent to the server for click-tracking purposes. Optional.
 *   ranking: Related to the importance of the menu item. The ranking of the first menu item
 *            determines the colour of the button. Optional. Default is "primary".
 * }
 *
 * See JavaScriptAction.cs for the corresponding C# class to represent these menu items
 */
Kobo.Gizmo.MultiButton = function (gizmo, options) {
  'use strict';

  var self = this,
      createTemplate,
      setMenuItems,
      setupPrimaryMenuItem,
      refresh,
      setupDropDownHandlers,
      toggleDropDown,
      openDropDown,
      closeDropDown,
      showDropDownArrow,
      hideDropDownArrow,
      _onDropDownClosed,
      isActive,
      init; // inherit from base class


  Kobo.Gizmo.apply(this, arguments);
  self.setType('MultiButton');

  createTemplate = function createTemplate() {
    self.$rootButton = Kobo.$('<div class="multi-button"></div>');
    self.$primaryLink = Kobo.$('<a></a>');
    self.$dropDownArrow = Kobo.$('<a href="#" class="drop-down-button"' + 'aria-label="Toggle options"' + 'aria-controls="multibutton-drop-down"' + 'rel="nofollow">' + '</a>'); //TODO localize aria-label

    self.$rootButton.append(self.$primaryLink);
    self.$rootButton.append(self.$dropDownArrow);
    self.$gizmo.empty();
    self.$gizmo.append(self.$rootButton);
  };

  setMenuItems = function setMenuItems(menuItems) {
    if (!menuItems) {
      return;
    }

    if (!menuItems.length) {
      throw 'No menu items to set in the multibutton. At least one must be specified.';
    }

    self.menuItems = menuItems;
    refresh();
  };

  refresh = function refresh() {
    setupPrimaryMenuItem();

    if (self.menuItems.length > 1) {
      showDropDownArrow();
    } else {
      hideDropDownArrow();
    }

    self.fire('multiButtonDropDown::close'); // close drop-down because contents are no longer valid
  };

  setupPrimaryMenuItem = function setupPrimaryMenuItem() {
    var primaryMenuItem = self.menuItems[0],
        // primary menu item is the first item by convention
    cssClass;

    Kobo.Gizmo.MultiButton._dropDown.setupMenuItemLink(primaryMenuItem, self.$primaryLink); // set css


    if (primaryMenuItem.ranking === 'secondary') {
      cssClass = 'secondary-button';
    } else if (primaryMenuItem.ranking === 'tertiary') {
      cssClass = 'tertiary-button';
    } else {
      cssClass = 'primary-button';
    }

    self.$primaryLink.removeClass().addClass(cssClass);
  };

  showDropDownArrow = function showDropDownArrow() {
    self.$primaryLink.addClass('add-padding');
    self.$dropDownArrow.show();
  };

  hideDropDownArrow = function hideDropDownArrow() {
    self.$primaryLink.removeClass('add-padding');
    self.$dropDownArrow.hide();
  };
  /**
   * A multibutton is active if the drop down arrow has been clicked and the drop-down menu is showing under it
   */


  isActive = function isActive() {
    return self.$dropDownArrow.hasClass('active');
  };

  closeDropDown = function closeDropDown() {
    self.fire('multiButtonDropDown::close');
    self.$dropDownArrow.removeClass('active');
  };

  openDropDown = function openDropDown() {
    self.subscribe('multiButtonDropDown::closed', _onDropDownClosed);
    self.$dropDownArrow.addClass('active');
    self.fire('multiButtonDropDown::open', {
      menuItems: self.menuItems.slice(1),
      // exclude the first element that is already displayed in the root button
      anchor: self.$rootButton[0]
    });
  };

  toggleDropDown = function toggleDropDown(event) {
    if (isActive()) {
      closeDropDown();
    } else {
      openDropDown();
    }

    event.preventDefault();
    event.stopPropagation(); // prevent bubbling to document click handler, which will dismiss the drop down menu
  };

  _onDropDownClosed = function onDropDownClosed(eventName, data) {
    // check if the drop-down was closed for this multibutton, not some other one
    if (data.detail.anchor === self.$rootButton[0]) {
      self.unSubscribe('multiButtonDropDown::closed', _onDropDownClosed);
      self.$dropDownArrow.removeClass('active');
    }
  };

  setupDropDownHandlers = function setupDropDownHandlers() {
    self.$dropDownArrow.on('click', toggleDropDown);
  };

  self.destroy = function () {
    self.$primaryLink.off('click');
    self.unSubscribe('multiButtonDropDown::closed', _onDropDownClosed);
    self.$dropDownArrow.off('click', toggleDropDown);
  };

  init = function init() {
    createTemplate();
    setMenuItems(options.menuItems);
    setupDropDownHandlers();
  };

  init();
};

Kobo.Gizmo.MultiButton.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype); // Singleton that displays a menu list below a specified element.
// The latter element is known as the anchor element.
// Only one menu list can appear at a time. Thus, no closing animation is possible
// because the menu list may have to be opened immediately on another anchor element.

Kobo.Gizmo.MultiButton._dropDown = function () {
  'use strict';

  var init,
      createDropDownMenu,
      replaceMenuItems,
      setupMenuItemLink,
      onMenuItemClick,
      isOpen,
      open,
      _close,
      setClickTrackingInfo,
      positionTo,
      $dropDownMenu,
      $menuList,
      _anchor,
      // the element where the drop down is positioned under
  DROP_DOWN_ID = 'multibutton-drop-down';

  createDropDownMenu = function createDropDownMenu() {
    var $dropDownStem = Kobo.$('<div class="arrow"></div>');
    $dropDownMenu = Kobo.$('<div id="' + DROP_DOWN_ID + '"></div>');
    $menuList = Kobo.$('<ol aria-live="polite"></ol>');
    $dropDownMenu.append($dropDownStem);
    $dropDownMenu.append($menuList);
    Kobo.$body.append($dropDownMenu);
  };

  replaceMenuItems = function replaceMenuItems(menuItems) {
    var $listItem, $link, i;
    $menuList.empty(); // clear existing contents

    for (i = 0; i < menuItems.length; ++i) {
      $listItem = Kobo.$('<li></li>');
      $link = Kobo.$('<a></a>');
      setupMenuItemLink(menuItems[i], $link);
      $listItem.append($link);
      $menuList.append($listItem);
    }
  };

  setupMenuItemLink = function setupMenuItemLink(menuItem, $linkElement) {
    var eventName = menuItem.eventName,
        trackAttribute = Kobo._tracker.getTrackAttribute(),
        href = '#';

    if (!eventName) {
      throw 'Menu items must have an associated Kobo event';
    }

    if (eventName === 'navigate') {
      href = menuItem.actionUrl;

      if (!href) {
        throw 'Navigate events must provide an actionUrl';
      }
    } // set link attributes


    $linkElement.html(menuItem.label).attr('href', href).attr(trackAttribute, menuItem.trackingPayload).attr('rel', 'nofollow');
    $linkElement.click(menuItem, onMenuItemClick);
  };

  onMenuItemClick = function onMenuItemClick(event) {
    var menuItem = event.data,
        // menu item corresponding to the clicked element; passed in using jQuery 'on' function in setupMenuItemLink
    additionalData = {
      actionUrl: menuItem.actionUrl
    };

    if (menuItem.eventName === 'navigate') {
      return; // let the <a> click through and bring the user to wondrous pages
    }

    if (menuItem.eventData) {
      Kobo.$.extend(additionalData, menuItem.eventData);
    }

    Kobo._mediator.fire(menuItem.eventName, additionalData);

    event.preventDefault();
  }; // From the user's point of view, the dropdown menu appears to be part of the anchor element
  // but it is not implemented as such. The menu is a direct child of <body>. This is because
  // the menu needs to be shown over the containing element of the anchorElement.
  // Unfortunately, this breaks the context of click-tracking (see Tracker.js for how click-tracking works).
  // Here, we copy the click-tracking context of the anchorElement to the dropdown menu.


  setClickTrackingInfo = function setClickTrackingInfo(anchorElement) {
    var anchorContext = Kobo._tracker.aggregateTrackInfo(anchorElement),
        trackAttribute = Kobo._tracker.getTrackAttribute();

    if (Kobo.$.isEmptyObject(anchorContext)) {
      // remove any previously-set click tracking attribute, if any
      $dropDownMenu.removeAttr(trackAttribute);
    } else {
      $dropDownMenu.attr(trackAttribute, JSON.stringify(anchorContext));
    }
  };

  open = function open(menuItems, anchorElement) {
    var hasChangedAnchorElement = _anchor && _anchor !== anchorElement;

    if (!menuItems || !menuItems.length || !anchorElement) {
      throw 'Both menuItems and an anchor element have to be specified to display a drop-down';
    }

    if (hasChangedAnchorElement) {
      $dropDownMenu.css('transition', 'none'); // disable closing animation for current anchor

      _close();
    }

    replaceMenuItems(menuItems);
    _anchor = anchorElement;
    positionTo(_anchor);

    if (hasChangedAnchorElement) {
      setTimeout(function () {
        $dropDownMenu.css('transition', ''); // re-enable animations

        $dropDownMenu.addClass('show');
      }, 0); // give time for drop-down to layout under the new anchor
    } else {
      $dropDownMenu.addClass('show');
    }

    setClickTrackingInfo(anchorElement); // hide the drop down on click and touch move anywhere on the document, or when the window is resized

    Kobo.$body.on('click', _close);
    Kobo.$body.on('touchmove', _close);

    Kobo._mediator.subscribe(Kobo._mediator.defaultEventNames.resize, _close);
  };

  _close = function close() {
    $dropDownMenu.removeClass('show');

    if (_anchor) {
      Kobo._mediator.fire('multiButtonDropDown::closed', {
        anchor: _anchor
      });

      _anchor = null;
    } // remove listeners for closing drop-down


    Kobo._mediator.unSubscribe(Kobo._mediator.defaultEventNames.resize, _close);

    Kobo.$body.off('click', _close);
    Kobo.$body.off('touchmove', _close);
  };

  isOpen = function isOpen() {
    return $dropDownMenu.hasClass('show');
  };

  positionTo = function positionTo(anchorElement) {
    var $anchorElement = Kobo.$(anchorElement),
        top = $anchorElement.offset().top + $anchorElement.height() - 6,
        left = $anchorElement.offset().left;
    $dropDownMenu.width($anchorElement.width()); // match anchor's width

    $dropDownMenu.offset({
      top: top,
      left: left
    });
  };

  init = function init() {
    Kobo._mediator.register('multiButtonDropDown::open');

    Kobo._mediator.register('multiButtonDropDown::close');

    Kobo._mediator.register('multiButtonDropDown::closed');

    Kobo._mediator.subscribe('multiButtonDropDown::open', function (eventName, data) {
      open(data.detail.menuItems, data.detail.anchor);
    });

    Kobo._mediator.subscribe('multiButtonDropDown::close', _close);

    createDropDownMenu();
  };

  init();
  return {
    reset: function reset() {
      // used for teardown in unit tests
      _close();

      $dropDownMenu.remove();
      $dropDownMenu = $menuList = _anchor = null;
      createDropDownMenu();

      Kobo._mediator.unSubscribe(Kobo._mediator.defaultEventNames.resize, _close);
    },
    dropDownId: DROP_DOWN_ID,
    // for unit testing
    setupMenuItemLink: setupMenuItemLink // reused by Kobo.Gizmo.MultiButton

  };
}();