"use strict";

/*globals Kobo,ko*/
(function () {
  'use strict';

  function AudiobookPlanSwitcherVM(data) {
    var self = this;
    this.fromPlan = data.fromPlan;
    this.toPlan = data.toPlan;
    this.planDetails = data.planDetails;
    this.nextBillingDate = data.nextBillingDate;
    this.purchasePathUrl = data.purchasePathUrl;
    this.creditAmountString = data.creditAmountString;
    this.closeDialogEvent = new ko.subscribable();

    this.closeDialog = function () {
      self.closeDialogEvent.notifySubscribers();
    };
  }

  Kobo.Utilities.assignToNamespace('Kobo.Product.Audiobooks.ViewModels.AudiobookPlanSwitcherVM', AudiobookPlanSwitcherVM);
})();