"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,ko*/
Kobo.Gizmo.SavePaymentMethod = function (el, options) {
  "use strict";

  var self = this;
  var FailureReason = Kobo.SavePaymentMethod.FailureReason;
  var isCaptchaEnabled = options.IsCaptchaEnabled,
      caller = options.Caller;

  this.savePaymentMethod = function (data) {
    return new Promise(function (resolve, reject) {
      Kobo.$.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: options.SavePaymentMethodUrl,
        headers: {
          '__RequestVerificationToken': Kobo.Utilities.getAntiForgeryToken()
        },
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          caller: caller
        })),
        success: function success(response) {
          if (!response.IsSuccess) {
            if (response.NextStepUrl) {
              resolve({
                nextStepUrl: response.NextStepUrl
              });
              return;
            }

            reject(FailureReason.parseFromServer(response.FailureReason));
            return;
          }

          resolve();
        },
        error: reject
      });
    });
  };

  this.executeHCaptcha = function () {
    return new Promise(function (resolve, reject) {
      if (!isCaptchaEnabled) return resolve({
        response: ''
      });
      var data = {
        containerId: 'save-payment-method-captcha',
        successCallback: resolve,
        failureCallback: reject
      };

      Kobo._mediator.fire('hcaptcha::executeAsync', data);
    });
  };

  this.init = function () {
    var viewModelOptions = {
      paymentMethods: options.PaymentMethods,
      braintreeClientToken: options.BraintreeClientToken,
      savePaymentMethod: self.savePaymentMethod,
      returnUrl: options.ReturnUrl,
      messageBoxName: options.MessageBoxName,
      recordVerifyCardMetricsUrl: options.RecordVerifyCardMetricsUrl,
      stripeRedirectUrl: options.StripeRedirectUrl,
      executeHCaptcha: self.executeHCaptcha,
      caller: options.Caller
    };
    var viewModel = new Kobo.SavePaymentMethod.ViewModels.SavePaymentMethodVM(viewModelOptions);
    ko.applyBindings(viewModel, el);
  };

  this.init();
};

Kobo.Gizmo.SavePaymentMethod.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);