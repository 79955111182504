"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.PaymentMethodDisplayUpdate = function (el, options) {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("PaymentMethodDisplayUpdate");
  var viewModel;

  this.init = function () {
    var viewModelOptions = {
      messageBoxName: options.MessageBoxName,
      paymentMethods: options.PaymentMethods
    };
    viewModel = new Kobo.PaymentMethodsDisplay.ViewModels.PaymentMethodDisplayUpdateVM(viewModelOptions);
    ko.applyBindings(viewModel, el);
  };

  this.init();
};

Kobo.Gizmo.PaymentMethodDisplayUpdate.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);