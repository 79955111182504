"use strict";

/*globals Kobo,ko*/
(function () {
  'use strict';

  function AgeVerificationVM() {
    this.closeDialog = ko.observable();
    this.confirmAgeVerification = ko.observable();
  }

  Kobo.Utilities.assignToNamespace('Kobo.AgeVerification.ViewModels.AgeVerificationVM', AgeVerificationVM);
})();