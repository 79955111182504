"use strict";

/* globals ko, Kobo */
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.ViewModels.BraintreeValidationVM', BraintreeValidationVM);

  function BraintreeValidationVM() {
    var self = this;
    this.savePaymentInfoErrorMsg = ko.observable(DynamicConfiguration.resourceStrings.genericSavePaymentInfoErrorMsg);
    this.creditCardInvalidMsg = ko.observable(DynamicConfiguration.resourceStrings.creditCard.creditCardInvalidMsg);
    this.creditCardInvalidLengthMsg = ko.observable(DynamicConfiguration.resourceStrings.creditCard.creditCardInvalidLengthMsg);
    this.cvvInvalidMsg = ko.observable(DynamicConfiguration.resourceStrings.creditCard.cvvInvalidMsg);
    this.expiryDateInvalidMsg = ko.observable(DynamicConfiguration.resourceStrings.creditCard.expiryDateInvalidMsg);
    this.isCreditCardValid = ko.observable(true);
    this.isCVVValid = ko.observable(true);
    this.isExpirationDateValid = ko.observable(true);
    this.isPaymentValid = ko.observable(false);
    this.showSavePaymentErrorMsg = ko.observable(false);
    this.showTooltipContent = ko.observable(false);
    this.isTooltipClicked = ko.observable(false);
  }
})();